import { useContext } from "react";

import { Button } from "@mui/material";
import { RefreshIcon } from "../../assets/icons/svgAssets";

import { ProjectContext } from "../../context";
import { ModeTypeEnum } from "../../context/ProjectContext/ProjectEntitesTypes";

const ResetPositionButton = (): JSX.Element => {
  const { threeScene, sceneStarted, isLoading, secondaryCameraId, mode } =
    useContext(ProjectContext);

  const handleOnClick = () => {
    if (threeScene && threeScene.sceneControls.orbitControl) {
      threeScene.sceneControls.orbitControl.reset();

      mode === ModeTypeEnum.zoneView &&
        threeScene.sceneControls.orbitControl.setCameraPosition(500, 0, 0);

      mode === ModeTypeEnum.zoneView &&
        threeScene.sceneControls.orbitControl.setMinMaxPolarAngle(0, 0);
    }
  };

  const disabled = !!secondaryCameraId || !sceneStarted || isLoading;

  return (
    <Button
      variant="outlined"
      color="blue"
      disabled={disabled}
      onClick={handleOnClick}
      startIcon={<RefreshIcon />}
    >
      Reset my position
    </Button>
  );
};

export default ResetPositionButton;

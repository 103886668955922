import SvgIcon from "@mui/material/SvgIcon";
import { SxProps, Theme, useTheme } from "@mui/material/styles";

interface ILiveBadgeProps {
  sx?: SxProps<Theme>;
}

const LiveBadge = ({ sx }: ILiveBadgeProps): JSX.Element => {
  const theme = useTheme();

  return (
    <SvgIcon
      sx={[
        {
          width: "64px",
          height: "24px",
        },
        // You cannot spread `sx` directly because `SxProps` (typeof sx) can be an array.
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <svg
        width="64"
        height="24"
        viewBox="0 0 64 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="64" height="24" rx="12" fill="#071627" fillOpacity="0.6" />
        <circle cx="17" cy="12" r="5" fill={theme.palette.error.main} />
        <path
          d="M26.9588 16V7.27273H28.2756V14.8665H32.2301V16H26.9588ZM35.049 7.27273V16H33.7322V7.27273H35.049ZM37.7294 7.27273L40.1584 14.3807H40.2564L42.6854 7.27273H44.1087L40.968 16H39.4467L36.3061 7.27273H37.7294ZM45.369 16V7.27273H50.8406V8.40625H46.6857V11.0653H50.555V12.1946H46.6857V14.8665H50.8917V16H45.369Z"
          fill={theme.palette.otherBackgroundLight.main}
        />
      </svg>
    </SvgIcon>
  );
};

export default LiveBadge;

export const getDate = (): string => {
  const date = new Date();

  const day = date.getDate();
  const month = date.getMonth();
  const year = date.getFullYear();

  const dayText = day > 9 ? day : `0${day}`;
  const monthText = month > 8 ? month + 1 : `0${month + 1}`;

  return `${dayText}.${monthText}.${year}`;
};

import { Vector3 } from "three";

import SceneObjectBuilder from "./SceneObjectBuilder";
import TowerSide from "./TowerSide";

import { setMaterial } from "../SceneUtils/MaterilsUtils";
import { createGroup } from "../SceneUtils/CreateMesh";
import { caclulateBboxFromObject } from "../SceneUtils/GeometryUtils";

import TowerSideData from "../../data/TowerSideData";

class TowerBuilder extends SceneObjectBuilder {
  async getObjectInstance(objectData, envMap) {
    if (!this.objectTypes[objectData.pathKey]) {
      this.objectTypes[objectData.pathKey] = await Promise.all(
        objectData.assetsList.map(async assetData => {
          const object = await this.loadModel(assetData.path);

          object.castShadow = true;

          object.receiveShadow = true;

          await this.updateModelMaterial(object, assetData?.textures, envMap);

          return {
            objectToClone: object,
            materialToClone: object.children[0].material,
            name: assetData.name,
          };
        })
      );
    }

    return this.objectTypes[objectData.pathKey];
  }

  createObjectInstance(listOfObjectToClone, objectData, defaultObjectPosition) {
    const object = createGroup(objectData.name);

    object.ID = objectData.id;

    object.EntityType = objectData.EntityType;

    object.name = objectData.name;

    this.setRotation(object, objectData.rotation);

    this.setPosition(object, objectData.position, defaultObjectPosition);

    this.setObjectInitialQuaternion(object, objectData?.initialQuaternion);

    this.addTowerSections(object, listOfObjectToClone, objectData.height);

    this.addTowerSideWrapper(object);

    this.setObjectRenderOrder(object);

    return object;
  }

  addTowerSections(object, listOfObjectToClone, towerHeightInMeters) {
    const modelWrapper = createGroup(
      `${object.EntityType}-ModelWrapper`,
      object
    );

    const towerHeightInMetersFloor = Math.floor(towerHeightInMeters);

    const oneSectionHeightInMeters = 1;
    const partScale = towerHeightInMeters / towerHeightInMetersFloor;

    const addSectiontoTower = (sectionObjectData, positionY, partScale) => {
      const { objectToClone, materialToClone, name } = sectionObjectData;

      const towerPart = objectToClone.clone(true);

      towerPart.name = name;

      setMaterial(towerPart, materialToClone.clone());

      modelWrapper.add(towerPart);

      towerPart.position.y = positionY;

      towerPart.scale.y = partScale;
    };

    listOfObjectToClone.forEach(data => {
      if (data.name === "bottom") {
        addSectiontoTower(data, 0, partScale);
      } else if (data.name === "mid") {
        for (
          let positionY = 1;
          positionY < towerHeightInMetersFloor - oneSectionHeightInMeters;
          positionY++
        ) {
          addSectiontoTower(data, positionY * partScale, partScale);
        }
      } else if (data.name === "top") {
        addSectiontoTower(
          data,
          (towerHeightInMetersFloor - oneSectionHeightInMeters) * partScale,
          partScale
        );
      }
    });
  }

  addTowerSideWrapper(object) {
    const towerSideWrapper = createGroup("TowerSideWrapper", object);

    const size = new Vector3();
    const boundingBox = caclulateBboxFromObject(object);

    boundingBox.getSize(size);

    TowerSideData.forEach(data => {
      const sideObject = new TowerSide(data, size);

      towerSideWrapper.add(sideObject.object);
    });
  }
}

export default TowerBuilder;

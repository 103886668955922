import { FC } from "react";

import Box from "@mui/material/Box";

import { Group } from "../../API";
import {
  VideosTableSelectionDispatch,
  VideosTableSelectionState,
} from "../store/useVideosTableSelectionReducer";
import GroupCard from "./GroupCard";

interface IGroupCardsProps {
  groups: Group[];
  clientId: string;
  clientName: string;
  state: VideosTableSelectionState;
  dispatch: VideosTableSelectionDispatch;
  deleteGroup: (groupId: string, clientId: string) => void;
}

const GroupCards: FC<IGroupCardsProps> = ({
  groups,
  clientId,
  clientName,
  state,
  dispatch,
  deleteGroup,
}) => {
  return (
    <>
      {groups.map((group, index) => {
        const selectedRows =
          state.selectedRows.find(row => row.groupId === group.id)?.videos ??
          [];

        return (
          <Box key={group.id} sx={{ margin: ".5em 0" }}>
            <GroupCard
              groupIndex={index}
              group={group}
              clientId={clientId}
              clientName={clientName}
              selectedRows={selectedRows}
              dispatch={dispatch}
              deleteGroup={deleteGroup}
            />
          </Box>
        );
      })}
    </>
  );
};

export default GroupCards;

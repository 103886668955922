import { useEffect } from "react";
import { useLazyQuery } from "@apollo/client";

import { GET_CUSTOMER_DT_OBJECTS } from "../../../../common/operations/queries";
import { DTObject } from "../../../../API";

import { useCustomerIdGuard } from "../../../../common/hooks/useCustomerIdGuard";

export interface UseGetCustomerDTObjectsDataInterface {
  getCustomerDTObjects: DTObject[];
}

export interface UseGetCustomerDTObjectsInterface {
  data: UseGetCustomerDTObjectsDataInterface;
  loading: boolean;
}

export const useGetCustomerDTObjects = (): UseGetCustomerDTObjectsInterface => {
  const selectedCustomerId = useCustomerIdGuard();

  const [getCustomerDTObjects, { data, loading }] = useLazyQuery(
    GET_CUSTOMER_DT_OBJECTS,
    {
      fetchPolicy: "network-only",
      refetchWritePolicy: "merge",
    }
  );

  useEffect(() => {
    if (selectedCustomerId) {
      fetchDTObjects();
    }
  }, [selectedCustomerId]);

  const fetchDTObjects = () => {
    getCustomerDTObjects({
      variables: {
        customerId: selectedCustomerId,
      },
    });
  };

  return { data, loading };
};

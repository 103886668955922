import { HTMLAttributes } from "react";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import { Box } from "@mui/material";

type CustomPaperComponentProps = {
  text: string;
  onClick: () => void;
};

const withCustomPaper = ({ text, onClick }: CustomPaperComponentProps) => {
  return (props: HTMLAttributes<HTMLElement>) => (
    <Paper {...props} onMouseDown={event => event.preventDefault()}>
      {props.children}

      <Box
        sx={{
          width: "100%",
          height: "36px",
          display: "flex",
          justifyContent: "flex-end",
          boxShadow: "0px 4px 20px 0px #0816331A",
        }}
      >
        <Button
          sx={{
            height: "36px",
            textTransform: "none",
            padding: "8px",
            color: (theme): string => theme.palette.text.primary,
            backgroundColor: "inherit",
            "&:hover": {
              backgroundColor: "inherit",
            },
            borderRadius: 0,
          }}
          variant="text"
          startIcon={<AddIcon />}
          color="primary"
          onClick={onClick}
        >
          {text}
        </Button>
      </Box>
    </Paper>
  );
};

export default withCustomPaper;

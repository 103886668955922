import { ApolloError, useMutation } from "@apollo/client";

import { UPDATE_DT_PROJECT } from "../../../../common/operations/mutations";
import { DTProject, UpdateDTProjectInput } from "../../../../API";

export interface UseUpdateDTProjectDataInterface {
  updateDTProject: DTProject | null;
}

export interface UseUpdateDTProjectInterface {
  updateDTProject: (input: UpdateDTProjectInput) => Promise<any>;
  data: UseUpdateDTProjectDataInterface;
  loading: boolean;
  error: ApolloError | undefined;
}

export const useUpdateDTProject = () => {
  const [updateDTProjectMutation, { data, loading, error }] =
    useMutation(UPDATE_DT_PROJECT);

  const updateDTProject = async (input: UpdateDTProjectInput): Promise<any> => {
    return await updateDTProjectMutation({
      variables: {
        input,
      },
    });
  };

  return { updateDTProject, data, error, loading };
};

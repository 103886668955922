import { ReactNode } from "react";

import Box from "@mui/material/Box";

const MediaErrorBoxStyled = ({ children }: { children: ReactNode }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: theme => theme.palette.otherBackground.main,
      }}
    >
      {children}
    </Box>
  );
};

export default MediaErrorBoxStyled;

import { FC, useEffect, useRef } from "react";
import Hls from "hls.js";
import { videoPlayerVariable } from "../../../../../../common/variables/videoPlayer";

interface LiveStreamPlayerProps {
  streamUrl: string;
  setDialogStyles?: boolean;
}

const LiveStreamPlayer: FC<LiveStreamPlayerProps> = ({
  streamUrl,
  setDialogStyles,
}): JSX.Element => {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    let hls: Hls;

    if (videoRef.current) {
      if (Hls.isSupported()) {
        hls = new Hls({
          liveSyncDuration: 1,
          liveBackBufferLength: 0,
          maxBufferLength: 5,
          maxMaxBufferLength: 5,
          liveDurationInfinity: true,
        });

        hls.loadSource(streamUrl);

        hls.attachMedia(videoRef.current);

        hls.on(Hls.Events.MANIFEST_PARSED, () => {
          videoRef.current
            ?.play()
            .then(() => {
              videoPlayerVariable(true);
            })
            .catch((error: any) =>
              console.error("Error playing video:", error)
            );
        });
      } else if (
        videoRef.current.canPlayType("application/vnd.apple.mpegurl")
      ) {
        videoRef.current.src = streamUrl;

        videoRef.current?.addEventListener("loadedmetadata", () => {
          videoRef.current
            ?.play()
            .then(() => {
              videoPlayerVariable(true);
            })
            .catch((error: any) =>
              console.error("Error playing video:", error)
            );
        });
      }

      // Prevent user from seeking backward
      videoRef.current?.addEventListener("seeking", () => {
        if (hls && videoRef.current) {
          const liveEdge = hls.liveSyncPosition || videoRef.current.duration;

          if (videoRef.current.currentTime < liveEdge) {
            videoRef.current.currentTime = liveEdge;
          }
        }
      });

      // Ensure playback stays at live edge
      const liveEdgeInterval = setInterval(() => {
        if (hls && videoRef.current) {
          const liveEdge = hls.liveSyncPosition || videoRef.current.duration;

          if (videoRef.current.currentTime < liveEdge - 1) {
            videoRef.current.currentTime = liveEdge;
          }
        }
      }, 1000); // Check every second

      return () => {
        hls?.destroy();

        clearInterval(liveEdgeInterval);
      };
    }
  }, [streamUrl]);

  return (
    <video
      ref={videoRef}
      autoPlay
      muted
      playsInline
      style={{
        width: "100%",
        height: "100%",
        borderRadius: setDialogStyles ? "0px" : "8px",
        objectFit: "fill",
      }}
    >
      Your browser does not support the video tag.
    </video>
  );
};

export default LiveStreamPlayer;

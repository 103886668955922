import { CssBaseline, ThemeProvider } from "@mui/material";

import { theme } from "./Theme";

interface MuiThemeProviderProps {
  children: React.ReactNode;
}

const WebglMuiThemeProvider = ({
  children,
}: MuiThemeProviderProps): JSX.Element => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

export default WebglMuiThemeProvider;

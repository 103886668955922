import { styled, useTheme } from "@mui/material";
import Box from "@mui/material/Box";

import { SceneObject3dContextMenuAnchorInterface } from "../../context/SceneObject3dContextMenuContext/SceneObject3dContextMenuContext";
import {
  GapBlock,
  HintText,
  MenuTitle,
  SeparatorLine,
  SeparatorWrapper,
  StyledMenu,
  StyledMenuItem,
} from "../../../../common/components/styledComponents/styledContextMenu/ContextMenuStyledComponents";

import { CopyIcon } from "../../../../common/assets/icons/CopyIcon";
import { PasteIcon } from "../../../../common/assets/icons/PasteIcon";
import { DeleteIcon } from "../../../../common/assets/icons/svgAssets";

interface SceneObject3dContextMenuButtonInterface {
  onClick: () => void;
  disabled: boolean;
}

interface SceneObject3dContextMenuInterface {
  contextMenuAnchor: SceneObject3dContextMenuAnchorInterface;
  onClose: () => void;
  copyButtonSettings: SceneObject3dContextMenuButtonInterface;
  pasteButtonSettings: SceneObject3dContextMenuButtonInterface;
  deleteButtonSettings: SceneObject3dContextMenuButtonInterface;
}

const SceneObject3dContextMenu = ({
  contextMenuAnchor,
  onClose,
  copyButtonSettings,
  pasteButtonSettings,
  deleteButtonSettings,
}: SceneObject3dContextMenuInterface): JSX.Element => {
  const theme = useTheme();

  return (
    <Wrapper onContextMenu={e => e.preventDefault()}>
      <StyledMenu
        open={!!contextMenuAnchor}
        onClose={onClose}
        anchorReference="anchorPosition"
        anchorPosition={{
          left: contextMenuAnchor.left,
          top: contextMenuAnchor.top,
        }}
      >
        <StyledMenuItem
          disabled={copyButtonSettings.disabled}
          onClick={copyButtonSettings.onClick}
        >
          <CopyIcon fontSize="small" />
          <MenuTitle variant="body14Regular">Copy</MenuTitle>
          <GapBlock />
          <HintText>⌘+C/Ctrl+C</HintText>
        </StyledMenuItem>
        <StyledMenuItem
          disabled={pasteButtonSettings.disabled}
          onClick={pasteButtonSettings.onClick}
        >
          <PasteIcon fontSize="small" />
          <MenuTitle variant="body14Regular">Paste</MenuTitle>
          <GapBlock />
          <HintText>⌘+V/Ctrl+V</HintText>
        </StyledMenuItem>

        <SeparatorWrapper>
          <SeparatorLine />
        </SeparatorWrapper>

        <StyledMenuItem
          disabled={deleteButtonSettings.disabled}
          onClick={deleteButtonSettings.onClick}
        >
          <DeleteIcon size={20} color={theme.palette["base-label"]} />
          <MenuTitle variant="body14Regular">Delete</MenuTitle>
          <GapBlock />
          <HintText>⌘+Backspace/Ctrl+Del</HintText>
        </StyledMenuItem>
      </StyledMenu>
    </Wrapper>
  );
};

export default SceneObject3dContextMenu;

const Wrapper = styled(Box)(() => ({
  position: "absolute",
  right: 0,
  top: 0,
}));

import { useContext, useEffect } from "react";

import TextInput from "../../input/TextInput";

import { useTextInputState } from "../../../hooks/useTextInputState";
import { CameraDataInterface } from "../../../context/ProjectContext/ProjectEntitesTypes";
import { ProjectContext } from "../../../context";

import { textNameValidation } from "../../../validation";

interface CameraNameInputInterface {
  activeCamera: CameraDataInterface;
}

const CameraNameInput = ({ activeCamera }: CameraNameInputInterface) => {
  const { updateCamera, activeCameraId } = useContext(ProjectContext);
  const cameraName = useTextInputState(
    {
      value: activeCamera.name,
      error: false,
      errorMessage: "",
      trackError: true,
    },
    textNameValidation
  );

  const handleOnNameChange = (value: string) => {
    const isError = cameraName.onChange(value);

    if (!isError) {
      updateCamera(activeCamera.id, { name: value });
    }
  };

  useEffect(() => {
    cameraName.resetState({
      value: activeCamera.name,
      error: false,
      errorMessage: "",
      trackError: true,
    });
  }, [activeCameraId]);

  const invalid = cameraName.state.error;

  return (
    <TextInput
      label="Camera name"
      value={cameraName.state.value}
      isError={invalid}
      handleOnChange={handleOnNameChange}
    />
  );
};

export default CameraNameInput;

import { useState } from "react";

interface ValidationResponseInterface {
  error: boolean;
  errorMessage: string;
}

export interface MultiSelectStateInterface {
  value: string[];
  placeholder: string;
  options: string[];
  error: boolean;
  errorMessage: string;
  trackError: boolean;
}

export interface UseMultiSelectStateInputInterface {
  onChange: (text: string[]) => boolean;
  resetState: (state?: MultiSelectStateInterface) => void;
  state: MultiSelectStateInterface;
}

export const useMultiSelectInputState = (
  defaultState: MultiSelectStateInterface
): UseMultiSelectStateInputInterface => {
  const [state, setState] = useState<MultiSelectStateInterface>(defaultState);

  const doValidation = (value: string[]): ValidationResponseInterface => {
    return {
      error: !value.length,
      errorMessage: "Select option",
    };
  };

  const onChange = (value: string[]): boolean => {
    const { error, errorMessage } = doValidation(value);

    setState({
      value: value,
      placeholder: defaultState.placeholder,
      options: defaultState.options,
      error,
      errorMessage,
      trackError: true,
    });

    return error;
  };

  const resetState = (newState?: MultiSelectStateInterface): void => {
    setState(newState || defaultState);
  };

  return { onChange, state, resetState };
};

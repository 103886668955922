import { useContext } from "react";
import { Box, Stack, Typography, styled } from "@mui/material";

import CameraNameInput from "./CameraNameInput";
import CameraControlPanelCameraSettings from "./CameraControlPanelCameraSettings";
import TurnOnCameraViewButton from "./TurnOnCameraViewButton";
import TurnOnCameraGasViewButton from "./TurnOnCameraGasViewButton";
import DeleteCameraButton from "./DeleteCameraButton";
import AddZoneButton from "./AddZoneButton";

import {
  ConfiguratorPermissionContext,
  ProjectContext,
} from "../../../context";

import { CamerasType } from "../../../data/CamerasData";

import { CameraDataInterface } from "../../../context/ProjectContext/ProjectEntitesTypes";

const CameraControlPanel = (): JSX.Element => {
  const { cameras, activeCameraId, activeZoneId } = useContext(ProjectContext);
  const { isAllowEditing } = useContext(ConfiguratorPermissionContext);

  const activeCamera = cameras.find(
    (camera: CameraDataInterface) => camera.id === activeCameraId
  );

  return (
    <>
      {activeCameraId && activeCamera && !activeZoneId ? (
        <CameraPanelWrapper>
          <CameraPanelHeader>
            <CameraTitle variant="body12Medium">
              {activeCamera.name}
            </CameraTitle>
          </CameraPanelHeader>
          <CameraPanelMain>
            <CameraNameInput activeCamera={activeCamera} />
            <CameraControlPanelCameraSettings activeCamera={activeCamera} />

            <Stack direction="row" justifyContent="space-between">
              <TurnOnCameraViewButton cameraType={activeCamera.cameraType} />

              {activeCamera.cameraType === CamerasType.MinervaGasCamera && (
                <TurnOnCameraGasViewButton />
              )}

              {isAllowEditing && <DeleteCameraButton />}
            </Stack>

            {activeCamera.cameraType !== CamerasType.MinervaCamera && (
              <AddZoneButton />
            )}
          </CameraPanelMain>
        </CameraPanelWrapper>
      ) : null}
    </>
  );
};

export default CameraControlPanel;

const CameraPanelWrapper = styled(Box)(({ theme }) => ({
  position: "absolute",
  left: 0,
  bottom: "72px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "start",
  backgroundColor: theme.palette["base-background"],
  border: `1px solid ${theme.palette["grey-dark"]}`,
  borderRadius: "4px",
  width: "243px",
  overflow: "hidden",
}));

const CameraPanelHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  background: theme.palette["blue-background"],
  padding: "2px 10px",
}));

const CameraTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.base.white,
}));

const CameraPanelMain = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  padding: "6px 8px",
  gap: "8px",
}));

import { useMemo } from "react";

import { useQuery } from "@apollo/client";

import {
  GetUserByIdQuery,
  GetUserByIdQueryVariables,
  UserSettingsInput,
} from "../../API";
import { useAuthenticatedUser } from "../../common/hooks/useAuthenticatedUser";
import { useCustomerIdGuard } from "../../common/hooks/useCustomerIdGuard";
import { GET_USER_BY_ID } from "../../common/operations/queries";

export const useGetUser = () => {
  const selectedCustomerId = useCustomerIdGuard();

  const { userSettings: defaultUserSettings, user } = useAuthenticatedUser();

  const userId = `U#${user?.attributes?.sub as string}`;

  const { data, loading, refetch } = useQuery<
    GetUserByIdQuery,
    GetUserByIdQueryVariables
  >(GET_USER_BY_ID, {
    variables: {
      customerId: selectedCustomerId,
      userId,
    },
    skip: !userId || !selectedCustomerId,
  });

  const memoizedValue = useMemo(() => {
    let userSettings: UserSettingsInput | null = null;

    try {
      if (data?.getUserById.userSettings) {
        const settings = JSON.parse(data?.getUserById.userSettings);

        userSettings = {
          defaultCameraFov:
            +settings?.defaultCameraFov ??
            defaultUserSettings?.defaultCameraFov,
          defaultWindspeedUnit:
            settings?.defaultWindspeedUnit ??
            defaultUserSettings?.defaultWindspeedUnit,
          defaultVideoSpeed:
            +settings?.defaultVideoSpeed ??
            defaultUserSettings?.defaultVideoSpeed,
        };

        return {
          ...data?.getUserById,
          userSettings: userSettings,
        };
      }
    } catch (error) {
      console.error(error);

      return {
        ...data?.getUserById,
        userSettings: null,
      };
    }
  }, [data]);

  return { userInfo: memoizedValue, loading, refetch };
};

import { useState } from "react";

interface ValidationResponseInterface {
  error: boolean;
  errorMessage: string;
}

export interface SelectStateInterface {
  value: string;
  placeholder: string;
  options: string[];
  error: boolean;
  errorMessage: string;
  trackError: boolean;
}

export interface UseSelectInputStateInterface {
  onChange: (text: string) => boolean;
  resetState: (state?: SelectStateInterface) => void;
  state: SelectStateInterface;
}

export const useSelectInputState = (
  defaultState: SelectStateInterface
): UseSelectInputStateInterface => {
  const [state, setState] = useState<SelectStateInterface>(defaultState);

  const doValidation = (value: string): ValidationResponseInterface => {
    return {
      error: !value,
      errorMessage: "Select option",
    };
  };

  const onChange = (value: string): boolean => {
    const { error, errorMessage } = doValidation(value);

    setState({
      value: value,
      placeholder: defaultState.placeholder,
      options: defaultState.options,
      error,
      errorMessage,
      trackError: true,
    });

    return error;
  };

  const resetState = (newState?: SelectStateInterface): void => {
    setState(newState || defaultState);
  };

  return { onChange, state, resetState };
};

import { Box, Button, List, ListItem, Typography, styled } from "@mui/material";

import { EyeOffIcon, EyeOnIcon } from "../../../assets/icons/svgAssets";

import { CameraHelpersInterface } from "../../../context/ProjectContext/ProjectEntitesTypes";

interface FieldOfCamerasViewControlPanelInterface {
  cameraHelpersList: CameraHelpersInterface[];
  onButtonToggleClick: (isVisibleFieldOfView: boolean) => void;
  onItemclick: (id: string) => void;
}

const FieldOfCamerasViewControlPanel = ({
  cameraHelpersList,
  onButtonToggleClick,
  onItemclick,
}: FieldOfCamerasViewControlPanelInterface): JSX.Element => {
  const handleOnShowAllClick = () => {
    onButtonToggleClick(true);
  };

  const handleOnHideAllClick = () => {
    onButtonToggleClick(false);
  };

  const showAllDisabled = cameraHelpersList.every(
    data => data.isVisibleFieldOfView
  );
  const hideAllDisabled = cameraHelpersList.every(
    data => !data.isVisibleFieldOfView
  );

  return (
    <Wrapper>
      <StyledList>
        {cameraHelpersList.map(data => (
          <StyledListItem key={data.id} onClick={() => onItemclick(data.id)}>
            {data.isVisibleFieldOfView ? (
              <EyeOnIcon size={20} color="#202832" />
            ) : (
              <EyeOffIcon size={20} color="#202832" />
            )}
            <CameraName variant="body14Regular">{data.name}</CameraName>
          </StyledListItem>
        ))}
      </StyledList>
      <ButtonWrapper>
        <Button
          variant="outlined"
          color="blue"
          disabled={showAllDisabled}
          onClick={handleOnShowAllClick}
        >
          Show all
        </Button>
        <Button
          variant="outlined"
          color="blue"
          disabled={hideAllDisabled}
          onClick={handleOnHideAllClick}
        >
          Hide all
        </Button>
      </ButtonWrapper>
    </Wrapper>
  );
};

export default FieldOfCamerasViewControlPanel;

const Wrapper = styled(Box)(({ theme }) => ({
  position: "absolute",
  bottom: "36px",
  left: "0px",

  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-end",
  width: "100%",

  borderRadius: "4px",
  boxShadow: "0px 6px 12px 0px rgba(0, 0, 0, 0.10)",
  backgroundColor: theme.palette.base.white,
  padding: "4px",
}));

const StyledList = styled(List)(({ theme }) => ({
  padding: "0px",
  maxHeight: "180px",
  overflowY: "auto",

  "&::-webkit-scrollbar": {
    width: "7px",
    height: "7px",
  },

  "&::-webkit-scrollbar-track": {
    background: "transparent",
  },

  "&::-webkit-scrollbar-thumb": {
    backgroundColor: theme.palette["blue-scrollbar"],
    borderRadius: "30px",
  },
}));

const StyledListItem = styled(ListItem)(() => ({
  display: "flex",
  padding: "8px",
  alignItems: "center",
  gap: "8px",
  cursor: "pointer",
  flexDirection: "row-reverse",

  "&:hover": {
    textDecorationLine: "underline",
  },
}));

const CameraName = styled(Typography)(({ theme }) => ({
  color: theme.palette["navy-blue"],

  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
  width: "calc(100% - 30px)",
}));

const ButtonWrapper = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  padding: "8px 4px",
}));

export const formatJsonString = (data: string): string => {
  const parsedToCorrectString = JSON.parse(JSON.stringify(data));
  const parseToObject = JSON.parse(parsedToCorrectString);

  return JSON.stringify(parseToObject, null, 2);
};

/**
 * The mediaOutput could either look like this /C_cleanconnect_L_office_N_office-platform-bigguns/tanklevel-office-flir-tlm/media/2024/04/15/15/15_01_18_out.jpg
 * or like this "\"/C_cleanconnect_L_office_N_office-platform-bigguns/tanklevel-office-flir1/media/2024/04/09/13/13_41_06_out.jpg\""
 *
 * This function will parse the string if it need to be parsed
 * @param str
 * @return parsed string or the original string if it does not need parsing
 */

export const parseEventMediaPath = (str: string): string => {
  try {
    return JSON.parse(str);
  } catch (_error) {
    return str;
  }
};

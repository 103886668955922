import { CommonColors, PaletteColorOptions, createTheme } from "@mui/material";
import { TypographyStyleOptions } from "@mui/material/styles/createTypography";

import { ThemePalette } from "./ThemePalette";
import { ThemeTypography } from "./ThemeTypography";
import { ThemeMuiButton } from "./ThemeMuiButton";
import { ThemeMuiToggleButton } from "./ThemeMuiToggleButton";
import { ThemeMuiOutlinedInput } from "./ThemeMuiOutlinedInput";
import { ThemeMuiFilledInput } from "./ThemeMuiFilledInput";
import { ThemeMuiChip } from "./ThemeMuiChip";

export type CustomPaletteColor = "blue" | "green" | "yellow" | "red";

export const convertToPaletteColor = (color: string) =>
  color as CustomPaletteColor;

declare module "@mui/material/styles" {
  interface PaletteColor {
    lighter?: string;
  }

  interface SimplePaletteColorOptions {
    lighter?: string;
  }
}

declare module "@mui/material/styles" {
  interface Palette {
    blue: PaletteColor;
    green: PaletteColor;
    yellow: PaletteColor;
    red: PaletteColor;
    base: Partial<CommonColors>;
    "base-background": string;
    "base-background-lighter": string;
    "base-divider": string;
    "base-hint": string;
    "base-label": string;
    "base-placeholder": string;
    "box-shadow": string;
    "blue-background": string;
    "blue-scrollbar": string;
    "error-alert-background": string;
    "error-alert-border": string;
    "grey-dark": string;
    "grey-light": string;
    "info-alert-background": string;
    "info-alert-border": string;
    "navy-blue": string;
    "primary-text": string;
    "success-alert-background": string;
    "success-alert-border": string;
    "tower-header-border": string;
    "tower-selected-background": string;
    viewport: string;
    "warning-alert-background": string;
    "warning-alert-border": string;
  }

  interface PaletteOptions {
    blue?: PaletteColorOptions;
    green?: PaletteColorOptions;
    yellow?: PaletteColorOptions;
    red?: PaletteColorOptions;
    base?: Partial<CommonColors>;
    "base-background"?: string;
    "base-background-lighter"?: string;
    "base-divider"?: string;
    "base-hint"?: string;
    "base-label"?: string;
    "base-placeholder"?: string;
    "box-shadow"?: string;
    "blue-background"?: string;
    "blue-scrollbar"?: string;
    "error-alert-background"?: string;
    "error-alert-border"?: string;
    "grey-dark"?: string;
    "grey-light"?: string;
    "info-alert-background"?: string;
    "info-alert-border"?: string;
    "navy-blue"?: string;
    "primary-text"?: string;
    "success-alert-background"?: string;
    "success-alert-border"?: string;
    "tower-header-border"?: string;
    "tower-selected-background"?: string;
    viewport?: string;
    "warning-alert-background"?: string;
    "warning-alert-border"?: string;
  }
}

declare module "@mui/material/styles" {
  interface TypographyVariants {
    title: TypographyStyleOptions;
    body20Medium: TypographyStyleOptions;
    body16Medium: TypographyStyleOptions;
    body14Medium: TypographyStyleOptions;
    body12Medium: TypographyStyleOptions;
    body10Medium: TypographyStyleOptions;
    body8Medium: TypographyStyleOptions;
    body14Regular: TypographyStyleOptions;
    body12Regular: TypographyStyleOptions;
    body12RegularItalic: TypographyStyleOptions;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    title?: TypographyStyleOptions;
    body20Medium?: TypographyStyleOptions;
    body16Medium?: TypographyStyleOptions;
    body14Medium?: TypographyStyleOptions;
    body12Medium?: TypographyStyleOptions;
    body10Medium?: TypographyStyleOptions;
    body8Medium?: TypographyStyleOptions;
    body14Regular?: TypographyStyleOptions;
    body12Regular?: TypographyStyleOptions;
    body12RegularItalic?: TypographyStyleOptions;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    title: true;
    body20Medium: true;
    body16Medium: true;
    body14Medium: true;
    body12Medium: true;
    body10Medium: true;
    body8Medium: true;
    body14Regular: true;
    body12Regular: true;
    body12RegularItalic: true;
  }
}

declare module "@mui/material/Button" {
  interface ButtonClasses {
    primary: true;
    secondary: true;
  }

  interface ButtonPropsVariantOverrides {
    primary: true;
    secondary: true;
  }

  interface ButtonPropsColorOverrides {
    blue: true;
    green: true;
    red: true;
  }
}

declare module "@mui/material/ToggleButton" {
  interface ToggleButtonPropsColorOverrides {
    blue: true;
    green: true;
    red: true;
  }
}

declare module "@mui/material/Chip" {
  interface ChipPropsColorOverrides {
    blue: true;
    green: true;
    red: true;
  }
}

export const theme = createTheme({
  palette: ThemePalette,
  typography: ThemeTypography,
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          color: ThemePalette["primary-text"],
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: ThemeMuiButton,
    },
    MuiToggleButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: ThemeMuiToggleButton,
    },
    MuiOutlinedInput: {
      styleOverrides: ThemeMuiOutlinedInput,
    },
    MuiFilledInput: {
      defaultProps: {
        disableUnderline: true,
      },
      styleOverrides: ThemeMuiFilledInput,
    },
    MuiChip: {
      styleOverrides: ThemeMuiChip,
    },
  },
});

import { PaletteOptions } from "@mui/material";

export const ThemePalette = {
  blue: {
    main: "#3483EB",
    lighter: "#F0F4FD",
    light: "#0C64D9",
    dark: "#06182D",
    contrastText: "#FFFFFF",
  },
  green: {
    main: "#58BF62",
    lighter: "#F9FDFA",
    light: "#5CA762",
    dark: "#37663B",
    contrastText: "#FFFFFF",
  },
  yellow: {
    main: "#FFD747",
  },
  red: {
    main: "#CF1E1E",
    lighter: "#E17575",
    light: "#E17575",
    dark: "#7C1212",
    contrastText: "#FFFFFF",
  },
  base: {
    black: "#000000",
    white: "#FFFFFF",
  },
  divider: "#98A7BC",
  "base-background": "#F5F5F5",
  "base-background-lighter": "#F9F9F9",
  "base-hint": "#787878",
  "base-label": "#202832",
  "base-placeholder": "#B3B3B3",
  "box-shadow": "#0000001a",
  "blue-background": "#326CFB",
  "blue-scrollbar": "#E0E9FE",
  "error-alert-background": "#FFF5F5",
  "error-alert-border": "#FDB6B6",
  "grey-dark": "#999999",
  "grey-light": "#D9D9D9",
  "info-alert-background": "#E0E9FE",
  "info-alert-border": "#82A4F9",
  "navy-blue": "#3C4F66",
  "primary-text": "#0D254A",
  "success-alert-background": "#F9FDFA",
  "success-alert-border": "#CEEED1",
  "tower-header-border": "#5298E5",
  "tower-selected-background": "rgba(255, 255, 255, 0.8)",
  viewport: "#06182D4D",
  "warning-alert-background": "#FFFAD6",
  "warning-alert-border": "#FDF5B6",
} as PaletteOptions;

import { FC } from "react";

import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { useConfirm } from "material-ui-confirm";

import CloseIcon from "../../../common/components/icons/CloseIcon";
import confirmDialogStyleOptions from "../../../common/helpers/confirmDialogParams";
import { VideoAnalysisData } from "../../pages/UploadVideoGroupPage";
import uuid from "react-uuid";

interface IVideoPreviewProps {
  files: VideoAnalysisData[];
  removeVideo: (index: number) => Promise<void>;
}

const UploadsList: FC<IVideoPreviewProps> = ({ files, removeVideo }) => {
  const confirm = useConfirm();

  if (!files?.length) {
    return null;
  }

  return (
    <Box>
      <Typography variant="caption" color="otherTextTertiary.main">
        {files.length} uploads
      </Typography>
      <Box
        sx={{
          maxHeight: "300px",
          height: "100%",
          overflowY: "auto",
        }}
      >
        {files.map((file, index) => {
          const confirmDelete = (): void => {
            confirm({
              titleProps: {
                variant: "h4",
                component: "h4",
                fontSize: "26px",
              },
              title: "Are you sure you want to delete the video?",
              content: (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "12px",
                    borderRadius: "8px",
                    padding: "1em",
                    border: theme =>
                      `1px solid ${theme.palette.otherOutlineBorder.main}`,
                    backgroundColor: theme =>
                      theme.palette.otherBackground.main,
                  }}
                >
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      gap: "8px",
                      alignItems: "center",
                      "& video": {
                        width: "64px",
                        height: "48px",
                        borderRadius: "4px",
                        margin: "4px 0",
                      },
                    }}
                  >
                    <video id={file.video.name} src={file.video.preview} />
                    <Typography variant="body2Bold" fontSize="14px">
                      {file.video.name}
                    </Typography>
                  </Box>
                </Box>
              ),
              confirmationText: "Delete",
              cancellationText: "Cancel",
              ...confirmDialogStyleOptions,
              confirmationButtonProps: {
                ...confirmDialogStyleOptions.confirmationButtonProps,
              },
            }).then(async (): Promise<void> => {
              await removeVideo(index);
            });
          };

          const key = uuid();

          return (
            <Box
              key={key}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
                "& video": {
                  width: "64px",
                  height: "48px",
                  borderRadius: "4px",
                  margin: "4px 0",
                },
              }}
            >
              <video id={key} src={file.video.preview} />
              {file.video.name}
              <CloseIcon onClick={confirmDelete} sx={{ cursor: "pointer" }} />
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default UploadsList;

import { useTheme } from "@mui/material";

const ToastSuccessIcon = (): JSX.Element => {
  const theme = useTheme();

  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width="31"
        height="31"
        rx="7.5"
        fill={theme.palette.primary.shades?.["8p"]}
      />
      <path
        d="M11.8333 16.75L14.4524 19.75L21 12.25"
        stroke={theme.palette.primary.main}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="0.5"
        y="0.5"
        width="31"
        height="31"
        rx="7.5"
        stroke={theme.palette.primary.shades?.["30p"]}
      />
    </svg>
  );
};

export default ToastSuccessIcon;

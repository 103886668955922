import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import RouterLink from "../../../common/components/RouterLink";
import { useAuthenticatedUser } from "../../hooks/useAuthenticatedUser";
import { RoleEnum } from "../../models/enums";

export interface BreadcrumbItem {
  label: string;
  path?: string;
  onClick?: () => void;
}

interface BreadcrumbNavigationProps {
  items: BreadcrumbItem[];
}

const BreadcrumbNavigation: React.FC<BreadcrumbNavigationProps> = ({
  items,
}): JSX.Element => {
  const { role } = useAuthenticatedUser();

  return role !== RoleEnum.VERIFIER ? (
    <Stack spacing={0.5} direction="row" alignItems="center">
      {items.map((item, index): JSX.Element => {
        const isLastItem = index === items.length - 1;
        const color = isLastItem ? "text.primary" : "otherTextTertiary.main";

        return (
          <React.Fragment key={item.label}>
            {item.path && !item.onClick ? (
              <RouterLink to={item.path}>
                <Typography
                  sx={{
                    ":hover": {
                      color: "primary.main",
                    },
                  }}
                  variant="body2Regular"
                  color={color}
                >
                  {item.label}
                </Typography>
              </RouterLink>
            ) : item.onClick ? (
              <Box sx={{ cursor: "pointer" }} onClick={item.onClick}>
                <Typography
                  variant="body2Regular"
                  sx={{
                    ":hover": {
                      color: "primary.main",
                    },
                  }}
                  color={color}
                >
                  {item.label}
                </Typography>
              </Box>
            ) : (
              <Typography variant="body2Regular" color={color}>
                {item.label}
              </Typography>
            )}

            {!isLastItem && (
              <Typography variant="body2Regular" color={color}>
                /
              </Typography>
            )}
          </React.Fragment>
        );
      })}
    </Stack>
  ) : (
    <></>
  );
};

export default BreadcrumbNavigation;

import { gql } from "@apollo/client";

import { Category } from "../../API";
import client from "../../configs/apolloClient";
import { useCustomerIdGuard } from "./useCustomerIdGuard";

const useCategoryFromCache = () => {
  const customerId = useCustomerIdGuard();

  const getCachedCategoryFromName = (categoryName: string): Category | null => {
    const id = customerId + "#CG#" + categoryName;

    const normalizedId =
      client.cache.identify({
        id,
        __typename: "Category",
      }) ?? "";

    const category = client.cache.readFragment<Category>({
      id: normalizedId,
      fragment: gql`
        fragment CategoryFragment on Category {
          id
          categoryName
        }
      `,
    });

    return category;
  };

  return { getCachedCategoryFromName };
};

export default useCategoryFromCache;

interface ValidationResponseInterface {
  error: boolean;
  errorMessage: string;
}

const checkTextLength = (text: string): boolean => {
  return text.length >= 3 && text.length <= 30;
};

const checkIfTextHasOnlyLatinOrNumbers = (text: string): boolean => {
  return !!text.match(/^[A-Z0-9][A-Z0-9 ]*$/i);
};

export const textNameValidation = (
  value: string
): ValidationResponseInterface => {
  if (!checkTextLength(value)) {
    return { error: true, errorMessage: "From 3 to 30 characters" };
  }

  if (!checkIfTextHasOnlyLatinOrNumbers(value)) {
    return {
      error: true,
      errorMessage: "Latin characters or numbers only",
    };
  }

  return { error: false, errorMessage: "" };
};

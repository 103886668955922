import { FC } from "react";

import ListItem from "@mui/material/ListItem";

import { IVideoSpeedOption } from "./VideoSpeedSection";

interface IVideoSpeedOptionProps {
  value: number;
  label: string;
  active?: boolean;
  onSelectedSpeedChange: (speed: IVideoSpeedOption) => void;
}

const VideoSpeedOption: FC<IVideoSpeedOptionProps> = ({
  value,
  label,
  active,
  onSelectedSpeedChange,
}) => {
  return (
    <ListItem
      className={(active && "active") || ""}
      sx={{
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        "&.active": {
          backgroundColor: theme => theme.palette.primary.shades?.["8p"],
          borderLeft: theme => `3px solid ${theme.palette.primary.main}`,
        },
      }}
      onClick={() => {
        onSelectedSpeedChange({ value, label });
      }}
    >
      {label}
    </ListItem>
  );
};

export default VideoSpeedOption;

import NoMediaIcon from "../../../../common/components/icons/NoMediaIcon";
import MediaErrorBoxStyled from "./layout/MediaErrorBoxStyled";
import MediaErrorSubTitleStyled from "./layout/MediaErrorSubTitleStyled";
import MediaErrorTitleStyled from "./layout/MediaErrorTitleStyled";

const InvalidMediaType = () => {
  return (
    <MediaErrorBoxStyled>
      <NoMediaIcon />
      <MediaErrorTitleStyled>The media can’t be opened</MediaErrorTitleStyled>
      <MediaErrorSubTitleStyled>
        The media type in this URL isn’t supported
      </MediaErrorSubTitleStyled>
    </MediaErrorBoxStyled>
  );
};

export default InvalidMediaType;

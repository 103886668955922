import { ApolloError, useMutation } from "@apollo/client";

import { UPDATE_CUSTOMER_DT_OBJECT } from "../../../../common/operations/mutations";
import { DTObject, DTObjectInput } from "../../../../API";

export interface useUpdateCustomerDTObjectDataInterface {
  updateCustomerDTObject: DTObject | null;
}

export interface useUpdateCustomerDTObjectInterface {
  editCustomerDTObject: (input: DTObjectInput) => Promise<any>;
  data: useUpdateCustomerDTObjectDataInterface;
  loading: boolean;
  error: ApolloError | undefined;
}

export const useUpdateCustomerDTObject = () => {
  const [updateCustomerDTObject, { data, loading, error }] = useMutation(
    UPDATE_CUSTOMER_DT_OBJECT
  );

  const editCustomerDTObject = async (input: DTObjectInput): Promise<any> => {
    return await updateCustomerDTObject({
      variables: {
        input,
      },
    });
  };

  return { editCustomerDTObject, data, loading, error };
};

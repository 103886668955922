import { useContext } from "react";

import {
  GapBlock,
  MenuTitle,
  StyledMenuItem,
} from "../../styledComponents/styledContextMenu/ContextMenuStyledComponents";
import { EditDTObjectContext } from "../../../../pages/project-editor/context";

import { EditIcon } from "../../../assets/icons/EditIcon";

import { DTObject } from "../../../../../API";

interface ObjectCardContextMenuEditButtonInterface {
  onCloseContextMenu: (event: React.MouseEvent<HTMLElement>) => void;
  objectToEdit: DTObject;
  disabledButton: boolean;
}

const ObjectCardContextMenuEditButton = ({
  onCloseContextMenu,
  objectToEdit,
  disabledButton,
}: ObjectCardContextMenuEditButtonInterface): JSX.Element => {
  const { openEditDTObjectForm } = useContext(EditDTObjectContext);

  const handleOnClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    openEditDTObjectForm(objectToEdit);

    onCloseContextMenu(event);
  };

  return (
    <StyledMenuItem disabled={disabledButton} onClick={handleOnClick}>
      <EditIcon fontSize="small" />
      <MenuTitle variant="body14Regular">Edit</MenuTitle>
      <GapBlock />
    </StyledMenuItem>
  );
};

export default ObjectCardContextMenuEditButton;

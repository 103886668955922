import { makeVar, useReactiveVar } from "@apollo/client";

import { AutocompleteOptionType } from "../../../common/models/autocomplete";
import { IJsonData } from "../../devices/hooks/useGetDeviceByUrl";
import { ModelType } from "../../../API";

export enum ServiceTypeEnum {
  GAS_LEAK = "Gas Leak",
  LEAK = "Leak",
  LIQUID_LEAK = "Liquid Leak",
  HARD_HAT = "Hard Hat",
  FIRE = "Fire",
  SMOKE = "Smoke",
  FIRE_SMOKE = "Fire/Smoke",
  TANK_LEVEL = "Tank Level",
  GATE_GUARD = "Gate Guard",
}

interface IDefaultAIManagerModel {
  serviceType: string;
  locationId: AutocompleteOptionType;
  deviceId: string;
  nodeId: string;
}

export interface ILeakModel {
  docker_image?: string;
  gpu_id: string;
  calc_flow_rate?: string;
  min_length_leak?: string;
  file_dur?: string;
}

export interface IGasLeakModel extends ILeakModel, ILeakModel {
  display_rate?: string;
  camera_view_angle: string;
  target_distance: string;
}

export interface ILiquidLeakModel extends ILeakModel, ILeakModel {
  liquid_gas?: string;
}

export interface IFireSmokeModel {
  gpu_id?: string;
  docker_image?: string;
  model_threshold?: string;
  report_threshold?: string;
  online_update_interval?: string;
  report_interval?: string;
  report_fire_int?: string;
  report_smoke_int?: string;
  catch_size?: string;
}

export interface IHardHatModel {
  gpu_id?: string;
  docker_image?: string;
  model_threshold?: string;
  report_threshold?: string;
  online_update_interval?: string;
  report_interval?: string;
}

export interface ITankLevelModel {
  docker_image?: string;
  report_interval?: string;
  file_dur?: string;
}

export interface IGateGuardModel {
  docker_image?: string;
  gpu_id?: string;
  file_dur?: string;
}

export interface IAIManagerFormState extends IDefaultAIManagerModel {
  [ServiceTypeEnum.LEAK]: IGasLeakModel;
  [ServiceTypeEnum.LIQUID_LEAK]: ILiquidLeakModel;
}

export const gasLeakDefaultModelValues: IGasLeakModel = {
  calc_flow_rate: "true",
  display_rate: "false",
  gpu_id: "0",
  camera_view_angle: "14.5",
  target_distance: "75",
  min_length_leak: "20",
  file_dur: "120",
};

export const liquidLeakDefaultModelValues: ILiquidLeakModel = {
  calc_flow_rate: "true",
  gpu_id: "0",
  min_length_leak: "20",
  file_dur: "120",
  liquid_gas: "false",
};

export const fireSmokeDefaultModelsValues: IFireSmokeModel = {
  gpu_id: "0",
  model_threshold: "0.4",
  report_threshold: "0.75",
  online_update_interval: "120",
  report_interval: "300",
  report_fire_int: "300",
  report_smoke_int: "300",
  catch_size: "15",
};

export const hardHatDefaultModelValues: IHardHatModel = {
  gpu_id: "0",
  model_threshold: "0.4",
  report_threshold: ".75",
  online_update_interval: "120",
  report_interval: "300",
};

export const tankLevelDefaultModelValues: ITankLevelModel = {
  report_interval: "300",
  file_dur: "0.5",
};

export const gateGuardDefualtModelValues: IGateGuardModel = {
  gpu_id: "0",
  file_dur: "30",
};

export const configurationParametersByModelName: IJsonData = {
  [ServiceTypeEnum.LEAK]: gasLeakDefaultModelValues,
  [ServiceTypeEnum.LIQUID_LEAK]: liquidLeakDefaultModelValues,
  [ServiceTypeEnum.FIRE_SMOKE]: fireSmokeDefaultModelsValues,
  [ServiceTypeEnum.HARD_HAT]: hardHatDefaultModelValues,
  [ServiceTypeEnum.TANK_LEVEL]: tankLevelDefaultModelValues,
  [ServiceTypeEnum.GATE_GUARD]: gateGuardDefualtModelValues,
};

export const configurationParametersByModelType: IJsonData = {
  [ModelType.gasleak]: gasLeakDefaultModelValues,
  [ModelType.liquidleak]: liquidLeakDefaultModelValues,
  [ModelType.fire]: fireSmokeDefaultModelsValues,
  [ModelType.smoke]: fireSmokeDefaultModelsValues,
  firesmoke: fireSmokeDefaultModelsValues,
  [ModelType.hardhat]: hardHatDefaultModelValues,
  [ModelType.tlm]: tankLevelDefaultModelValues,
  [ModelType.agg]: gateGuardDefualtModelValues,
};

const defaultAIManagerValues: IDefaultAIManagerModel = {
  serviceType: ServiceTypeEnum.LEAK,
  locationId: null,
  deviceId: "",
  nodeId: "",
};

export const defaultAIManagerFormState: IAIManagerFormState = {
  ...defaultAIManagerValues,
  [ServiceTypeEnum.LEAK]: gasLeakDefaultModelValues,
  [ServiceTypeEnum.LIQUID_LEAK]: liquidLeakDefaultModelValues,
};

export const aiManagerVariable = makeVar<IAIManagerFormState>(
  defaultAIManagerFormState
);

export const useSelectedServiceModel = () => useReactiveVar(aiManagerVariable);

export const selectedLocationAIManagerVariable =
  makeVar<AutocompleteOptionType>(null);

export const useSelectedLocationAIManager = () =>
  useReactiveVar(selectedLocationAIManagerVariable);

export const selectedTagLocationAIManagerVariable = makeVar<
  AutocompleteOptionType[] | null
>(null);

export const useSelectedTagLocationAIManager = () =>
  useReactiveVar(selectedTagLocationAIManagerVariable);

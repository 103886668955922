import { CSSInterpolation, ComponentsOverrides, Theme } from "@mui/material";

export const ThemeMuiFilledInput: ComponentsOverrides<Theme>["MuiFilledInput"] =
  {
    root: ({ theme }): CSSInterpolation => ({
      width: "100%",
      height: "29px",
      padding: "0",
      gap: "4px",
      borderRadius: "4px",

      backgroundColor: theme.palette.blue.lighter,
      color: theme.palette.blue.main,

      "& .MuiTypography-root": {
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "20px",
      },

      "svg path": {
        fill: theme.palette.blue.main,
      },

      "&:hover": {
        backgroundColor: theme.palette.blue.lighter,
        color: theme.palette.blue.light,

        "svg path": {
          fill: theme.palette.blue.light,
        },
      },

      "&:focus": {
        backgroundColor: theme.palette.blue.lighter,
        color: theme.palette.blue.light,

        "svg path": {
          fill: theme.palette.blue.light,
        },
      },

      ".MuiFilledInput-input:focus": {
        backgroundColor: "transparent",
      },

      "&.Mui-focused": {
        backgroundColor: theme.palette.blue.lighter,
      },

      "&.Mui-disabled": {
        backgroundColor: theme.palette["grey-light"],
        color: theme.palette["grey-dark"],

        "svg, path, circle": {
          fill: theme.palette["grey-dark"],
        },

        "&:hover": {
          backgroundColor: theme.palette["grey-light"],
          color: theme.palette["grey-dark"],

          "svg path": {
            fill: theme.palette["grey-dark"],
          },
        },
      },

      "& .MuiSelect-select": {
        padding: "6px 6px 6px 32px !important",
      },

      "@media (max-width: 1500px)": {
        "& .MuiTypography-root": {
          fontSize: "12px",
          lineHeight: "18px",
        },
      },
    }),
  };

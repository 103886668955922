import { ReactNode } from "react";

import Typography from "@mui/material/Typography";

const MediaErrorTitleStyled = ({ children }: { children: ReactNode }) => {
  return (
    <Typography
      variant="h5"
      sx={{
        marginTop: "1em",
      }}
    >
      {children}
    </Typography>
  );
};

export default MediaErrorTitleStyled;

import {
  WindLight,
  WindNormal,
  WindStormy,
} from "../../../../assets/icons/WeatherIcons";

type Props = {
  windSpeed: number;
};

const WindIcon = ({ windSpeed }: Props): JSX.Element => {
  if (windSpeed < 11) {
    return <WindLight />;
  } else if (windSpeed >= 11 && windSpeed < 20) {
    return <WindNormal />;
  } else {
    return <WindStormy />;
  }
};

export default WindIcon;

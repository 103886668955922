import { useEffect, useState } from "react";

import {
  selectedZoneVariable,
  useSelectedZoneVariable,
} from "../../pages/data-hub/components/liveview/components/devices/ptzonelist/zones.variable";
import { usePublishNode } from "../../pages/devices/hooks/usePublishNode";
import { selectedKeyframeVariable } from "../variables/selectedKeyframe";
import { useZoneFormValidation } from "../../pages/devices/zones-setup-page/hooks/useZoneFormValidation";
import { PAN_TILT_PATTERN } from "../variables/common";

interface IUsePTController {
  deviceName: string;
  nodeId: string;
}

export const usePTController = ({ deviceName, nodeId }: IUsePTController) => {
  const selectedZone = useSelectedZoneVariable();
  const { validatePanTilt, validationState, setValidationState, validateZoom } =
    useZoneFormValidation();

  const { publishNode } = usePublishNode();

  const [pan, setPan] = useState("");
  const [tilt, setTilt] = useState("");
  const [zoom, setZoom] = useState("");

  useEffect(() => {
    if (selectedZone) {
      setPan(selectedZone.pan);

      setTilt(selectedZone.tilt);

      setZoom(selectedZone.zoom);
    }
  }, [selectedZone.pan, selectedZone.tilt, selectedZone.zoom]);

  const movePtUp = (): void => {
    selectedKeyframeVariable("");

    selectedZoneVariable({ zoneName: "", pan: pan, tilt: tilt, zoom: zoom });

    adjustByStep.setVerticalUp();

    publishNode({
      message: JSON.stringify({
        TARGET: "PANTILT",
        ACTION: "MOVEUP_PT",
        device_name: deviceName,
      }),
      nodeId,
    });
  };

  const movePtDown = (): void => {
    selectedKeyframeVariable("");

    selectedZoneVariable({ zoneName: "", pan: pan, tilt: tilt, zoom: zoom });

    adjustByStep.setVerticalDown();

    publishNode({
      message: JSON.stringify({
        TARGET: "PANTILT",
        ACTION: "MOVEDOWN_PT",
        device_name: deviceName,
      }),
      nodeId,
    });
  };

  const movePtLeft = (): void => {
    selectedKeyframeVariable("");

    selectedZoneVariable({ zoneName: "", pan: pan, tilt: tilt, zoom: zoom });

    adjustByStep.setHorizontalDown();

    publishNode({
      message: JSON.stringify({
        TARGET: "PANTILT",
        ACTION: "MOVELEFT_PT",
        device_name: deviceName,
      }),
      nodeId,
    });
  };

  const movePtRight = (): void => {
    selectedKeyframeVariable("");

    selectedZoneVariable({ zoneName: "", pan: pan, tilt: tilt, zoom: zoom });

    adjustByStep.setHorizontalUp();

    publishNode({
      message: JSON.stringify({
        TARGET: "PANTILT",
        ACTION: "MOVERIGHT_PT",
        device_name: deviceName,
      }),
      nodeId,
    });
  };

  const handleGoToPt = (pan: string, tilt: string, zoom: string): void => {
    selectedKeyframeVariable("");

    selectedZoneVariable({ zoneName: "", pan: pan, tilt: tilt, zoom: zoom });

    const isValid = validatePanTilt(pan, tilt);

    const isValidZoom = validateZoom(zoom);

    if (!isValid && !isValidZoom) return;

    publishNode({
      message: JSON.stringify({
        TARGET: "PANTILT",
        ACTION: "GOTO_PT",
        device_name: deviceName,
        pan: pan,
        tilt: tilt,
        zoom: zoom,
      }),
      nodeId,
    });
  };

  const handlePanInputChange = (pan = ""): void => {
    selectedKeyframeVariable("");

    selectedZoneVariable({ zoneName: "", pan: pan, tilt: tilt, zoom: zoom });

    setValidationState(prev => ({
      ...prev,
      pan: {
        hasError: false,
        errorMessage: "",
      },
      panValue: {
        hasError: false,
        errorMessage: "",
      },
    }));

    if (PAN_TILT_PATTERN.test(pan)) {
      setPan(pan);
    }
  };

  const handleTiltInputChange = (tilt = ""): void => {
    selectedKeyframeVariable("");

    selectedZoneVariable({ zoneName: "", pan: pan, tilt: tilt, zoom: zoom });

    setValidationState(prev => ({
      ...prev,
      tilt: {
        hasError: false,
        errorMessage: "",
      },
      tiltValue: {
        hasError: false,
        errorMessage: "",
      },
    }));

    if (PAN_TILT_PATTERN.test(tilt)) {
      setTilt(tilt);
    }
  };

  const handleZoomInputChange = (zoom = ""): void => {
    selectedKeyframeVariable("");

    selectedZoneVariable({ zoneName: "", pan: pan, tilt: tilt, zoom: zoom });

    setValidationState(prev => ({
      ...prev,
      zoom: {
        hasError: false,
        errorMessage: "",
      },
      zoomValue: {
        hasError: false,
        errorMessage: "",
      },
    }));

    setZoom(zoom);
  };

  const adjustByStep = {
    setHorizontalUp(): void {
      // MOVE_PT_RIGHT
      if (+pan > 179.9) {
        setPan("180");

        return;
      }

      setPan(`${(+pan + 0.1).toFixed(1)}`);
    },

    setHorizontalDown(): void {
      // MOVE_PT_LEFT
      if (+pan < -179.9) {
        setPan("-180");

        return;
      }

      setPan(`${(+pan - 0.1).toFixed(1)}`);
    },

    setVerticalUp(): void {
      // MOVE_PT_UP
      if (+tilt > 29.9) {
        setTilt("30");

        return;
      }

      setTilt(`${(+tilt + 0.1).toFixed(1)}`);
    },

    setVerticalDown(): void {
      if (+tilt < -89.9) {
        setTilt("-90");

        return;
      }

      setTilt(`${(+tilt - 0.1).toFixed(1)}`);
    },
  };

  return {
    pan,
    tilt,
    zoom,
    adjustByStep,
    movePtUp,
    movePtDown,
    movePtLeft,
    movePtRight,
    handleGoToPt,
    handlePanInputChange,
    handleTiltInputChange,
    handleZoomInputChange,
    validationState,
  };
};

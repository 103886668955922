import { makeVar } from "@apollo/client";
import type { AlertColor } from "@mui/lab";

interface NotificationSnackbarInterface {
  isOpen: boolean;
  severity: AlertColor | undefined;
  message: string;
}

export const defaultNotificationVariable: NotificationSnackbarInterface = {
  isOpen: false,
  message: "",
  severity: "success",
};

export const notificationVariable = makeVar<NotificationSnackbarInterface>(
  defaultNotificationVariable
);

/**
 * Opens error notification. Use this in request or mutation response.
 * @param {string} message: Custom error message, default is "Something went wrong!"
 */
export const errorNotification = (message = "Something went wrong") => {
  notificationVariable({
    isOpen: true,
    message,
    severity: "error",
  });
};

/**
 * Opens success notification. Use this in request or mutation response.
 * @param {string} message: Custom success message, default is "Done!"
 */
export const successNotification = (message = "Done") => {
  notificationVariable({
    isOpen: true,
    message,
    severity: "success",
  });
};

import { ApolloError, useMutation } from "@apollo/client";

import {
  DeleteAnnotationInput,
  DeleteAnnotationMutation,
  DeleteAnnotationMutationVariables,
} from "../../../API";
import { DELETE_ANNOTATION } from "../../../common/operations/mutations";
import { errorNotification } from "../../../common/variables/notification";

export const useDeleteAnnotation = () => {
  const [deleteAnnotationMutation, { data, loading }] = useMutation<
    DeleteAnnotationMutation,
    DeleteAnnotationMutationVariables
  >(DELETE_ANNOTATION);

  const deleteAnnotation = async ({
    zoneId,
    serviceId,
    id,
  }: DeleteAnnotationInput) => {
    return await deleteAnnotationMutation({
      variables: {
        input: { zoneId, serviceId, id },
      },
      update: (cache, { data }) => {
        if (data?.deleteAnnotation) {
          const id = zoneId;
          const normalizedId = cache.identify({ id, __typename: "Annotation" });

          cache.modify({
            id: normalizedId,
            fields: {
              getAnnotations(existing) {
                return existing.filter(
                  (annotation: { id: string | null | undefined }): boolean =>
                    annotation.id !== data?.deleteAnnotation?.id
                );
              },
            },
          });
        }
      },
      onError: (error: ApolloError): void => {
        errorNotification(error.message);

        console.error(error.message);
      },
      refetchQueries: ["GetAnnotations"],
    });
  };

  return { deleteAnnotation, data, loading };
};

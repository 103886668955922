import type { ComponentsOverrides, Theme } from "@mui/material";

export const MuiButtonDarkStyleOverrides: ComponentsOverrides<Theme>["MuiButton"] =
  {
    root: ({ theme }) => ({
      borderRadius: "40px",
      textTransform: "none",
      "&.Mui-disabled": {
        backgroundColor: theme.palette.primary.shades?.["30p"],
        color: theme.palette.primary.contrastText,
      },
    }),

    containedPrimary: ({ theme }) => ({
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      "&:hover": {
        backgroundColor: theme.palette.primary.dark,
      },
    }),
    outlinedSecondary: ({ theme }) => ({
      color: theme.palette.text.secondary,
      borderColor: theme.palette.text.secondary,
      "&:hover": {
        backgroundColor: "transparent",
        borderColor: theme.palette.primary.main,
        color: theme.palette.primary.main,
      },
    }),
    outlinedPrimary: ({ theme }) => ({
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      "&:hover": {
        backgroundColor: "transparent",
        borderColor: theme.palette.primary.dark,
        color: theme.palette.primary.dark,
      },
    }),
    containedError: ({ theme }) => ({
      color: theme.palette.otherBackgroundLight.main,
      backgroundColor: theme.palette.error.main,
      "&:hover": {
        backgroundColor: theme.palette.error.dark,
      },
    }),
    textPrimary: ({ theme }) => ({
      color: theme.palette.primary.main,
      "&:hover": {
        backgroundColor: "transparent",
        color: theme.palette.primary.dark,
      },
    }),
    sizeSmall: {
      paddingLeft: "1rem",
      paddingRight: "1rem",
      height: "40px",
    },
    sizeMedium: {
      paddingLeft: "2rem",
      paddingRight: "2rem",
      height: "48px",
    },
    disabled: ({ theme }) => ({
      backgroundColor: theme.palette.primary.shades?.["30p"],
      color: theme.palette.primary.contrastText,
    }),
  };

import { useQuery } from "@apollo/client";

import {
  GetGroupByClientIdInput,
  GetGroupByClientIdQuery,
  GetGroupByClientIdQueryVariables,
} from "../../API";
import { GET_GROUP_BY_CLIENT_ID } from "../../common/operations/queries";

export const useGetGroupByClientId = (input: GetGroupByClientIdInput) => {
  const { data, loading, refetch } = useQuery<
    GetGroupByClientIdQuery,
    GetGroupByClientIdQueryVariables
  >(GET_GROUP_BY_CLIENT_ID, {
    variables: {
      input,
    },
    skip: !input.groupId || !input.clientId,
  });

  return { data, loading, refetch };
};

import SketchplanGeojsonLayer from "./SketchplanGeojsonLayer";
import SketchplanKMZLayer from "./SketchplanKMZLayer";

import { createGroup } from "../SceneUtils/CreateMesh";

class SceneFacilitySketchplan {
  constructor(scene, mapTiles) {
    this.scene = scene;

    this.mapTiles = mapTiles;

    this.sketchplanGroup = createGroup("shapeGroup", this.scene);
  }

  loadSketchplanGeojsonLayer(multilinestring, elevationDiff) {
    const geojsonLayer = new SketchplanGeojsonLayer(
      this.sketchplanGroup,
      this.mapTiles
    );

    geojsonLayer.load(multilinestring, elevationDiff);
  }

  async loadSketchplanKMZLayer(kmzLayerURL, kmzLayerPlaneData, elevationDiff) {
    const kmzLayer = new SketchplanKMZLayer(
      this.sketchplanGroup,
      this.mapTiles
    );

    await kmzLayer.load(kmzLayerURL, kmzLayerPlaneData, elevationDiff);
  }

  toggleVisibility(toggler) {
    this.sketchplanGroup.traverse(child => {
      toggler ? child.layers.enable() : child.layers.disable();
    });
  }
}

export default SceneFacilitySketchplan;

import { IFormValidationState } from "../../common/models/formValidation";

export interface IGroupForm {
  groupName: string;
}

export const defaultGroupFormVariables = {
  groupName: "",
};

interface IGroupFormValidationState {
  groupName: IFormValidationState;
}

export const defaultGroupFormValidationState: IGroupFormValidationState = {
  groupName: {
    hasError: false,
    errorMessage: "",
  },
};

export const groupFormRules = {
  groupName: {
    required: true,
    pattern: /^(?!^ +$)[a-zA-Z0-9]+(?:\s[a-zA-Z0-9]+)*$/,
  },
};

import { ApolloError, gql, useMutation } from "@apollo/client";

import {
  UpdateClientGroupInput,
  UpdateClientGroupMutation,
  UpdateClientGroupMutationVariables,
} from "../../API";

import { UPDATE_CLIENT_GROUP } from "../../common/operations/mutations";
import { errorNotification } from "../../common/variables/notification";

export const useUpdateClientGroup = () => {
  const [updateClientGroupMutation, { data, loading }] = useMutation<
    UpdateClientGroupMutation,
    UpdateClientGroupMutationVariables
  >(UPDATE_CLIENT_GROUP);

  const updateClientGroup = (input: UpdateClientGroupInput) => {
    return updateClientGroupMutation({
      variables: {
        input,
      },
      onCompleted: () => {
        // need add comparing into UploadVideoStepComponent between old and new group name, and run update only if there is difference
        // if (response.updateClientGroup) {
        //   successNotification("Group name is updated");
        // }
      },
      onError: (error: ApolloError): void => {
        errorNotification(error.message);

        console.error(error.message);
      },
      update: (cache, response): void => {
        cache.modify({
          fields: {
            getGroupByClientId(existing) {
              const newClientGroupRef = cache.writeFragment({
                data: response.data?.updateClientGroup,
                fragment: gql`
                  fragment NewGroup on Group {
                    id
                    groupName
                    customerId
                    clientId
                  }
                `,
              });

              let updatedItems;

              if (existing.items) {
                updatedItems = [...existing.items, newClientGroupRef];
              } else {
                updatedItems = [newClientGroupRef];
              }

              return {
                ...existing,
                items: updatedItems,
              };
            },
          },
        });
      },
    });
  };

  return { updateClientGroup, data, loading };
};

import { MathUtils, Vector3, Raycaster, ArrowHelper } from "three";

import { PointerLockControls } from "three/addons/controls/PointerLockControls.js";

class FPVModeControls {
  constructor(camera, domElement) {
    this.camera = camera;

    this.domElement = domElement;

    this.moveForward = false;

    this.moveBackward = false;

    this.moveLeft = false;

    this.moveRight = false;

    this.canJump = false;

    this.velocity = new Vector3();

    this.direction = new Vector3();

    this.init();
  }

  init = () => {
    this.controls = new PointerLockControls(this.camera, this.domElement);

    this.raycaster = new Raycaster();
  };

  addSettings(bbox, ground, objectsGroup) {
    this.ground = ground;

    this.objectsGroup = objectsGroup;

    this.upVector = new Vector3(0, bbox.min.y, 0);

    this.bboxMax = bbox.max.clone();

    this.bboxMax.y = Infinity;

    this.bboxMin = bbox.min.clone();

    this.bboxMin.y = -Infinity;
  }

  onKeyDown = event => {
    switch (event.code) {
      case "ArrowUp":
      case "KeyW":
        this.moveForward = true;
        break;

      case "ArrowLeft":
      case "KeyA":
        this.moveLeft = true;
        break;

      case "ArrowDown":
      case "KeyS":
        this.moveBackward = true;
        break;

      case "ArrowRight":
      case "KeyD":
        this.moveRight = true;
        break;
    }
  };

  onKeyUp = event => {
    switch (event.code) {
      case "ArrowUp":
      case "KeyW":
        this.moveForward = false;
        break;

      case "ArrowLeft":
      case "KeyA":
        this.moveLeft = false;
        break;

      case "ArrowDown":
      case "KeyS":
        this.moveBackward = false;
        break;

      case "ArrowRight":
      case "KeyD":
        this.moveRight = false;
        break;
    }
  };

  unlockFPVMode = () => {
    this.controls.lock();
  };

  lockFPVMode = () => {
    this.controls.unlock();
  };

  addEvents = setIsFPVModeUnlocked => {
    this.setIsFPVModeUnlocked = setIsFPVModeUnlocked;

    document.addEventListener("keydown", this.onKeyDown);

    document.addEventListener("keyup", this.onKeyUp);

    this.controls.addEventListener("lock", () => {
      this.setIsFPVModeUnlocked(true);
    });

    this.controls.addEventListener("unlock", () => {
      this.setIsFPVModeUnlocked(false);
    });
  };

  removeEvents = () => {
    document.removeEventListener("keydown", this.onKeyDown);

    document.removeEventListener("keyup", this.onKeyUp);

    this.controls.removeEventListener("lock", () => {
      this.setIsFPVModeUnlocked(true);
    });

    this.controls.removeEventListener("unlock", () => {
      this.setIsFPVModeUnlocked(false);
    });
  };

  update = delta => {
    if (this.controls.isLocked === true) {
      this.velocity.x -= this.velocity.x * 10.0 * delta;

      this.velocity.z -= this.velocity.z * 10.0 * delta;

      this.velocity.y -= 9.8 * 100.0 * delta;

      this.direction.z = Number(this.moveForward) - Number(this.moveBackward);

      this.direction.x = Number(this.moveRight) - Number(this.moveLeft);

      this.direction.normalize();

      if (this.moveForward || this.moveBackward) {
        this.velocity.z -= this.direction.z * 100.0 * delta;
      }

      if (this.moveLeft || this.moveRight) {
        this.velocity.x -= this.direction.x * 100.0 * delta;
      }

      this.controls.moveRight(-this.velocity.x * delta);

      this.controls.moveForward(-this.velocity.z * delta);

      this.camera.position.clamp(this.bboxMin, this.bboxMax);

      this.raycaster.set(this.camera.position, this.upVector.negate());
      const intersects = this.raycaster.intersectObject(this.ground);

      if (intersects.length > 0) {
        this.velocity.y = intersects[0].point.y + 2;

        this.camera.position.y = this.velocity.y;
      }

      this.camera.updateProjectionMatrix();
    }
  };
}

export default FPVModeControls;

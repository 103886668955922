import { Typography, styled } from "@mui/material";

export type HintType = "default" | "success" | "error";

export interface HintInterface {
  type: HintType;
  text: string;
}

const InputHint = ({ type, text }: HintInterface) => {
  return (
    <Hint className={"hint-" + type} variant="body12Regular">
      {text}
    </Hint>
  );
};

export default InputHint;

const Hint = styled(Typography)(({ theme }) => ({
  "&.hint": {
    "&-default": {
      color: theme.palette["base-hint"],
    },

    "&-success": {
      color: theme.palette.green.main,
    },

    "&-error": {
      color: theme.palette.red.lighter,
    },
  },
}));

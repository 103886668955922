import { ConfirmOptions } from "material-ui-confirm";

const confirmDialogStyleOptions: ConfirmOptions = {
  dialogProps: {
    sx: {
      "& .MuiDialog-paper": {
        borderRadius: "12px",
      },
    },
  },
  contentProps: {
    sx: {
      color: "text.primary",
      textAlign: "center",
    },
  },
  titleProps: {
    sx: {
      fontSize: "26px",
      fontWeight: "bold",
      paddingTop: "48px",
      textAlign: "center",
    },
  },
  dialogActionsProps: {
    sx: {
      display: "flex",
      justifyContent: "center",
      textAlign: "center",
      alignItems: "center",
      padding: "20px 0 48px 0",
    },
  },
  cancellationButtonProps: {
    style: { height: "48px", textTransform: "none" },
    variant: "outlined",
    color: "inherit",
  },
  confirmationButtonProps: {
    style: { height: "48px", textTransform: "none" },
    variant: "contained",
    color: "error",
  },
};

export default confirmDialogStyleOptions;

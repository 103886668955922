import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import { CSSObject, styled } from "@mui/material/styles";

import { IAlertProps } from "./AlertFilled";
import InfoIconOrange from "./InfoIconOrange";

const StyledAlert = styled(Alert)(
  ({ theme }): CSSObject => ({
    "&": {
      color: theme.palette.warning.main,
      paddingLeft: 0,
      border: 0,
      display: "flex",
      alignItems: "center",
    },
    "& .MuiAlert-icon": {
      marginRight: "8px",
    },
  })
);

const AlertWithIcon = ({
  message = "",
  children,
  ...props
}: IAlertProps): JSX.Element => {
  const icon = props.icon ?? <InfoIconOrange />;

  return (
    <StyledAlert {...props} icon={icon} variant="outlined">
      {children ?? <Typography variant="body2Regular">{message}</Typography>}
    </StyledAlert>
  );
};

export default AlertWithIcon;

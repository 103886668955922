import { makeVar, useReactiveVar } from "@apollo/client";

type SelectedZoneVariable = {
  zoneName: string;
  pan: string;
  tilt: string;
  zoom: string;
};

export const selectedZoneVariable = makeVar<SelectedZoneVariable>({
  zoneName: "",
  pan: "",
  tilt: "",
  zoom: "",
});

export const useSelectedZoneVariable = () =>
  useReactiveVar(selectedZoneVariable);

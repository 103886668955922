import { useState } from "react";

import { DTObjectDeletionRestrictionModalContext } from "./DTObjectDeletionRestrictionModalContext";
import DTObjectDeletionRestrictionModal from "../../components/DTObjectDeletionRestrictionModal";

export const DTObjectDeletionRestrictionModalContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const [dtObjectDeletionRestrictionData, setDTObjectDeletionRestrictionData] =
    useState<any | null>(null);

  const handleOnClose = () => {
    setDTObjectDeletionRestrictionData(null);
  };

  return (
    <DTObjectDeletionRestrictionModalContext.Provider
      value={{
        dtObjectDeletionRestrictionData,
        setDTObjectDeletionRestrictionData,
        handleOnClose,
      }}
    >
      {children}

      {dtObjectDeletionRestrictionData && (
        <DTObjectDeletionRestrictionModal
          data={dtObjectDeletionRestrictionData}
          handleOnClose={handleOnClose}
        />
      )}
    </DTObjectDeletionRestrictionModalContext.Provider>
  );
};

import React from "react";
import { Box, MobileStepper, Typography, useTheme } from "@mui/material";

type MobileStepperComponentProps = {
  countOfAllSteps: number;
  activeStep: number;
  nameOfEssence: string;
};

const MobileStepperComponent: React.FC<MobileStepperComponentProps> = ({
  countOfAllSteps,
  nameOfEssence,
  activeStep,
}) => {
  const theme = useTheme();

  return (
    <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
      <MobileStepper
        variant="progress"
        steps={countOfAllSteps + 1}
        position="static"
        activeStep={activeStep}
        backButton={null} // prop must exist
        nextButton={null} // prop must exist
        sx={{
          flexGrow: 1,
          paddingLeft: "0",
          ".MuiLinearProgress-root": {
            backgroundColor: theme.palette.otherDivider.main,
            height: "11px",
            borderRadius: "8px",
            width: "100%",
          },
        }}
      />

      <Typography
        sx={{
          display: "flex",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
        variant="inputLabel"
      >{`${activeStep}/${countOfAllSteps} ${nameOfEssence}`}</Typography>
    </Box>
  );
};

export default MobileStepperComponent;

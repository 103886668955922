import { useMemo } from "react";

import { type PaletteMode, type Theme, ThemeProvider } from "@mui/material";

import { themes } from "./design-tokens/tokens";

export interface ThemeProviderInterface {
  theme?: Theme;
  children: any;
  mode?: PaletteMode;
}

export const themeMode = {
  light: "light",
  dark: "dark",
};

const MuiThemeProvider = ({
  theme,
  children,
  mode = themeMode.light as PaletteMode,
}: ThemeProviderInterface): JSX.Element => {
  const memoizedTheme = useMemo(
    (): Theme => theme ?? themes[mode],
    [theme, mode]
  );

  return <ThemeProvider theme={memoizedTheme}>{children}</ThemeProvider>;
};

export default MuiThemeProvider;

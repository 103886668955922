import { Box, Typography, useTheme } from "@mui/material";

const NoDataBanner = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        textAlign: "center",
        gap: "34px",
        width: "100%",
        flexGrow: 1,
        background: theme.palette.blue.lighter,
        borderRadius: "4px",
        marginTop: "20px",
        color: theme.palette.divider,
      }}
    >
      <Typography variant="h1">
        You haven&apos;t created any project yet
      </Typography>
      <Typography
        variant="h2"
        sx={{
          maxWidth: "500px",
        }}
      >
        Please, use a button ‘’+ New project’’ to create one. Your future
        projects will appear here
      </Typography>
    </Box>
  );
};

export default NoDataBanner;

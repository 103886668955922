export const roundNumber = (value: number, step: number) => {
  return Math.round(value / step) * step;
};

export const isRoundNumber = (value: number, step: number) => {
  return value % step === 0;
};

export const formatNumber = (
  value: number,
  amountAfterDecimalPoint: number
) => {
  const valueString = value.toString();

  const decimalIndex = valueString.indexOf(".");

  if (decimalIndex !== -1) {
    const fractionalPart = valueString.slice(decimalIndex + 1);

    if (fractionalPart.replace(/0/g, "").length > amountAfterDecimalPoint) {
      return Number(value.toFixed(amountAfterDecimalPoint));
    }
  }

  return Number(value);
};

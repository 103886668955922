import { useContext } from "react";
import { ToggleButton } from "@mui/material";

import { ProjectContext } from "../../../context";

import { SecondaryViewTypes } from "../../../data/SecondaryViewData";
import { CamerasType } from "../../../data/CamerasData";

interface TurnOnCameraViewButtonInterface {
  cameraType: CamerasType;
}

const TurnOnCameraViewButton = ({
  cameraType,
}: TurnOnCameraViewButtonInterface): JSX.Element => {
  const {
    isLoading,
    activeCameraId,
    setSecondaryCameraId,
    secondaryViewType,
    setSecondaryViewType,
  } = useContext(ProjectContext);

  const handleOnClick = () => {
    setSecondaryCameraId(activeCameraId);

    if (
      cameraType === CamerasType.MinervaGasCamera ||
      cameraType === CamerasType.MinervaCamera
    ) {
      setSecondaryViewType(SecondaryViewTypes.view360);
    }

    if (cameraType === CamerasType.RGBCamera) {
      setSecondaryViewType(SecondaryViewTypes.viewRGB);
    }
  };

  return (
    <ToggleButton
      color="blue"
      className="outlined-text"
      selected={
        secondaryViewType === SecondaryViewTypes.view360 ||
        secondaryViewType === SecondaryViewTypes.viewRGB
      }
      value="cam-view"
      disabled={isLoading}
      onClick={handleOnClick}
    >
      Cam view
    </ToggleButton>
  );
};

export default TurnOnCameraViewButton;

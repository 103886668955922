import { useState } from "react";

interface IUseDialog {
  open: boolean;
  handleDialogOpen: () => void;
  handleDialogClose: () => void;
}

export const useDialog = (): IUseDialog => {
  const [open, setOpen] = useState(false);

  const handleDialogOpen = (): void => {
    setOpen(true);
  };

  const handleDialogClose = (): void => {
    setOpen(false);
  };

  return { open, handleDialogOpen, handleDialogClose };
};

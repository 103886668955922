import { createContext } from "react";

import { ConfirmationModalInterface } from "../../components/ConfirmationModal";

export interface ConfirmationModalContextInterface {
  confirmationModalData: ConfirmationModalInterface | null;
  setConfirmationModalData: (
    value: React.SetStateAction<ConfirmationModalInterface | null>
  ) => void;
}

export const ConfirmationModalContext =
  createContext<ConfirmationModalContextInterface>(
    {} as ConfirmationModalContextInterface
  );

import { CSSInterpolation, ComponentsOverrides, Theme } from "@mui/material";

import { convertToPaletteColor } from "./Theme";

export const ThemeMuiToggleButton: ComponentsOverrides<Theme>["MuiToggleButton"] =
  {
    root: ({ theme, ownerState }): CSSInterpolation => {
      const paletteColor = convertToPaletteColor(ownerState.color as string);
      const color = theme.palette[paletteColor];

      return {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "36px",
        padding: "8px",
        borderRadius: "4px",
        backgroundColor: `${color.lighter} !important`,
        border: "2px solid transparent",
        color: color.main,

        "svg, path, circle": {
          fill: color.main,
        },

        "&:hover": {
          color: color.light,

          "svg, path, circle": {
            fill: color.light,
          },
        },

        "&:active": {
          color: color.dark,

          "svg, path, circle": {
            fill: color.dark,
          },
        },

        "&.Mui-selected": {
          borderColor: color.dark,
          color: color.dark,

          "svg, path, circle": {
            fill: color.dark,
          },

          "&:hover": {
            color: color.light,

            "svg, path, circle": {
              fill: color.light,
            },
          },
        },

        "&.Mui-disabled": {
          backgroundColor: `${theme.palette["grey-light"]} !important`,
          border: `2px solid ${theme.palette["grey-light"]}`,
          color: theme.palette["grey-dark"],
          outline: "none",

          "svg, path, circle": {
            fill: theme.palette["grey-dark"],
          },
        },

        "&.outlined-text": {
          border: `1px solid ${color.main}`,

          "&:hover": {
            borderColor: color.light,
            color: color.light,
          },

          "&:active": {
            borderColor: color.dark,
            color: color.dark,
          },

          "&.Mui-selected": {
            borderColor: "transparent !important",
            outline: `2px solid ${color.dark}`,
          },

          "&.Mui-disabled": {
            borderColor: theme.palette["grey-light"],
          },
        },

        "&.icon-text": {
          flexDirection: "column",
          gap: "1px",
          minWidth: "36px",
          padding: "4px",
        },

        "&.icon": {
          width: "36px",
        },
      };
    },
  };

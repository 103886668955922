import { useState } from "react";
import { Typography, Button, styled, Box, Stack } from "@mui/material";

import ModalWindow from "../../../../common/components/ModalWindow";
import DTProjectNameInput from "../../../dashboard/components/settingFields/DTProjectNameInput";
import DTProjectMeasurementSelector from "../../../dashboard/components/settingFields/DTProjectMeasurementSelector";
import SaveProjectButton from "./SaveProjectButton";

import { useTextInputState } from "../../../../common/hooks/useTextInputState";
import { DTProjectInterface } from "../../../../common/interfaces";
import { MeasurementSystemMode } from "../../../../common/enums";
import { textNameValidation } from "../../../../common/validation";

type EditProjectProps = {
  onClose: () => void;
  dtProject: DTProjectInterface;
  disabled: boolean;
};

const EditProjectModal = ({
  onClose,
  dtProject,
  disabled,
}: EditProjectProps) => {
  const projectName = useTextInputState(
    {
      value: dtProject.displayName,
      error: false,
      errorMessage: "",
      trackError: true,
    },
    textNameValidation
  );

  const [selectedSystem, selectSystem] = useState<MeasurementSystemMode>(
    dtProject.measurementSystem
  );

  const handleOnChangeName = (value: string) => {
    projectName.onChange(value);
  };

  const onMeasurementChange = (system: MeasurementSystemMode): void => {
    selectSystem(system);
  };

  return (
    <ModalWindow isOpen={true} onClose={onClose}>
      <Wrapper>
        <Typography variant="h2">Edit project</Typography>

        <DTProjectNameInput
          projectNameState={projectName.state}
          onProjectNameInputChange={handleOnChangeName}
        />

        <DTProjectMeasurementSelector
          defaultValue={selectedSystem}
          onProjectMeasurementSystemChange={onMeasurementChange}
        />
        <ButtonGroup>
          <Button
            disabled={disabled}
            onClick={onClose}
            variant="secondary"
            color="blue"
          >
            Cancel
          </Button>
          <SaveProjectButton
            CB={onClose}
            dtProject={{
              ...dtProject,
              displayName: projectName.state.value,
              measurementSystem: selectedSystem,
            }}
            allowToSave={!projectName.state.error}
          />
        </ButtonGroup>
      </Wrapper>
    </ModalWindow>
  );
};

export default EditProjectModal;

const Wrapper = styled(Stack)(() => ({
  gap: "10px",
}));

const ButtonGroup = styled(Box)(() => ({
  display: "flex",
  gap: "8px",
  marginLeft: "auto",
  paddingTop: "16px",
}));

import { TypographyOptions } from "@mui/material/styles/createTypography";

export const ThemeTypography: TypographyOptions = {
  fontFamily: "Inter",
  h1: {
    fontFamily: "Inter",
    fontWeight: 500,
    fontSize: 36,
    lineHeight: "44px",
    "@media (max-width: 1500px)": {
      fontSize: "30px",
      lineHeight: "38px",
    },
  },
  h2: {
    fontFamily: "Inter",
    fontWeight: 500,
    fontSize: 20,
    lineHeight: "38px",
    "@media (max-width: 1500px)": {
      fontSize: "15px",
      lineHeight: "33px",
    },
  },
  title: {
    fontFamily: "Inter",
    fontWeight: 600,
    fontSize: 28,
    lineHeight: "36px",
    "@media (max-width: 1500px)": {
      fontSize: "23px",
      lineHeight: "31px",
    },
  },
  body20Medium: {
    fontFamily: "Inter",
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "22px",
    "@media (max-width: 1500px)": {
      fontSize: "15px",
      lineHeight: "18px",
    },
  },
  body16Medium: {
    fontFamily: "Inter",
    fontWeight: 500,
    fontSize: 16,
    lineHeight: "24px",
    "@media (max-width: 1500px)": {
      fontSize: "14px",
      lineHeight: "22px",
    },
  },
  body14Medium: {
    fontFamily: "Inter",
    fontWeight: 500,
    fontSize: 14,
    lineHeight: "20px",
  },
  body12Medium: {
    fontFamily: "Inter",
    fontWeight: 500,
    fontSize: 12,
    lineHeight: "16px",
  },
  body10Medium: {
    fontFamily: "Inter",
    fontWeight: 500,
    fontSize: 10,
    lineHeight: "13px",
  },
  body8Medium: {
    fontFamily: "Inter",
    fontWeight: 500,
    fontSize: 8,
    lineHeight: "10px",
  },
  body14Regular: {
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: 14,
    lineHeight: "20px",
  },
  body12Regular: {
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: 12,
    lineHeight: "16px",
  },
  body12RegularItalic: {
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: 12,
    fontStyle: "italic",
    lineHeight: "16px",
  },
  button: {
    fontFamily: "Inter",
    fontSize: 14,
    lineHeight: "20px",
    textTransform: "none",
    "@media (max-width: 1500px)": {
      fontSize: "12px",
      lineHeight: "18px",
    },
  },
};

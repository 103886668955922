import { Typography } from "@mui/material";

type Props = {
  description: string;
};

const WeatherDescription = ({ description }: Props): JSX.Element => {
  return (
    <Typography variant={"body12Medium"} color={"white"}>
      {description[0].toUpperCase() + description.slice(1).toLowerCase()}
    </Typography>
  );
};

export default WeatherDescription;

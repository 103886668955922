import { CSSInterpolation, ComponentsOverrides, Theme } from "@mui/material";

export const ThemeMuiOutlinedInput: ComponentsOverrides<Theme>["MuiOutlinedInput"] =
  {
    root: ({ theme }): CSSInterpolation => ({
      width: "100%",
      height: "29px",
      fontFamily: "Inter",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "16px",
      paddingRight: "0",

      input: {
        color: theme.palette["base-label"],
        padding: "6px",
        borderRadius: "4px",

        "&::placeholder": {
          color: theme.palette["base-hint"],
          opacity: 1,
        },
      },

      "&:not(.Mui-error):not(.Mui-disabled)": {
        ".MuiOutlinedInput-notchedOutline": {
          borderColor: `${theme.palette["grey-dark"]}`,
        },

        "&:hover .MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline":
          {
            borderColor: `${theme.palette.blue.light}`,
          },
      },

      "&.Mui-error .MuiOutlinedInput-notchedOutline": {
        borderColor: `${theme.palette.red.main} !important`,
      },

      "&.Mui-disabled": {
        backgroundColor: theme.palette["base-background"],

        ".MuiOutlinedInput-notchedOutline": {
          borderColor: theme.palette["grey-light"],
        },

        "input::placeholder": {
          color: theme.palette["grey-dark"],
          WebkitTextFillColor: theme.palette["grey-dark"],
        },

        "svg, path, circle": {
          fill: theme.palette["grey-dark"],
        },
      },

      "& .MuiSelect-select": {
        padding: "6px 12px 6px 6px",
      },

      ".MuiInputAdornment-root button": {
        padding: "7px",
      },
    }),
  };

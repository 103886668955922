import { useMutation } from "@apollo/client";

import { AddClientMutation, AddClientMutationVariables } from "../../API";
import { useCustomerIdGuard } from "../../common/hooks/useCustomerIdGuard";
import { ADD_CLIENT } from "../../common/operations/mutations";

export const useCreateClient = () => {
  const selectedCustomerId = useCustomerIdGuard();

  const [addClient, { data, loading }] = useMutation<
    AddClientMutation,
    AddClientMutationVariables
  >(ADD_CLIENT);

  const createClient = (clientName: string) => {
    return addClient({
      variables: {
        input: {
          customerId: selectedCustomerId,
          clientName: clientName.trim(),
        },
      },
      // TODO: code for update client
      // update: (cache, response): void => {
      //   cache.modify({
      //     fields: {
      //       getClientByCustomer(existing) {
      //         const newClientRef = cache.writeFragment({
      //           data: response.data?.addClient,
      //           fragment: gql`
      //             fragment NewClient on Client {
      //               clientName
      //               customerId
      //               id
      //             }
      //           `,
      //         });

      //         if (!existing) {
      //           return response?.data;
      //         }

      //         return {
      //           ...existing,
      //           items: [...existing.items, newClientRef],
      //         };
      //       },
      //     },
      //   });
      // },
    });
  };

  return { createClient, data, loading };
};

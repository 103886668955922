import { Auth } from "aws-amplify";

export const getJwtToken = async (): Promise<string> => {
  try {
    const session = await Auth.currentSession();
    const jwtToken = session.getIdToken().getJwtToken();

    return jwtToken;
  } catch (error) {
    return "";
  }
};

import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { SubmitHandler, useForm } from "react-hook-form";

import MainPaperWrapper from "../../common/components/item/MainPaperWrapper";
import BreadcrumbNavigation from "../../common/components/tabs/BreadcrumbNavigation";
import { RouteEnum } from "../../common/models/enums";
import StyledLoadingButton from "../../common/providers/theme/design-tokens/LoadingButton/StyledLoadingButton";
import {
  errorNotification,
  successNotification,
} from "../../common/variables/notification";
import { useCreateClient } from "../hooks/useCreateClient";
import { IClientUploadsForm } from "../variables/clientUploadsData";
import AddNewClientForm from "./AddNewClientForm";

const AddNewClientPage = (): JSX.Element => {
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IClientUploadsForm>();
  const { createClient, loading } = useCreateClient();

  const handleCancel = (): void => {
    navigate(RouteEnum.LeakFinderClientUploads);
  };

  const breadcrumbItems = useMemo(
    () => [
      { label: "Client uploads", path: RouteEnum.LeakFinderClientUploads },
      {
        label: "Add new client",
      },
    ],
    []
  );

  const onSubmit: SubmitHandler<IClientUploadsForm> = data => {
    createClient(data.clientName)
      .then((result): void => {
        if (result.errors) {
          errorNotification("New Client Failed To Create");

          console.error(result.errors);

          return;
        }

        successNotification(`${data.clientName} client is added`);

        navigate(RouteEnum.LeakFinderClientUploads);
      })
      .catch((error: Error): void => {
        errorNotification(error.message);

        console.error(error);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h3" sx={{ paddingBottom: ".5em" }}>
        Add new client
      </Typography>
      <Box sx={{ marginBottom: "1em" }}>
        <BreadcrumbNavigation items={breadcrumbItems} />
      </Box>
      <MainPaperWrapper>
        <AddNewClientForm control={control} errors={errors} />
      </MainPaperWrapper>
      <Stack direction="row-reverse" spacing={2} sx={{ paddingTop: 3 }}>
        <StyledLoadingButton
          sx={{
            marginTop: "1.5em",
          }}
          type="submit"
          loading={loading}
          loadingPosition="start"
          variant="contained"
          color="primary"
        >
          Add new client
        </StyledLoadingButton>
        <StyledLoadingButton
          sx={{
            marginTop: "1.5em",
          }}
          loading={loading}
          loadingPosition="start"
          variant="outlined"
          color="inherit"
          onClick={handleCancel}
        >
          Cancel
        </StyledLoadingButton>
      </Stack>
    </form>
  );
};

export default AddNewClientPage;

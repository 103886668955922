import { RefObject } from "react";
import uuid from "react-uuid";
import { OutlinedInput, TypographyProps } from "@mui/material";

import { HintInterface } from "./components/InputHint";
import InputContainer from "./components/InputContainer";

interface TextInputPropsInterface {
  inputRef?: RefObject<HTMLInputElement> | null;
  label?: string;
  labelFontVariant?: TypographyProps["variant"];
  value?: string;
  placeholder?: string;
  type?: string;
  hint?: HintInterface;
  isError?: boolean;
  disabled?: boolean;
  required?: boolean;
  readonly?: boolean;
  endAdornment?: JSX.Element;
  startAdornment?: JSX.Element;
  viewType?: "row" | "column";
  labelWidth?: string;
  inputWidth?: string;
  handleOnChange?: (value: string) => void;
  handleOnClick?: () => void;
}

const TextInput = ({
  inputRef,
  label,
  labelFontVariant,
  value,
  placeholder,
  hint,
  isError,
  disabled,
  required,
  readonly,
  endAdornment,
  startAdornment,
  viewType = "column",
  labelWidth = "100%",
  inputWidth = "100%",
  handleOnChange,
  handleOnClick,
}: TextInputPropsInterface): JSX.Element => {
  const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value as string;

    handleOnChange && handleOnChange(value);
  };

  const id = label || uuid();

  return (
    <InputContainer
      id={id}
      label={label}
      labelFontVariant={labelFontVariant}
      hint={hint}
      required={required}
      viewType={viewType}
      labelWidth={labelWidth}
      inputWidth={inputWidth}
    >
      <OutlinedInput
        ref={inputRef}
        id={`${label}-input`}
        value={value}
        placeholder={placeholder}
        error={isError}
        disabled={disabled}
        readOnly={readonly}
        endAdornment={endAdornment}
        startAdornment={startAdornment}
        onChange={onChange}
        onClick={handleOnClick}
      />
    </InputContainer>
  );
};

export default TextInput;

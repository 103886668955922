import { useContext } from "react";
import { ListItem, styled } from "@mui/material";

import TowerPanelListItemHeader from "./TowerPanelListItemHeader";

import { ProjectContext } from "../../../context";

import {
  ModeTypeEnum,
  ZoneDataInterface,
} from "../../../context/ProjectContext/ProjectEntitesTypes";

interface CameraZoneListItemInterface {
  data: ZoneDataInterface;
}

const CameraZoneListItem = ({
  data,
}: CameraZoneListItemInterface): JSX.Element => {
  const { sceneStarted, handleSetActiveZoneId, activeZoneId, mode } =
    useContext(ProjectContext);

  const handleOnItemClick = () => {
    if (sceneStarted && mode === ModeTypeEnum.standard) {
      handleSetActiveZoneId(data);
    }
  };

  return (
    <StyledListItem>
      <TowerPanelListItemHeader
        text={data.name}
        selected={data.id === activeZoneId}
        onItemClick={handleOnItemClick}
        withWarningIcon={!data.isSaved}
      />
    </StyledListItem>
  );
};

export default CameraZoneListItem;

const StyledListItem = styled(ListItem)(() => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  background: "transparent",
  padding: "0px",
}));

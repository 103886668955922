import { FC } from "react";

import Box from "@mui/material/Box";

import { Client, PaginatedGroups } from "../../API";
import SimplePaperWrapper from "../../common/components/item/SimplePaperWrapper";
import { useVideosTableSelectionReducer } from "../store/useVideosTableSelectionReducer";
import { useClientUploadsSearchVariable } from "../variables/clientUploads";
import ClientCard from "./ClientCard";
import NoSearchResults from "./search/NoSearchResults";

interface IClientCardsProps {
  deleteGroup: (clientId: string, groupId: string) => void;
  clients: Client[];
  groups: PaginatedGroups[];
}

const ClientCards: FC<IClientCardsProps> = ({
  clients,
  deleteGroup,
  groups,
}) => {
  const clientUploadsSearchValue = useClientUploadsSearchVariable();

  const { state, dispatch } = useVideosTableSelectionReducer();

  const items = clients.filter(client => {
    if (!clientUploadsSearchValue) {
      return true;
    }

    const clientGroups = groups.find(group =>
      group.items.some(item => item.clientId === client.id)
    );

    return (
      client.clientName
        .toLowerCase()
        .includes(clientUploadsSearchValue.toLowerCase()) ||
      clientGroups?.items.some(item =>
        item.groupName
          .toLowerCase()
          .includes(clientUploadsSearchValue.toLowerCase())
      )
    );
  });

  if (items.length === 0 && clientUploadsSearchValue !== "") {
    return <NoSearchResults />;
  }

  return (
    <Box>
      {items.map(client => {
        const clientGroup = groups.find(group =>
          group.items.some(item => item.clientId === client.id)
        );

        const clientGroups = clientGroup?.items ?? [];

        const filteredGroups = clientGroups.filter(group => {
          if (clientUploadsSearchValue === "") {
            return true;
          }

          return group.groupName
            .toLowerCase()
            .includes(clientUploadsSearchValue.toLowerCase());
        });

        const groupItems =
          items.length > 0 && filteredGroups.length === 0
            ? clientGroups
            : filteredGroups;

        const expanded =
          clientUploadsSearchValue !== "" &&
          groupItems.length > 0 &&
          filteredGroups.length > 0;

        return (
          <SimplePaperWrapper
            key={client.id}
            sx={{
              marginBottom: "8px",
            }}
          >
            <ClientCard
              deleteGroup={deleteGroup}
              client={client}
              groups={groupItems}
              opened={expanded}
              state={state}
              dispatch={dispatch}
            />
          </SimplePaperWrapper>
        );
      })}
    </Box>
  );
};

export default ClientCards;

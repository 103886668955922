import {
  BufferGeometry,
  Float32BufferAttribute,
  Object3D,
  Points,
  PointsMaterial,
} from "three";

import { degToRad } from "three/src/math/MathUtils";

import drop from "../../assets/texture/drop.png";
import { GROUND_RADIUS_IN_M } from "../Constants";
import { loadTexture } from "../SceneUtils/AssetLoaders";

class RainSystem {
  constructor(scene) {
    this.scene = scene;

    this.rainCountMax = 800000;

    this.maxRange = GROUND_RADIUS_IN_M * 2;

    this.minRange = this.maxRange / 4;

    this.minHeight = 0;

    this.windAngle = 0;

    this.windGust = 8;

    this.windSpeed = 4;

    this.texture = null;
  }

  addRain = async (rain, wind, center) => {
    this.removeRain();

    this.windAngle = wind.deg;

    this.windSpeed = wind.speed;

    if (rain < 6) {
      this.rainCount = this.rainCountMax / 3;
    } else if (rain < 18) {
      this.rainCount = this.rainCountMax / 2;
    } else {
      this.rainCount = this.rainCountMax;
    }

    this.center = center;

    this.positions = [];

    const rainGeo = new BufferGeometry();

    for (let i = 0; i < this.rainCount; i++) {
      this.positions.push(
        (Math.random() - 0.5) * (this.maxRange - this.minRange),
        (Math.random() - 0.5) * this.minRange + this.minHeight,
        (Math.random() - 0.5) * (this.maxRange - this.minRange)
      );
    }

    rainGeo.setAttribute(
      "position",
      new Float32BufferAttribute(this.positions, 3)
    );

    if (!this.texture) {
      this.texture = await loadTexture(drop);
    }

    const material = new PointsMaterial({
      size: 0.1,
      color: 0xc7d9dc,
      transparent: true,
      map: this.texture,
    });

    this.rainMesh = new Points(rainGeo, material);

    this.scene.add(this.rainMesh);

    this.rainMesh.position.copy(center);
  };

  removeRain = () => {
    this.rainMesh && this.scene.remove(this.rainMesh);
  };

  rainAnimation = dt => {
    const positions = this.rainMesh.geometry.attributes.position.array;

    this.center = this.rainMesh.geometry.boundingSphere.center.y;

    const Coef = 2200;
    const CoefXZ = Coef / 50;
    const g = 9.8;

    for (let i = 0; i < this.rainCount * 3; i += 3) {
      const step = dt * dt * g * (Math.random() + 0.01) * Coef;

      positions[i + 1] -= step;

      if (this.windAngle > 0) {
        positions[i] +=
          Math.sin(degToRad(this.windAngle)) * this.windSpeed * dt * CoefXZ;

        positions[i + 2] +=
          Math.cos(degToRad(this.windAngle)) * this.windSpeed * dt * CoefXZ;
      }

      if (positions[i + 1] <= this.center) {
        positions[i] = (Math.random() - 0.5) * (this.maxRange - this.minRange);

        positions[i + 1] =
          (Math.random() - 0.5) * this.minRange + this.minHeight;

        positions[i + 2] =
          (Math.random() - 0.5) * (this.maxRange - this.minRange);
      }

      this.rainMesh.geometry.attributes.position.needsUpdate = true;
    }
  };

  update(dt) {
    this.rainMesh && this.rainAnimation(dt);
  }
}

export default RainSystem;

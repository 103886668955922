import { useContext } from "react";
import { Button, Typography } from "@mui/material";

import { IconPlus } from "../../../../common/assets/icons/svgAssets";

import CreateDTProjectContext, {
  CreateDTProjectContextInterface,
} from "../../context/CreateDTProjectContext/CreateDTProjectContext";

const NewDTProjectButton = () => {
  const { openModal } = useContext(
    CreateDTProjectContext
  ) as CreateDTProjectContextInterface;

  return (
    <Button
      onClick={openModal}
      variant="primary"
      color="green"
      startIcon={<IconPlus width="20px" />}
    >
      <Typography variant="body16Medium">New project</Typography>
    </Button>
  );
};

export default NewDTProjectButton;

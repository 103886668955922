import { useContext } from "react";
import { ToggleButton, Typography } from "@mui/material";

import { DeleteIcon } from "../../../assets/icons/svgAssets";

import { ProjectContext } from "../../../context";

const ObjectTowerDeleteButton = () => {
  const { isLoading, activeObjectsListId, handleDeleteObjectTowerData } =
    useContext(ProjectContext);

  const onDeleteObjectButton = () => {
    handleDeleteObjectTowerData(activeObjectsListId);
  };

  return (
    <ToggleButton
      color="blue"
      className="icon-text"
      value="delete"
      disabled={!activeObjectsListId.length || isLoading}
      selected={false}
      onClick={onDeleteObjectButton}
    >
      <DeleteIcon />
      <Typography variant="body8Medium" component="p">
        Delete
      </Typography>
    </ToggleButton>
  );
};

export default ObjectTowerDeleteButton;

import { ReactNode } from "react";

import Typography from "@mui/material/Typography";

const MediaErrorSubTitleStyled = ({ children }: { children: ReactNode }) => {
  return (
    <Typography
      variant="body2Regular"
      sx={{
        color: theme => theme.palette.text.secondary,
      }}
    >
      {children}
    </Typography>
  );
};

export default MediaErrorSubTitleStyled;

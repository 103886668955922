import { Box, Button, List, styled } from "@mui/material";
import ZoneListItem from "./ZoneListItem";

interface FieldOfCamerasViewControlPanelInterface {
  zoneList: any[];
  onButtonToggleClick: (isVisibleFieldOfView: boolean) => void;
  onItemclick: (id: string[]) => void;
}

const FieldOfZonesViewControlPanel = ({
  zoneList,
  onButtonToggleClick,
  onItemclick,
}: FieldOfCamerasViewControlPanelInterface): JSX.Element => {
  const handleOnShowAllClick = () => {
    onButtonToggleClick(true);
  };

  const handleOnHideAllClick = () => {
    onButtonToggleClick(false);
  };

  const showAllDisabled = zoneList.every(camera =>
    camera.zones.every((zone: any) => zone.isVisible)
  );
  const hideAllDisabled = zoneList.every(camera =>
    camera.zones.every((zone: any) => !zone.isVisible)
  );

  return (
    <Wrapper>
      <StyledList>
        {zoneList.map(data => (
          <ZoneListItem
            key={`zoneGroup-${data.id}`}
            data={data}
            onItemClick={onItemclick}
          />
        ))}
      </StyledList>
      <ButtonWrapper>
        <Button
          variant="outlined"
          color="blue"
          disabled={showAllDisabled}
          onClick={handleOnShowAllClick}
        >
          Show all
        </Button>
        <Button
          variant="outlined"
          color="blue"
          disabled={hideAllDisabled}
          onClick={handleOnHideAllClick}
        >
          Hide all
        </Button>
      </ButtonWrapper>
    </Wrapper>
  );
};

export default FieldOfZonesViewControlPanel;

const Wrapper = styled(Box)(({ theme }) => ({
  position: "absolute",
  bottom: "36px",
  right: "0px",

  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-end",
  width: "auto",

  borderRadius: "4px",
  boxShadow: "0px 6px 12px 0px rgba(0, 0, 0, 0.10)",
  backgroundColor: theme.palette.base.white,
  padding: "4px",
}));

const StyledList = styled(List)(({ theme }) => ({
  padding: "0px",
  maxHeight: "180px",
  overflowY: "auto",

  "&::-webkit-scrollbar": {
    width: "7px",
    height: "7px",
  },

  "&::-webkit-scrollbar-track": {
    background: "transparent",
  },

  "&::-webkit-scrollbar-thumb": {
    backgroundColor: theme.palette["blue-scrollbar"],
    borderRadius: "30px",
  },
}));

const ButtonWrapper = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  padding: "8px 4px",
  gap: "4px",
  "& button": {
    minWidth: "100px",
  },
}));

import { DOMParser } from "xmldom";

import { createBufferDataUrl, readZipFile } from ".";

interface LatLonBox {
  north: number;
  south: number;
  east: number;
  west: number;
}

export const isKMZHasFile = async (
  file: File,
  fileExtension: string
): Promise<boolean> => {
  const zip = await readZipFile(file);

  for (const fileName in zip.files) {
    if (fileName.toLowerCase().endsWith(fileExtension)) {
      return true;
    }
  }

  return false;
};

export const extractImagesFromKMZFile = async (
  file: File
): Promise<string[]> => {
  const zip = await readZipFile(file);
  const pngFiles = zip.file(/\.png$/);

  if (pngFiles.length > 0) {
    const pngPromises: Promise<string>[] = pngFiles.map(async pngFile => {
      const pngContent: ArrayBuffer = await pngFile.async("arraybuffer");

      return createBufferDataUrl(pngContent, "image/png");
    });

    return Promise.all(pngPromises);
  } else {
    return [];
  }
};

export const getCenterFromKMZ = async (
  file: File
): Promise<{ latitude: number; longitude: number } | null> => {
  const zip = await readZipFile(file);

  const kmlFile = zip.file(/\.kml$/)[0];

  if (kmlFile) {
    const kmlContent: string = await kmlFile.async("text");
    const latLonBoxes = extractLatLonBoxes(kmlContent);

    if (latLonBoxes.length > 0) {
      return calculateLatLonBoxesAverageCenter(latLonBoxes);
    }

    return null;
  }

  return null;
};

export const getLatLonBoxesFromKMZ = async (
  file: File
): Promise<LatLonBox[] | null> => {
  const zip = await readZipFile(file);

  const kmlFile = zip.file(/\.kml$/)[0];

  if (kmlFile) {
    const kmlContent: string = await kmlFile.async("text");
    const latLonBoxes = extractLatLonBoxes(kmlContent);

    return latLonBoxes;
  }

  return null;
};

const extractLatLonBoxes = (kmlContent: string): LatLonBox[] => {
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(kmlContent, "application/xml");
  const latLonBoxElements = xmlDoc.getElementsByTagName("LatLonBox");

  const latLonBoxes: LatLonBox[] = Array.from(latLonBoxElements).map(el => {
    return {
      north: parseFloat(el.getElementsByTagName("north")[0].textContent || "0"),
      south: parseFloat(el.getElementsByTagName("south")[0].textContent || "0"),
      east: parseFloat(el.getElementsByTagName("east")[0].textContent || "0"),
      west: parseFloat(el.getElementsByTagName("west")[0].textContent || "0"),
    };
  });

  return latLonBoxes;
};

export const calculateLatLonBoxCenter = (
  latLonBox: LatLonBox
): { latitude: number; longitude: number } => {
  return {
    latitude: (latLonBox.north + latLonBox.south) / 2,
    longitude: (latLonBox.east + latLonBox.west) / 2,
  };
};

export const calculateLatLonBoxesAverageCenter = (
  latLonBoxes: LatLonBox[]
): { latitude: number; longitude: number } => {
  const totalLatLon = latLonBoxes.reduce(
    (total, item) => {
      const currentBox = calculateLatLonBoxCenter(item);

      return {
        latitude: total.latitude + currentBox.latitude,
        longitude: total.longitude + currentBox.longitude,
      };
    },
    { latitude: 0, longitude: 0 }
  );

  const averageLatitude = totalLatLon.latitude / latLonBoxes.length;
  const averageLongitude = totalLatLon.longitude / latLonBoxes.length;

  return { latitude: averageLatitude, longitude: averageLongitude };
};

interface Point {
  latitude: number;
  longitude: number;
}

export const generateLatLonBoxesGridPoints = (
  latLonBox: LatLonBox,
  numRows: number,
  numCols: number
): Point[] => {
  const points: Point[] = [];

  for (let row = 0; row < numRows; row++) {
    for (let col = 0; col < numCols; col++) {
      const latitude =
        latLonBox.south +
        (latLonBox.north - latLonBox.south) * (row / (numRows - 1));
      const longitude =
        latLonBox.west +
        (latLonBox.east - latLonBox.west) * (col / (numCols - 1));

      points.push({ latitude, longitude });
    }
  }

  return points;
};

export const getLatLonBoxDemensions = (
  latLonBox: LatLonBox
): { width: number; height: number } => {
  const earthRadius = 6371000;

  const lat1 = latLonBox.south;
  const lon1 = latLonBox.west;
  const lat2 = latLonBox.north;
  const lon2 = latLonBox.east;

  const deltaLat = (lat2 - lat1) * (Math.PI / 180);
  const deltaLon = (lon2 - lon1) * (Math.PI / 180);

  const a =
    Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
    Math.cos(lat1 * (Math.PI / 180)) *
      Math.cos(lat2 * (Math.PI / 180)) *
      Math.sin(deltaLon / 2) *
      Math.sin(deltaLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const width = earthRadius * c;
  const height = deltaLat * earthRadius;

  return {
    width,
    height,
  };
};

import { createContext } from "react";

import { DTObject } from "../../../../API";

export interface ProjectObjects3dContextInterface {
  object3dData: DTObject[] | null;
  loading: boolean;
}

export const ProjectObjects3dContext =
  createContext<ProjectObjects3dContextInterface>(
    {} as ProjectObjects3dContextInterface
  );

import { Uniform, Color } from "three";

import { RenderPass } from "three/addons/postprocessing/RenderPass";
import { EffectComposer } from "three/addons/postprocessing/EffectComposer";
import { ShaderPass } from "three/addons/postprocessing/ShaderPass";
import { FilmPass } from "three/addons/postprocessing/FilmPass";

import { GammaCorrectionShader } from "three/addons/shaders/GammaCorrectionShader";
import { ColorifyShader } from "three/addons/shaders/ColorifyShader";
import { VignetteShader } from "three/addons/shaders/VignetteShader";
import { BleachBypassShader } from "three/addons/shaders/BleachBypassShader";

export default class PostEffects {
  constructor(renderer, scene) {
    this.renderer = renderer;

    this.scene = scene;
  }

  initComposer(camera) {
    this.composer = new EffectComposer(this.renderer);

    this.composer.addPass(new RenderPass(this.scene, camera));

    this.addGasCameraEffect();
  }

  addGasCameraEffect() {
    const effectColorify1 = new ShaderPass(ColorifyShader);

    effectColorify1.uniforms["color"] = new Uniform(new Color(1, 1, 1));

    const gammaCorrection = new ShaderPass(GammaCorrectionShader);

    gammaCorrection.uniforms["tDiffuse"].value = 1;

    const effectFilmBW = new FilmPass(0.1, 0.3, 2048, true);

    const effectVignette = new ShaderPass(VignetteShader);

    effectVignette.uniforms["offset"].value = 0.97;

    effectVignette.uniforms["darkness"].value = -0.1;

    const bleachEffect = new ShaderPass(BleachBypassShader);

    bleachEffect.uniforms["opacity"].value = 0.3;

    this.composer.addPass(gammaCorrection);

    this.composer.addPass(effectColorify1);

    this.composer.addPass(effectFilmBW);

    this.composer.addPass(effectVignette);

    this.composer.addPass(bleachEffect);
  }

  resize(w, h) {
    this.composer.setSize(w, h);
  }

  render(camera) {
    const renderPass = this.composer.passes.find(
      pass => pass instanceof RenderPass
    );

    if (renderPass && camera) {
      renderPass.camera = camera;

      this.composer.render();
    }
  }
}

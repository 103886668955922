import { ApolloError, gql, useMutation } from "@apollo/client";

import { ADD_CUSTOMER_DT_OBJECT } from "../../../../common/operations/mutations";
import { DTObject, DTObjectInput } from "../../../../API";

export interface UseAddCustomerDTObjectDataInterface {
  addCustomerDTObject: DTObject | null;
}

export interface UseAddCustomerDTObjectInterface {
  createCustomerDTObject: (input: DTObjectInput) => Promise<any>;
  data: UseAddCustomerDTObjectDataInterface;
  loading: boolean;
  error: ApolloError | undefined;
}

export const useAddCustomerDTObject = (): UseAddCustomerDTObjectInterface => {
  const [addCustomerDTObject, { data, loading, error }] = useMutation(
    ADD_CUSTOMER_DT_OBJECT
  );

  const createCustomerDTObject = async (input: DTObjectInput): Promise<any> => {
    return await addCustomerDTObject({
      variables: {
        input,
      },
      update(cache, response) {
        cache.modify({
          fields: {
            getCustomerDTObjects(existing) {
              const newDTObjectRef = cache.writeFragment({
                data: response.data?.addCustomerDTObject,
                fragment: gql`
                  fragment NewDTObject on DTObject {
                    id
                    objectId
                    objectName
                    objectType
                    objectManufacturerName
                    filesInfo
                    isCustomerObject
                    version
                  }
                `,
              });

              if (!existing) {
                return response?.data;
              }

              return [...existing, newDTObjectRef];
            },
          },
        });
      },
    });
  };

  return { createCustomerDTObject, data, loading, error };
};

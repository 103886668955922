import React from "react";
import { Box, Button, Typography, useTheme } from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { FieldArrayWithId, useForm } from "react-hook-form";
import ItemDetailTable from "../../../../common/components/item/ItemDetailTable";
import {
  LeakFinderAnnotation,
  VideoAnalysisFormState,
} from "../../UploadVideoGroupPage";
import AnnotationForm from "../AnnotationForm";
import {
  errorNotification,
  successNotification,
} from "../../../../common/variables/notification";

interface LeakFinderAnnotationContainerProps {
  focusAreaMarker: boolean | number;
  annotations?: LeakFinderAnnotation;
  setFormValue: (newValue: string, annotationIndex: number) => void;
  focusAreaMarkerChange: (state: boolean | number) => void;
  onAddAnnotation: (name: string) => void;
  onDeleteAnnotation: (index: number, name: string) => void;
  focusAreaFields: FieldArrayWithId<
    VideoAnalysisFormState,
    `videos.${number}.annotations.focusAreas`,
    "id"
  >[];
}

export type AnnotationNameFormState = {
  name: string;
};

const LeakFinderAnnotationContainer: React.FC<
  LeakFinderAnnotationContainerProps
> = ({
  focusAreaMarker,
  focusAreaFields,
  focusAreaMarkerChange,
  onDeleteAnnotation,
  onAddAnnotation,
  annotations,
  setFormValue,
}) => {
  const { handleSubmit, reset, control, setValue } =
    useForm<AnnotationNameFormState>();

  const theme = useTheme();

  const focusAreas = annotations?.focusAreas ?? [];
  const isAddingAnnotationOpen = focusAreaMarker === true;

  const putAnnotationName = (data: AnnotationNameFormState) => {
    try {
      onAddAnnotation(data.name);

      successNotification(`${data.name} focus area is added`);
    } catch (error) {
      errorNotification("Failed to add focus area");
    }

    reset();
  };

  const saveChangesHandler = (data: AnnotationNameFormState) => {
    try {
      if (typeof focusAreaMarker === "number") {
        setFormValue(data.name, focusAreaMarker);
      }

      focusAreaMarkerChange(false);

      successNotification("Changes are saved");
    } catch (error) {
      errorNotification("Failed to save changes");
    }
  };

  const addAnnotationSubmit = () => {
    if (typeof focusAreaMarker !== "boolean") {
      handleSubmit(saveChangesHandler)();

      return;
    }

    handleSubmit(putAnnotationName)();
  };

  const addAnnotationHandler = () => {
    focusAreaMarkerChange(true);

    reset();
  };

  const onCancelHandler = () => {
    focusAreaMarkerChange(false);

    reset();
  };

  const openEditAnnotation = (index: number) => {
    if (focusAreas[index]) setValue("name", focusAreas[index].name);

    focusAreaMarkerChange(index);
  };

  const annotationFields = focusAreaFields.map((field, index) => {
    if (index === focusAreaMarker) {
      return {
        children: (
          <Box sx={{ margin: "0.5em 0" }}>
            <AnnotationForm
              key={field.id}
              editing
              control={control}
              onCancelHandler={onCancelHandler}
              addAnnotationSubmit={addAnnotationSubmit}
            />
          </Box>
        ),
      };
    }

    return {
      label: "NAME",
      value: field.name,
      onDeleteHandler: () => {
        try {
          onDeleteAnnotation(index, field.name);

          successNotification(`${field.name} focus area is deleted`);
        } catch (error) {
          errorNotification("Failed to delete focus area");
        }
      },
      onEditHandler: () => openEditAnnotation(index),
      rowStyles: { height: "64px" },
    };
  });

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: "0.5em",
        }}
      >
        <Box>
          <Box>
            <Typography variant="body2Bold">
              Add focus area to the video (optional)
            </Typography>
          </Box>

          <Box>
            <Typography
              sx={{ color: theme.typography.inputLabel.color }}
              variant="body2Regular"
            >
              Area need to be added if you want to analyse only specific part of
              the video. You can add multiple areas
            </Typography>
          </Box>
        </Box>

        <Box>
          <Button
            sx={{
              marginLeft: "auto",
              width: "max-content",
            }}
            disabled={isAddingAnnotationOpen}
            variant="contained"
            size="small"
            startIcon={<AddOutlinedIcon />}
            onClick={addAnnotationHandler}
          >
            Focus area
          </Button>
        </Box>
      </Box>

      {focusAreaFields && (
        <Box sx={{ margin: "1em 0" }}>
          <ItemDetailTable withSeparateBackgroundRows data={annotationFields} />
        </Box>
      )}

      {isAddingAnnotationOpen && (
        <AnnotationForm
          control={control}
          onCancelHandler={onCancelHandler}
          addAnnotationSubmit={addAnnotationSubmit}
        />
      )}
    </>
  );
};

export default LeakFinderAnnotationContainer;

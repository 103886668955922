import { ApolloError, gql, useMutation } from "@apollo/client";

import {
  AddLicensePlateMutation,
  AddLicensePlateMutationVariables,
} from "../../../../../API";
import { useCustomerIdGuard } from "../../../../../common/hooks/useCustomerIdGuard";
import { ADD_LICENSE_PLATE } from "../../../../../common/operations/mutations";
import {
  errorNotification,
  successNotification,
} from "../../../../../common/variables/notification";

export const useCreateLicensePlate = () => {
  const customerId = useCustomerIdGuard();

  const [addLicensePlate, { data, loading }] = useMutation<
    AddLicensePlateMutation,
    AddLicensePlateMutationVariables
  >(ADD_LICENSE_PLATE);

  const createLicensePlate = (
    licensePlate: string,
    categoryId: string,
    company: string,
    driverName: string
  ) => {
    return addLicensePlate({
      variables: {
        input: {
          customerId,
          categoryId,
          licensePlate,
          company,
          driverName,
        },
      },
      onCompleted: response => {
        if (response.addLicensePlate) {
          successNotification("New plate added");
        }
      },
      onError: (result: ApolloError): void => {
        console.error(result.message);

        const { message } = result;
        const didConditionalCheckFail = message.includes(
          "ConditionalCheckFailed"
        );

        if (didConditionalCheckFail) {
          errorNotification(`Duplicate Plate: ${licensePlate}`);
        } else {
          errorNotification("License plate failed to create");
        }

        console.error(result.message);
      },
      update: (cache, response): void => {
        cache.modify({
          fields: {
            getLicensePlatesByCustomer(existing) {
              const newLicensePlateRef = cache.writeFragment({
                data: response.data?.addLicensePlate,
                fragment: gql`
                  fragment NewLicensePlate on LicensePlate {
                    categoryId
                    company
                    driverName
                    id
                    licensePlate
                  }
                `,
              });

              if (!existing) {
                return response?.data;
              }

              return {
                ...existing,
                items: [...existing.items, newLicensePlateRef],
              };
            },
          },
        });
      },
    });
  };

  return { createLicensePlate, data, loading };
};

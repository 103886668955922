import { useTheme } from "@mui/material/styles";

const MaximizeIcon = (): JSX.Element => {
  const theme = useTheme();

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_8783_330)">
        <path
          d="M6 9.99996L1.33333 14.6666M1.33333 14.6666H5.2381M1.33333 14.6666V10.7619"
          stroke={theme.palette.primary.main}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 6.00004L14.6667 1.33337M14.6667 1.33337H10.7619M14.6667 1.33337V5.23814"
          stroke={theme.palette.primary.main}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_8783_330">
          <path d="M0 0H16V16H0V0Z" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MaximizeIcon;

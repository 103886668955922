import { FC } from "react";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { useTheme } from "@mui/material/styles";

type FullPageLoaderProps = {
  onlyContent?: boolean;
};

function hexToRgba(hex: string, alpha = 1): string {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

const FullPageLoader: FC<FullPageLoaderProps> = ({
  onlyContent,
}): JSX.Element => {
  const theme = useTheme();

  if (onlyContent) {
    return (
      <Box
        sx={{
          minHeight: "100%",
          display: "flex",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flex: "1",
            backgroundColor: hexToRgba(theme.palette.otherBackground.main, 0.4),
            zIndex: 9999,
          }}
        >
          <CircularProgress />
        </Box>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        transition: "opacity 500ms ease-in-out",
        backgroundColor: hexToRgba(theme.palette.otherBackground.main, 0.4),
        zIndex: 9999,
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default FullPageLoader;

import { Vector2, Raycaster, EventDispatcher, Group } from "three";

const _onObjectSelectEvent = { type: "selectObject" };
const _onObjectHoverEvent = { type: "hoverObject" };

class SelectObjectOnScene extends EventDispatcher {
  constructor(camera, domElement) {
    super();

    this.enabled = false;

    this.camera = camera;

    this.domElement = domElement;

    this.selectIntersection = null;

    this.hoverIntersection = null;

    this.isMultipleSelection = false;

    this.intersectObjectsArray = [];

    this.pointerDown = new Vector2();

    this.pointerUp = new Vector2();

    this.pointerMove = new Vector2();

    this.raycaster = new Raycaster();

    this.domElement.addEventListener(
      "mousedown",
      this.handleMouseDown.bind(this)
    );

    this.domElement.addEventListener("mouseup", this.handleMouseUp.bind(this));

    this.domElement.addEventListener("contextmenu", event => {
      event.preventDefault();
    });

    this.domElement.addEventListener(
      "mousemove",
      this.handleMouseMove.bind(this)
    );
  }

  dispose() {
    this.domElement.removeEventListener(
      "mousedown",
      this.handleMouseDown.bind(this)
    );

    this.domElement.removeEventListener(
      "mouseup",
      this.handleMouseUp.bind(this)
    );

    this.domElement.removeEventListener(
      "mousemove",
      this.handleMouseMove.bind(this)
    );
  }

  handleMouseDown(event) {
    this.setPointerPosition(event, this.pointerDown);
  }

  handleMouseUp(event) {
    if (!this.enabled) {
      return;
    }

    this.setPointerPosition(event, this.pointerUp);

    this.isMultipleSelection = event.ctrlKey || event.metaKey;

    if (this.pointerDown.equals(this.pointerUp)) {
      this.selectIntersection = this.getIntersection(this.pointerUp);

      this.dispatchEvent(_onObjectSelectEvent);
    }
  }

  handleMouseMove(event) {
    if (!this.enabled) {
      return;
    }

    this.setPointerPosition(event, this.pointerMove);

    this.hoverIntersection = this.getIntersection(this.pointerMove);

    this.dispatchEvent(_onObjectHoverEvent);
  }

  setIntersectingObjects(objectsGroup) {
    this.intersectObjectsArray = [objectsGroup];
  }

  setPointerPosition(event, vector) {
    const rect = this.domElement.getBoundingClientRect();

    const x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
    const y = -((event.clientY - rect.top) / rect.height) * 2 + 1;

    vector.set(x, y);
  }

  getIntersection(pointer) {
    this.raycaster.setFromCamera(pointer, this.camera);

    const intersects = this.raycaster.intersectObjects(
      this.intersectObjectsArray,
      true
    );

    return intersects.length ? intersects[0] : null;
  }
}

export default SelectObjectOnScene;

import { useEffect, useState } from "react";

import {
  useVideoPlayerVariable,
  videoPlayerVariable,
} from "../variables/videoPlayer";
import { useStream } from "./useStream";
import { selectedStreamObjectVariable } from "../variables/selectedStreamObject";
import { usePublishNode } from "../../pages/devices/hooks/usePublishNode";
import { IDeviceTableRow } from "../../pages/devices/hooks/useDeviceTableRows";

export const useStreamProcesses = () => {
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [streamLoading, setStreamLoading] = useState(false);
  const [hasPanTilt, setHasPanTilt] = useState(false);

  const { getStreamName } = useStream();
  const { publishNode } = usePublishNode();

  const isPlayerLoaded = useVideoPlayerVariable();

  const startStreaming = (device: IDeviceTableRow): void => {
    setStreamLoading(true);

    const { node, rowId: deviceId, deviceData, name: deviceName } = device;

    const { id: nodeId } = node;

    const streamName = getStreamName(nodeId, deviceId);

    // set showPanTiltButton according to device data
    setHasPanTilt(deviceData.hasPanTilt);

    publishNode({
      message: JSON.stringify({
        TARGET: "KINESIS_STREAM",
        ACTION: "KINESIS_STREAM_START",
        streamName,
        deviceName,
      }),
      nodeId,
    }).then((): void => {
      selectedStreamObjectVariable({
        streamName,
        deviceName,
        nodeId,
      });

      setStreamLoading(false);
    });
  };

  useEffect((): void => {
    setIsVideoLoaded(isPlayerLoaded);
  }, [isPlayerLoaded]);

  const stopStreaming = (device: IDeviceTableRow): void => {
    const { node, rowId: deviceId, name: deviceName } = device;

    const { id: nodeId } = node;

    const streamName = getStreamName(nodeId, deviceId);

    if (streamName) {
      publishNode({
        message: JSON.stringify({
          TARGET: "KINESIS_STREAM",
          ACTION: "KINESIS_STREAM_END",
          streamName,
          deviceName,
        }),
        nodeId,
      });

      selectedStreamObjectVariable(null);

      videoPlayerVariable(false);
    }
  };

  return {
    isVideoLoaded,
    hasPanTilt,
    streamLoading,
    startStreaming,
    stopStreaming,
  };
};

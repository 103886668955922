import { useContext, useEffect } from "react";
import { Button, Stack, Typography, styled } from "@mui/material";

import ModalWindow from "../../ModalWindow";
import MapLocationInput from "../../MapLocationInput";

import { useMapLocation } from "../../../../pages/dashboard/hooks/useMapLocation";
import { WeatherConditionsContext } from "../../../context";

interface WeatherConditonSettingsInterface {
  onClose: () => void;
}

const WeatherConditonSettings = ({
  onClose,
}: WeatherConditonSettingsInterface): JSX.Element => {
  const mapLocation = useMapLocation();

  const {
    defaultLocation,
    location,
    setCurrentLocation,
    weatherVisibility,
    toggleWeatherVisibility,
  } = useContext(WeatherConditionsContext);

  const onApply = () => {
    setCurrentLocation([
      mapLocation.mapCenterCoordinates.lat,
      mapLocation.mapCenterCoordinates.lng,
    ]);

    !weatherVisibility && toggleWeatherVisibility();

    onClose();
  };

  const onResetToDefault = () => {
    mapLocation.setMapCenterCoordinatesDirectly(defaultLocation);

    if (mapLocation.locationInputRef && mapLocation.locationInputRef.current) {
      const input = Array.from(
        mapLocation.locationInputRef.current.children
      ).find(el => el.id === "Location-input") as HTMLInputElement;

      if (input) {
        input.value = "";
      }
    }
  };

  useEffect(() => {
    mapLocation.setMapCenterCoordinatesDirectly(location);
  }, []);

  return (
    <ModalWindow isOpen={true} onClose={onClose}>
      <ModalWrapper>
        <Typography variant="body20Medium" color="dark">
          Weather simulator
        </Typography>
        <MapLocationInput mapLocation={mapLocation} />
        <Stack
          justifyContent="flex-end"
          alignItems="center"
          direction="row"
          gap="8px"
          pt="16px"
        >
          <Button variant="secondary" color="blue" onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={onResetToDefault} variant="secondary" color="blue">
            Reset to default
          </Button>
          <Button onClick={onApply} variant="contained" color="blue">
            Apply
          </Button>
        </Stack>
      </ModalWrapper>
    </ModalWindow>
  );
};

export default WeatherConditonSettings;

const ModalWrapper = styled(Stack)(() => ({
  gap: "8px",
}));

import { useContext, useEffect, useState } from "react";
import { Box, Collapse, ListItem, styled } from "@mui/material";

import TowerPanelListItemHeader from "./TowerPanelListItemHeader";
import TowerCameraList from "./TowerCameraList";

import {
  ConfiguratorPermissionContext,
  ProjectContext,
} from "../../../context";
import { AddCameraContext } from "../../../../pages/project-editor/context";

import {
  CameraDataInterface,
  ModeTypeEnum,
  SideDataInterface,
} from "../../../context/ProjectContext/ProjectEntitesTypes";

interface TowerSideListItemInterface {
  sideData: SideDataInterface;
  towerId: string;
}

const TowerSideListItem = ({
  sideData,
  towerId,
}: TowerSideListItemInterface): JSX.Element => {
  const {
    sceneStarted,
    handleSetActiveTowerSideIndex,
    handleSetHoveredTowerSideIndex,
    activeObjectsListId,
    activeTowerSideIndex,
    activeCameraId,
    cameras,
    mode,
  } = useContext(ProjectContext);

  const { isAllowEditing } = useContext(ConfiguratorPermissionContext);
  const { openAddCameraModal } = useContext(AddCameraContext);

  const [open, setOpen] = useState<boolean>();

  const sideCamerasData = cameras.filter(
    (camera: CameraDataInterface) =>
      camera.sideIndex === sideData.index && camera.towerId === towerId
  );

  const handleOnItemClick = () => {
    if (sceneStarted && mode === ModeTypeEnum.standard) {
      handleSetActiveTowerSideIndex(sideData.index);
    }
  };

  const handleOnItemOver = () => {
    if (!sceneStarted || mode !== ModeTypeEnum.standard) return;

    handleSetHoveredTowerSideIndex(sideData.index);
  };

  const handleItemLeaved = () => {
    sceneStarted && handleSetHoveredTowerSideIndex(null);
  };

  const handleOnCollapseIconClick = () => {
    if (!sceneStarted || mode !== ModeTypeEnum.standard) return;

    handleSetActiveTowerSideIndex(sideData.index);

    setOpen(prev => !prev);
  };

  const handleOnAddClick = (): void => {
    isAllowEditing &&
      openAddCameraModal({ towerId, sideIndex: sideData.index });
  };

  const isSideHasCameras = sideCamerasData.length > 0;

  const isSideHasActiveCamera =
    isSideHasCameras &&
    sideCamerasData.find(
      (camera: CameraDataInterface) => camera.id === activeCameraId
    );
  const isActive =
    activeObjectsListId.length === 1 &&
    activeObjectsListId[0] === towerId &&
    activeTowerSideIndex === sideData.index;

  const isSelected = isActive && !activeCameraId;

  useEffect(() => {
    if (!isActive) {
      setOpen(false);
    } else if (isActive && activeCameraId && isSideHasActiveCamera) {
      setOpen(true);
    }
  }, [isActive, activeCameraId, isSideHasActiveCamera]);

  return (
    <StyledListItem
      onMouseOver={handleOnItemOver}
      onMouseLeave={handleItemLeaved}
    >
      <TowerPanelListItemHeader
        text={`${sideData.index}. Side (${
          sideCamerasData && sideCamerasData.length
        })`}
        open={isSideHasCameras && open}
        selected={isSelected}
        withCollapseIcon={isSideHasCameras}
        onCollapseIconClick={handleOnCollapseIconClick}
        withAddIcon={isAllowEditing}
        onAddClick={handleOnAddClick}
        onItemClick={handleOnItemClick}
      />
      <StyledCollapse in={isSideHasCameras && open} timeout="auto">
        <ChildrenListWrapper>
          <TowerCameraList camerasData={sideCamerasData} />
        </ChildrenListWrapper>
      </StyledCollapse>
    </StyledListItem>
  );
};

export default TowerSideListItem;

const StyledListItem = styled(ListItem)(() => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  background: "transparent",
  padding: "0px",
}));

const StyledCollapse = styled(Collapse)(() => ({
  width: "100%",
}));

const ChildrenListWrapper = styled(Box)(() => ({
  padding: "9px 0 0 24px",
}));

import { makeVar, useReactiveVar } from "@apollo/client";

export interface IDateRangeVariable {
  from: Date | null;
  to: Date | null;
}

export const dateRangeVariable = makeVar<IDateRangeVariable | null>({
  from: null,
  to: null,
});

export const useDateRange = () => useReactiveVar(dateRangeVariable);

import React from "react";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { styled } from "@mui/system";
import { ServiceTypeEnum } from "../../model-manager/variables/modelManager";

const CustomToggleButton = styled(ToggleButton)(({ theme }) => ({
  width: 170,
  height: 50,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: theme.palette.grey[200],
  color: "black",
  borderRadius: 25,
  border: "none",
  "&.Mui-selected": {
    backgroundColor: theme.palette.success.light,
    color: "white",
    borderRadius: 25,
    "&:hover": {
      backgroundColor: theme.palette.success.light,
    },
  },
  "&:not(.Mui-selected)": {
    borderRadius: 25,
    "&:hover": {
      backgroundColor: theme.palette.grey[300],
    },
  },
}));

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  display: "flex",
  borderRadius: 25,
  backgroundColor: theme.palette.grey[200],
  "& .MuiToggleButtonGroup-grouped": {
    margin: theme.spacing(0.5),
    border: 0,
    "&:not(:first-of-type)": {
      borderRadius: 25,
    },
    "&:first-of-type": {
      borderRadius: 25,
    },
  },
}));

interface ToggleButtonGroupComponentProps {
  value: ServiceTypeEnum;
  onChange: (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: ServiceTypeEnum
  ) => void;
  ServiceTypeEnum: typeof ServiceTypeEnum;
}

const ToggleButtonGroupComponent: React.FC<ToggleButtonGroupComponentProps> = ({
  value,
  onChange,
  ServiceTypeEnum,
}) => {
  return (
    <StyledToggleButtonGroup
      color="success"
      value={value}
      onChange={onChange}
      exclusive
    >
      <CustomToggleButton value={ServiceTypeEnum.LEAK}>
        Exclusion Area
      </CustomToggleButton>
      <CustomToggleButton value={ServiceTypeEnum.LIQUID_LEAK}>
        Inclusion Area
      </CustomToggleButton>
    </StyledToggleButtonGroup>
  );
};

export default ToggleButtonGroupComponent;

import { useContext } from "react";
import { useTheme } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { WarningIcon } from "../../../../common/assets/icons/svgAssets";

import {
  ConfirmationModalContext,
  NotificationContext,
  ProjectContext,
} from "../../../../common/context";

import { useUploadDataToS3 } from "../../../../common/hooks/useUploadDataToS3";
import { useAmplifyUser } from "../../../../../common/hooks/useAmplifyUser";
import { useCustomerIdGuard } from "../../../../../common/hooks/useCustomerIdGuard";
import { useUpdateDTProject } from "../../hooks/useUpdateDTProject";

import { DTProjectInterface } from "../../../../common/interfaces";

import { getDate, getUserNameFromEmailAddress } from "../../../../common/utils";

interface SaveProjectButtonInterface {
  dtProject: DTProjectInterface;
  CB?: () => void;
  allowToSave?: boolean;
}

const SaveProjectButton = ({
  dtProject,
  CB,
  allowToSave = true,
}: SaveProjectButtonInterface): JSX.Element => {
  const theme = useTheme();

  const {
    sceneStarted,
    isLoading,
    setIsLoading,
    setProjectData,
    isConfigDataChanged,
    getProjectConfigData,
    setZoneListSaveState,
    isProjectHasUnsavedZone,
    getUsedObjectsAssetsIds,
  } = useContext(ProjectContext);
  const { openNotification } = useContext(NotificationContext);
  const { setConfirmationModalData } = useContext(ConfirmationModalContext);

  const selectedCustomerId = useCustomerIdGuard();
  const { user } = useAmplifyUser();
  const { updateDTProject, loading: updateDTProjectLoading } =
    useUpdateDTProject();
  const { uploadDataToS3, loading: uploadDataToS3Loading } =
    useUploadDataToS3();

  const saveProject = async () => {
    const projectConfig = getProjectConfigData();

    const input = {
      projectId: dtProject.projectId,
      customerId: selectedCustomerId,
      displayName: dtProject.displayName,
      lastModifierUserName:
        user.username && getUserNameFromEmailAddress(user.username),
      lastModificationDate: getDate(),
      withKMZLayer: dtProject.withKMZLayer,
      measurementSystem: dtProject.measurementSystem,
      usedObjects: getUsedObjectsAssetsIds(),
    };

    const response = await updateDTProject(input);

    if (response.data && isConfigDataChanged()) {
      await uploadDataToS3(
        response.data.updateDTProject.configURL,
        JSON.stringify(projectConfig),
        "json"
      );
    }

    setProjectData({
      configData: { ...projectConfig },
      settings: {
        ...response.data.updateDTProject,
      },
    });
  };

  const onSaveProject = async () => {
    setIsLoading(true);

    await saveProject();

    setIsLoading(false);

    openNotification({
      title: "Saved",
      message: "Your project was successfully saved",
      severity: "success",
    });

    // eslint-disable-next-line new-cap
    CB && CB();
  };

  const onConfirmationAction = () => {
    setZoneListSaveState({ isSaved: true });

    onSaveProject();
  };

  const handleOnClick = () => {
    if (isProjectHasUnsavedZone()) {
      setConfirmationModalData({
        icon: <WarningIcon fillColor={theme.palette.yellow.main} />,
        title: "The project has unsaved zones",
        message: "Are you sure you want to apply changes and save the project?",
        actionButton: {
          color: "blue",
          title: "Apply and Save",
          action: onConfirmationAction,
        },
      });
    } else {
      onSaveProject();
    }
  };

  const loading = updateDTProjectLoading || uploadDataToS3Loading;
  const disabled = loading || !sceneStarted || isLoading || !allowToSave;

  return (
    <LoadingButton
      loading={loading}
      disabled={disabled}
      onClick={handleOnClick}
      variant="primary"
      color="blue"
      endIcon={<></>}
      loadingPosition="end"
    >
      Save
    </LoadingButton>
  );
};

export default SaveProjectButton;

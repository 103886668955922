import { useEffect } from "react";

import { useLazyQuery } from "@apollo/client";

import { GetServicesQuery, GetServicesQueryVariables } from "../../../../API";
import client from "../../../../configs/apolloClient";
import { useCustomerIdGuard } from "../../../hooks/useCustomerIdGuard";
import { GET_SERVICES } from "../../../operations/queries";
import { errorNotification } from "../../../variables/notification";
import { DEFAULT_LIMIT_COUNT } from "../../../variables/common";

export const useGetServicesByCustomer = () => {
  const selectedCustomerId = useCustomerIdGuard();

  useEffect(() => {
    if (selectedCustomerId) {
      fetchServices(selectedCustomerId);
    }
  }, [selectedCustomerId]);

  const handleRequestError = async (): Promise<void> => {
    await client.cache.reset();

    errorNotification();
  };

  const [getServices, { data, loading }] = useLazyQuery<
    GetServicesQuery,
    GetServicesQueryVariables
  >(GET_SERVICES, {
    fetchPolicy: "network-only",
  });

  const fetchServices = (customerId: string, nextToken?: string): void => {
    getServices({
      variables: {
        limit: DEFAULT_LIMIT_COUNT,
        customerId,
        nextToken,
      },
    })
      .then(async (response): Promise<void> => {
        if (response.data?.getServices.nextToken) {
          fetchServices(customerId, response.data?.getServices.nextToken);
        }

        if (response.error) {
          await handleRequestError();
        }
      })
      .catch(async (): Promise<void> => {
        await handleRequestError();
      });
  };

  return { data, loading };
};

import { ReactElement } from "react";

import { useTheme } from "@mui/material";
import { SvgIconProps } from "@mui/material/SvgIcon";

const NoMediaIcon = (props: SvgIconProps): ReactElement => {
  const theme = useTheme();

  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      fillRule="evenodd"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M58.6663 32.0026C58.6663 44.5734 58.6663 50.8588 54.7611 54.764C50.8559 58.6693 44.5705 58.6693 31.9997 58.6693C19.4289 58.6693 13.1435 58.6693 9.23825 54.764C5.33301 50.8588 5.33301 44.5734 5.33301 32.0026C5.33301 19.4318 5.33301 13.1464 9.23825 9.24118C13.1435 5.33594 19.4289 5.33594 31.9997 5.33594"
        stroke={theme.palette.primary.main}
        strokeWidth="4"
        strokeLinecap="round"
      />
      <path
        d="M5.33301 33.3311L10.0039 29.2441C12.434 27.1178 16.0964 27.2398 18.3796 29.523L29.8189 40.9623C31.6515 42.7949 34.5363 43.0448 36.6568 41.5545L37.4519 40.9957C40.5032 38.8513 44.6315 39.0997 47.4036 41.5946L55.9997 49.3311"
        stroke={theme.palette.primary.main}
        strokeWidth="4"
        strokeLinecap="round"
      />
      <path
        d="M58.6669 5.336L42.667 21.3359M42.6669 5.33594L58.6669 21.3359"
        stroke={theme.palette.primary.main}
        strokeWidth="4"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default NoMediaIcon;

import { AllowLeakFinder } from "../../../../API";
import { IFormValidationState } from "../../../../common/models/formValidation";

export interface ICustomerForm {
  name: string;
  id: string;
  domains: string[];
  allowLeakFinder: AllowLeakFinder;
  usageLeakFinderLimit: number;
}

export const defaultCustomerFormVariables = {
  name: "",
  id: "",
  domains: [""],
};

interface ICustomerFormValidationState {
  name: IFormValidationState;
}

export const defaultCustomerFormValidationState: ICustomerFormValidationState =
  {
    name: {
      hasError: false,
      errorMessage: "",
    },
  };

export const customerFormRules = {
  name: {
    required: true,
    pattern: /^(?!^ +$)[a-zA-Z0-9]+(?:\s[a-zA-Z0-9]+)*$/,
  },
};

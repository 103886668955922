import { useState } from "react";

import { styled } from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";

import ObjectCardContextMenuEditButton from "./ObjectCardContextMenuEditButton";
import ObjectCardContextMenuDeleteButton from "./ObjectCardContextMenuDeleteButton";

import { ShowMoreIcon } from "../../../assets/icons/svgAssets";

import { DTObject } from "../../../../../API";
import {
  SeparatorLine,
  SeparatorWrapper,
  StyledMenu,
} from "../../styledComponents/styledContextMenu/ContextMenuStyledComponents";

interface ObjectCardContextMenuInterface {
  disabledButton: boolean;
  objectData: DTObject;
}

const ObjectCardContextMenu = ({
  disabledButton,
  objectData,
}: ObjectCardContextMenuInterface): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const onShowMoreButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();

    event.stopPropagation();

    setAnchorEl(event.currentTarget);
  };

  const onCloseContextMenu = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    setAnchorEl(null);
  };

  return (
    <Wrapper>
      <StyledIconButton
        onClick={onShowMoreButtonClick}
        disabled={disabledButton}
      >
        <ShowMoreIcon />
      </StyledIconButton>
      <StyledMenu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={onCloseContextMenu}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        transformOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <ObjectCardContextMenuEditButton
          disabledButton={disabledButton}
          objectToEdit={objectData}
          onCloseContextMenu={onCloseContextMenu}
        />
        <SeparatorWrapper>
          <SeparatorLine />
        </SeparatorWrapper>
        <ObjectCardContextMenuDeleteButton
          disabledButton={disabledButton}
          objectToDelete={objectData}
          onCloseContextMenu={onCloseContextMenu}
        />
      </StyledMenu>
    </Wrapper>
  );
};

export default ObjectCardContextMenu;

const Wrapper = styled(Box)(() => ({
  position: "absolute",
  right: 0,
  top: 0,
}));

const StyledIconButton = styled(IconButton)(() => ({
  "&:hover": {
    backgroundColor: "unset",
  },

  "& svg path": {
    fill: "#202832 !important",
  },
}));

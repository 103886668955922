import { ApolloError, gql, useMutation } from "@apollo/client";

import { ADD_DT_OBJECT } from "../../../../common/operations/mutations";
import { DTObject, DTObjectInput } from "../../../../API";

export interface UseAddDTObjectDataInterface {
  addDTObject: DTObject | null;
}

export interface UseAddDTObjectInterface {
  createDTObject: (input: DTObjectInput) => Promise<any>;
  data: UseAddDTObjectDataInterface;
  loading: boolean;
  error: ApolloError | undefined;
}

export const useAddDTObject = (): UseAddDTObjectInterface => {
  const [addDTObject, { data, loading, error }] = useMutation(ADD_DT_OBJECT);

  const createDTObject = async (input: DTObjectInput): Promise<any> => {
    return await addDTObject({
      variables: {
        input,
      },
      update(cache, response) {
        cache.modify({
          fields: {
            getDTObjects(existing) {
              const newDTObjectRef = cache.writeFragment({
                data: response.data?.addDTObject,
                fragment: gql`
                  fragment NewDTObject on DTObject {
                    id
                    objectId
                    objectName
                    objectType
                    objectManufacturerName
                    filesInfo
                    isCustomerObject
                    version
                  }
                `,
              });

              if (!existing) {
                return response?.data;
              }

              return [...existing, newDTObjectRef];
            },
          },
        });
      },
    });
  };

  return { createDTObject, data, loading, error };
};

import { useMutation, type FetchResult } from "@apollo/client";

import type {
  SyncShadowMutation,
  SyncShadowMutationVariables,
} from "../../../API";
import { SYNC_SHADOW } from "../../../common/operations/mutations";

interface SyncShadowInterface {
  syncShadowsForNode: (input: any) => Promise<FetchResult<SyncShadowMutation>>;
  data?: SyncShadowMutation | null;
  loading: boolean;
}

export const useSyncShadow = (): SyncShadowInterface => {
  const [syncShadow, { data, loading }] = useMutation<
    SyncShadowMutation,
    SyncShadowMutationVariables
  >(SYNC_SHADOW);

  const syncShadowsForNode = async (
    nodeId: string
  ): Promise<FetchResult<SyncShadowMutation>> => {
    return await syncShadow({
      variables: {
        nodeId,
      },
    });
  };

  return { syncShadowsForNode, data, loading };
};

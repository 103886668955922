import { FC } from "react";

import Step from "@mui/material/Step";
import StepConnector from "@mui/material/StepConnector";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Typography from "@mui/material/Typography";
import { CSSObject, styled, useTheme } from "@mui/material/styles";

type Props = {
  steps: string[];
  activeStep: number;
};

const CustomStepper: FC<Props> = ({ steps, activeStep }): JSX.Element => {
  const theme = useTheme();

  const CustomConnector = styled(StepConnector)(
    ({ theme }): CSSObject => ({
      "& .MuiStepConnector-line": {
        borderTopWidth: 2,
        borderColor: theme.palette.otherOutlineBorder.main,
        width: "8px",
      },
    })
  );

  return (
    <Stepper
      activeStep={activeStep}
      connector={<CustomConnector />}
      sx={{
        maxWidth: 400,
        marginBottom: "1em",
        "& .MuiStep-root": {
          flex: "1 1 auto",
        },
      }}
    >
      {steps.map((label, index): JSX.Element => {
        const activeColor =
          index === activeStep
            ? theme.palette.text.secondary
            : theme.palette.otherOutlineBorder.main;
        const activeTextColor =
          index === activeStep
            ? theme.palette.text.secondary
            : theme.palette.otherTextTertiary.main;

        return (
          <Step
            key={label}
            sx={{
              "& .MuiSvgIcon-root": {
                color: `${theme.palette.otherBackground.main} !important`,
                borderRadius: "50%",
                border: `1px solid `,
                borderColor: activeColor,
              },
              "& .Mui-completed": {
                color: `${theme.palette.otherOutlineBorder.main} !important`,
              },
              "& .MuiStepIcon-text": {
                fill: activeTextColor,
              },
            }}
          >
            <StepLabel>
              <Typography
                sx={{
                  color:
                    index === activeStep
                      ? theme.palette.text.secondary
                      : theme.palette.otherTextTertiary.main,
                }}
              >
                {label}
              </Typography>
            </StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
};

export default CustomStepper;

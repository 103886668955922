import { ChangeEvent } from "react";

import { PaletteMode } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch, { SwitchProps } from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { CSSObject, styled } from "@mui/material/styles";

import { themeMode } from "../../../providers/theme/MuiThemeProvider";
import {
  themeModeVariable,
  useThemeModeVariable,
} from "../../../variables/themeMode";

const UserSettingsPopover = (): JSX.Element => {
  const { dark, light } = themeMode;
  const mode = useThemeModeVariable();

  const handleThemeChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.checked ? dark : light;

    localStorage.setItem("themeMode", value);

    themeModeVariable(value as PaletteMode);
  };

  const CustomSwitch = styled(
    (props: SwitchProps): JSX.Element => (
      <Switch
        focusVisibleClassName=".Mui-focusVisible"
        disableRipple
        {...props}
      />
    )
  )(
    ({ theme }): CSSObject => ({
      width: 42,
      height: 26,
      padding: 0,
      "& .MuiSwitch-switchBase": {
        padding: 0,
        margin: 3,
        transitionDuration: "300ms",
        "&.Mui-checked": {
          transform: "translateX(16px)",
          color: "white",
          "& + .MuiSwitch-track": {
            backgroundColor: theme.palette.primary.main,
          },
          "&.Mui-disabled + .MuiSwitch-track": {},
        },
      },
      "& .MuiSwitch-thumb": {
        boxSizing: "border-box",
        width: 20,
        height: 20,
      },
      "& .MuiSwitch-track": {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.otherStandardInputLine.main,
      },
    })
  );

  const modeLabel = mode === dark ? "Light Mode" : "Dark Mode";

  return (
    <Tooltip title={""} placement="bottom">
      <FormControlLabel
        control={
          <>
            <Typography>{modeLabel}</Typography>

            <CustomSwitch
              sx={{ m: 1 }}
              checked={mode === dark}
              onChange={handleThemeChange}
              inputProps={{ "aria-label": "Dark Mode Switch" }}
            />
          </>
        }
        label={""}
      />
    </Tooltip>
  );
};

export default UserSettingsPopover;

import type { AmplifyUser } from "@aws-amplify/ui";
import { useAuthenticator } from "@aws-amplify/ui-react";

import { useTargetDomain } from "./useTargetDomain";
import client from "../../configs/apolloClient";

export const useAmplifyUser = () => {
  const { redirect } = useTargetDomain();
  const { user, signOut: handleSignOut } = useAuthenticator(
    (context): AmplifyUser[] => [context.user]
  );

  const signOut = async (): Promise<void> => {
    handleSignOut();

    await client.cache.reset();

    redirect();
  };

  return { user, signOut };
};

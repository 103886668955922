import { Control, Controller, FieldErrors } from "react-hook-form";

import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

import PlatesCategoryDropdown from "./plate-category/PlatesCategoryDropdown";
import {
  IPlateNumberForm,
  plateNumberFormRules,
} from "../variables/plateNumberData";

interface ILocationFormProps {
  fullWidthInput?: boolean;
  control: Control<IPlateNumberForm, any>;
  errors: FieldErrors<IPlateNumberForm>;
  preSelectedCategory?: string;
}

const PlateNumberForm = ({
  fullWidthInput,
  control,
  preSelectedCategory,
  errors,
}: ILocationFormProps): JSX.Element => {
  const sm = fullWidthInput ? 12 : 6;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={sm}>
        <Controller
          name="plateNumber"
          control={control}
          rules={{
            required: "This field is required",
            pattern: {
              value: plateNumberFormRules.plateNumber.pattern,
              message: "Valid characters: A-Z, 0-9",
            },
            validate: {
              containsLetterAndNumber: value =>
                plateNumberFormRules.plateNumber.containsLetterAndNumber.pattern.test(
                  value
                ) ||
                plateNumberFormRules.plateNumber.containsLetterAndNumber
                  .message,
            },
          }}
          render={({ field }) => (
            <TextField
              {...field}
              onChange={e => {
                field.onChange(e.target.value.toUpperCase());
              }}
              sx={{ height: "60px" }}
              size="medium"
              label={"Plate Number"}
              fullWidth
              error={!!errors.plateNumber}
              helperText={errors.plateNumber ? errors.plateNumber.message : ""}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={sm}>
        <Controller
          name="companyName"
          control={control}
          rules={{
            pattern: {
              value: plateNumberFormRules.companyName.pattern,
              message: "Valid characters: A-Z, a-z, 0-9",
            },
          }}
          render={({ field }) => (
            <TextField
              {...field}
              size="medium"
              label={"Company Name"}
              sx={{ height: "60px" }}
              fullWidth
              error={!!errors.companyName}
              helperText={errors.companyName ? errors.companyName.message : ""}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={sm}>
        <Controller
          name="driverName"
          control={control}
          rules={{
            pattern: {
              value: plateNumberFormRules.driverName.pattern,
              message: "Valid characters: A-Z, a-z, 0-9",
            },
          }}
          render={({ field }) => (
            <TextField
              {...field}
              sx={{ height: "60px" }}
              size="medium"
              label={"Driver Name"}
              fullWidth
              error={!!errors.driverName}
              helperText={errors.driverName ? errors.driverName.message : ""}
            />
          )}
        />
      </Grid>
      {!preSelectedCategory && (
        <Grid item xs={12} sm={sm}>
          <Controller
            name="category"
            control={control}
            rules={{ required: "This field is required" }}
            render={({ field }) => (
              <PlatesCategoryDropdown
                selectedValue={field.value}
                setSelectedValue={field.onChange}
                errors={errors}
              />
            )}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default PlateNumberForm;

export const useStream = () => {
  const getStreamName = (nodeId: string, deviceId: string) => {
    if (!nodeId || !deviceId) return "";

    const streamName = (nodeId + "_" + deviceId).replace(
      /[^a-zA-Z0-9_.-]/g,
      "_"
    );

    return streamName;
  };

  return { getStreamName };
};

import { useMemo } from "react";

import {
  GetUserByIdQuery,
  GetUserByIdQueryVariables,
  UserSettingsInput,
} from "../../API";
import { useAuthenticatedUser } from "../../common/hooks/useAuthenticatedUser";
import { GET_USER_BY_ID } from "../../common/operations/queries";
import client from "../../configs/apolloClient";

export const useCachedUserSettings = () => {
  const {
    userSettings: defaultUserSettings,
    user,
    customerId,
  } = useAuthenticatedUser();

  const memoizedCachedUserInfo = useMemo(() => {
    const userId = `U#${user?.attributes?.sub as string}`;

    let userSettingsFromCache: UserSettingsInput | null = null;

    const cachedUser = client.readQuery<
      GetUserByIdQuery,
      GetUserByIdQueryVariables
    >({
      query: GET_USER_BY_ID,
      variables: {
        customerId,
        userId,
      },
    });

    try {
      if (cachedUser?.getUserById) {
        const settings = cachedUser?.getUserById?.userSettings
          ? JSON.parse(cachedUser?.getUserById?.userSettings)
          : null;

        userSettingsFromCache = {
          defaultCameraFov:
            +settings?.defaultCameraFov ??
            defaultUserSettings?.defaultCameraFov,
          defaultWindspeedUnit:
            settings?.defaultWindspeedUnit ??
            defaultUserSettings?.defaultWindspeedUnit,
          defaultVideoSpeed:
            +settings?.defaultVideoSpeed ??
            defaultUserSettings?.defaultVideoSpeed,
        };

        return {
          ...cachedUser?.getUserById,
          userSettings: userSettingsFromCache,
        };
      }
    } catch (error) {
      console.error(error);

      return {
        ...cachedUser?.getUserById,
        userSettings: null,
      };
    }
  }, [user?.attributes?.sub, customerId]);

  return {
    ...memoizedCachedUserInfo,
    userSettings: memoizedCachedUserInfo?.userSettings,
  };
};

import { useQuery } from "@apollo/client";

import { GET_DT_OBJECTS } from "../../../../common/operations/queries";
import { DTObject } from "../../../../API";

export interface UseGetDTObjectsDataInterface {
  getDTObjects: DTObject[];
}

export interface UseGetDTObjectsInterface {
  data: UseGetDTObjectsDataInterface;
  loading: boolean;
}

export const useGetDTObjects = (): UseGetDTObjectsInterface => {
  const { data, loading } = useQuery(GET_DT_OBJECTS);

  return { data, loading };
};

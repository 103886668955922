import { Button, useTheme } from "@mui/material";
import React, { ReactElement } from "react";

import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import {
  GridActionsCellItem,
  GridActionsCellItemProps,
  GridColDef,
} from "@mui/x-data-grid";

import { VideoMethod, VideoState } from "../../API";
import { customRound } from "../../common/helpers/customRound";
import useUserSettingsFromCache from "../../common/hooks/useUserSettingsFromCache";
import DeleteVideoContainer from "../components/DeleteVideoContainer";
import { UnitsEnum } from "../pages/UploadVideoGroupPage";

export const useGetVideosTableColumns = (
  setVideoUrl: (url: string) => void,
  deleteGroup: (clientId: string, groupId: string) => void,
  countOfVideos: number
): {
  columns: GridColDef[];
} => {
  const theme = useTheme();

  const { userSettings } = useUserSettingsFromCache();

  const columns: GridColDef[] = [
    {
      field: "videoName",
      headerName: "VIDEO",
      flex: 1,
      minWidth: 160,
      hideable: false,
      disableColumnMenu: true,
      renderCell: (params): JSX.Element | null => {
        const isStatePending = params?.row.videoState === VideoState.PENDING;
        const isStateProcessing =
          params?.row.videoState === VideoState.PROCESSING;

        const isStateWaiting = isStatePending || isStateProcessing;

        const tooltipTitle = isStateWaiting
          ? "The video is not available right now, results are processing"
          : params?.row.videoType;

        let textColor = theme.palette.text.primary;

        if (isStateWaiting) {
          textColor = theme.palette.otherTextTertiary.main;
        }

        return params ? (
          <Stack
            direction="row"
            alignItems="center"
            style={{
              height: "2em",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            <Tooltip title={tooltipTitle}>
              <Button
                variant="text"
                disableRipple={isStateWaiting}
                sx={{
                  color: textColor,
                  cursor: isStateWaiting ? "progress" : "pointer",
                }}
                onClick={() => {
                  if (isStateWaiting) return;

                  setVideoUrl(params?.row.modelOutputS3Key);
                }}
              >
                <Typography
                  style={{
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                  sx={
                    isStateWaiting
                      ? {
                          color: textColor,
                        }
                      : {}
                  }
                  variant="body2Bold"
                >
                  {params?.row.videoName}
                </Typography>
              </Button>
            </Tooltip>

            {/* TODO: Get file size */}

            {/* <Typography variant="caption" fontSize="12px"></Typography> */}
          </Stack>
        ) : null;
      },
    },
    {
      field: "geometry",
      headerName: "ANGLE / HEIGHT / DISTANCE",
      flex: 1,
      maxWidth: 250,
      hideable: false,
      disableColumnMenu: true,
      renderCell: (params): JSX.Element | null => {
        if (!params) {
          return null;
        }

        return (
          <Stack direction="row" alignItems="center">
            {params?.row.geometry && JSON.parse(params.row.geometry).angle && (
              <>
                <Typography
                  sx={{
                    marginRight: "4px",
                    fontSize: "12px",
                    color: theme => theme.palette.otherTextTertiary.main,
                  }}
                  variant="subtitle2"
                >
                  {params?.row.geometry &&
                    JSON.parse(params.row.geometry).angle &&
                    "A"}
                </Typography>
                <Typography
                  sx={{
                    marginRight: "8px",
                    fontSize: "13px",
                    color: theme => theme.palette.text.primary,
                  }}
                  variant="body2Regular"
                >
                  {params?.row.geometry &&
                    JSON.parse(params.row.geometry).angle && (
                      <React.Fragment>
                        {JSON.parse(params.row.geometry).angle}°
                      </React.Fragment>
                    )}
                </Typography>
              </>
            )}

            {params?.row.geometry && JSON.parse(params.row.geometry).height && (
              <>
                <Typography
                  sx={{
                    marginRight: "4px",
                    fontSize: "12px",
                    color: theme => theme.palette.otherTextTertiary.main,
                  }}
                  variant="subtitle2"
                >
                  {params?.row.geometry &&
                    JSON.parse(params.row.geometry).height &&
                    "H"}
                </Typography>
                <Typography
                  sx={{
                    marginRight: "8px",
                    fontSize: "13px",
                    color: theme => theme.palette.text.primary,
                  }}
                  variant="body2Regular"
                >
                  {params?.row.geometry &&
                    JSON.parse(params.row.geometry).height && (
                      <React.Fragment>
                        {JSON.parse(params.row.geometry).height}'
                      </React.Fragment>
                    )}
                </Typography>
              </>
            )}

            {params?.row.geometry &&
              JSON.parse(params.row.geometry).distance && (
                <>
                  <Typography
                    sx={{
                      marginRight: "4px",
                      fontSize: "12px",
                      color: theme => theme.palette.otherTextTertiary.main,
                    }}
                    variant="subtitle2"
                  >
                    {params?.row.geometry &&
                      JSON.parse(params.row.geometry).distance &&
                      "D"}
                  </Typography>
                  <Typography
                    sx={{
                      marginRight: "8px",
                      fontSize: "13px",
                      color: theme => theme.palette.text.primary,
                    }}
                    variant="body2Regular"
                  >
                    {params?.row.geometry &&
                      JSON.parse(params.row.geometry).distance && (
                        <React.Fragment>
                          {JSON.parse(params.row.geometry).distance}ft
                        </React.Fragment>
                      )}
                  </Typography>
                </>
              )}
          </Stack>
        );
      },
    },
    {
      field: "fov",
      headerName: "FOV",
      flex: 1,
      maxWidth: 80,
      hideable: false,
      disableColumnMenu: true,
      renderCell: (params): JSX.Element | null =>
        params ? (
          <Tooltip title={params?.row.fov}>
            <Typography variant="body2"> {params?.row.fov}° </Typography>
          </Tooltip>
        ) : null,
    },
    {
      field: "windspeed",
      headerName: "WINDSPEED",
      flex: 1,
      maxWidth: 150,
      hideable: false,
      disableColumnMenu: true,
      renderCell: (params): JSX.Element | null => {
        if (!params) {
          return null;
        }

        const unit =
          userSettings?.defaultWindspeedUnit === UnitsEnum.MPH
            ? UnitsEnum.MPH
            : UnitsEnum.KPH;

        const mphToKph = 1.609344;

        const windspeed =
          unit === UnitsEnum.KPH
            ? params?.row.windspeed * mphToKph
            : params?.row.windspeed;

        return (
          <Typography variant="body2">
            {windspeed.toFixed(1)} {unit}
          </Typography>
        );
      },
    },
    {
      field: "outputMeasurement",
      headerName: "RESULT",
      flex: 1,
      maxWidth: 150,
      disableColumnMenu: true,
      hideable: false,
      sortable: false,
      valueGetter: (outputMeasurement?: number | null): string | null => {
        if (!outputMeasurement) {
          return null;
        }

        const convertOutputMeasurement = outputMeasurement * 86400;

        return customRound(convertOutputMeasurement);
      },
      renderCell: (params): JSX.Element | null => {
        if (!params) {
          return null;
        }

        if (
          params?.row.videoState === VideoState.PENDING ||
          params?.row.videoState === VideoState.PROCESSING
        ) {
          return (
            <Typography
              sx={{ color: theme.palette.otherTextTertiary.main }}
              variant="body2Regular"
            >
              Processing
            </Typography>
          );
        }

        if (params?.row.videoState === VideoState.ERROR) {
          return (
            <Typography
              sx={{ color: theme.palette.error.main }}
              variant="body2Regular"
            >
              ERROR
            </Typography>
          );
        }

        return (
          <Typography
            variant="body2Regular"
            sx={{
              color: theme.palette.error.main,
            }}
          >
            {params.formattedValue} MCF/d
          </Typography>
        );
      },
    },
    {
      field: "videoMethod",
      headerName: "VIDEO TYPE",
      flex: 1,
      maxWidth: 150,
      hideable: false,
      disableColumnMenu: true,
      renderCell: (params): JSX.Element | null => {
        const videoMethodText =
          params?.row.videoMethod === VideoMethod.DRONE ? "Drone" : "Handheld";

        return params ? (
          <Tooltip title={params?.row.videoType}>
            <Typography variant="body2"> {videoMethodText} </Typography>
          </Tooltip>
        ) : null;
      },
    },
    {
      field: "notes",
      headerName: "NOTES",
      flex: 1,
      maxWidth: 500,
      hideable: false,
      disableColumnMenu: true,
      renderCell: (params): JSX.Element | null =>
        params ? (
          <Tooltip title={params?.row.notes}>
            <Typography
              sx={{
                overflow: "visible",
                whiteSpace: "normal",
              }}
              variant="body2Regular"
            >
              {params?.row.notes}
            </Typography>
          </Tooltip>
        ) : null,
    },
    {
      field: "actions",
      type: "actions",
      width: 10,
      getActions: (params): ReactElement<GridActionsCellItemProps>[] => [
        <GridActionsCellItem
          key={`delete-${params.row?.id}`}
          icon={
            <DeleteVideoContainer
              deleteGroup={deleteGroup}
              countOfVideos={countOfVideos}
              row={params.row}
            />
          }
          label="Delete"
          className="actionButton"
          disableRipple
        />,
      ],
    },
  ];

  return { columns };
};

import { createTheme } from "@mui/material";

import { darkThemeToken } from "./dark-theme-tokens";
import { lightThemeToken } from "./light-theme-tokens";

/**
 * NOTE: adding new variant for Typography
 * https://mui.com/material-ui/customization/typography/#adding-amp-disabling-variants
 */
declare module "@mui/material/styles" {
  interface TypographyVariants {
    buttonMedium: React.CSSProperties;
    tooltip: React.CSSProperties;
    body1Bold: React.CSSProperties;
    body1Regular: React.CSSProperties;
    body2Bold: React.CSSProperties;
    body2Regular: React.CSSProperties;
    inputText: React.CSSProperties;
    inputLabel: React.CSSProperties;
    helperText: React.CSSProperties;
    caption: React.CSSProperties;
    avatarLetter: React.CSSProperties;
    tag: React.CSSProperties;
    tagXs: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    buttonMedium?: React.CSSProperties;
    tooltip?: React.CSSProperties;
    body1Bold?: React.CSSProperties;
    body1Regular?: React.CSSProperties;
    body2Bold?: React.CSSProperties;
    body2Regular?: React.CSSProperties;
    inputText?: React.CSSProperties;
    inputLabel?: React.CSSProperties;
    helperText?: React.CSSProperties;
    caption?: React.CSSProperties;
    avatarLetter?: React.CSSProperties;
    tag?: React.CSSProperties;
    tagXs?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    buttonMedium: true;
    tooltip: true;
    body1Bold: true;
    body1Regular: true;
    body2Bold: true;
    body2Regular: true;
    inputText: true;
    inputLabel: true;
    helperText: true;
    caption: true;
    avatarLetter: true;
    tag: true;
    tagXs: true;
  }
}
declare module "@mui/material/styles" {
  interface SimplePaletteColorOptions {
    shades?: {
      "4p"?: string;
      "8p"?: string;
      "12p"?: string;
      "30p"?: string;
      "50p"?: string;
    };
  }

  interface PaletteColor {
    shades?: {
      "4p"?: string;
      "8p"?: string;
      "12p"?: string;
      "30p"?: string;
      "50p"?: string;
    };
  }
}

declare module "@mui/material/styles" {
  interface Palette {
    otherBackground: Palette["primary"];
    otherDisabledBackground: Palette["primary"];
    otherBackgroundLight: Palette["primary"];
    otherTextTertiary: Palette["primary"];
    otherDivider: Palette["primary"];
    otherOutlineBorder: Palette["primary"];
    otherStandardInputLine: Palette["primary"];
    otherBackdropOverlay: Palette["primary"];
    otherSnackbarBackground: Palette["primary"];
    otherElevation: Palette["primary"];
  }

  interface PaletteOptions {
    otherBackground?: PaletteOptions["primary"];
    otherDisabledBackground: PaletteOptions["primary"];
    otherBackgroundLight?: PaletteOptions["primary"];
    otherTextTertiary?: PaletteOptions["primary"];
    otherDivider?: PaletteOptions["primary"];
    otherOutlineBorder?: PaletteOptions["primary"];
    otherStandardInputLine?: PaletteOptions["primary"];
    otherBackdropOverlay?: PaletteOptions["primary"];
    otherSnackbarBackground?: PaletteOptions["primary"];
    otherElevation: PaletteOptions["primary"];
  }
}

declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    xxl: true;
  }
}

const lightTheme = createTheme(lightThemeToken);
const darkTheme = createTheme(darkThemeToken);

export const themes = {
  light: lightTheme,
  dark: darkTheme,
  default: lightTheme,
};

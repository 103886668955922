import { useContext, useEffect, useState } from "react";

import MapLocationInput from "../../../../common/components/MapLocationInput";

import { useMapLocation } from "../../hooks/useMapLocation";

import CreateDTProjectContext, {
  CreateDTProjectContextInterface,
} from "../../context/CreateDTProjectContext/CreateDTProjectContext";

import "./DTProjectForm.css";

const DTProjectLocationInput = (): JSX.Element => {
  const mapLocation = useMapLocation();

  const { onProjectLocationCoordinatesChange } = useContext(
    CreateDTProjectContext
  ) as CreateDTProjectContextInterface;

  const [isInteract, setIsInteract] = useState(false);

  const handleOnClick = () => {
    !isInteract && setIsInteract(true);
  };

  useEffect(() => {
    if (isInteract) {
      onProjectLocationCoordinatesChange(mapLocation.mapCenterCoordinates);
    }
  }, [mapLocation.mapCenterCoordinates]);

  return (
    <MapLocationInput
      onUserInteract={handleOnClick}
      mapLocation={mapLocation}
    ></MapLocationInput>
  );
};

export default DTProjectLocationInput;

import { ApolloError, useMutation } from "@apollo/client";

import {
  DeleteVideoInput,
  DeleteVideoMutation,
  DeleteVideoMutationVariables,
} from "../../API";
import { DELETE_VIDEO } from "../../common/operations/mutations";
import {
  errorNotification,
  successNotification,
} from "../../common/variables/notification";

export const useDeleteVideo = () => {
  const [removeVideo, { data, loading }] = useMutation<
    DeleteVideoMutation,
    DeleteVideoMutationVariables
  >(DELETE_VIDEO);

  const deleteVideo = async (input: DeleteVideoInput) => {
    await removeVideo({
      variables: {
        input,
      },
      onCompleted: response => {
        const { deleteVideo } = response;

        if (deleteVideo) {
          successNotification(`${deleteVideo.videoName} is deleted`);
        }
      },
      onError: (error: ApolloError): void => {
        errorNotification(error.message);

        console.error(error.message);
      },
      update(cache) {
        const normalizedId = cache.identify({
          id: input.videoId,
          __typename: "LeakFinderVideo",
        });

        cache.evict({ id: normalizedId });

        cache.gc();
      },
    });
  };

  return { deleteVideo, data, loading };
};

import { MathUtils, EventDispatcher } from "three";

const _changeEvent = { type: "change" };
const _endEvent = { type: "end" };

class LookCameraControl extends EventDispatcher {
  constructor(
    domElement,
    cameraObjectName,
    objectHorizontalRotateName,
    objectVerticalRotateName
  ) {
    super();

    this.enabled = false;

    this.domElement = domElement;

    this.cameraObjectName = cameraObjectName;

    this.objectHorizontalRotateName = objectHorizontalRotateName;

    this.objectVerticalRotateName = objectVerticalRotateName;

    this.object = null;

    this.camera = null;

    this.horizontalRotationObject = null;

    this.verticalRotationObject = null;

    this.minZoom = 1;

    this.maxZoom = 5;

    this.minAzimuthAngle = MathUtils.degToRad(-180);

    this.maxAzimuthAngle = MathUtils.degToRad(180);

    this.minPolarAngle = MathUtils.degToRad(0.5);

    this.maxPolarAngle = MathUtils.degToRad(179.5);

    this.pointerSpeed = 3 * 0.002;

    this.domElement.addEventListener(
      "mousedown",
      this.handleMouseDown.bind(this)
    );

    this.domElement.addEventListener(
      "mousemove",
      this.handleMouseMove.bind(this)
    );

    this.domElement.addEventListener("contextmenu", event => {
      event.preventDefault();
    });

    this.domElement.addEventListener("wheel", this.handOnWheel.bind(this));

    document.body.addEventListener("mouseup", this.handleMouseUp.bind(this));
  }

  dispose() {
    this.domElement.removeEventListener(
      "mousedown",
      this.handleMouseDown.bind(this)
    );

    this.domElement.removeEventListener(
      "mousemove",
      this.handleMouseMove.bind(this)
    );

    this.domElement.removeEventListener("wheel", this.handOnWheel.bind(this));

    document.body.removeEventListener("mouseup", this.handleMouseUp.bind(this));
  }

  attach(object) {
    this.object = object;

    if (this.object) {
      this.camera = this.object.getObjectByName(this.cameraObjectName);

      this.horizontalRotationObject = this.object.getObjectByName(
        this.objectHorizontalRotateName
      );

      this.verticalRotationObject = this.object.getObjectByName(
        this.objectVerticalRotateName
      );

      this.enabled = true;
    }
  }

  detach() {
    this.camera = null;

    this.object = null;

    this.horizontalRotationObject = null;

    this.verticalRotationObject = null;

    this.enabled = false;
  }

  handleMouseDown(event) {
    if (!this.enabled || !this.camera || !this.object) {
      return;
    }

    if (event.button === 0) {
      this.leftDown = true;
    }
  }

  handleMouseMove(event) {
    if (!this.enabled || !this.camera || !this.object) {
      return;
    }

    event.preventDefault();

    if (this.leftDown) {
      const dx =
        event.movementX || event.mozMovementX || event.webkitMovementX || 0;
      const dy =
        event.movementY || event.mozMovementY || event.webkitMovementY || 0;

      this.rotateCamera(dx, dy);

      this.dispatchEvent(_changeEvent);
    }
  }

  handleMouseUp() {
    if (this.leftDown) {
      this.dispatchEvent(_endEvent);
    }

    this.leftDown = false;
  }

  handOnWheel(event) {
    if (!this.enabled || !this.camera || !this.object) {
      return;
    }

    event.preventDefault();

    this.zoomCamera(event.deltaY);

    this.dispatchEvent(_changeEvent);
  }

  rotateCamera(dx, dy) {
    this.horizontalRotationObject.rotation.z += dx * this.pointerSpeed;

    this.horizontalRotationObject.rotation.z = MathUtils.clamp(
      this.horizontalRotationObject.rotation.z,
      this.minAzimuthAngle,
      this.maxAzimuthAngle
    );

    this.verticalRotationObject.rotation.x -= dy * this.pointerSpeed;

    this.verticalRotationObject.rotation.x = MathUtils.clamp(
      this.verticalRotationObject.rotation.x,
      Math.PI / 2 - this.maxPolarAngle,
      Math.PI / 2 - this.minPolarAngle
    );
  }

  zoomCamera(delta) {
    this.camera.zoom -= delta * 0.002;

    this.camera.zoom = MathUtils.clamp(
      this.camera.zoom,
      this.minZoom,
      this.maxZoom
    );

    this.camera.updateProjectionMatrix();
  }
}

export default LookCameraControl;

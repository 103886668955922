import { useContext } from "react";
import { Box, Stack, Typography, styled } from "@mui/material";

import ZoneNameInput from "./ZoneNameInput";
import ZoneControlPanelCameraSettings from "./ZoneControlPanelCameraSettings";
import DeleteZoneButton from "./DeleteZoneButton";
import SaveZoneButton from "./SaveZoneButton";

import {
  ConfiguratorPermissionContext,
  ProjectContext,
} from "../../../context";

import { ZoneDataInterface } from "../../../context/ProjectContext/ProjectEntitesTypes";

const ZoneControlPanel = () => {
  const { zoneList, activeZoneId } = useContext(ProjectContext);
  const { isAllowEditing } = useContext(ConfiguratorPermissionContext);

  const activeZone = zoneList.find(
    (zone: ZoneDataInterface) => zone.id === activeZoneId
  );

  return (
    <>
      {activeZoneId && activeZone ? (
        <ZonePanelWrapper>
          <ZonePanelHeader>
            <ZoneTitle variant="body12Medium">{activeZone.name}</ZoneTitle>
          </ZonePanelHeader>
          <ZonePanelMain>
            <ZoneNameInput activeZone={activeZone} />
            <ZoneControlPanelCameraSettings activeZone={activeZone} />
            {isAllowEditing && (
              <Stack direction="row" justifyContent="flex-end" gap="10px">
                {!activeZone.isSaved && <SaveZoneButton />}
                <DeleteZoneButton />
              </Stack>
            )}
          </ZonePanelMain>
        </ZonePanelWrapper>
      ) : null}
    </>
  );
};

export default ZoneControlPanel;

const ZonePanelWrapper = styled(Box)(({ theme }) => ({
  position: "absolute",
  left: 0,
  bottom: "72px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "start",
  backgroundColor: theme.palette["base-background"],
  border: `1px solid ${theme.palette["grey-dark"]}`,
  borderRadius: "4px",
  width: "243px",
  overflow: "hidden",
}));

const ZonePanelHeader = styled(Box)(({ theme }) => ({
  width: "100%",
  background: theme.palette["blue-background"],
  padding: "2px 10px",
  display: "flex",
  alignItems: "center",
}));

const ZoneTitle = styled(Typography)(({ theme }) => ({
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
  width: "100%",
  color: theme.palette.base.white,
}));

const ZonePanelMain = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  padding: "6px 8px",
  gap: "8px",
}));

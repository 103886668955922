import { EntityTypeEnum } from "../../context/ProjectContext/ProjectEntitesTypes";

export const findObjectAncestorsInditificator = child => {
  let objectId = null;
  let sideIndex = null;
  let cameraId = null;

  const setValues = object => {
    if (
      object.EntityType === EntityTypeEnum.Tower ||
      object.EntityType === EntityTypeEnum.Object
    ) {
      objectId = object.ID;
    } else if (object.EntityType === EntityTypeEnum.TowerSide) {
      sideIndex = object.SideIndex;
    } else if (object.EntityType === EntityTypeEnum.Camera) {
      cameraId = object.ID;
    }
  };

  setValues(child);

  child.traverseAncestors(ancestor => setValues(ancestor));

  return { objectId, sideIndex, cameraId };
};

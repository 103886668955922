import { CSS2DObject } from "three/addons/renderers/CSS2DRenderer.js";
import { createGroup } from "./SceneUtils/CreateMesh";

class DistanceMeasurementLabels {
  constructor(scene) {
    this.scene = scene;

    this.distanceMeasurementGroup = createGroup(
      "distanceMeasurementGroup",
      this.scene
    );

    this.createHtmlLable();
  }

  createHtmlLable() {
    const lable = document.createElement("div");

    lable.style.display = "flex";

    lable.style.alignItems = "center";

    lable.style.justifyContent = "center";

    lable.style.width = "fit-content";

    lable.style.height = "32px";

    lable.style.backgroundColor = "#FFFFFF";

    lable.style.border = "1px solid #98A7BC";

    lable.style.borderRadius = "4px";

    lable.style.padding = "2px 6px 2px 10px";

    const lableText = document.createElement("p");

    lableText.classList.add("lableText");

    lableText.textContent = "";

    lableText.style.fontFamily = "Inter";

    lableText.style.fontWeight = 500;

    lableText.style.fontSize = "20px";

    lableText.style.lineHeight = "28px";

    lableText.style.color = "#202832";

    lableText.style.margin = "0";

    lable.appendChild(lableText);

    this.htmlLable = lable;
  }

  addLable(position, distance) {
    const lable = this.htmlLable.cloneNode(true);

    const lableText = lable.querySelector(".lableText");

    lableText.textContent = `${distance}`;

    const labelObject = new CSS2DObject(lable);

    labelObject.position.set(position.x, position.y, position.z);

    this.distanceMeasurementGroup.add(labelObject);
  }

  removeAllLables() {
    while (this.distanceMeasurementGroup.children.length) {
      this.distanceMeasurementGroup.remove(
        this.distanceMeasurementGroup.children[0]
      );
    }
  }
}

export default DistanceMeasurementLabels;

import { useContext } from "react";
import { Typography, Theme, useTheme } from "@mui/material";

import OutlinedBox from "../../../../common/components/OutlinedBox";
import FileInput from "../../../../common/components/input/FileInput";

import CreateDTProjectContext, {
  CreateDTProjectContextInterface,
  ProjectCreatingType,
} from "../../context/CreateDTProjectContext/CreateDTProjectContext";

import {
  convertKmlToGeojson,
  convertKmzToGeojson,
  convertShpToGeojson,
} from "../../../../common/convertor";

import {
  createJsonFile,
  getFileExtension,
  isKMZHasFile,
} from "../../../../common/utils";

const DTProjectTerrainInput = (): JSX.Element => {
  const theme = useTheme<Theme>();

  const {
    geojsonLayerFileState,
    onProjectGeojsonLayerFileInputChange,
    kmzLayerFileState,
    onProjectKMZLayerFileInputChange,
    handleSetCreatingType,
  } = useContext(CreateDTProjectContext) as CreateDTProjectContextInterface;

  const handleOnClearInput = () => {
    handleSetCreatingType(ProjectCreatingType.GeojsonLayer);

    geojsonLayerFileState.resetState();

    kmzLayerFileState.resetState();
  };

  const onShapeInputChange = async (files: FileList): Promise<void> => {
    const file = files[0];
    const fileExtension = getFileExtension(file.name);

    if (fileExtension === "geojson") {
      onProjectGeojsonLayerFileInputChange(file);
    } else if (fileExtension === "kmz") {
      const isKmzHasPng = await isKMZHasFile(file, ".png");

      if (isKmzHasPng) {
        onProjectKMZLayerFileInputChange(file);
      } else {
        const geojsonData = await convertKmzToGeojson(file);
        const newFile = createJsonFile(geojsonData, file.name);

        onProjectGeojsonLayerFileInputChange(newFile);
      }
    } else if (fileExtension === "kml") {
      const geojsonData = await convertKmlToGeojson(file);
      const newFile = createJsonFile(geojsonData, file.name);

      onProjectGeojsonLayerFileInputChange(newFile);
    } else if (fileExtension === "shp") {
      const geojsonData = await convertShpToGeojson(file);
      const newFile = createJsonFile(geojsonData, file.name);

      onProjectGeojsonLayerFileInputChange(newFile);
    }
  };

  return (
    <OutlinedBox
      paddingSpacing="19px 0 25px"
      label={
        <Typography variant="body16Medium" color={theme.palette["base-label"]}>
          Drag and drop here or browse files
        </Typography>
      }
      borderRadius="4px"
      borderColor={theme.palette["grey-dark"]}
      backgroundColor={theme.palette.base.white}
    >
      <FileInput
        fileState={
          geojsonLayerFileState.state.file
            ? geojsonLayerFileState.state
            : kmzLayerFileState.state
        }
        onClearInput={handleOnClearInput}
        onFileUpload={onShapeInputChange}
        fileTypeName=".shp analogue"
        supportedFormats={{
          hint: ".geojson .kmz .kml .shp (EPSG:4326 WGS-84 coordinate system)",
          accept: ".geojson, .kmz, .kml, .shp",
        }}
        required
        isLoading={geojsonLayerFileState.loading || kmzLayerFileState.loading}
      />
    </OutlinedBox>
  );
};

export default DTProjectTerrainInput;

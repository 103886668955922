import React from "react";

import { Authenticator } from "@aws-amplify/ui-react";
import { useTargetDomain } from "../hooks/useTargetDomain";

const withAuthenticatorProvider = (
  ComposedComponent: React.ComponentType
): React.FC => {
  return (props): JSX.Element => {
    ComposedComponent.displayName =
      "withAuthenticatorProviderComposedComponent";

    useTargetDomain();

    return (
      <Authenticator.Provider>
        <ComposedComponent {...props} />
      </Authenticator.Provider>
    );
  };
};

export default withAuthenticatorProvider;

import { useLazyQuery } from "@apollo/client";

import { GET_DT_FILES } from "../../../common/operations/queries";

export const useGetDTFiles = () => {
  const [getDTFiles, { data, loading, error }] = useLazyQuery(GET_DT_FILES, {
    fetchPolicy: "network-only",
    refetchWritePolicy: "merge",
  });

  const fetchDTFiles = async keys => {
    try {
      const response = await getDTFiles({
        variables: {
          keys,
        },
      });

      if (response.error) {
        console.error(response.error);

        return null;
      }

      return response.data.getDTFiles;
    } catch (error) {
      console.error(error);

      return null;
    }
  };

  return { fetchDTFiles, data, loading, error };
};

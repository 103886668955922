import { useTheme } from "@mui/material/styles";

const LogOutHeaderIcon = (): JSX.Element => {
  const theme = useTheme();

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.998 17C14.9859 19.175 14.8895 20.3529 14.1211 21.1213C13.2424 22 11.8282 22 8.99978 22L7.99978 22C5.17136 22 3.75714 22 2.87846 21.1213C1.99978 20.2426 1.99978 18.8284 1.99978 16L1.99979 8C1.99979 5.17157 1.99979 3.75736 2.87847 2.87868C3.75715 2 5.17136 2 7.99979 2L8.99979 2C11.8282 2 13.2424 2 14.1211 2.87868C14.8895 3.64706 14.9859 4.82497 14.998 7"
        stroke={theme.palette.text.secondary}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M9 12L22 12M22 12L18.5 15M22 12L18.5 9"
        stroke={theme.palette.text.secondary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LogOutHeaderIcon;

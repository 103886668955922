import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  ToggleButton,
  Typography,
  styled,
  Theme,
  useTheme,
} from "@mui/material";

import {
  DeleteIcon,
  WarningIcon,
} from "../../../common/assets/icons/svgAssets";

import {
  ConfirmationModalContext,
  NotificationContext,
} from "../../../common/context";

import { useCustomerIdGuard } from "../../../../common/hooks/useCustomerIdGuard";
import { useDeleteDTProject } from "../hooks/useDeleteDTProject";

import { DTProjectInterface } from "../../../common/interfaces";

interface ProjectCardInterface {
  data: DTProjectInterface;
}

const ProjectCard = ({ data }: ProjectCardInterface): JSX.Element => {
  const theme = useTheme<Theme>();
  const navigate = useNavigate();

  const { openNotification } = useContext(NotificationContext);

  const { setConfirmationModalData } = useContext(ConfirmationModalContext);

  const [selectedProjectId, setSelectedProjectId] = useState<string | null>(
    null
  );
  const selectedCustomerId = useCustomerIdGuard();
  const { deleteDTProject } = useDeleteDTProject();

  const handleOnDeleteButton = (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    e.preventDefault();

    e.stopPropagation();

    const action = async () => {
      await deleteDTProject(data.projectId, selectedCustomerId);

      openNotification({
        title: "Deleted",
        message: "Your project was successfully deleted",
        severity: "success",
      });

      setSelectedProjectId(null);
    };

    const actionOnClose = () => {
      setSelectedProjectId(null);
    };

    setSelectedProjectId(data.projectId);

    setConfirmationModalData({
      icon: <WarningIcon fillColor={theme.palette.red.lighter} />,
      title: "Delete the project",
      message: `Are you sure you want to delete the ${data.displayName}?`,
      actionButton: {
        color: "red",
        title: "Delete",
        action: action,
      },
      actionOnClose: actionOnClose,
    });
  };

  const handleOnOpenProject = (): void => {
    const projectId = data.projectId.replace("#", "_");

    navigate(`/webgl-project-editor/${projectId}`);
  };

  const descriptionText =
    data.lastModificationDate && data.lastModifierUserName
      ? `Edited ${data.lastModificationDate} by ${data.lastModifierUserName}`
      : "";

  return (
    <CardWrapper onClick={handleOnOpenProject}>
      <TitleDescriptionWrapper>
        <Title variant="body16Medium">{data.displayName}</Title>
        <Description variant="body12Medium">{descriptionText}</Description>
      </TitleDescriptionWrapper>
      <DeleteButtonWrapper>
        <ToggleButton
          color="blue"
          className="icon-text"
          value="delete-project"
          selected={selectedProjectId === data.projectId}
          onClick={handleOnDeleteButton}
        >
          <DeleteIcon />
          <Typography variant="body8Medium" component="p">
            Delete
          </Typography>
        </ToggleButton>
      </DeleteButtonWrapper>
    </CardWrapper>
  );
};

export default ProjectCard;

const CardWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "flex-start",
  width: "calc((100% - 60px)/4)",
  minWidth: "450px",
  height: "94px",
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: "4px",
  padding: "25px 20px",
  gap: "8px",

  "&:hover": {
    border: `1px solid ${theme.palette.blue.light}`,
  },

  "&:active": {
    border: `1px solid ${theme.palette.blue.dark}`,
  },
}));

const TitleDescriptionWrapper = styled(Box)(() => ({
  height: "100%",
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "4px",
}));

const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette["base-label"],
}));

const Description = styled(Typography)(({ theme }) => ({
  color: theme.palette["base-hint"],
}));

const DeleteButtonWrapper = styled(Box)(() => ({
  height: "100%",
  display: "flex",
  alignItems: "center",
}));

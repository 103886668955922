import { FC } from "react";
import { Controller } from "react-hook-form";

import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";

import { videoSpeedOptions } from "../../../../pages/system-settings/tabs/user-preferences/components/video-speed/VideoSpeedSection";
import { ILeakFinderUserPreferencesFormProps } from "./LeakFinderUserPreferencesForm";

const LeakFinderVideoSpeedForm: FC<ILeakFinderUserPreferencesFormProps> = ({
  control,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        gap: "1em",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Typography variant="body2Regular">Set default video speed</Typography>

      <Controller
        name="videoSpeed"
        control={control}
        rules={{
          required: "This field is required",
        }}
        render={({ field }) => (
          <Select
            {...field}
            id="videoSpeed"
            size="small"
            variant="standard"
            disableUnderline
            value={field.value ?? ""}
            onChange={field.onChange}
            IconComponent={ExpandMoreRoundedIcon}
          >
            {videoSpeedOptions.map(option => {
              return (
                <MenuItem key={option.label} value={option.value}>
                  {option.label}
                </MenuItem>
              );
            })}
          </Select>
        )}
      />
    </Box>
  );
};

export default LeakFinderVideoSpeedForm;

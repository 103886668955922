import { useTheme } from "@mui/material/styles";

const UploadVideoIcon = () => {
  const theme = useTheme();

  return (
    <svg
      width="40"
      height="41"
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        y="0.5"
        width="40"
        height="40"
        rx="20"
        fill={theme.palette.primary.shades?.["30p"]}
      />
      <path
        d="M10 20C10 16.7125 10 15.0688 10.908 13.9624C11.0742 13.7599 11.2599 13.5742 11.4624 13.408C12.5688 12.5 14.2125 12.5 17.5 12.5C20.7875 12.5 22.4312 12.5 23.5376 13.408C23.7401 13.5742 23.9258 13.7599 24.092 13.9624C25 15.0688 25 16.7125 25 20V21C25 24.2875 25 25.9312 24.092 27.0376C23.9258 27.2401 23.7401 27.4258 23.5376 27.592C22.4312 28.5 20.7875 28.5 17.5 28.5C14.2125 28.5 12.5688 28.5 11.4624 27.592C11.2599 27.4258 11.0742 27.2401 10.908 27.0376C10 25.9312 10 24.2875 10 21V20Z"
        stroke={theme.palette.primary.main}
        strokeWidth="1.5"
      />
      <path
        d="M25 18L25.6584 17.6708C27.6042 16.6979 28.5772 16.2114 29.2886 16.6511C30 17.0908 30 18.1785 30 20.3541V20.6459C30 22.8215 30 23.9093 29.2886 24.3489C28.5772 24.7886 27.6042 24.3021 25.6584 23.3292L25 23V18Z"
        stroke={theme.palette.primary.main}
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default UploadVideoIcon;

import { SvgIcon, SvgIconProps } from "@mui/material";
import { ReactElement } from "react";

export const SelectInputIcon = (props: SvgIconProps): ReactElement => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.58751 5.33785C3.81532 5.11004 4.18466 5.11004 4.41247 5.33785L7.49999 8.42537L10.5875 5.33785C10.8153 5.11004 11.1847 5.11004 11.4125 5.33785C11.6403 5.56565 11.6403 5.935 11.4125 6.1628L7.91247 9.6628C7.68466 9.89061 7.31532 9.89061 7.08751 9.6628L3.58751 6.1628C3.35971 5.935 3.35971 5.56565 3.58751 5.33785Z"
        fill="#787878"
      />
    </SvgIcon>
  );
};

import { useCallback } from "react";
import { customRound } from "../../common/helpers/customRound";

const useConvertArrayToCSV = () => {
  const convertArrayToCSV = useCallback((array: any[], columns: any[]) => {
    const columnFields = columns
      .filter(col => col.field !== "checkBox" && col.field !== "actions")
      .map(col => ({ field: col.field, headerName: col.headerName }));

    const header = columnFields.map(col => col.headerName).join(",");

    const rows = array.map(obj => {
      return columnFields
        .map(col => {
          let value = obj[col.field];

          if (col.field === "geometry") {
            value = `"${value.replace(/"/g, '""')}"`; // Ensure the JSON string is correctly formatted
          } else if (col.field === "outputMeasurement") {
            value = customRound(value * 86400); // Apply customRound AND MFC/d conversion to outputMeasurement field
          } else if (typeof value === "object" || typeof value === "string") {
            value = `"${value}"`;
          }

          return value;
        })
        .join(",");
    });

    return [header, ...rows].join("\n");
  }, []);

  return { convertArrayToCSV };
};

export default useConvertArrayToCSV;

import { createContext } from "react";

import { DTObject } from "../../../../../API";

export interface EditDTObjectContextInterface {
  objectDataToEdit: DTObject | null;
  openEditDTObjectForm: (data: DTObject) => void;
  closeEditDTObjectForm: () => void;
}

export const EditDTObjectContext = createContext<EditDTObjectContextInterface>(
  {} as EditDTObjectContextInterface
);

const allowedMediaTypes = ["mp4", "jpg", "png"];

export enum MEDIA_TYPE_VALIDATION_RESULTS {
  VALID = "VALID",
  INVALID = "INVALID",
  UNKNOWN_TYPE = "UNKNOWN_TYPE",
}

type MediaTypeValidationResult =
  | MEDIA_TYPE_VALIDATION_RESULTS.VALID
  | MEDIA_TYPE_VALIDATION_RESULTS.INVALID
  | MEDIA_TYPE_VALIDATION_RESULTS.UNKNOWN_TYPE;

interface IUseMediaValidationResult {
  validationResult: MediaTypeValidationResult;
  extension?: string;
}

/**
 * This function is used to validate the media file extension
 * @param {string} media - image S3 key or video stream name
 * @return {IUseMediaValidationResult} - result of the media type validation
 */
export const validateMediaFileExtension = (
  media: string
): IUseMediaValidationResult => {
  const mediaType = media.split(".");

  // If the media type is empty or separator does not occur in the returned array, the returned array
  // contains one element consisting of the entire string
  if (!mediaType.length || mediaType.length === 1) {
    return {
      validationResult: MEDIA_TYPE_VALIDATION_RESULTS.UNKNOWN_TYPE,
    };
  }

  const extension = mediaType.pop();

  if (!extension || extension.length === 1) {
    return { validationResult: MEDIA_TYPE_VALIDATION_RESULTS.UNKNOWN_TYPE };
  }

  if (!allowedMediaTypes.includes(extension)) {
    return {
      validationResult: MEDIA_TYPE_VALIDATION_RESULTS.INVALID,
      extension,
    };
  }

  return { validationResult: MEDIA_TYPE_VALIDATION_RESULTS.VALID, extension };
};

import { SvgIcon, SvgIconProps } from "@mui/material";
import { ReactElement } from "react";

export const EditIcon = (props: SvgIconProps): ReactElement => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7441 1.91107C13.0695 1.58563 13.5971 1.58563 13.9226 1.91107L18.0892 6.07774C18.4147 6.40317 18.4147 6.93081 18.0892 7.25625L7.25591 18.0896C7.09963 18.2459 6.88767 18.3337 6.66666 18.3337H2.49999C2.03975 18.3337 1.66666 17.9606 1.66666 17.5003V13.3337C1.66666 13.1126 1.75445 12.9007 1.91073 12.7444L12.7441 1.91107ZM3.33332 13.6788V16.667H6.32148L16.3215 6.66699L13.3333 3.67884L3.33332 13.6788Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

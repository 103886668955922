import { useState, useRef } from "react";
import axios from "axios";
import { S3Helper } from "../../common/utils/s3helper";
import {
  errorNotification,
  successNotification,
} from "../../common/variables/notification";

interface UseAddFile {
  addFile: (uploadVideoPath: string, file: File) => Promise<void>;
  waitForAllUploads: () => Promise<void>;
  error: Error | null;
  loading: boolean;
}

const useAddFile = (): UseAddFile => {
  const [error, setError] = useState<Error | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const uploadPromises = useRef<Promise<any>[]>([]);

  const addFile = async (uploadVideoPath: string, file: File) => {
    setIsLoading(true);

    setError(null);

    try {
      const signedURLPath = await S3Helper.getSignedUrl(
        uploadVideoPath,
        "putObject",
        file.type
      );
      const uploadPromise = axios.put(signedURLPath, file, {
        headers: {
          "Content-Type": file.type,
        },
      });

      uploadPromises.current.push(uploadPromise);
    } catch (err) {
      const errorToSet =
        err instanceof Error ? err : new Error("Unknown error occurred");

      setError(errorToSet);

      errorNotification(errorToSet.message);

      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const waitForAllUploads = async (): Promise<void> => {
    setIsLoading(true);

    const results = await Promise.allSettled(uploadPromises.current);
    const errors = results.filter(result => result.status === "rejected");

    if (errors.length > 0) {
      const errorMessage = "Some uploads failed.";

      setError(new Error(errorMessage));

      errorNotification(errorMessage);
    } else {
      successNotification("All files uploaded successfully.");
    }

    uploadPromises.current = [];

    setIsLoading(false);
  };

  return { addFile, waitForAllUploads, error, loading: isLoading };
};

export default useAddFile;

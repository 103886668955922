import React, { useEffect, useState } from "react";

import { Box, FormGroup, IconButton, Tooltip } from "@mui/material";

import DeleteIconButton from "../../../common/components/icons/DeleteIconButtonWithText";
import StatusToggle from "../../../common/components/item/StatusToggle";
import { IDeviceTableRow, IModel } from "../hooks/useDeviceTableRows";
import { useServiceActions } from "../hooks/useServiceActions";
import { useGetAnnotations } from "../hooks/useGetAnnotations";
import { ServiceTypeEnum } from "../../model-manager/variables/modelManager";

type ModelActionControlsProps = {
  device: IDeviceTableRow;
  deviceModel: IModel;
  isModelRunning: boolean;
  setIsModelRunning: (newStatus: boolean) => void;
};

const ModelActionControls: React.FC<ModelActionControlsProps> = ({
  device,
  deviceModel,
  isModelRunning,
  setIsModelRunning,
}): JSX.Element => {
  const [allowStartTlmModel, setAllowStartTlmModel] = useState(false);

  const deviceDetailPageLink = `/device/${encodeURIComponent(
    device?.rowId ?? ""
  )}`;

  const { data: annotationsData, loading: annotationsDataLoading } =
    useGetAnnotations({
      serviceId: deviceModel?.serviceId ?? "",
    });

  useEffect(() => {
    if (!annotationsDataLoading && annotationsData?.getAnnotations.items) {
      setAllowStartTlmModel(annotationsData?.getAnnotations.items.length > 0);
    }
  }, [annotationsData?.getAnnotations.items]);

  const { changeModelStatusAction, deleteModelAction, serviceLoading } =
    useServiceActions({
      device,
      deviceModel,
    });

  const onChangeModelStatusHandler = (): void => {
    changeModelStatusAction(isModelRunning).then((): void => {
      setIsModelRunning(!isModelRunning);
    });
  };

  const onDeleteModelHandler = (): void => {
    deleteModelAction(deviceDetailPageLink);
  };

  return (
    <Box
      sx={{
        marginLeft: "auto",
        display: "flex",
        gap: "1em",
        alignItems: "center",
        pointerEvents: serviceLoading.commonLoading ? "none" : "auto",
        opacity: serviceLoading.commonLoading ? 0.6 : 1,
      }}
    >
      <IconButton disableRipple onClick={onDeleteModelHandler}>
        <DeleteIconButton />
      </IconButton>

      <FormGroup>
        <Tooltip
          title={
            deviceModel?.serviceName === ServiceTypeEnum.TANK_LEVEL &&
            !allowStartTlmModel
              ? "TLM model can't be started if Tanks have not been defined"
              : isModelRunning
              ? "Disable model. Zones will not be monitored while disabled"
              : "Enable model. In case you enable the model, it will be monitored"
          }
          arrow
          placement="top"
        >
          <span>
            <StatusToggle
              loading={serviceLoading.updateStatusLoading}
              disabled={
                deviceModel?.serviceName === ServiceTypeEnum.TANK_LEVEL &&
                !allowStartTlmModel
              }
              checked={!!isModelRunning}
              handleChange={onChangeModelStatusHandler}
            />
          </span>
        </Tooltip>
      </FormGroup>
    </Box>
  );
};

export default React.memo(ModelActionControls);

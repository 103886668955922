import { Link, useLocation } from "react-router-dom";
import { AppBar, Box, Toolbar, useTheme, styled } from "@mui/material";

// eslint-disable-next-line no-restricted-imports
import { type CSSProperties } from "@mui/material/styles/createMixins";

import AvatarPopover from "./components/AvatarPopover";
import UserSettingsPopover from "./components/UserSettingsPopover";
import CustomerSelect from "./components/CustomerSelect";
import LogoLight from "../layout-new/logo/LogoLight";
import LogoDark from "../layout-new/logo/LogoDark";

const WebGLDashboardRoute = "/webgl-dashboard";

const WebglConfiguratorHeaderTopBar = (): JSX.Element => {
  const theme = useTheme();
  const location = useLocation();

  const isShowCustomerSelect = location.pathname.includes(WebGLDashboardRoute);

  return (
    <AppBar enableColorOnDark color="primary" position="static">
      <StyledToolbar>
        <Link to={WebGLDashboardRoute}>
          {theme.palette.mode === "light" ? <LogoLight /> : <LogoDark />}
        </Link>

        {isShowCustomerSelect && <CustomerSelect size="small" />}

        <UserSettingsWrapper>
          <UserSettingsPopover />
          <AvatarPopover />
        </UserSettingsWrapper>
      </StyledToolbar>
    </AppBar>
  );
};

export default WebglConfiguratorHeaderTopBar;

const StyledToolbar = styled(Toolbar)(
  (): CSSProperties => ({
    height: "100%",
    gap: "1em",
  })
);

const UserSettingsWrapper = styled(Box)(
  (): CSSProperties => ({
    display: "flex",
    gap: "4px",
    marginLeft: "auto",
  })
);

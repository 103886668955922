import { useEffect, useState } from "react";

import { kinesishelper } from "../utils/kinesishelper";
import { useSelectedStreamObject } from "../variables/selectedStreamObject";

const useHlsStream = () => {
  const [loading, setLoading] = useState(false);
  const [hlsUrl, setHlsUrl] = useState("");
  const [retryCount, setRetryCount] = useState(0);

  const selectedStreamObject = useSelectedStreamObject();
  const stream = selectedStreamObject ? selectedStreamObject.streamName : "";

  const maxRetries = 20;

  const fetchHlsUrl = () => {
    try {
      kinesishelper
        .getUrl(stream)
        .then(endpoint =>
          kinesishelper.getHLSStreamingSessionURL(stream, endpoint ?? "")
        )
        .then(output => {
          setHlsUrl(output ?? "");
        })
        .catch(() => {
          setRetryCount(prevCount => prevCount + 1);
        });
    } catch (error) {
      console.error("Error while fetching HLS URL", error);

      setLoading(false);

      setHlsUrl("");
    }
  };

  useEffect(() => {
    if (stream && !hlsUrl && retryCount < maxRetries) {
      setLoading(true);

      fetchHlsUrl();
    }

    if (retryCount >= maxRetries) {
      setLoading(false);
    }

    if (hlsUrl) {
      setRetryCount(0);

      setLoading(false);
    }
  }, [stream, hlsUrl, retryCount]);

  useEffect((): (() => void) => {
    return (): void => {
      setLoading(false);

      setRetryCount(0);

      setHlsUrl("");
    };
  }, []);

  const retry = () => {
    // set retrCount to 0 to trigger useEffect
    setRetryCount(0);
  };

  return {
    loading,
    hlsUrl,
    retryCount,
    needRetry: retryCount >= maxRetries,
    retry,
    setLoading,
  };
};

export default useHlsStream;

import { useLazyQuery } from "@apollo/client";

import { GET_DT_FILE } from "../../../common/operations/queries";

export const useGetDTFile = () => {
  const [getDTFile, { data, loading, error }] = useLazyQuery(GET_DT_FILE, {
    fetchPolicy: "network-only",
    refetchWritePolicy: "merge",
  });

  const fetchDTFile = async key => {
    try {
      const response = await getDTFile({
        variables: {
          key,
        },
      });

      if (response.error) {
        console.error(response.error);

        return null;
      }

      return response.data.getDTFiles;
    } catch (error) {
      console.error(error);

      return null;
    }
  };

  return { fetchDTFile, data, loading, error };
};

import { ChangeEvent } from "react";

import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";

import SearchIcon from "../../../common/components/icons/SearchIcon";
import {
  clientUploadsSearchVariable,
  useClientUploadsSearchVariable,
} from "../../variables/clientUploads";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "../../../common/components/icons/CloseIcon";

const LeakFinderSearchInput = () => {
  const leakFinderSearchValue = useClientUploadsSearchVariable();

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
    clientUploadsSearchVariable(e.target.value);
  };

  const clearSearchValue = (): void => {
    clientUploadsSearchVariable("");
  };

  return (
    <TextField
      label="Search by clients or groups"
      value={leakFinderSearchValue}
      onChange={handleInputChange}
      size="small"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: (
          <IconButton
            onClick={clearSearchValue}
            disableRipple
            sx={{
              visibility: leakFinderSearchValue ? "visible" : "hidden",
            }}
          >
            <CloseIcon />
          </IconButton>
        ),
      }}
    />
  );
};

export default LeakFinderSearchInput;

import { Box, Stack, Typography, styled } from "@mui/material";
import { useContext, useEffect } from "react";
import { ProjectContext } from "../../../context";
import { ModeTypeEnum } from "../../../context/ProjectContext/ProjectEntitesTypes";

const FPVModeInstructions = () => {
  const { threeScene, setIsFPVModeUnlocked, mode, isFPVModeUnlocked } =
    useContext(ProjectContext);

  const closeFPVModeInstruction = () => {
    setIsFPVModeUnlocked(true);
  };

  useEffect(() => {
    if (threeScene) {
      threeScene.sceneControls.FPVModeControls.addEvents(setIsFPVModeUnlocked);
    }

    return () => {
      if (threeScene) {
        threeScene.sceneControls.FPVModeControls.removeEvents();
      }
    };
  }, [threeScene]);

  useEffect(() => {
    if (!threeScene) return;

    if (mode === ModeTypeEnum.standard) {
      setIsFPVModeUnlocked(false);
    }

    threeScene.sceneControls.FPVModeControls.lockFPVMode();

    isFPVModeUnlocked &&
      threeScene.sceneControls.FPVModeControls.unlockFPVMode();
  }, [mode, isFPVModeUnlocked]);

  return (
    <Wrapper
      disabled={mode === ModeTypeEnum.fpvMode && !isFPVModeUnlocked ? 0 : 1}
      onClick={closeFPVModeInstruction}
    >
      <Typography variant="h4">Click to play</Typography>
      <Stack gap="4px">
        <Typography variant="body16Medium">Move: WASD</Typography>
        <Typography variant="body16Medium">Look: MOUSE</Typography>
        <Typography variant="body16Medium">Exit: ESC</Typography>
      </Stack>
    </Wrapper>
  );
};

export default FPVModeInstructions;

const Wrapper = styled(Box)<{ disabled: number }>(({ disabled }) => ({
  position: "absolute",
  width: "100%",
  height: "100%",

  display: !disabled ? "flex" : "none",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "24px",

  textAlign: "center",

  cursor: "pointer",
  background: "rgba(0,0,0,0.5)",
  color: "white",
}));

import { Box, Slider, useTheme } from "@mui/material";
import React from "react";
import { Control, Controller } from "react-hook-form";
import uuid from "react-uuid";
import { VideoAnalysisFormState } from "../../UploadVideoGroupPage";

interface KeyFramePlayerProps {
  urlVideoLink: string;
  state: boolean;
  videoRef: React.RefObject<HTMLVideoElement>;
  control: Control<VideoAnalysisFormState>;
  currentStep: number;
}

const KeyFramePlayer: React.FC<KeyFramePlayerProps> = ({
  urlVideoLink,
  state,
  videoRef,
  control,
  currentStep,
}) => {
  const theme = useTheme();

  const handleTimeSliderChange = (event: Event, value: number | number[]) => {
    const time = Array.isArray(value) ? value[0] : value;

    if (videoRef.current) {
      videoRef.current.currentTime = time;
    }
  };

  const formatTime = (value: number) => {
    const minutes = Math.floor(value / 60);
    const seconds = Math.floor(value % 60);

    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const videoId = uuid();

  return (
    <Box>
      <video
        ref={videoRef}
        id={videoId}
        src={urlVideoLink}
        style={{
          width: "100%",
          borderRadius: "8px",
          overflow: "hidden",
        }}
        controls={false}
      />

      {state && (
        <Controller
          name={`videos.${currentStep}.keyframeTime`}
          control={control}
          defaultValue={0}
          render={({ field: { onChange, value } }) => (
            <Slider
              min={0}
              max={videoRef.current?.duration || 100}
              value={typeof value === "number" ? value : 0}
              valueLabelDisplay="auto"
              valueLabelFormat={formatTime}
              onChange={(e, val) => {
                onChange(val);

                handleTimeSliderChange(e, val);
              }}
              sx={{
                width: "100%",
                color: "primary.main",
                "& .MuiSlider-thumb": {
                  backgroundColor: theme.palette.primary.main,
                },
                "& .MuiSlider-rail": {
                  backgroundColor: "primary.dark",
                },
              }}
            />
          )}
        />
      )}
    </Box>
  );
};

export default KeyFramePlayer;

import { useContext, useEffect } from "react";

import TextInput from "../../input/TextInput";

import { useTextInputState } from "../../../hooks/useTextInputState";
import { ProjectContext } from "../../../context";
import { ZoneDataInterface } from "../../../context/ProjectContext/ProjectEntitesTypes";

import { textNameValidation } from "../../../validation";

interface ZoneNameInputInterface {
  activeZone: ZoneDataInterface;
}

const ZoneNameInput = ({ activeZone }: ZoneNameInputInterface): JSX.Element => {
  const { updateZone } = useContext(ProjectContext);
  const zoneName = useTextInputState(
    {
      value: activeZone.name,
      error: false,
      errorMessage: "",
      trackError: true,
    },
    textNameValidation
  );

  const handleOnNameChange = (value: string) => {
    const isError = zoneName.onChange(value);

    if (!isError) {
      updateZone(activeZone.id, { name: value });
    }
  };

  useEffect(() => {
    if (zoneName.state.value !== activeZone.name) {
      handleOnNameChange(activeZone.name);
    }
  }, [activeZone.name]);

  const invalid = zoneName.state.error;

  return (
    <TextInput
      label="Zone name"
      value={zoneName.state.value}
      isError={invalid}
      handleOnChange={handleOnNameChange}
    />
  );
};

export default ZoneNameInput;

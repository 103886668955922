import { Box, Typography, styled } from "@mui/material";

import NewDTProjectButton from "./createProject/NewDTProjectButton";

const DashboardPageHeader = () => {
  return (
    <HeaderWrapper>
      <Title variant="h1">Projects</Title>
      <NewDTProjectButton />
    </HeaderWrapper>
  );
};

export default DashboardPageHeader;

const HeaderWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "44px 0 56px",
  borderBottom: `1px solid ${theme.palette.divider}`,
  "@media (max-width:1500px)": {
    padding: "35px 0 48px",
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette["base-label"],
}));

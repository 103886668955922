import { useContext } from "react";
import { Box, styled } from "@mui/material";

import AddTowerButton from "../createTower/AddTowerButton";
import TowerList from "./TowerList";

import { ConfiguratorPermissionContext } from "../../../context";

const TowersPanel = (): JSX.Element => {
  const { isAllowEditing } = useContext(ConfiguratorPermissionContext);

  return (
    <Wrapper>
      {isAllowEditing && <AddTowerButton />}
      <TowerList />
    </Wrapper>
  );
};

export default TowersPanel;

const Wrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  gap: "18px",
  background: theme.palette.blue.lighter,
  borderRadius: "8px",
  padding: "10px 15px",
}));

import { makeVar, useReactiveVar } from "@apollo/client";

import { AutocompleteOptionType } from "../../../common/models/autocomplete";
import { IFormValidationState } from "../../../common/models/formValidation";
import { IDeviceTableRow } from "../hooks/useDeviceTableRows";
import { IJsonData } from "../hooks/useGetDeviceByUrl";

export interface DisplayNameInput {
  id: string;
  name: string;
}

interface IDeviceForm {
  isDirty?: boolean;
  deviceName: string;
  location: AutocompleteOptionType;
  node: DisplayNameInput | null;
  nodeIP?: string | null;
  deviceType: string;
  cameraIpAddress: string;
  deviceData: IJsonData;
  deviceTypeShortName?: string;
  hasPanTilt?: boolean;
  hasZoom?: boolean;
  rtspTemplate?: string;
  defaultUserPwd?: string;
  deviceMakePanTiltValue?: boolean;
  deviceMakeHasZoomValue?: boolean;
}

interface IDeviceFormValidationState {
  location: IFormValidationState;
  deviceName: IFormValidationState;
  node: IFormValidationState;
  deviceType: IFormValidationState;
  cameraIpAddress: IFormValidationState;
  panTiltIP: IFormValidationState;
  [key: string]: IFormValidationState;
}

export const defaultDeviceFormValidationState: IDeviceFormValidationState = {
  location: {
    hasError: false,
    errorMessage: "",
  },
  deviceName: {
    hasError: false,
    errorMessage: "",
  },
  node: {
    hasError: false,
    errorMessage: "",
  },
  deviceType: {
    hasError: false,
    errorMessage: "",
  },
  cameraIpAddress: {
    hasError: false,
    errorMessage: "",
  },
  panTiltIP: {
    hasError: false,
    errorMessage: "",
  },
};

export const deviceFormRules = {
  deviceName: {
    required: true,
    unique: true,
    pattern: /^[a-zA-Z0-9_.-]+$/, // no spaces, only alphanumerics, underscore, dash and dot
    maxLength: 30,
  },
  node: {
    required: true,
  },
  deviceType: {
    required: true,
  },
  cameraIpAddress: {
    required: true,
  },
  panTiltIP: {
    required: true,
  },
};

export const defaultDeviceFormValues: IDeviceForm = {
  isDirty: false,
  deviceName: "",
  location: {
    value: "",
    title: "",
  },
  node: {
    id: "",
    name: "",
  },
  nodeIP: "",
  deviceType: "",
  deviceData: {},
  cameraIpAddress: "",
  deviceTypeShortName: "",
  rtspTemplate: "",
  defaultUserPwd: "",
};

export const deviceFormVariables = makeVar<IDeviceForm>(
  defaultDeviceFormValues
);

export const useDeviceFormVariables = () => useReactiveVar(deviceFormVariables);

export const selectedLocationDevicesVariable =
  makeVar<AutocompleteOptionType>(null);

export const useSelectedLocationDevices = () =>
  useReactiveVar(selectedLocationDevicesVariable);

export const devicesLoadingVariable = makeVar<boolean>(true);

export const useDevicesTableLoading = () =>
  useReactiveVar(devicesLoadingVariable);

export const deviceFormValidationStateVariable =
  makeVar<IDeviceFormValidationState>(defaultDeviceFormValidationState);

export const useDeviceFormValidationState = () =>
  useReactiveVar(deviceFormValidationStateVariable);

export const deviceDataVariable = makeVar<IDeviceTableRow | null>(null);

export const useDeviceDataState = () => useReactiveVar(deviceDataVariable);

import { useState } from "react";
import { useGetZonesByDevice } from "../../../data-hub/components/liveview/components/zonelist/hooks/useGetZonesByDevice";

export interface ICreateZoneValidationState {
  locationName: {
    hasError: boolean;
    errorMessage: string;
  };
  minutes: {
    hasError: boolean;
    errorMessage: string;
  };
  seconds: {
    hasError: boolean;
    errorMessage: string;
  };
  pan: {
    hasError: boolean;
    errorMessage: string;
  };
  panValue: {
    hasError: boolean;
    errorMessage: string;
  };
  tilt: {
    hasError: boolean;
    errorMessage: string;
  };
  tiltValue: {
    hasError: boolean;
    errorMessage: string;
  };
  zoom: {
    hasError: boolean;
    errorMessage: string;
  };
  zoomValue: {
    hasError: boolean;
    errorMessage: string;
  };
}

export const validationStateDefault: ICreateZoneValidationState = {
  locationName: {
    hasError: false,
    errorMessage: "",
  },
  minutes: {
    hasError: false,
    errorMessage: "",
  },
  seconds: {
    hasError: false,
    errorMessage: "",
  },
  pan: {
    hasError: false,
    errorMessage: "",
  },
  tilt: {
    hasError: false,
    errorMessage: "",
  },
  zoom: {
    hasError: false,
    errorMessage: "",
  },
  panValue: {
    hasError: false,
    errorMessage: "",
  },
  tiltValue: {
    hasError: false,
    errorMessage: "",
  },
  zoomValue: {
    hasError: false,
    errorMessage: "",
  },
};

const PAN_MIN = -180;
const PAN_MAX = 180;
const TILT_MIN = -90;
const TILT_MAX = 35;
const ZOOM_MIN = 0;
const ZOOM_MAX = 100;

export const useZoneFormValidation = (
  originalZoneName: string | undefined = undefined
) => {
  const [validationState, setValidationState] =
    useState<ICreateZoneValidationState>(validationStateDefault);

  const { data } = useGetZonesByDevice();
  const zones = data?.getZonesByDevice.items;

  const resetValidationState = () => {
    setValidationState(validationStateDefault);
  };

  const validateCreateZoneForm = (
    zoneName: string,
    pan: number | string,
    tilt: number | string,
    zoom: number | string,
    minutes: string,
    seconds: string
  ) => {
    resetValidationState();

    let state = { ...validationStateDefault };

    if (!zoneName) {
      const locationNameError = {
        hasError: true,
        errorMessage: "This field is required",
      };

      state = {
        ...state,
        locationName: locationNameError,
      };

      setValidationState(
        (prev): ICreateZoneValidationState => ({
          ...prev,
          locationName: locationNameError,
        })
      );
    }

    if (
      zones?.some(
        zone => zone?.name === zoneName && originalZoneName !== zoneName
      )
    ) {
      const locationNameError = {
        hasError: true,
        errorMessage: "Zone name already exists",
      };

      state = {
        ...state,
        locationName: locationNameError,
      };

      setValidationState(
        (prev): ICreateZoneValidationState => ({
          ...prev,
          locationName: locationNameError,
        })
      );
    }

    if (Number(minutes) < 0 || Number(minutes) > 6) {
      const minutesError = {
        hasError: true,
        errorMessage: "Minutes must be between 0 and 6",
      };

      state = {
        ...state,
        minutes: minutesError,
      };

      setValidationState(
        (prev): ICreateZoneValidationState => ({
          ...prev,
          minutes: minutesError,
        })
      );
    }

    if (
      seconds !== "00" &&
      (!Number(seconds) || Number(seconds) < 0 || Number(seconds) > 59)
    ) {
      const secondsError = {
        hasError: true,
        errorMessage: "Seconds must be between 00 and 59",
      };

      state = {
        ...state,
        seconds: secondsError,
      };

      setValidationState(
        (prev): ICreateZoneValidationState => ({
          ...prev,
          seconds: secondsError,
        })
      );
    }

    if (!Number(minutes) && (Number(seconds) < 30 || seconds === "00")) {
      const secondsMinError = {
        hasError: true,
        errorMessage: "Minimum inspection time is 30 seconds",
      };

      state = {
        ...state,
        seconds: secondsMinError,
      };

      setValidationState(
        (prev): ICreateZoneValidationState => ({
          ...prev,
          seconds: secondsMinError,
        })
      );
    }

    if (pan === null || pan === undefined || pan === "") {
      const panError = {
        hasError: true,
        errorMessage: "Required",
      };

      state = {
        ...state,
        pan: panError,
      };

      setValidationState(
        (prev): ICreateZoneValidationState => ({
          ...prev,
          pan: panError,
        })
      );
    }

    if (Number(pan) < PAN_MIN || Number(pan) > PAN_MAX) {
      const panValueError = {
        hasError: true,
        errorMessage: `Pan must be between ${PAN_MIN} and ${PAN_MAX}`,
      };

      state = {
        ...state,
        panValue: panValueError,
      };

      setValidationState(
        (prev): ICreateZoneValidationState => ({
          ...prev,
          panValue: panValueError,
        })
      );
    }

    if (tilt === null || tilt === undefined || tilt === "") {
      const tiltError = {
        hasError: true,
        errorMessage: "Required",
      };

      state = {
        ...state,
        tilt: tiltError,
      };

      setValidationState(
        (prev): ICreateZoneValidationState => ({
          ...prev,
          tilt: tiltError,
        })
      );
    }

    if (Number(tilt) < TILT_MIN || Number(tilt) > TILT_MAX) {
      const tiltValueError = {
        hasError: true,
        errorMessage: `Tilt must be between ${TILT_MIN} and ${TILT_MAX}`,
      };

      state = {
        ...state,
        tiltValue: tiltValueError,
      };

      setValidationState(
        (prev): ICreateZoneValidationState => ({
          ...prev,
          tiltValue: tiltValueError,
        })
      );
    }

    if (zoom === null || zoom === undefined || zoom === "") {
      const zoomError = {
        hasError: true,
        errorMessage: "Required",
      };

      state = {
        ...state,
        zoom: zoomError,
      };

      setValidationState(
        (prev): ICreateZoneValidationState => ({
          ...prev,
          zoom: zoomError,
        })
      );
    }

    if (Number(zoom) < ZOOM_MIN || Number(zoom) > ZOOM_MAX) {
      const zoomValueError = {
        hasError: true,
        errorMessage: `Zoom must be between ${ZOOM_MIN} and ${ZOOM_MAX}`,
      };

      state = {
        ...state,
        zoomValue: zoomValueError,
      };

      setValidationState(
        (prev): ICreateZoneValidationState => ({
          ...prev,
          zoomValue: zoomValueError,
        })
      );
    }

    if (Object.values(state).some((item): boolean => item.hasError)) {
      return false;
    }

    return true;
  };

  const validatePanTilt = (pan: number | string, tilt: number | string) => {
    let state = { ...validationStateDefault };

    if (pan === null || pan === undefined || pan === "") {
      const panError = {
        hasError: true,
        errorMessage: "Required",
      };

      state = {
        ...state,
        pan: panError,
      };

      setValidationState(
        (prev): ICreateZoneValidationState => ({
          ...prev,
          pan: panError,
        })
      );
    }

    if (Number(pan) < PAN_MIN || Number(pan) > PAN_MAX) {
      const panValueError = {
        hasError: true,
        errorMessage: `Pan must be between ${PAN_MIN} and ${PAN_MAX}`,
      };

      state = {
        ...state,
        panValue: panValueError,
      };

      setValidationState(
        (prev): ICreateZoneValidationState => ({
          ...prev,
          panValue: panValueError,
        })
      );
    }

    if (tilt === null || tilt === undefined || tilt === "") {
      const tiltError = {
        hasError: true,
        errorMessage: "Required",
      };

      state = {
        ...state,
        tilt: tiltError,
      };

      setValidationState(
        (prev): ICreateZoneValidationState => ({
          ...prev,
          tilt: tiltError,
        })
      );
    }

    if (Number(tilt) < TILT_MIN || Number(tilt) > TILT_MAX) {
      const tiltValueError = {
        hasError: true,
        errorMessage: `Tilt must be between ${TILT_MIN} and ${TILT_MAX}`,
      };

      state = {
        ...state,
        tiltValue: tiltValueError,
      };

      setValidationState(
        (prev): ICreateZoneValidationState => ({
          ...prev,
          tiltValue: tiltValueError,
        })
      );
    }

    if (Object.values(state).some((item): boolean => item.hasError)) {
      return false;
    }

    return true;
  };

  const validateZoom = (zoom: number | string) => {
    let state = { ...validationStateDefault };

    if (zoom === null || zoom === undefined || zoom === "") {
      const zoomError = {
        hasError: true,
        errorMessage: "Required",
      };

      state = {
        ...state,
        zoom: zoomError,
      };

      setValidationState(
        (prev): ICreateZoneValidationState => ({
          ...prev,
          zoom: zoomError,
        })
      );
    }

    if (Number(zoom) < ZOOM_MIN || Number(zoom) > ZOOM_MAX) {
      const zoomValueError = {
        hasError: true,
        errorMessage: `Zoom must be between ${ZOOM_MIN} and ${ZOOM_MAX}`,
      };

      state = {
        ...state,
        zoomValue: zoomValueError,
      };

      setValidationState(
        (prev): ICreateZoneValidationState => ({
          ...prev,
          zoomValue: zoomValueError,
        })
      );
    }

    if (Object.values(state).some((item): boolean => item.hasError)) {
      return false;
    }

    return true;
  };

  return {
    validationState,
    setValidationState,
    resetValidationState,
    validateCreateZoneForm,
    validatePanTilt,
    validateZoom,
  };
};

import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import { useTheme } from "@mui/material/styles";

const RejectedCircleIcon = (props: SvgIconProps): JSX.Element => {
  const theme = useTheme();

  return (
    <SvgIcon
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        width: "2em",
        height: "2em",
      }}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44 24C44 35.0457 35.0457 44 24 44C12.9543 44 4 35.0457 4 24C4 12.9543 12.9543 4 24 4C35.0457 4 44 12.9543 44 24ZM17.9393 17.9393C18.525 17.3535 19.4748 17.3535 20.0606 17.9393L23.9999 21.8787L27.9393 17.9393C28.525 17.3536 29.4748 17.3536 30.0606 17.9393C30.6464 18.5251 30.6464 19.4749 30.0606 20.0607L26.1213 24L30.0605 27.9393C30.6463 28.525 30.6463 29.4748 30.0605 30.0606C29.4747 30.6464 28.525 30.6464 27.9392 30.0606L23.9999 26.1213L20.0606 30.0606C19.4748 30.6464 18.5251 30.6464 17.9393 30.0606C17.3535 29.4748 17.3535 28.5251 17.9393 27.9393L21.8786 24L17.9393 20.0606C17.3535 19.4748 17.3535 18.5251 17.9393 17.9393Z"
        fill={theme.palette.error.main}
      />
    </SvgIcon>
  );
};

export default RejectedCircleIcon;

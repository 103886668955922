import { useReactiveVar } from "@apollo/client";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import {
  defaultNotificationVariable,
  notificationVariable,
} from "../../variables/notification";
import AlertError from "./components/AlertError";
import AlertSuccess from "./components/AlertSuccess";
import CloseToastIcon from "./components/CloseToastIcon";

const DEFAULT_AUTO_HIDE_DURATION = 5000;

const Notification = (): JSX.Element | null => {
  const { isOpen, message, severity } = useReactiveVar(notificationVariable);

  const clearNotification = (): void => {
    notificationVariable(defaultNotificationVariable);
  };

  if (!severity && !isOpen) {
    return null;
  }

  return (
    <>
      {severity === "success" && (
        <Snackbar
          open={isOpen}
          autoHideDuration={DEFAULT_AUTO_HIDE_DURATION}
          onClose={clearNotification}
          TransitionComponent={Slide}
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
        >
          <Box>
            <AlertSuccess
              message={message}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={clearNotification}
                >
                  <CloseToastIcon />
                </IconButton>
              }
            >
              <Typography variant="subtitle1">{message}</Typography>
            </AlertSuccess>
          </Box>
        </Snackbar>
      )}
      {severity === "error" && (
        <Snackbar
          open={isOpen}
          autoHideDuration={DEFAULT_AUTO_HIDE_DURATION}
          onClose={clearNotification}
          TransitionComponent={Slide}
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
        >
          <Box>
            <AlertError
              message={message}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={clearNotification}
                >
                  <CloseToastIcon />
                </IconButton>
              }
            >
              <Typography variant="subtitle1">{message}</Typography>
            </AlertError>
          </Box>
        </Snackbar>
      )}
    </>
  );
};

export default Notification;

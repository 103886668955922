import { useContext, useEffect } from "react";
import { Box, styled } from "@mui/material";

import NumberInput from "../../input/NumberInput";

import { useNumberInputState } from "../../../hooks/useNumberInputState";

import {
  ConfiguratorPermissionContext,
  ProjectContext,
} from "../../../context";

import { ZoneDataInterface } from "../../../context/ProjectContext/ProjectEntitesTypes";

import { formatNumber } from "../../../utils/numberUtils";
import { numberValidationOnlyRangeCheck } from "../../../validation";

const AmountNumbersAfterDecimalPointInInputs = 4;

interface ZoneControlPanelCameraSettingsInterface {
  activeZone: ZoneDataInterface;
}

const ZoneControlPanelCameraSettings = ({
  activeZone,
}: ZoneControlPanelCameraSettingsInterface): JSX.Element => {
  const {
    isLoading,
    threeScene,
    updateCameraProperties,
    distanceMeasurementsMode,
  } = useContext(ProjectContext);

  const { isAllowEditing } = useContext(ConfiguratorPermissionContext);

  const zoneVerticalAngle = useNumberInputState(
    {
      value: `${formatNumber(
        activeZone.tilt,
        AmountNumbersAfterDecimalPointInInputs
      )}`,
      step: 0.5,
      min: -89.5,
      max: 89.5,
      error: false,
      errorMessage: "",
      trackError: true,
    },
    numberValidationOnlyRangeCheck
  );

  const zoneHorizontalAngle = useNumberInputState(
    {
      value: `${formatNumber(
        activeZone.pan,
        AmountNumbersAfterDecimalPointInInputs
      )}`,
      step: 0.5,
      min: -180,
      max: 180,
      error: false,
      errorMessage: "",
      trackError: true,
    },
    numberValidationOnlyRangeCheck
  );

  const zoneRelativeHorizontalAngle = useNumberInputState(
    {
      value: `${formatNumber(
        activeZone.relativePan || 0,
        AmountNumbersAfterDecimalPointInInputs
      )}`,
      step: 0.5,
      min: -180,
      max: 180,
      error: false,
      errorMessage: "",
      trackError: true,
    },
    numberValidationOnlyRangeCheck
  );

  const handleOnZoneVerticalAngleInputChange = (value: string) => {
    const isError = zoneVerticalAngle.onChange(value);

    if (!isError) {
      threeScene.sceneFacility3dObjects.facilityCameras.updateCameraRotation(
        activeZone.cameraId,
        "verticalAngle",
        value
      );

      updateCameraProperties([activeZone.cameraId], activeZone.id);
    }
  };

  const handleOnZoneHorizontalAngleInputChange = (value: string) => {
    const isError = zoneHorizontalAngle.onChange(value);

    if (!isError) {
      threeScene.sceneFacility3dObjects.facilityCameras.updateCameraRotation(
        activeZone.cameraId,
        "horizontalAngle",
        value
      );

      updateCameraProperties([activeZone.cameraId], activeZone.id);
    }
  };

  const handleOnZoneRelativeHorizontalAngleInputChange = (value: string) => {
    const isError = zoneRelativeHorizontalAngle.onChange(value);

    if (!isError) {
      threeScene.sceneFacility3dObjects.facilityCameras.updateCameraRotation(
        activeZone.cameraId,
        "relativeHorizontalAngle",
        value
      );

      updateCameraProperties([activeZone.cameraId], activeZone.id);
    }
  };

  useEffect(() => {
    if (
      Number(zoneVerticalAngle.state.value).toFixed(4) !==
      activeZone.tilt.toFixed(4)
    ) {
      zoneVerticalAngle.resetState({
        value: `${formatNumber(
          activeZone.tilt,
          AmountNumbersAfterDecimalPointInInputs
        )}`,
        step: 0.5,
        min: -89.5,
        max: 89.5,
        error: false,
        errorMessage: "",
        trackError: true,
      });
    }

    if (
      Number(zoneHorizontalAngle.state.value).toFixed(4) !==
      activeZone.pan.toFixed(4)
    ) {
      zoneHorizontalAngle.resetState({
        value: `${formatNumber(
          activeZone.pan,
          AmountNumbersAfterDecimalPointInInputs
        )}`,
        step: 0.5,
        min: -180,
        max: 180,
        error: false,
        errorMessage: "",
        trackError: true,
      });
    }

    if (
      Number(zoneRelativeHorizontalAngle.state.value).toFixed(4) !==
      activeZone.relativePan?.toFixed(4)
    ) {
      zoneRelativeHorizontalAngle.resetState({
        value: `${formatNumber(
          activeZone.relativePan || 0,
          AmountNumbersAfterDecimalPointInInputs
        )}`,
        step: 0.5,
        min: -180,
        max: 180,
        error: false,
        errorMessage: "",
        trackError: true,
      });
    }
  }, [activeZone]);

  const disabledInputs =
    distanceMeasurementsMode || isLoading || !isAllowEditing;

  return (
    <CameraSettingsWrapper>
      <NumberInput
        label="Tilt:"
        labelFontVariant="body12Regular"
        step={zoneVerticalAngle.state.step}
        min={zoneVerticalAngle.state.min}
        max={zoneVerticalAngle.state.max}
        viewType="row"
        labelWidth="60%"
        value={zoneVerticalAngle.state.value}
        placeholder={`${formatNumber(
          activeZone.tilt,
          AmountNumbersAfterDecimalPointInInputs
        )}`}
        isError={zoneVerticalAngle.state.error}
        disabled={disabledInputs}
        handleOnChange={handleOnZoneVerticalAngleInputChange}
      />
      <NumberInput
        label="Pan:"
        labelFontVariant="body12Regular"
        step={zoneHorizontalAngle.state.step}
        min={zoneHorizontalAngle.state.min}
        max={zoneHorizontalAngle.state.max}
        viewType="row"
        labelWidth="60%"
        value={zoneHorizontalAngle.state.value}
        placeholder={`${formatNumber(
          activeZone.pan,
          AmountNumbersAfterDecimalPointInInputs
        )}`}
        isError={zoneHorizontalAngle.state.error}
        disabled={disabledInputs}
        handleOnChange={handleOnZoneHorizontalAngleInputChange}
      />
      <NumberInput
        label="Relative pan:"
        labelFontVariant="body12Regular"
        step={zoneRelativeHorizontalAngle.state.step}
        min={zoneRelativeHorizontalAngle.state.min}
        max={zoneRelativeHorizontalAngle.state.max}
        viewType="row"
        labelWidth="60%"
        value={zoneRelativeHorizontalAngle.state.value}
        placeholder={`${formatNumber(
          activeZone.relativePan || 0,
          AmountNumbersAfterDecimalPointInInputs
        )}`}
        isError={zoneRelativeHorizontalAngle.state.error}
        disabled={disabledInputs}
        handleOnChange={handleOnZoneRelativeHorizontalAngleInputChange}
      />
    </CameraSettingsWrapper>
  );
};

export default ZoneControlPanelCameraSettings;

const CameraSettingsWrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "8px",
}));

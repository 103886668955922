import { useState } from "react";

import { ProjectModalContext } from "./ProjectModalContext";

export const ProjectModalContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const [isDisplayShareModal, setIsDisplayShareModal] =
    useState<boolean>(false);
  const [isDisplayImport3dModelModal, setIsDisplayImport3dModelModal] =
    useState<boolean>(false);

  return (
    <ProjectModalContext.Provider
      value={{
        isDisplayImport3dModelModal,
        setIsDisplayImport3dModelModal,

        isDisplayShareModal,
        setIsDisplayShareModal,
      }}
    >
      {children}
    </ProjectModalContext.Provider>
  );
};

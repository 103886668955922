import { ApolloError, useMutation } from "@apollo/client";

import {
  BatchDeleteVideosInput,
  BatchDeleteVideosMutation,
  BatchDeleteVideosMutationVariables,
} from "../../API";
import { BATCH_DELETE_VIDEOS } from "../../common/operations/mutations";
import {
  errorNotification,
  successNotification,
} from "../../common/variables/notification";

export const useBatchDeletVideos = () => {
  const [deleteSelectedVideosMutation, { data, loading }] = useMutation<
    BatchDeleteVideosMutation,
    BatchDeleteVideosMutationVariables
  >(BATCH_DELETE_VIDEOS);

  const deleteSelectedVideos = async (input: BatchDeleteVideosInput[]) => {
    return await deleteSelectedVideosMutation({
      variables: {
        input,
      },
      update: (cache, { data }) => {
        data?.batchDeleteVideos.forEach(video => {
          cache.evict({ id: cache.identify(video) });
        });

        cache.gc();
      },
      onCompleted: () => {
        successNotification(`Selected uploads are deleted`);
      },
      onError: (error: ApolloError): void => {
        errorNotification(error.message);

        console.error(error.message);
      },
      refetchQueries: ["GetCustomerCounters"],
    });
  };

  return { deleteSelectedVideos, data, loading };
};

import { Box, Tooltip, Zoom } from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import confirmDialogStyleOptions from "../../common/helpers/confirmDialogParams";
import { Client } from "../../API";
import DeleteIconButton from "../../common/components/icons/DeleteIconButton";
import { useDeleteClient } from "../hooks/useDeleteClient";
import { S3Helper } from "../../common/utils/s3helper";
import { useCustomerIdGuard } from "../../common/hooks/useCustomerIdGuard";

type DeleteClientContainerProps = {
  row: Omit<Client, "__typename">;
  disabled?: boolean;
};

const DeleteClientContainer = ({
  row,
  disabled,
}: DeleteClientContainerProps): JSX.Element => {
  const confirm = useConfirm();

  const { deleteClient, loading } = useDeleteClient();
  const customerId = useCustomerIdGuard();

  const handleClick = (): void => {
    confirm({
      title: `Are you sure you want to delete the client ${row.clientName}?`,
      confirmationText: "Delete",
      cancellationText: "Cancel",
      ...confirmDialogStyleOptions,
      confirmationButtonProps: {
        ...confirmDialogStyleOptions.confirmationButtonProps,
        disabled: loading,
      },
    }).then(async (): Promise<void> => {
      await deleteClient({ customerId: row.customerId, clientId: row.id });

      const folderToDelete = customerId + "/" + row.id;

      try {
        await S3Helper.deleteFolderFromS3(folderToDelete);
      } catch (error) {
        console.error(error);
      }
    });
  };

  return (
    <Tooltip
      TransitionComponent={Zoom}
      arrow
      disableInteractive
      placement="left"
      title="Delete"
    >
      <Box
        component="span"
        sx={{
          height: "32px",
        }}
      >
        <DeleteIconButton onClick={handleClick} />
      </Box>
    </Tooltip>
  );
};

export default DeleteClientContainer;

import { FC } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import CloseDialogIcon from "../../../common/components/icons/CloseDialogIcon";

const CONTACT_PERSON = {
  name: "Nathan",
  mobileNumber: "720-229-8292",
  email: "nathan@cleanconnect.ai",
  scheduleLink:
    "https://info.cleanconnect.ai/meetings/nathan-marolf/discovery-call?uuid=fbb05220-913f-4ab5-b117-cda36d58218a",
};

interface IContactsDialogProps {
  open: boolean;
  handleClose: () => void;
  preventUpload?: (state: boolean) => void;

  name?: string;
  runOutText?: string;
  mobileNumber?: string;
  email?: string;
}

const ContactsDialog: FC<IContactsDialogProps> = ({
  open,
  handleClose,
  preventUpload,
  runOutText = "",
  name = CONTACT_PERSON.name,
  email = CONTACT_PERSON.email,
  mobileNumber = CONTACT_PERSON.mobileNumber,
}) => {
  const handleEmailClick = () => {
    if (preventUpload) preventUpload(true);
  };

  const handleCloseClick = () => {
    if (preventUpload) preventUpload(false);

    handleClose();
  };

  return (
    <Dialog
      maxWidth="sm"
      open={open}
      onClose={handleCloseClick}
      sx={{
        "& .MuiPaper-root": {
          padding: "2em 3em",
          borderRadius: "12px",
        },
      }}
    >
      <DialogTitle textAlign="center">
        <Typography variant="h4" component="h4" fontSize="26px">
          {runOutText} Contact sales team to get unlimited access
        </Typography>
      </DialogTitle>
      <CloseDialogIcon onClose={handleCloseClick} />
      <DialogContent>
        <Box
          sx={{
            padding: "1.5em",
            borderRadius: "8px",
            border: theme => `1px solid ${theme.palette.otherDivider.main}`,
            backgroundColor: theme => theme.palette.otherBackground.main,
          }}
        >
          <Table
            sx={{
              ".MuiTableCell-root": {
                border: "none",
                pt: "0.5em",
                pb: "0.5em",
              },
              ".MuiTableCell-head": {
                paddingLeft: 0,
              },
            }}
          >
            <TableBody>
              <TableRow>
                <TableCell variant="head">
                  <Typography
                    variant="subtitle2"
                    sx={{
                      color: "otherTextTertiary.main",
                    }}
                  >
                    NAME
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2Regular">{name}</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head">
                  <Typography
                    variant="subtitle2"
                    sx={{
                      color: "otherTextTertiary.main",
                    }}
                  >
                    MOBILE NUMBER
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2Regular">{mobileNumber}</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head">
                  <Typography
                    variant="subtitle2"
                    sx={{
                      color: "otherTextTertiary.main",
                    }}
                  >
                    EMAIL
                  </Typography>
                </TableCell>
                <TableCell>
                  <ListItem
                    disablePadding
                    key="Email"
                    component="a"
                    href={`mailto:${email}`}
                    onClick={handleEmailClick}
                  >
                    <Typography
                      variant="subtitle1"
                      sx={{
                        color: "text.primary",
                        textDecoration: "underline",
                      }}
                    >
                      {email}
                    </Typography>
                  </ListItem>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      </DialogContent>
      <Divider>OR</Divider>
      <DialogActions
        sx={{
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "1em",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Button
            sx={{
              width: "fit-content",
            }}
            href={CONTACT_PERSON.scheduleLink}
            target="_blank"
            rel="noreferrer"
            variant="contained"
            color="primary"
            size="large"
          >
            Schedule a discovery call
          </Button>

          <Typography variant="body2Regular">
            Schedule a discovery call and we will reach you out as soon as
            possible
          </Typography>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default ContactsDialog;

import { useLazyQuery } from "@apollo/client";
import { useEffect } from "react";

import { GET_ANNOTATIONS } from "../../../common/operations/queries";
import { errorNotification } from "../../../common/variables/notification";

import client from "../../../configs/apolloClient";
import {
  GetAnnotationsInput,
  GetAnnotationsQuery,
  GetAnnotationsQueryVariables,
} from "../../../API";
import { useCustomerIdGuard } from "../../../common/hooks/useCustomerIdGuard";
import { DEFAULT_LIMIT_COUNT } from "../../../common/variables/common";

export type useGetAnnotationsType = {
  polygon?: JSON;
};

export const useGetAnnotations = ({
  zoneId,
  serviceId,
  deviceId,
}: GetAnnotationsInput) => {
  const selectedCustomerId = useCustomerIdGuard();

  useEffect(() => {
    if (zoneId && serviceId) {
      fetchAnnotations({ zoneId, serviceId });

      return;
    }

    if (deviceId) {
      fetchAnnotations({ deviceId });
    }

    if (serviceId) {
      fetchAnnotations({ serviceId });
    }
  }, [zoneId, serviceId, deviceId, selectedCustomerId]);

  const handleRequestError = () => {
    client.cache.reset();

    errorNotification();
  };

  const [getAnnotations, { data, loading }] = useLazyQuery<
    GetAnnotationsQuery,
    GetAnnotationsQueryVariables
  >(GET_ANNOTATIONS, {
    fetchPolicy: "network-only",
  });

  const customerId = useCustomerIdGuard();

  const fetchAnnotations = ({
    serviceId,
    zoneId,
    deviceId,
  }: GetAnnotationsInput) => {
    getAnnotations({
      variables: {
        input: {
          customerId,
          serviceId,
          deviceId,
          zoneId,
          limit: DEFAULT_LIMIT_COUNT,
        },
      },
    })
      .then(response => {
        if (response.data?.getAnnotations.nextToken) {
          const getNextPartsOfData = {
            serviceId,
            zoneId,
            deviceId,
            nextToken: response.data?.getAnnotations.nextToken,
            limit: DEFAULT_LIMIT_COUNT,
          };

          fetchAnnotations(getNextPartsOfData);
        }

        if (response.error) {
          handleRequestError();
        }
      })
      .catch(() => {
        handleRequestError();
      });
  };

  return { data, loading, fetchAnnotations };
};

import { createContext } from "react";

export interface ProjectModalContextInterface {
  isDisplayShareModal: boolean;
  setIsDisplayShareModal: (value: React.SetStateAction<boolean>) => void;

  isDisplayImport3dModelModal: boolean;
  setIsDisplayImport3dModelModal: (
    value: React.SetStateAction<boolean>
  ) => void;
}

export const ProjectModalContext = createContext<ProjectModalContextInterface>(
  {} as ProjectModalContextInterface
);

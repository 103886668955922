import { Box, FormLabel, useTheme } from "@mui/material";

const OutlinedBox = ({
  paddingSpacing = "0px",
  gapSpacing = "0px",
  borderColor,
  borderRadius = "4px",
  backgroundColor,
  label,
  children,
}) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        marginTop: "10px",
      }}
    >
      {label && (
        <FormLabel
          sx={{
            marginTop: "-0.71em",
            paddingLeft: "0.44em",
            paddingRight: "0.44em",
            zIndex: 2,
            backgroundColor: backgroundColor || theme.palette.base.white,
            position: "absolute",
            width: "auto",
          }}
        >
          {label}
        </FormLabel>
      )}
      <Box
        sx={{
          width: "100%",
          height: "100%",
          position: "relative",
          border: `1px solid ${borderColor || theme.palette["grey-light"]}`,
          borderRadius: borderRadius,
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            padding: paddingSpacing,
            gap: gapSpacing,
            display: "flex",
            flexWrap: "wrap",
            overflow: "auto",
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default OutlinedBox;

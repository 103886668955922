import { useState } from "react";
import axios from "axios";

export const useGetOpenWeatherData = () => {
  const [loading, setLoading] = useState(false);

  axios.interceptors.response.use(
    response => {
      return response;
    },
    async error => {
      if (error.response.status === 401) {
        await axios.get(
          `http://api.openweathermap.org/data/2.5/forecast/daily?appid=${process.env.REACT_APP_OPEN_WEATHER_API_KEY}`
        );

        return axios(error.config);
      }

      console.error("Error get data from Open Weather", error);

      return error;
    }
  );

  const getData = (lat: number, lon: number) => {
    setLoading(true);

    const data = axios
      .get(
        `https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lon}&appid=${process.env.REACT_APP_OPEN_WEATHER_API_KEY}&units=metric`
      )
      .then(response => {
        setLoading(false);

        return response.data;
      });

    return data;
  };

  return { getData, loading };
};

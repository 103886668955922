import { Box, styled } from "@mui/material";

import DashboardPageHeader from "./components/DashboardPageHeader";
import DashboardGrid from "./components/DashboardGrid";
import CreateDTProjectForm from "./components/createProject/CreateDTProjectForm";

import CreateDTProjectContextProvider from "./context/CreateDTProjectContext/CreateDTProjectContextProvider";

const WebglConfiguratorDashboardPage = (): JSX.Element => {
  return (
    <CreateDTProjectContextProvider>
      <PageWrapper>
        <DashboardPageHeader />
        <DashboardGrid />
        <CreateDTProjectForm />
      </PageWrapper>
    </CreateDTProjectContextProvider>
  );
};

export default WebglConfiguratorDashboardPage;

const PageWrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  height: "100%",
  paddingBottom: "1.25em",
}));

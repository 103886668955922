import {
  BackSide,
  DoubleSide,
  SphereGeometry,
  MeshBasicMaterial,
  Mesh,
  Color,
} from "three";

import { loadFBXAsset, loadTexture } from "./SceneUtils/AssetLoaders";
import { degToRad } from "three/src/math/MathUtils";

import { GROUND_RADIUS_IN_M } from "./Constants";

import clouds from "../assets/texture/sky/T_skydome_a01_clouds-1_BC.png";

class SceneSkybox {
  constructor(scene, sceneLocationCenter) {
    this.scene = scene;

    this.sceneLocationCenter = sceneLocationCenter;

    this.windInf = 3;

    this.windGust = 0.1;
  }

  setDefault = () => {
    if (!this.defaultTexture) {
      return;
    }

    this.skyboxObject.material.map = this.defaultTexture;

    this.cloudsObject.material.color = new Color("white");

    this.cloudsObject.material.opacity = 1;

    this.windInf = 3;

    this.windGust = 0.1;
  };

  async addSkybox(texture) {
    if (this.skyboxObject) {
      this.scene.remove(this.skyboxObject);

      this.skyboxObject = null;
    }

    const skyGeometry = new SphereGeometry(GROUND_RADIUS_IN_M, 32, 16);

    const skyMaterial = new MeshBasicMaterial({
      map: texture,
      side: BackSide,
    });

    this.skyboxObject = new Mesh(skyGeometry, skyMaterial);

    const cloudsGeometry = new SphereGeometry(GROUND_RADIUS_IN_M - 10);

    const cloudsTexture = await loadTexture(clouds);

    const cloudsMaterial = new MeshBasicMaterial({
      map: cloudsTexture,
      side: DoubleSide,
      transparent: true,
    });

    this.cloudsObject = new Mesh(cloudsGeometry, cloudsMaterial);

    this.cloudsObject.scale.set(-1, 1, 1);

    this.skyboxObject.position.copy(this.sceneLocationCenter);

    this.cloudsObject.position.copy(this.sceneLocationCenter);

    this.scene.add(this.cloudsObject);

    this.scene.add(this.skyboxObject);
  }

  changeSky = (texture, cloudsIntensity, cloudsColor, wind) => {
    this.skyboxObject.material.map = texture;

    this.cloudsObject.material.color = new Color(cloudsColor);

    this.cloudsObject.material.opacity = cloudsIntensity / 100;

    this.windInf = Math.sin(degToRad(wind.deg)) * wind.speed;

    this.windGust = wind.gust;
  };

  update(dt) {
    if (this.skyboxObject && this.cloudsObject) {
      this.skyboxObject.rotateY(degToRad(-0.005));

      this.cloudsObject.rotateY(
        this.windInf * dt * dt * 0.5 * Math.random(0.1, this.windGust)
      );

      this.skyboxObject.position.copy(this.sceneLocationCenter);
    }
  }
}

export default SceneSkybox;

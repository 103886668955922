import { Box, Tooltip, Zoom } from "@mui/material";
import { useConfirm } from "material-ui-confirm";

import { Group } from "../../API";
import DeleteIconButton from "../../common/components/icons/DeleteIconButton";
import confirmDialogStyleOptions from "../../common/helpers/confirmDialogParams";
import { useDeleteClientGroup } from "../hooks/useDeleteClientGroup";
import { VideosTableSelectionDispatch } from "../store/useVideosTableSelectionReducer";

type DeleteClientGroupContainerProps = {
  group: Omit<Group, "__typename">;
  dispatch: VideosTableSelectionDispatch;
  deleteGroup: (groupId: string, clientId: string) => void;
};

const DeleteClientGroupContainer = ({
  group,
  deleteGroup,
}: DeleteClientGroupContainerProps): JSX.Element => {
  const confirm = useConfirm();

  const { deleteClientGroup, loading } = useDeleteClientGroup();

  const handleClick = (): void => {
    confirm({
      title: `Are you sure you want to delete the group ${group.groupName}?`,
      confirmationText: "Delete",
      cancellationText: "Cancel",
      ...confirmDialogStyleOptions,
      confirmationButtonProps: {
        ...confirmDialogStyleOptions.confirmationButtonProps,
        disabled: loading,
      },
    }).then(() => {
      deleteClientGroup({ groupId: group.id, clientId: group.clientId })
        .then(res => {
          if (!res.errors) {
            deleteGroup(group.clientId, group.id);
          } else {
            new Error(`${res.errors}`);
          }
        })
        .catch(err => {
          console.error("Error deleting group", err);
        });
    });
  };

  return (
    <Tooltip
      TransitionComponent={Zoom}
      arrow
      disableInteractive
      placement="left"
      title="Delete"
    >
      <Box
        component="span"
        sx={{
          height: "32px",
        }}
      >
        <DeleteIconButton onClick={handleClick} />
      </Box>
    </Tooltip>
  );
};

export default DeleteClientGroupContainer;

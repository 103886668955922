import React from "react";
import { Box, Button, TextField, Typography, useTheme } from "@mui/material";
import { Controller, Control } from "react-hook-form";
import { AnnotationNameFormState } from "./components/LeakFinderAnnotationContainer";
import WarningBox from "../../../common/components/box/WarningBox";

interface AnnotationFormProps {
  control: Control<AnnotationNameFormState>;
  onCancelHandler: () => void;
  addAnnotationSubmit: () => void;
  editing?: boolean;
}

const AnnotationForm: React.FC<AnnotationFormProps> = ({
  control,
  editing,
  onCancelHandler,
  addAnnotationSubmit,
}) => {
  const theme = useTheme();

  const annotationLabel = editing ? "Edit focus area" : "Add focus area";
  const saveButtonLabel = editing ? "Save changes" : "Add focus area";
  const warningMessageLabel = editing
    ? "Move the orange highlighted rectangle on the video to edit area"
    : "Move the orange highlighted rectangle that appeared on the video to indicate the focus area and save it";

  return (
    <Box
      sx={{
        width: "100%",
        borderRadius: "8px",
        padding: "1em",
        backgroundColor: theme.palette.otherBackground.main,
      }}
    >
      <Typography variant="body2Bold">{annotationLabel}</Typography>

      <Box sx={{ margin: "12px 0" }}>
        <WarningBox>{warningMessageLabel}</WarningBox>
      </Box>

      <Controller
        name="name"
        control={control}
        rules={{ required: "This field is required" }}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            value={field.value ?? ""}
            margin="dense"
            autoFocus
            sx={{
              height: "68px",
              "& .MuiOutlinedInput-root": {
                borderRadius: "8px",
              },
            }}
            label="Focus area name"
            fullWidth
            error={!!error}
            helperText={error ? error.message : ""}
          />
        )}
      />

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "0.75em",
        }}
      >
        <Button
          size="small"
          variant="outlined"
          onClick={onCancelHandler}
          color="inherit"
        >
          Cancel
        </Button>

        <Button onClick={addAnnotationSubmit} size="small" variant="contained">
          {saveButtonLabel}
        </Button>
      </Box>
    </Box>
  );
};

export default AnnotationForm;

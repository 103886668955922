import { createContext } from "react";

import { DTObject } from "../../../../../API";

export enum DTObjectsLibraryType {
  Customer = "Customer",
  General = "General",
}

export enum DTObjectsLibraryGroupTypeEnum {
  Type = "Group by type",
  Manufacturers = "Group by manufacturers",
  Name = "Group by name",
  Library = "Group by library",
}

export interface DTObjectsFilterQueryInterface {
  type: string[];
  manufacturer: string[];
  library: string[];
}

export interface DTObjectsSearchQueryInterface {
  value: string;
}

export interface DTObjectsGroupQueryInterface {
  value: DTObjectsLibraryGroupTypeEnum;
}

export interface DTObjectsQueriesInterface {
  filterQuery: DTObjectsFilterQueryInterface;
  searchQuery: DTObjectsSearchQueryInterface;
  groupQuery: DTObjectsGroupQueryInterface;
}

export interface SearchFilterGroupObjectsContextInterface {
  queries: DTObjectsQueriesInterface;
  handleSetFilterQuery: (filterQuery: DTObjectsFilterQueryInterface) => void;
  handleSetGroupQuery: (groupQuery: DTObjectsGroupQueryInterface) => void;
  handleSetSearchQuery: (searchQuery: DTObjectsSearchQueryInterface) => void;
  applyAllQueries: (data: DTObject[]) => DTObject[];
}

export const SearchFilterGroupObjectsContext =
  createContext<SearchFilterGroupObjectsContextInterface>(
    {} as SearchFilterGroupObjectsContextInterface
  );

import { useState } from "react";
import { Box, ListItem, Typography, styled, Stack } from "@mui/material";

import {
  CollapseArrowSVG,
  EyeOffIcon,
  EyeOnIcon,
} from "../../../assets/icons/svgAssets";

interface FieldOfCamerasViewControlPanelInterface {
  data: any;
  onItemClick: (id: string[]) => void;
}

const ZoneListItem = ({
  data,
  onItemClick,
}: FieldOfCamerasViewControlPanelInterface): JSX.Element => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <>
      <Wrapper>
        <DropDownButton
          state={isOpen ? 1 : 0}
          onClick={() => setIsOpen(!isOpen)}
        >
          <CollapseArrowSVG />
        </DropDownButton>
        <StyledListText
          onClick={() => onItemClick(data.zones.map((el: any) => el.id))}
        >
          {data.zones.find((el: any) => el.isVisible) ? (
            <EyeOnIcon size={20} color="#202832" />
          ) : (
            <EyeOffIcon size={20} color="#202832" />
          )}
          <CameraName variant="body14Regular">{data.cameraName}</CameraName>
        </StyledListText>
      </Wrapper>
      {isOpen && (
        <ZonesWrapper>
          {data.zones.map((zone: any) => (
            <Wrapper key={zone.id}>
              <StyledListText onClick={() => onItemClick([zone.id])}>
                {zone.isVisible ? (
                  <EyeOnIcon size={20} color="#202832" />
                ) : (
                  <EyeOffIcon size={20} color="#202832" />
                )}
                <CameraName variant="body14Regular">{zone.name}</CameraName>
              </StyledListText>
            </Wrapper>
          ))}
        </ZonesWrapper>
      )}
    </>
  );
};

export default ZoneListItem;

const Wrapper = styled(ListItem)(() => ({
  display: "flex",
  padding: "8px",
  alignItems: "center",
  gap: "8px",
  justifyContent: "space-between",
  width: "100%",
}));

const ZonesWrapper = styled(Box)(() => ({
  display: "flex",
  paddingLeft: "32px",
  gap: "4px",
  flexDirection: "column",
  justifyContent: "space-between",
  width: "100%",
}));

const StyledListText = styled(Stack)(() => ({
  flexDirection: "row-reverse",
  width: "100%",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "8px",
  "&:hover": {
    textDecorationLine: "underline",
    cursor: "pointer",
  },
}));

const CameraName = styled(Typography)(({ theme }) => ({
  color: theme.palette["navy-blue"],

  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
  width: "calc(100% - 20px)",
}));

const DropDownButton = styled(Box)<{ state: number }>(({ state, theme }) => ({
  height: "100%",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  transition: "all 0.2s",
  pointerEvents: "auto",

  transform: state ? "rotate(0deg)" : "rotate(-90deg)",

  "& svg": {
    fontSize: "12px",
    color: theme.palette["grey-dark"],
    "&:hover": {
      color: theme.palette["base-label"],
    },
  },
}));

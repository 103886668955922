import { loadFBXAsset } from "../threeWebGl/SceneUtils/AssetLoaders";
import { readFileAsArrayBuffer } from "../utils";

interface ValidationResponseInterface {
  error: boolean;
  errorMessage: string;
}

const isFBX = [75, 97, 121, 100, 97, 114, 97, 32];

const checkIs3dAssetsFileTypeValid = (buffers: Uint8Array): boolean => {
  return [isFBX].some(headers => {
    const checkHead = headers.every(
      (header, index) => header === buffers[index]
    );

    return checkHead;
  });
};

const checkIs3dAssetsFileStructureValid = async (file: File) => {
  try {
    await loadFBXAsset(URL.createObjectURL(file));

    return true;
  } catch (error) {
    return false;
  }
};

export const assets3dValidation = async (
  file: File
): Promise<ValidationResponseInterface> => {
  const buffer = await readFileAsArrayBuffer(file, true, 0, 8);
  const fileHeaders = new Uint8Array(buffer as ArrayBuffer);

  const is3dAssetsFileTypeValid = checkIs3dAssetsFileTypeValid(fileHeaders);

  if (!is3dAssetsFileTypeValid) {
    return {
      error: true,
      errorMessage: "Invalid 3d assets file type",
    };
  }

  const is3dAssetsFileStructureValid = await checkIs3dAssetsFileStructureValid(
    file
  );

  if (!is3dAssetsFileStructureValid) {
    return {
      error: true,
      errorMessage: "Invalid 3d assets file structure",
    };
  }

  return { error: false, errorMessage: "" };
};

import { SvgIcon, SvgIconProps } from "@mui/material";
import { ReactElement } from "react";

export const SmallCloseIcon = (props: SvgIconProps): ReactElement => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4126 3.58785C11.6404 3.81565 11.6404 4.185 11.4126 4.4128L4.41256 11.4128C4.18475 11.6406 3.81541 11.6406 3.5876 11.4128C3.3598 11.185 3.3598 10.8157 3.5876 10.5878L10.5876 3.58785C10.8154 3.36004 11.1848 3.36004 11.4126 3.58785Z"
        fill={props.color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5876 3.58785C3.81541 3.36004 4.18475 3.36004 4.41256 3.58785L11.4126 10.5878C11.6404 10.8157 11.6404 11.185 11.4126 11.4128C11.1848 11.6406 10.8154 11.6406 10.5876 11.4128L3.5876 4.4128C3.3598 4.185 3.3598 3.81565 3.5876 3.58785Z"
        fill={props.color}
      />
    </SvgIcon>
  );
};

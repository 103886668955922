import type { ThemeOptions } from "@mui/material";

import { MuiAppBarDarkStyleOverrides } from "./mui/AppBar/dark";
import { MuiAutocompleteDarkStyleOverrides } from "./mui/Autocomplete/dark";
import { MuiButtonDarkStyleOverrides } from "./mui/Button/dark";
import { MuiDividerDarkStyleOverrides } from "./mui/Divider/dark";
import { MuiFormHelperTextDarkStyleOverrides } from "./mui/FormHelperText/dark";
import { MuiInputLabelDarkStyleOverrides } from "./mui/InputLabel/dark";
import { MuiSelectDarkStyleOverrides } from "./mui/Select/dark";
import { MuiTextFieldDarkStyleOverrides } from "./mui/TextField/dark";
import { MuiToolBarDarkStyleOverrides } from "./mui/Toolbar/dark";

/**
 * NOTE: the list of links which can help with customization:
 * 1. https://mui.com/material-ui/customization/how-to-customize/
 * 2. https://mui.com/material-ui/customization/theme-components/
 * 3. https://mui.com/material-ui/customization/dark-mode/
 */
export const darkThemeToken: ThemeOptions = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      xxl: 1980,
    },
  },
  typography: {
    fontFamily: "Inter, sans-serif",
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontWeight: 700,
      fontSize: "2.875rem",
      lineHeight: "135%",
    },
    h2: {
      fontWeight: 700,
      fontSize: "2.25rem",
      lineHeight: "135%",
    },
    h3: {
      fontWeight: 700,
      fontSize: "1.875rem",
      lineHeight: "135%",
    },
    h4: {
      fontWeight: 700,
      fontSize: "1.625rem",
      lineHeight: "135%",
    },
    h5: {
      fontWeight: 700,
      fontSize: "1.25rem",
      lineHeight: "135%",
    },
    h6: {
      fontWeight: 700,
      fontSize: "1rem",
      lineHeight: "135%",
    },
    buttonMedium: {
      fontWeight: 600,
      fontSize: "0.875rem",
      lineHeight: "135%",
    },
    subtitle1: {
      fontWeight: 600,
      fontSize: "0.875rem",
      lineHeight: "135%",
    },
    subtitle2: {
      fontWeight: 500,
      fontSize: "0.75rem",
      lineHeight: "135%",
    },
    body1Bold: {
      fontWeight: 700,
      fontSize: "1rem",
      lineHeight: "135%",
    },
    body1Regular: {
      fontWeight: 400,
      fontSize: "1rem",
      lineHeight: "135%",
    },
    body2Bold: {
      fontWeight: 700,
      fontSize: "0.875rem",
      lineHeight: "135%",
    },
    body2Regular: {
      fontWeight: 400,
      fontSize: "0.875rem",
      lineHeight: "135%",
    },
    inputText: {
      fontWeight: 400,
      fontSize: "1rem",
      lineHeight: "135%",
    },
    inputLabel: {
      fontWeight: 400,
      fontSize: "0.75rem",
      lineHeight: "135%",
      color: "#8B91A0",
    },
    helperText: {
      fontWeight: 400,
      fontSize: "0.75rem",
      lineHeight: "135%",
    },
    caption: {
      fontWeight: 400,
      fontSize: "0.75rem",
      lineHeight: "135%",
    },
    tooltip: {
      fontWeight: 400,
      fontSize: "0.75rem",
      lineHeight: "135%",
    },
    avatarLetter: {
      fontWeight: 700,
      fontSize: "1rem",
      lineHeight: "135%",
    },
    overline: {
      fontWeight: 400,
      fontSize: "0.75rem",
      lineHeight: "135%",
    },
    tag: {
      fontWeight: 500,
      fontSize: "0.75rem",
      lineHeight: "135%",
    },
    tagXs: {
      fontWeight: 500,
      fontSize: "0.5rem",
      lineHeight: "135%",
    },
  },
  palette: {
    mode: "dark",
    text: {
      primary: "#FFFFFF",
      secondary: "#97A3B1",
      disabled: "rgba(255, 255, 255, 0.5)",
    },
    primary: {
      main: "#4CAF50",
      dark: "#087F23",
      light: "#E3F2FD",
      contrastText: "rgba(0, 0, 0, 0.87)",
      shades: {
        "8p": "#132624",
        "12p": "rgba(144, 202, 249, 0.12)",
        "30p": "#1D4526",
        "50p": "rgba(144, 202, 249, 0.5)",
      },
    },
    secondary: {
      main: "#CE93D8",
      dark: "#AB47BC",
      light: "#F3E5F5",
      contrastText: "rgba(0, 0, 0, 0.87)",
      shades: {
        "8p": "rgba(206, 147, 216, 0.08)",
        "12p": "rgba(206, 147, 216, 0.12)",
        "30p": "rgba(206, 147, 216, 0.3)",
        "50p": "rgba(206, 147, 216, 0.5)",
      },
    },
    error: {
      main: "#D32F2F",
      dark: "#7D0606",
      light: "#EA7272",
      contrastText: "#FFFFFF",
      shades: {
        "4p": "#4A1C1C",
        "8p": "rgba(211, 47, 47, 0.08)",
        "12p": "rgba(211, 47, 47, 0.12)",
        "30p": "#722121",
        "50p": "rgba(211, 47, 47, 0.5)",
      },
    },
    warning: {
      main: "#EF7300",
      dark: "#EF7300",
      light: "#FFB74D",
      contrastText: "rgba(0, 0, 0, 0.87)",
      shades: {
        "4p": "#251A0F",
        "8p": "#251A0F",
        "12p": "#5D3D21",
        "30p": "rgba(239, 115, 0, 0.3)",
        "50p": "rgba(239, 115, 0, 0.5)",
      },
    },
    info: {
      main: "#29B6F6",
      dark: "#0288D1",
      light: "#4FC3F7",
      contrastText: "rgba(0, 0, 0, 0.87)",
      shades: {
        "8p": "rgba(2, 136, 209, 0.08)",
        "12p": "rgba(2, 136, 209, 0.12)",
        "30p": "rgba(2, 136, 209, 0.3)",
        "50p": "rgba(2, 136, 209, 0.5)",
      },
    },
    success: {
      main: "#66BB6A",
      dark: "#388E3C",
      light: "#81C784",
      contrastText: "rgba(0, 0, 0, 0.87)",
      shades: {
        "8p": "rgba(102, 187, 106, 0.08)",
        "12p": "rgba(102, 187, 106, 0.12)",
        "30p": "rgba(102, 187, 106, 0.3)",
        "50p": "rgba(102, 187, 106, 0.5)",
      },
    },
    action: {
      active: "rgba(255, 255, 255, 0.56)",
      hover: "#8B91A0",
      selected: "rgba(255, 255, 255, 0.16)",
      disabled: "#29323F",
      disabledBackground: "#192530",
      focus: "rgba(255, 255, 255, 0.12)",
    },
    divider: "#1A2A38",
    otherBackground: {
      main: "#121822",
    },
    otherBackgroundLight: {
      main: "#13212E",
    },
    otherDisabledBackground: {
      main: "#192530",
    },
    otherTextTertiary: {
      main: "#8B91A0",
    },
    otherDivider: {
      main: "#1A2A38",
    },
    otherOutlineBorder: {
      main: "#29323F",
    },
    otherBackdropOverlay: {
      main: "#29323F",
    },
    otherStandardInputLine: {
      main: "#3D4653",
    },
    otherSnackbarBackground: {
      main: "#323232",
    },
    otherElevation: {
      main: "#111E2A",
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: MuiAppBarDarkStyleOverrides,
    },
    MuiToolbar: {
      styleOverrides: MuiToolBarDarkStyleOverrides,
    },
    MuiDivider: {
      styleOverrides: MuiDividerDarkStyleOverrides,
    },
    MuiSelect: {
      styleOverrides: MuiSelectDarkStyleOverrides,
    },
    MuiButton: {
      styleOverrides: MuiButtonDarkStyleOverrides,
    },
    MuiAutocomplete: {
      styleOverrides: MuiAutocompleteDarkStyleOverrides,
    },
    MuiTextField: {
      styleOverrides: MuiTextFieldDarkStyleOverrides,
    },
    MuiInputLabel: {
      styleOverrides: MuiInputLabelDarkStyleOverrides,
    },
    MuiFormHelperText: {
      styleOverrides: MuiFormHelperTextDarkStyleOverrides,
    },
  },
};

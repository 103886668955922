import { Box, Tooltip, Typography, Zoom } from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import { useDeleteVideo } from "../hooks/useDeleteVideo";
import confirmDialogStyleOptions from "../../common/helpers/confirmDialogParams";
import useDeleteS3File from "../hooks/useDeleteS3File";
import { LeakFinderVideo } from "../../API";
import DeleteIconButton from "../../common/components/icons/DeleteIconButton";
import { useDeleteClientGroup } from "../hooks/useDeleteClientGroup";

type DeleteVideoContainerProps = {
  row: Omit<LeakFinderVideo, "__typename">;
  deleteGroup: (clientId: string, groupId: string) => void;
  countOfVideos: number;
};

const DeleteVideoContainer = ({
  row,
  countOfVideos,
  deleteGroup,
}: DeleteVideoContainerProps): JSX.Element => {
  const confirm = useConfirm();

  const { deleteVideo, loading: deleteVideoLoading } = useDeleteVideo();
  const { deleteFile, loading: deleteFileLoading } = useDeleteS3File();

  const { deleteClientGroup } = useDeleteClientGroup();

  const loading = deleteVideoLoading || deleteFileLoading;

  const handleClick = (): void => {
    confirm({
      title: `Are you sure you want to delete the video ${row.videoName}?`,
      confirmationText: "Delete",
      cancellationText: "Cancel",
      ...confirmDialogStyleOptions,
      confirmationButtonProps: {
        ...confirmDialogStyleOptions.confirmationButtonProps,
        disabled: loading,
      },
    }).then(async (): Promise<void> => {
      if (countOfVideos === 1) {
        deleteClientGroup({ groupId: row.groupId, clientId: row.clientId })
          .then(res => {
            if (!res.errors) {
              deleteGroup(row.clientId, row.groupId);
            } else {
              new Error(`${res.errors}`);
            }
          })
          .catch(err => {
            console.error("Error deleting group", err);
          });
      } else {
        await deleteVideo({ groupId: row.groupId, videoId: row.id });
      }

      await deleteFile(row.sourceS3Key);

      if (row.modelOutputS3Key) {
        await deleteFile(row.modelOutputS3Key);
      }
    });
  };

  const disabled = !row.isVideoProcessed;

  return (
    <Tooltip
      TransitionComponent={Zoom}
      arrow
      disableInteractive
      placement="left"
      title={
        disabled ? (
          <Box textAlign="center">
            <Typography variant="tooltip">
              You cannot delete the video while it is processing
            </Typography>
          </Box>
        ) : null
      }
    >
      <Box
        component="span"
        sx={{
          height: "32px",
        }}
      >
        <DeleteIconButton disabled={disabled} onClick={handleClick} />
      </Box>
    </Tooltip>
  );
};

export default DeleteVideoContainer;

import { ReactNode } from "react";

import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";

export interface DialogTitleProps {
  children?: ReactNode;
  onClose: () => void;
}

const StyledDialogTitle = (props: DialogTitleProps): JSX.Element => {
  const { children, onClose, ...other } = props;

  const theme = useTheme();

  return (
    <DialogTitle
      component="div"
      sx={{
        p: 0,
        marginBottom: "2em",
        textAlign: "center",
      }}
      {...other}
    >
      <Typography variant="h4">{children}</Typography>
      <IconButton
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 14,
          top: 18,
          color: theme.palette.text.primary,
        }}
      >
        <HighlightOffRoundedIcon />
      </IconButton>
    </DialogTitle>
  );
};

export default StyledDialogTitle;

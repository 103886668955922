import { useEffect } from "react";
import uuid from "react-uuid";

import {
  Box,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  TextareaAutosize,
  Typography,
  styled,
} from "@mui/material";
import { useConfirm } from "material-ui-confirm";

import {
  Control,
  Controller,
  FieldArrayWithId,
  UseFormClearErrors,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";

import { VideoMethod } from "../../../../API";
import confirmDialogStyleOptions from "../../../../common/helpers/confirmDialogParams";
import useUserSettingsFromCache from "../../../../common/hooks/useUserSettingsFromCache";
import RadioButtonsGroup from "../../../components/ControlledRadioButtonsGroup";
import { UnitsEnum, VideoAnalysisFormState } from "../../UploadVideoGroupPage";
import LeakFinderAnnotationContainer from "./LeakFinderAnnotationContainer";
import ImageCard from "../../../../common/components/card/ImageCard";

type VideoDetailsFormProps = {
  watch: UseFormWatch<VideoAnalysisFormState>;
  control: Control<VideoAnalysisFormState>;
  setFormValue: UseFormSetValue<VideoAnalysisFormState>;
  saveChangesHandler: (newValue: string, annotationIndex: number) => void;
  clearErrors: UseFormClearErrors<VideoAnalysisFormState>;
  focusAreaMarkerChange: (state: boolean | number) => void;
  focusAreaMarker: number | boolean;
  onAddAnnotation: (name: string) => void;
  onDeleteAnnotation: (index: number) => void;
  focusAreaFields: FieldArrayWithId<
    VideoAnalysisFormState,
    `videos.${number}.annotations.focusAreas`,
    "id"
  >[];
  index: number;
};

const StyledTextArea = styled(TextareaAutosize)(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  width: "100%",
  padding: "18.5px 14px",
  borderRadius: 8,
  resize: "none",
  border: `1px solid ${theme.palette.otherStandardInputLine.main}`,
  "&:focus": {
    borderColor: theme.palette.primary.main,
    outline: "none",
  },
}));

const VideoDetailsForm: React.FC<VideoDetailsFormProps> = ({
  setFormValue,
  clearErrors,
  saveChangesHandler,
  focusAreaMarkerChange,
  onDeleteAnnotation,
  focusAreaFields,
  onAddAnnotation,
  focusAreaMarker,
  control,
  watch,
  index,
}) => {
  const confirm = useConfirm();

  const { userSettings } = useUserSettingsFromCache();

  const radioGroupLabels = ["Drone video", "Handheld video"];
  const radioGroupValues = [VideoMethod.DRONE, VideoMethod.HANDHELD];

  const unit = watch("unit");

  const key = uuid();

  const showFormBlock = !!watch().videos[index].videoMethod;
  const isVideoTypeDrone =
    watch().videos[index].videoMethod === VideoMethod.DRONE;
  const videoMethodKey = `videos.${index}.videoMethod`;

  const onWindspeedChangeHandler = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    onChange: (param: string) => void
  ) => {
    const input = e.target.value;

    const numericInput = parseFloat(input);

    if (!isNaN(numericInput) && unit === UnitsEnum.KPH) {
      onChange(String(numericInput / 1.60934));
    } else {
      onChange(String(input));
    }
  };

  const onConfirmChangeVideoType = (
    choice: VideoMethod,
    onChange: (value: VideoMethod) => void
  ) => {
    const currentFormValues = watch("videos")[index];

    const isFormNotEmpty = Boolean(
      currentFormValues.angle ||
        currentFormValues.annotations?.focusAreas?.length ||
        currentFormValues.windspeed ||
        currentFormValues.distance ||
        currentFormValues.notes
    );

    if (isFormNotEmpty) {
      confirm({
        title: "Are you sure you want to change the video type?",
        description:
          "You started filling video details If you change the video type, all information about the video will be deleted",
        confirmationText: "Change video type",
        cancellationText: "Cancel",
        ...confirmDialogStyleOptions,
        confirmationButtonProps: {
          ...confirmDialogStyleOptions.confirmationButtonProps,
          color: "primary",
        },
      }).then((): void => {
        setFormValue(`videos.${index}`, {
          video: currentFormValues.video,
          uploadLink: currentFormValues.uploadLink,
          videoMethod: choice,
          distance: undefined,
          notes: "",
          cameraFov: userSettings?.defaultCameraFov as number,
          windspeed: undefined,
          height: undefined,
          angle: undefined,
          annotations: undefined,
        });
      });
    } else {
      onChange(choice);

      clearErrors([
        `videos.${index}.distance`,
        `videos.${index}.notes`,
        `videos.${index}.cameraFov`,
        `videos.${index}.windspeed`,
        `videos.${index}.height`,
        `videos.${index}.angle`,
      ]);
    }
  };

  const annotations = watch().videos[index].annotations;

  return (
    <>
      <Box>
        <Typography variant="body2Bold">Select the video type</Typography>
      </Box>

      <Controller
        key={key}
        name={videoMethodKey as `videos.${number}.videoMethod`}
        rules={{ required: "This field is required" }}
        control={control}
        render={({ field, fieldState: { error } }) => {
          const onChangeHandler = (choice: VideoMethod) =>
            onConfirmChangeVideoType(choice, field.onChange);

          return (
            <RadioButtonsGroup
              {...field}
              value={field.value}
              onSetHandler={onChangeHandler}
              radioGroupValues={radioGroupValues}
              radioGroupLabels={radioGroupLabels}
              error={!!error}
              helperText={error ? error.message : ""}
            />
          );
        }}
      />

      {showFormBlock && (
        <Box sx={{ marginTop: "1em" }}>
          {isVideoTypeDrone && (
            <>
              <Box sx={{ marginBottom: "1em" }}>
                <ImageCard
                  alt="Drone concept image"
                  src="/img/drone_concept.png"
                />
              </Box>

              <Box sx={{ display: "flex", gap: "1em" }}>
                <Controller
                  key={`videos.${index}.angle`}
                  name={`videos.${index}.angle`}
                  control={control}
                  rules={{
                    required: "This field is required",
                    min: {
                      value: 0,
                      message: "Angle must be at least 0°",
                    },
                    max: {
                      value: 90,
                      message: "Angle cannot exceed 90°",
                    },
                  }}
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <TextField
                        {...field}
                        value={field.value ?? ""}
                        onWheel={e => (e.target as HTMLElement).blur()}
                        margin="dense"
                        type="number"
                        sx={{
                          height: "68px",
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "8px",
                          },
                        }}
                        label={"Angle"}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">°</InputAdornment>
                          ),
                        }}
                        fullWidth
                        error={!!error}
                        helperText={error ? error.message : ""}
                      />
                    );
                  }}
                />

                <Controller
                  key={`videos.${index}.height`}
                  name={`videos.${index}.height`}
                  control={control}
                  rules={{ required: "This field is required" }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      value={field.value ?? ""}
                      onWheel={e => (e.target as HTMLElement).blur()}
                      margin="dense"
                      type="number"
                      sx={{
                        height: "68px",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "8px",
                        },
                      }}
                      label={"Height"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">ft</InputAdornment>
                        ),
                      }}
                      fullWidth
                      error={!!error}
                      helperText={error ? error.message : ""}
                    />
                  )}
                />
              </Box>
            </>
          )}

          <Box sx={{ display: "flex", gap: "1em", margin: "0.3em 0 0.5em 0" }}>
            <Controller
              name={`videos.${index}.distance`}
              key={`videos.${index}.distance`}
              control={control}
              rules={{
                required: !isVideoTypeDrone ? "This field is required" : "",
              }}
              render={({ field, fieldState: { error } }) => {
                const angleValue = watch(`videos.${index}.angle`) ?? 0;
                const heightValue = watch(`videos.${index}.height`) ?? 0;

                const angleRadians = angleValue * (Math.PI / 180);

                const directLineDistance = heightValue / Math.cos(angleRadians);

                let calculatedValue = field.value ?? "";

                useEffect(() => {
                  if (isVideoTypeDrone && isFinite(directLineDistance)) {
                    field.onChange(directLineDistance.toFixed(2));

                    calculatedValue = directLineDistance.toFixed(2);
                  }
                }, [angleValue, heightValue]);

                const helperText = isVideoTypeDrone
                  ? "Distance is calculated based on angle and height"
                  : error
                  ? error.message
                  : "";

                return (
                  <TextField
                    {...field}
                    value={String(calculatedValue) ?? ""}
                    onWheel={e => (e.target as HTMLElement).blur()}
                    margin="dense"
                    type="number"
                    sx={{
                      height: "68px",
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "8px",
                      },
                    }}
                    label={"Distance"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">ft</InputAdornment>
                      ),
                    }}
                    disabled={isVideoTypeDrone}
                    fullWidth
                    error={!!error}
                    helperText={helperText}
                  />
                );
              }}
            />

            <Controller
              name={`videos.${index}.cameraFov`}
              key={`videos.${index}.cameraFov`}
              control={control}
              rules={{
                required: !isVideoTypeDrone ? "This field is required" : "",
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  value={field.value ?? ""}
                  onWheel={e => (e.target as HTMLElement).blur()}
                  margin="dense"
                  type="number"
                  sx={{
                    height: "68px",
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "8px",
                    },
                  }}
                  label={"Camera FOV"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">°</InputAdornment>
                    ),
                  }}
                  fullWidth
                  error={!!error}
                  helperText={error ? error.message : ""}
                />
              )}
            />
          </Box>

          <Box sx={{ display: "flex", gap: "1em" }}>
            <Box sx={{ flex: "1 1 50%" }}>
              <Controller
                key={`videos.${index}.windspeed`}
                name={`videos.${index}.windspeed`}
                control={control}
                rules={{ required: "This field is required" }}
                render={({ field, fieldState: { error } }) => {
                  const { onChange, ...restField } = field;
                  const { value } = field;

                  const calculatedValue = value
                    ? parseFloat((value * 1.60934).toFixed(2))
                    : "";

                  const fromKphToMiles = value
                    ? parseFloat(Number(value).toFixed(2))
                    : "";

                  const actualValue =
                    unit === UnitsEnum.MPH ? fromKphToMiles : calculatedValue;

                  const onWindspeedChange = (
                    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                  ) => onWindspeedChangeHandler(e, onChange);

                  return (
                    <TextField
                      {...restField}
                      value={actualValue ?? ""}
                      onChange={onWindspeedChange}
                      margin="dense"
                      type="number"
                      onWheel={e => (e.target as HTMLElement).blur()}
                      sx={{
                        height: "68px",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "8px",
                        },
                        width: "100%",
                      }}
                      label={"Windspeed"}
                      InputProps={{
                        endAdornment: (
                          <Controller
                            name={`unit`}
                            control={control}
                            render={({ field }) => (
                              <InputAdornment
                                sx={{ border: "none" }}
                                position="end"
                              >
                                |
                                <Select
                                  {...field}
                                  displayEmpty
                                  inputProps={{
                                    "aria-label": "Without label",
                                  }}
                                  size="small"
                                  sx={{
                                    ".MuiSelect-select": {
                                      paddingRight: "8px",
                                    },
                                    "& .MuiOutlinedInput-notchedOutline": {
                                      border: "none",
                                    },
                                  }}
                                >
                                  <MenuItem value="mph">mph</MenuItem>
                                  <MenuItem value="kph">kph</MenuItem>
                                </Select>
                              </InputAdornment>
                            )}
                          />
                        ),
                      }}
                      fullWidth
                      error={!!error}
                      helperText={error ? error.message : ""}
                    />
                  );
                }}
              />
            </Box>

            <Box sx={{ flex: "1 1 50%" }} />
          </Box>

          <Box
            sx={{
              margin: "1em 0",
            }}
          >
            <LeakFinderAnnotationContainer
              onDeleteAnnotation={onDeleteAnnotation}
              focusAreaMarker={focusAreaMarker}
              focusAreaMarkerChange={focusAreaMarkerChange}
              onAddAnnotation={onAddAnnotation}
              focusAreaFields={focusAreaFields}
              setFormValue={saveChangesHandler}
              annotations={annotations}
            />
          </Box>

          <Box sx={{ marginBottom: "0.5em" }}>
            <Typography variant="body2Bold">Leave note (optional)</Typography>
          </Box>

          <Box>
            <Controller
              name={`videos.${index}.notes`}
              key={`videos.${index}.notes`}
              control={control}
              render={({ field }) => (
                <StyledTextArea
                  {...field}
                  value={field.value}
                  minRows={3}
                  placeholder="Type your note here..."
                />
              )}
            />
          </Box>
        </Box>
      )}
    </>
  );
};

export default VideoDetailsForm;

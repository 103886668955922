import type { ComponentsOverrides, Theme } from "@mui/material";

export const MuiToolBarLightStyleOverrides: ComponentsOverrides<Theme>["MuiToolbar"] =
  {
    root: () => {
      return {
        padding: 0,
        height: "48px",
        minHeight: "48px",
        "@media (min-width: 600px)": {
          minHeight: "48px",
        },
      };
    },
  };

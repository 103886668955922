import { useEffect } from "react";

import { useLazyQuery } from "@apollo/client";
import {
  GetZonesByDeviceQuery,
  GetZonesByDeviceQueryVariables,
} from "../../../../../../../../API";
import { useCustomerIdGuard } from "../../../../../../../../common/hooks/useCustomerIdGuard";
import { GET_ZONES_BY_DEVICE } from "../../../../../../../../common/operations/queries";
import { errorNotification } from "../../../../../../../../common/variables/notification";
import client from "../../../../../../../../configs/apolloClient";
import { DEFAULT_LIMIT_COUNT } from "../../../../../../../../common/variables/common";

export const usePTGetZonesByDevice = (currentDeviceId: any) => {
  const selectedCustomerId = useCustomerIdGuard();

  const selectedDeviceId = currentDeviceId;

  useEffect(() => {
    if (selectedCustomerId && selectedDeviceId) {
      fetchZones(selectedDeviceId);
    }
  }, [selectedCustomerId, selectedDeviceId]);

  const handleRequestError = async (): Promise<void> => {
    await client.cache.reset();

    errorNotification();
  };

  const [getZonesByDevice, { data, loading }] = useLazyQuery<
    GetZonesByDeviceQuery,
    GetZonesByDeviceQueryVariables
  >(GET_ZONES_BY_DEVICE, {
    fetchPolicy: "network-only",
  });

  const fetchZones = (deviceId: string, nextToken?: string): void => {
    getZonesByDevice({
      variables: {
        limit: DEFAULT_LIMIT_COUNT,
        // temporary fix until we fix DDB schemas with new C# prefix for querying zones, annotations, etc. (this would involve adding the prefix for device and service creation)
        deviceId: selectedCustomerId + "#" + deviceId,
        customerId: selectedCustomerId,
        nextToken,
      },
    })
      .then(async (response): Promise<void> => {
        if (response.data?.getZonesByDevice.nextToken) {
          fetchZones(deviceId, response.data?.getZonesByDevice.nextToken);
        }

        if (response.error) {
          await handleRequestError();
        }
      })
      .catch(async (): Promise<void> => {
        await handleRequestError();
      });
  };

  return { data, loading };
};

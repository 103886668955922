export const splitArrayIntoChunks = (
  inputArray: Array<any>,
  perChunk: number
): Array<any> => {
  const result = inputArray.reduce(
    (resultArray: Array<any>, item: any, index: number) => {
      const chunkIndex = Math.floor(index / perChunk);

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = [];
      }

      resultArray[chunkIndex].push(item);

      return resultArray;
    },
    []
  );

  return result;
};

export const getUniqueValuesFromArray = <T>(array: T[]): T[] => {
  const uniqueSet = new Set(array);
  const uniqueArray: T[] = [];

  uniqueSet.forEach(value => {
    uniqueArray.push(value);
  });

  return uniqueArray;
};

export const getUniqueArrayOfObjectsByProperty = <T>(
  array: T[],
  property: keyof T
): T[] => {
  return array.filter(
    (obj, index, self) =>
      self.findIndex(o => o[property] === obj[property]) === index
  );
};

import { RefObject, useRef, useState } from "react";
import { useLoadScript, Libraries } from "@react-google-maps/api";

import { LocationCoordinatesType } from "../../../common/context/WeatherConditionsContext/WeatherConditionsContext";

export interface MapCenterCoordinateInterface {
  lat: number;
  lng: number;
}

export interface UseLocationMapInterface {
  isGoogleMapLibLoaded: boolean;
  mapCenterCoordinates: MapCenterCoordinateInterface;
  onMapLoad: (map: any) => void;
  onMapUnmount: () => void;
  onMapCenterChanged: () => void;
  onSearchMapLocation: (query: string) => void;
  onAutocompleteLoad: (autocomplete: google.maps.places.Autocomplete) => void;
  onAutocompletePlaceChanged: () => void;
  setMapCenterCoordinatesDirectly: (center: LocationCoordinatesType) => void;
  locationInputRef: RefObject<HTMLInputElement> | null;
}

const DefaultCenter = { lat: 39.85595232130446, lng: -101.79632717770171 };

const libraries: Libraries = ["places", "elevation"];

export const useMapLocation = (): UseLocationMapInterface => {
  const mapRef = useRef<google.maps.Map | null>(null);
  const locationInputRef = useRef<HTMLInputElement | null>(null);

  const [mapCenterCoordinates, setMapCenterCoordinates] =
    useState<MapCenterCoordinateInterface>(DefaultCenter);
  const [searchResult, setSearchResult] =
    useState<google.maps.places.Autocomplete>();

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY ?? "",
    libraries: libraries,
  });

  const onMapLoad = (map: google.maps.Map): void => {
    mapRef.current = map;

    mapRef.current.setCenter(mapCenterCoordinates);
  };

  const onMapUnmount = (): void => {
    mapRef.current = null;
  };

  const onMapCenterChanged = (): void => {
    if (mapRef.current !== undefined && mapRef.current !== null) {
      const newCenter = mapRef.current.getCenter();

      if (newCenter !== undefined) {
        const newCoords = {
          lat: newCenter.lat(),
          lng: newCenter.lng(),
        };

        setMapCenterCoordinates(newCoords);
      }
    }
  };

  const onAutocompleteLoad = (
    autocomplete: google.maps.places.Autocomplete
  ): void => {
    setSearchResult(autocomplete);
  };

  const onAutocompletePlaceChanged = (): void => {
    if (searchResult) {
      const place = searchResult.getPlace();

      if (place && place.name) {
        onSearchMapLocation(place.name);
      }
    }
  };

  const onSearchMapLocation = (query: string): void => {
    const request = {
      query,
      fields: ["name", "geometry"],
    };

    if (
      mapRef.current !== undefined &&
      mapRef.current !== null &&
      query !== ""
    ) {
      const map = mapRef.current;
      const service = new google.maps.places.PlacesService(map);

      service.textSearch(
        request,
        (
          results: google.maps.places.PlaceResult[] | null,
          status: google.maps.places.PlacesServiceStatus
        ): void => {
          if (status === google.maps.places.PlacesServiceStatus.OK && results) {
            map.setCenter(results[0].geometry!.location!);
          }
        }
      );
    }
  };

  const setMapCenterCoordinatesDirectly = (
    center: LocationCoordinatesType
  ): void => {
    if (center) {
      const newCoords = {
        lat: center[0],
        lng: center[1],
      };

      setMapCenterCoordinates(newCoords);

      if (mapRef && mapRef.current) {
        mapRef.current.setCenter(newCoords);
      }
    }
  };

  return {
    isGoogleMapLibLoaded: isLoaded,
    mapCenterCoordinates,
    onMapLoad,
    onMapUnmount,
    onMapCenterChanged,
    onSearchMapLocation,
    onAutocompleteLoad,
    onAutocompletePlaceChanged,
    setMapCenterCoordinatesDirectly,
    locationInputRef,
  };
};

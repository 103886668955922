import { pairs } from "d3";
import { mesh } from "topojson-client";

import {
  getCoordinatesFromLatLng,
  getLatLonFromCoordinates,
} from "./CartographicUtils";
import { calculateBboxCenterFromPoints } from "./GeometryUtils";

export const getPointsFromMultilinestring = (
  multilinestring,
  elevationDiff = 0
) => {
  const points = [];

  multilinestring.coordinates.forEach(line => {
    pairs(
      line.map(data => {
        const [longitude, latitude, height] = data;

        return getCoordinatesFromLatLng(
          latitude,
          longitude,
          height + elevationDiff
        );
      }),
      (a, b) => points.push(a, b)
    );
  });

  return points;
};

export const getMeshMultilinestring = topojsonData => {
  if (!topojsonData || !topojsonData.objects) {
    return [];
  }

  return Object.values(topojsonData.objects).map(obj =>
    mesh(topojsonData, obj)
  );
};

export const getCenterFromMultistring = multilinestring => {
  const points = multilinestring.reduce(
    (prev, item) => [...prev, ...getPointsFromMultilinestring(item)],
    []
  );

  const centerIn3dSpace = calculateBboxCenterFromPoints(points);

  return getLatLonFromCoordinates(centerIn3dSpace);
};

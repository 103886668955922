import CustomOrbitControl from "./CustomOrbitControl";
import CustomTransformControl from "./CustomTransformControl";
import LookCameraControl from "./LookCameraControl";
import DistanceMeasurementControl from "./DistanceMeasurementControl";
import FPVModeControls from "./FPVModeControls";
import SelectObjectOnScene from "./SelectObjectOnScene";

import { SecondaryViewTypes } from "../../data/SecondaryViewData";

class SceneControls {
  constructor(
    scene,
    mainCamera,
    mainViewportElement,
    secondaryViewportElement,
    sceneOrbitCenter
  ) {
    this.scene = scene;

    this.mainCamera = mainCamera;

    this.mainViewportElement = mainViewportElement;

    this.secondaryViewportElement = secondaryViewportElement;

    this.sceneOrbitCenter = sceneOrbitCenter;

    this.orbitControl = null;

    this.transformControl = null;

    this.lookCameraControl = null;

    this.distanceMeasurementControl = null;

    this.objectSelectionControl = null;

    this.initOrbitControl();

    this.initTransformControl();

    this.initLookCameraControl();

    this.initDistanceMeasurementControl();

    this.initObjectSelectionControl();

    this.initFPVMode();
  }

  dispose() {
    this.orbitControl?.dispose();

    this.transformControl?.dispose();

    this.lookCameraControl?.dispose();

    this.distanceMeasurementControl?.dispose();

    this.objectSelectionControl?.dispose();
  }

  addSettings(facilityBbox, groundObject, facilityObjectsGroup) {
    this.orbitControl.addControlTargetPositionLimitation(
      facilityBbox,
      groundObject
    );

    this.FPVModeControls.addSettings(
      facilityBbox,
      groundObject,
      facilityObjectsGroup
    );

    this.transformControl.addSettings(
      facilityBbox,
      groundObject,
      facilityObjectsGroup,
      () => (this.orbitControl.enabled = false),
      () => (this.orbitControl.enabled = true)
    );

    this.transformControl.enabled = true;

    this.distanceMeasurementControl.setIntersectingObjects(
      groundObject,
      facilityObjectsGroup
    );

    this.objectSelectionControl.setIntersectingObjects(facilityObjectsGroup);

    this.objectSelectionControl.enabled = true;
  }

  initOrbitControl() {
    this.orbitControl = new CustomOrbitControl(
      this.mainCamera,
      this.mainViewportElement
    );

    this.orbitControl.update();
  }

  initTransformControl() {
    this.transformControl = new CustomTransformControl(
      this.mainCamera,
      this.mainViewportElement
    );

    this.scene.add(this.transformControl);
  }

  initLookCameraControl() {
    this.lookCameraControl = new LookCameraControl(
      this.secondaryViewportElement,
      "SingleLensCamera",
      "SM_Camera_Hor",
      "SM_Camera_Vert"
    );

    this.lookCameraControl.enabled = false;
  }

  initDistanceMeasurementControl() {
    this.distanceMeasurementControl = new DistanceMeasurementControl(
      this.scene,
      this.secondaryViewportElement,
      "SingleLensCamera"
    );

    this.distanceMeasurementControl.enabled = false;
  }

  initObjectSelectionControl() {
    this.objectSelectionControl = new SelectObjectOnScene(
      this.mainCamera,
      this.mainViewportElement
    );

    this.objectSelectionControl.enabled = false;
  }

  initFPVMode() {
    this.FPVModeControls = new FPVModeControls(
      this.mainCamera,
      this.mainViewportElement
    );

    this.FPVModeControls.init();
  }

  setActiveCortrols(
    secondaryCamera,
    secondaryViewType,
    isDistanceMeasurementsMode
  ) {
    if (secondaryCamera && secondaryViewType !== SecondaryViewTypes.view360) {
      if (isDistanceMeasurementsMode) {
        this.lookCameraControl.detach();

        this.distanceMeasurementControl.attach(secondaryCamera);
      } else {
        this.lookCameraControl.attach(secondaryCamera);

        this.distanceMeasurementControl.detach();
      }
    } else {
      this.lookCameraControl.detach();

      this.distanceMeasurementControl.detach();
    }
  }

  update(dt) {
    if (
      this.orbitControl &&
      this.orbitControl.enabled &&
      !this.FPVModeControls.controls.isLocked
    ) {
      this.orbitControl.update();
    }

    if (this.FPVModeControls) {
      this.FPVModeControls.update(dt);
    }
  }
}

export default SceneControls;

import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { FC, ReactNode } from "react";

interface IUserPreferencesSectionProps {
  title: string;
  children: ReactNode;
}

const UserPreferencesSection: FC<IUserPreferencesSectionProps> = ({
  title,
  children,
}) => {
  return (
    <>
      <Typography
        variant="subtitle2"
        sx={{
          fontSize: "0.75em",
          fontWeight: "700",
          textTransform: "uppercase",
          mb: "0.25em",
          color: theme => theme.palette.otherTextTertiary.main,
        }}
      >
        {title}
      </Typography>
      <Paper
        elevation={0}
        sx={{
          backgroundColor: theme => theme.palette.otherBackgroundLight.main,
          border: theme => `1px solid ${theme.palette.otherOutlineBorder.main}`,
          borderRadius: "0.75em",
          padding: "1.5em 2em",
          maxWidth: theme => theme.breakpoints.values.md,
        }}
      >
        {children}
      </Paper>
    </>
  );
};

export default UserPreferencesSection;

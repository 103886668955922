import { MeshBasicMaterial, DoubleSide } from "three";

import {
  createGroup,
  createPlaneFromFlatArray,
} from "../SceneUtils/CreateMesh";
import { getBboxSizeFromPoints } from "../SceneUtils/GeometryUtils";
import { setElevationToLatLonBoxGrid } from "../SceneUtils/ElevationUtils";
import { getCoordinatesFromLatLng } from "../SceneUtils/CartographicUtils";
import { loadTexture } from "../SceneUtils/AssetLoaders";

import {
  extractImagesFromKMZFile,
  generateLatLonBoxesGridPoints,
  getLatLonBoxesFromKMZ,
  readFileFromPath,
} from "../../utils";

import { FACILITY_SKETCHPLAN_RENDER_ORDER } from "../Constants";

class SketchplanKMZLayer {
  constructor(parentObject, mapTiles) {
    this.parentObject = parentObject;

    this.mapTiles = mapTiles;

    this.kmzLayerGroup = createGroup("kmzLayerGroup", this.parentObject);
  }

  async load(kmzLayerURL, kmzLayerPlaneData, elevationDiff) {
    const tilesGroupMatrix = this.mapTiles.tiles.group.matrixWorld.clone();

    const kmzFile = await readFileFromPath(kmzLayerURL);

    const imageList = (await extractImagesFromKMZFile(kmzFile)).reverse();
    const planeDataList = kmzLayerPlaneData.reverse();

    // eslint-disable-next-line guard-for-in
    for (const index in planeDataList) {
      await this.addPngLayer(
        planeDataList[index],
        imageList[index],
        elevationDiff,
        tilesGroupMatrix,
        parseInt(index)
      );
    }
  }

  async addPngLayer(
    planeData,
    textureUrl,
    elevationDiff,
    tilesGroupMatrix,
    order
  ) {
    const plane = await this.createPlane(
      planeData,
      textureUrl,
      elevationDiff,
      tilesGroupMatrix,
      order
    );

    this.kmzLayerGroup.add(plane);
  }

  async createPlane(
    planeData,
    textureUrl,
    elevationDiff,
    tilesGroupMatrix,
    order
  ) {
    const { numRows, numCols, points } = planeData;

    const positionVectorList = points.map(
      ([longitude, latitude, elevation]) => {
        const positionVector = getCoordinatesFromLatLng(
          latitude,
          longitude,
          elevation + elevationDiff
        );

        positionVector.applyMatrix4(tilesGroupMatrix);

        return positionVector;
      }
    );

    const texture = await loadTexture(textureUrl);

    texture.flipY = false;

    const material = new MeshBasicMaterial({
      transparent: true,
      side: DoubleSide,
      map: texture,
      depthWrite: false,
      depthTest: false,
    });

    const plane = createPlaneFromFlatArray(
      positionVectorList,
      numRows,
      numCols,
      material
    );

    plane.renderOrder = FACILITY_SKETCHPLAN_RENDER_ORDER + order;

    return plane;
  }
}

export default SketchplanKMZLayer;

import { useState, useRef, useMemo } from "react";
import {
  Box,
  Button,
  InputAdornment,
  OutlinedInput,
  styled,
} from "@mui/material";

import DragAndDropForm from "../DragAndDropForm";
import TextInput from "./TextInput";
import ClearInputButton from "./components/ClearInputButton";
import LoadFileIcon from "./components/LoadFileIcon";

import { FileStateInterface } from "../../hooks/useUploadFileState";
import { HintInterface } from "./components/InputHint";

interface SupportedFormatsInterface {
  hint: string;
  accept: string;
}

interface FileInputPropsInterface {
  fileState: FileStateInterface;
  fileTypeName: string;
  supportedFormats: SupportedFormatsInterface;
  multiline?: boolean;
  required?: boolean;
  isLoading: boolean;
  onClearInput: () => void;
  onFileUpload: (files: FileList) => void;
}

const FileInput = ({
  fileState,
  fileTypeName,
  supportedFormats,
  multiline,
  required,
  isLoading,
  onClearInput,
  onFileUpload,
}: FileInputPropsInterface): JSX.Element => {
  const [isDragActive, setIsDragActive] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const textInputValue = useMemo<string>(
    () => (fileState.file ? fileState.file.name : ""),
    [fileState.file]
  );
  const textInputPlaceholder = useMemo<string>(
    () => (isLoading ? "Loading ..." : "Select file"),
    [isLoading]
  );

  const hint = useMemo<HintInterface>(() => {
    if (!isLoading) {
      if (fileState.file && !fileState.error) {
        return {
          type: "success",
          text: "File uploaded successfully",
        };
      }

      if (fileState.error) {
        return {
          type: "error",
          text: fileState.errorMessage,
        };
      }
    }

    return {
      type: "default",
      text: `Supported files formats: ${supportedFormats.hint}`,
    };
  }, [fileState.file, fileState.error, isLoading]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    if (e.target.files && e.target.files[0]) {
      onFileUpload(e.target.files);
    }
  };

  const onBrowseFile = (): void => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const onClearButton = (e: React.MouseEvent): void => {
    e.preventDefault();

    e.stopPropagation();

    if (inputRef.current) {
      inputRef.current.value = "";
    }

    onClearInput();
  };

  return (
    <Wrapper isdragactive={isDragActive ? "true" : "fasle"}>
      <DragAndDropForm
        onFileUpload={onFileUpload}
        handleIsDragActive={setIsDragActive}
        allowDrag={!isLoading}
      >
        <Container>
          <TextInput
            label={fileTypeName}
            value={textInputValue}
            required={required}
            disabled={isLoading}
            handleOnClick={onBrowseFile}
            hint={hint}
            readonly={true}
            placeholder={textInputPlaceholder}
            isError={!!fileState.error}
            endAdornment={
              <InputAdornment position="end">
                {isLoading && <LoadFileIcon />}
                {fileState.file && <ClearInputButton onClick={onClearButton} />}
              </InputAdornment>
            }
          />
          <OutlinedInput
            sx={{ display: "none" }}
            id={`${fileTypeName}-input`}
            inputRef={inputRef}
            onChange={handleChange}
            inputProps={{ accept: supportedFormats.accept }}
            readOnly
            type="file"
            multiline={multiline}
          />
          <Button
            sx={{
              height: "29px",
              marginTop: "34px",
            }}
            disabled={isLoading}
            onClick={onBrowseFile}
            variant="secondary"
            color="blue"
          >
            Browse...
          </Button>
        </Container>
      </DragAndDropForm>
    </Wrapper>
  );
};

export default FileInput;

const Wrapper = styled(Box)<{ isdragactive: "true" | "fasle" }>(
  ({ theme, isdragactive }) => ({
    width: "100%",
    padding: "14px 34px",

    ...(isdragactive === "true" && {
      backgroundColor: theme.palette["base-background"],
      border: `1px solid ${theme.palette.blue.light}`,
      borderRadius: "4px",
    }),
  })
);

const Container = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  gap: "10px",
}));

import * as THREE from "three";

import SceneObjectBuilder from "./SceneObjectBuilder";

import { createGroup } from "../SceneUtils/CreateMesh";
import { setMaterial } from "../SceneUtils/MaterilsUtils";

class ObjectBuilder extends SceneObjectBuilder {
  createObjectInstance(
    objectClone,
    materialClone,
    objectData,
    defaultObjectPosition
  ) {
    const object = createGroup(objectData.name);

    object.ID = objectData.id;

    object.EntityType = objectData.EntityType;

    object.name = objectData.name;

    this.addModelPart(object, objectClone, materialClone);

    this.setRotation(object, objectData.rotation);

    this.setPosition(object, objectData.position, defaultObjectPosition);

    this.setObjectInitialQuaternion(object, objectData?.initialQuaternion);

    this.setObjectRenderOrder(object);

    return object;
  }

  addModelPart(object, objectClone, materialClone) {
    const modelWrapper = createGroup(
      `${object.EntityType}-ModelWrapper`,
      object
    );

    const model = objectClone.clone(true);

    setMaterial(model, materialClone.clone());

    modelWrapper.add(model);
  }
}

export default ObjectBuilder;

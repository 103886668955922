import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";

import { useAuthenticatedUser } from "../common/hooks/useAuthenticatedUser";
import ClientsUploadTabPanel from "./components/ClientsUploadTabPanel";
import {
  ClientUploadsTabsKeysEnum,
  ClientUploadsTabsList,
  clientUploadsTabsVariable,
  useClientUploadsTabsVariable,
} from "./variables/clientUploads";
import { useGetPaginatedClientsAndGroups } from "./hooks/useGetPaginatedClientsGroups";

const ClientUploadsTabs = (): JSX.Element => {
  const { role } = useAuthenticatedUser();

  const { selectedTab } = useClientUploadsTabsVariable();

  const { clients, groups, deleteGroup, pageLoading } =
    useGetPaginatedClientsAndGroups();

  const handleChange = (
    _event: React.SyntheticEvent,
    newValue: ClientUploadsTabsKeysEnum
  ): void => {
    clientUploadsTabsVariable({
      selectedTab: newValue,
    });
  };

  const allowedTabs = ClientUploadsTabsList.filter((tab): boolean => {
    return tab.allowedRoles.includes(role);
  });

  const tabs = allowedTabs.map((tab): JSX.Element => {
    return (
      <Tab
        key={tab.id}
        label={
          <Typography variant="subtitle1" textTransform="capitalize">
            {tab.label}
          </Typography>
        }
        value={tab.id}
      />
    );
  });

  return (
    <>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: theme => theme.palette.otherOutlineBorder.main,
        }}
      >
        <Tabs
          onChange={handleChange}
          value={selectedTab}
          variant="scrollable"
          scrollButtons="auto"
        >
          {tabs}
        </Tabs>
      </Box>

      <ClientsUploadTabPanel
        clients={clients}
        groups={groups}
        deleteGroup={deleteGroup}
        pageLoading={pageLoading}
      />
    </>
  );
};

export default ClientUploadsTabs;

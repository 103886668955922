import { styled } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem, { MenuItemProps } from "@mui/material/MenuItem";

export const StyledMenu = styled((props: MenuProps) => <Menu {...props} />)(
  () => ({
    "& .MuiPaper-root": {
      minWidth: "223px",
      marginTop: "-10px",
      borderRadius: "4px",
      padding: "4px",
      boxShadow: "0px 6px 12px 0px rgba(0, 0, 0, 0.10)",
    },

    "& .MuiList-root": {
      padding: "0px",
    },
  })
);

export const SeparatorWrapper = styled(Box)(() => ({
  padding: "8px 16px",
  height: "1px",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

export const SeparatorLine = styled(Box)(({ theme }) => ({
  height: "1px",
  width: "100%",
  backgroundColor: theme.palette["grey-light"],
}));

export const StyledMenuItem = styled((props: MenuItemProps) => (
  <MenuItem disableRipple {...props} />
))(() => ({
  display: "flex",
  padding: "8px 16px",
  gap: "8px",
}));

export const MenuTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette["base-label"],
}));

export const HintText = styled(Typography)(({ theme }) => ({
  color: theme.palette.divider,
}));

export const GapBlock = styled(Box)(() => ({
  flex: 1,
}));

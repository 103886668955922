import { makeVar, useReactiveVar } from "@apollo/client";
import type { PaletteMode } from "@mui/material";

import { themeMode } from "../providers/theme/MuiThemeProvider";

const themeModeStorage = localStorage.getItem("themeMode");
let defaultValue = themeMode.light;

if (themeModeStorage) {
  defaultValue = themeModeStorage;
} else {
  localStorage.setItem("themeMode", defaultValue);
}

export const themeModeVariable = makeVar<PaletteMode>(
  defaultValue as PaletteMode
);

export const useThemeModeVariable = () => useReactiveVar(themeModeVariable);

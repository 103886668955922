import ProjectView from "./components/ProjectView";

import {
  ConfiguratorPermissionContextProvider,
  ProjectContextProvider,
  ProjectObjects3dContextProvider,
  WeatherConditionsContextProvider,
} from "../../common/context";

const WebglConfiguratorProjectViewerPage = () => {
  return (
    <ConfiguratorPermissionContextProvider>
      <ProjectContextProvider>
        <WeatherConditionsContextProvider>
          <ProjectObjects3dContextProvider>
            <ProjectView />
          </ProjectObjects3dContextProvider>
        </WeatherConditionsContextProvider>
      </ProjectContextProvider>
    </ConfiguratorPermissionContextProvider>
  );
};

export default WebglConfiguratorProjectViewerPage;

import { useContext, useState } from "react";
import { Button } from "@mui/material";

import EditProjectModal from "./EditProjectModal";

import { DTProjectInterface } from "../../../../common/interfaces";
import { ProjectContext } from "../../../../common/context";
interface SaveProjectButtonInterface {
  dtProject: DTProjectInterface;
}

const EditProjectButton = ({
  dtProject,
}: SaveProjectButtonInterface): JSX.Element => {
  const [isModalWindowOpen, setIsModalWindowOpen] = useState(false);
  const { sceneStarted, isLoading } = useContext(ProjectContext);

  const handleOnClick = () => {
    setIsModalWindowOpen(true);
  };

  const onCloseModal = () => {
    setIsModalWindowOpen(false);
  };

  return (
    <>
      <Button
        disabled={!sceneStarted || isLoading}
        onClick={handleOnClick}
        variant="primary"
        color="blue"
      >
        Edit
      </Button>

      {isModalWindowOpen && (
        <EditProjectModal
          dtProject={dtProject}
          onClose={onCloseModal}
          disabled={isLoading}
        />
      )}
    </>
  );
};

export default EditProjectButton;

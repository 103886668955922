import { useContext, useEffect } from "react";

import {
  Box,
  Button,
  Typography,
  Stack,
  OutlinedInput,
  useTheme,
} from "@mui/material";

import ModalWindow from "../../../common/components/ModalWindow";
import { ProjectModalContext } from "../../../common/context";

const ShareProjectModal = () => {
  const theme = useTheme();

  const { isDisplayShareModal, setIsDisplayShareModal } =
    useContext(ProjectModalContext);

  const linkValue = window.location.href.replace("editor", "viewer");

  const closeModal = () => {
    setIsDisplayShareModal(false);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(linkValue);
  };

  useEffect(() => {
    if (isDisplayShareModal) {
      copyToClipboard();
    }
  }, [isDisplayShareModal]);

  return (
    <ModalWindow isOpen={isDisplayShareModal} onClose={closeModal}>
      <Typography id="modal-modal-title" variant="h2">
        Share a link
      </Typography>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "4px",
          margin: "26px 0 34px",
        }}
      >
        <Typography id="modal-modal-description" variant="body14Regular">
          Link:
        </Typography>
        <OutlinedInput
          type="text"
          readOnly={true}
          defaultValue={linkValue}
          sx={{
            width: "100%",
            height: "28px",

            "& input": {
              color: theme.palette.blue.main,
            },
          }}
        />
      </Box>

      <Stack direction="row" spacing={1} justifyContent="flex-end">
        <Button onClick={copyToClipboard} variant="primary" color="blue">
          Copy
        </Button>
      </Stack>
    </ModalWindow>
  );
};

export default ShareProjectModal;

import React from "react";

import { type PopupState as PopupStateType } from "material-ui-popup-state/hooks";

import { CSSObject } from "@mui/styled-engine";
import { Menu, MenuItem, Typography, styled } from "@mui/material";

const StyledMenu = styled(Menu)(
  ({ theme }): CSSObject => ({
    "& .MuiPaper-root": {
      border: `1px solid ${theme.palette.otherOutlineBorder.main}`,
      borderRadius: "8px",
      marginTop: "5px",
      boxShadow: "0px 4px 20px 0px #0816331A",
    },
  })
);

const PauseMenuItem = styled(MenuItem)(
  ({ theme }): CSSObject => ({
    width: "100px",
    display: "flex",
    justifyContent: "center",
    alignContent: "center",

    "&:hover": {
      backgroundColor: "transparent",
      color: theme.palette.primary.main,
    },
  })
);

const DeleteMenuItem = styled(MenuItem)(
  ({ theme }): CSSObject => ({
    color: theme.palette.error.main,
    display: "flex",
    justifyContent: "center",
    alignContent: "center",

    "&:hover": {
      backgroundColor: "transparent",
      color: theme.palette.error.light,
    },
  })
);

type StyledModelMenuComponentProps = {
  popupState: PopupStateType;
  anchorEl: null | HTMLElement;
  isRunning?: boolean;
  handleClose: () => void;
  onDeleteHandler: () => void;
  onChangeModelStatus: () => void;
};

const StyledModelMenuComponent: React.FC<StyledModelMenuComponentProps> =
  React.memo(
    ({
      popupState,
      handleClose,
      anchorEl,
      isRunning,
      onDeleteHandler,
      onChangeModelStatus,
    }): JSX.Element => {
      return (
        <StyledMenu
          anchorEl={anchorEl}
          open={true}
          onClose={handleClose}
          onClick={handleClose}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
        >
          <PauseMenuItem
            key="pauseMenuItem"
            onClick={(): void => {
              onChangeModelStatus();

              popupState.close();
            }}
          >
            <Typography variant="body2Regular">
              {isRunning ? "Pause" : "Unpause"}
            </Typography>
          </PauseMenuItem>

          <DeleteMenuItem
            onClick={(): void => {
              popupState.close();

              onDeleteHandler();
            }}
          >
            <Typography variant="body2Regular">Delete</Typography>
          </DeleteMenuItem>
        </StyledMenu>
      );
    }
  );

export default StyledModelMenuComponent;

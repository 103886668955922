import { FC } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import MaximizeIcon from "../icons/MaximizeIcon";

interface ExtendButtonProps {
  changeExpandedMode: () => void;
  title: string;
}

const ExtendButton: FC<ExtendButtonProps> = ({ changeExpandedMode, title }) => {
  return (
    <Box
      sx={{
        position: "absolute",
        right: 6,
        top: 6,
      }}
    >
      <Button
        sx={{
          zIndex: 10,
          "&, &:hover": {
            color: "primary.main",
            borderColor: "primary.main",
            backgroundColor: "otherBackgroundLight.main",
            textTransform: "none",
          },
        }}
        startIcon={<MaximizeIcon />}
        variant="contained"
        size="small"
        onClick={changeExpandedMode}
      >
        <Typography variant="buttonMedium" component="span">
          {title}
        </Typography>
      </Button>
    </Box>
  );
};

export default ExtendButton;

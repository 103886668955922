import {
  Raycaster,
  Vector2,
  Vector3,
  Color,
  TubeGeometry,
  SphereGeometry,
  MeshBasicMaterial,
  Mesh,
  LineCurve3,
  MathUtils,
  EventDispatcher,
} from "three";

import { createGroup } from "../SceneUtils/CreateMesh";

import { EntityTypeEnum } from "../../context/ProjectContext/ProjectEntitesTypes";

const _changeEvent = { type: "change" };

class DistanceMeasurementControl extends EventDispatcher {
  constructor(scene, domElement, cameraObjectName) {
    super();

    this.enabled = false;

    this.scene = scene;

    this.domElement = domElement;

    this.cameraObjectName = cameraObjectName;

    this.result = null;

    this.ground = null;

    this.objectsGroup = null;

    this.activeObject = null;

    this.camera = null;

    this.lazerMinRadius = 0.003;

    this.lazerMaxRadius = 0.06;

    this.lazerPointRadiusAugmentation = 0.005;

    this.distanceDivideCoefficient = 100;

    this.lazerLine = null;

    this.lazerPoint = null;

    this.lazer = createGroup("lazer", this.scene);

    this.createLazer();

    this.raycaster = new Raycaster();

    this.pointer = new Vector2();

    this.domElement.addEventListener("click", this.handleOnClick.bind(this));

    this.domElement.addEventListener(
      "mousemove",
      this.handleOnMousemove.bind(this)
    );
  }

  dispose() {
    this.domElement.removeEventListener("click", this.handleOnClick.bind(this));

    this.domElement.removeEventListener(
      "mousemove",
      this.handleOnMousemove.bind(this)
    );
  }

  attach(activeObject) {
    if (!this.ground || !this.objectsGroup) {
      throw Error("Error: Ground and ObjectGroup are not provided");
    }

    this.activeObject = activeObject;

    if (this.activeObject) {
      this.camera = this.activeObject.getObjectByName(this.cameraObjectName);

      this.enabled = true;
    }
  }

  detach() {
    this.activeObject = null;

    this.camera = null;

    this.enabled = false;

    this.result = null;
  }

  setIntersectingObjects(ground, objectsGroup) {
    this.ground = ground;

    this.objectsGroup = objectsGroup;
  }

  handleOnClick(event) {
    if (!this.enabled) {
      return;
    }

    this.setPointerPosition(event, this.pointer);
    const objectToIntersect = this.getObjectToIntersect(false);
    const intersection = this.getIntersection(objectToIntersect);

    if (intersection) {
      const cameraPosition = this.getObjectWorldPosition(this.camera);
      const objectPosition = this.getObjectWorldPosition(
        intersection.object.parent
      );

      const distance = this.getDistanceToIntersectedObject(
        cameraPosition,
        objectPosition
      );

      const lablePosition = intersection.point;

      this.result = {
        id: intersection.object.parent.ID,
        distance,
        lablePosition,
      };

      this.dispatchEvent(_changeEvent);
    }
  }

  handleOnMousemove(event) {
    if (!this.enabled) {
      return;
    }

    this.setPointerPosition(event, this.pointer);
    const objectToIntersect = this.getObjectToIntersect(true);
    const intersection = this.getIntersection(objectToIntersect);

    this.setPointerFromBottomCenterViewport(this.pointer);
    const intersectionStartLazer = this.getIntersection(objectToIntersect);

    if (intersection && intersectionStartLazer) {
      this.updateLazer(intersectionStartLazer.point, intersection.point);
    }
  }

  getDistanceToIntersectedObject(cameraPosition, objectPosition) {
    const distance = cameraPosition.distanceTo(objectPosition);

    return distance;
  }

  createLazer() {
    const color = new Color("#3483EB");

    const tubeGeometry = new TubeGeometry();
    const material = new MeshBasicMaterial({ color: color });

    this.lazerLine = new Mesh(tubeGeometry, material);

    this.lazer.add(this.lazerLine);

    const pointGeometry = new SphereGeometry();
    const pointMaterial = new MeshBasicMaterial({ color: color });

    this.lazerPoint = new Mesh(pointGeometry, pointMaterial);

    this.lazer.add(this.lazerPoint);
  }

  updateLazer(from, to) {
    const curve = new LineCurve3(from, to);

    const interpolation = MathUtils.clamp(
      from.distanceTo(to) / this.distanceDivideCoefficient,
      0,
      1
    );

    const lerpRadius = MathUtils.lerp(
      this.lazerMinRadius,
      this.lazerMaxRadius,
      interpolation
    );

    const tubeGeometry = new TubeGeometry(curve, 64, lerpRadius, 16, false);

    this.lazerLine.geometry.dispose();

    this.lazerLine.geometry = tubeGeometry;

    const pointGeometry = new SphereGeometry(
      lerpRadius + this.lazerPointRadiusAugmentation,
      32,
      16
    );

    this.lazerPoint.geometry.dispose();

    this.lazerPoint.geometry = pointGeometry;

    this.lazerPoint.position.set(to.x, to.y, to.z);
  }

  setLazerVisibility(visible) {
    this.lazer.visible = visible;
  }

  getObjectWorldPosition(object) {
    const positionVector = new Vector3();

    object.getWorldPosition(positionVector);

    return positionVector;
  }

  getObjectToIntersect(withGround) {
    const objects = this.objectsGroup.children.filter(
      o => o.EntityType === EntityTypeEnum.Object
    );

    return withGround ? [...objects, this.ground] : objects;
  }

  setPointerFromBottomCenterViewport(vector) {
    const rect = this.domElement.getBoundingClientRect();

    const coords = {
      x: rect.width / 2 + rect.left,
      y: rect.bottom,
    };

    const x = ((coords.x - rect.left) / rect.width) * 2 - 1;
    const y = -((coords.y - rect.top) / rect.height) * 2 + 1;

    vector.set(x, y);
  }

  setPointerPosition(event, vector) {
    const rect = this.domElement.getBoundingClientRect();

    const x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
    const y = -((event.clientY - rect.top) / rect.height) * 2 + 1;

    vector.set(x, y);
  }

  getIntersection(objectsToIntersect) {
    this.raycaster.setFromCamera(this.pointer, this.camera);

    const intersects = this.raycaster.intersectObjects(
      objectsToIntersect,
      true
    );

    return intersects.length ? intersects[0] : null;
  }
}

export default DistanceMeasurementControl;

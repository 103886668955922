import { useState } from "react";

import { loadGeoJson } from "../../../common/threeWebGl/SceneUtils/AssetLoaders";
import {
  getCenterFromMultistring,
  getMeshMultilinestring,
} from "../../../common/threeWebGl/SceneUtils/MultiLineStringUtils";
import {
  getElevationToLocation,
  setElevationToLatLonBoxGrid,
  setElevationToMultiLineString,
} from "../../../common/threeWebGl/SceneUtils/ElevationUtils";
import {
  generateLatLonBoxesGridPoints,
  getCenterFromKMZ,
  getLatLonBoxDemensions,
  getLatLonBoxesFromKMZ,
} from "../../../common/utils";

import { ProjectCreatingType } from "../context/CreateDTProjectContext/CreateDTProjectContext";

export const useGetProjectConfig = () => {
  const [loading, setLoading] = useState(false);

  const getGeoJsonData = async file => {
    const topojsonData = await loadGeoJson(file);
    const meshMultilinestring = getMeshMultilinestring(topojsonData);

    return setElevationToMultiLineString(meshMultilinestring);
  };

  const getLatLonBoxPlaneData = async latLonBox => {
    const { width, height } = getLatLonBoxDemensions(latLonBox);

    const numRows = Math.ceil(height / 50);
    const numCols = Math.ceil(width / 50);

    const gridPoints = generateLatLonBoxesGridPoints(
      latLonBox,
      numRows,
      numCols
    );

    const gridPointsWithElevation = await setElevationToLatLonBoxGrid(
      gridPoints
    );

    return {
      numRows,
      numCols,
      points: gridPointsWithElevation,
    };
  };

  const getTerrainDataByGeojsonFile = async geojsonLayerFileData => {
    const shapeData = await getGeoJsonData(geojsonLayerFileData);
    const [latitude, longitude] = getCenterFromMultistring(shapeData);

    const locationElevation = await getElevationToLocation(latitude, longitude);

    return {
      shapeData,
      kmzLayerPlaneData: null,
      locationCoordinates: [latitude, longitude],
      locationElevation,
    };
  };

  const getTerrainDataByKMZLayerFile = async kmzLayerFile => {
    const { latitude, longitude } = await getCenterFromKMZ(kmzLayerFile);
    const locationElevation = await getElevationToLocation(latitude, longitude);

    const latLonBoxes = await getLatLonBoxesFromKMZ(kmzLayerFile);

    const kmzLayerPlaneData = await Promise.all(
      latLonBoxes.map(async latLonBox => await getLatLonBoxPlaneData(latLonBox))
    );

    return {
      shapeData: null,
      kmzLayerPlaneData,
      locationCoordinates: [latitude, longitude],
      locationElevation,
    };
  };

  const getTerrainDataByLocationCoordinates = async locationCoordinates => {
    const { lat: latitude, lng: longitude } = locationCoordinates;
    const locationElevation = await getElevationToLocation(latitude, longitude);

    return {
      shapeData: null,
      kmzLayerPlaneData: null,
      locationCoordinates: [latitude, longitude],
      locationElevation,
    };
  };

  const getProjectInitialConfig = async ({
    geojsonLayerFileData,
    kmzLayerFile,
    locationCoordinates,
    creatingType,
  }) => {
    setLoading(true);

    let terrainData = null;

    if (
      creatingType === ProjectCreatingType.GeojsonLayer &&
      geojsonLayerFileData
    ) {
      terrainData = await getTerrainDataByGeojsonFile(geojsonLayerFileData);
    } else if (creatingType === ProjectCreatingType.KMZLayer && kmzLayerFile) {
      terrainData = await getTerrainDataByKMZLayerFile(kmzLayerFile);
    } else if (
      creatingType === ProjectCreatingType.Location &&
      locationCoordinates
    ) {
      terrainData = await getTerrainDataByLocationCoordinates(
        locationCoordinates
      );
    }

    setLoading(false);

    if (!terrainData) {
      throw Error("Terrain Data is not exist");
    }

    return {
      terrainData,
      obj: [],
      cameras: [],
      towers: [],
      zoneList: [],
    };
  };

  return { getProjectInitialConfig, loading };
};

import { Box, styled } from "@mui/material";
import { type CSSProperties } from "@mui/material/styles/createMixins";

import { Spinner } from "../../assets/icons/svgAssets";

import "./Loader.css";

interface LoaderInterface {
  background?: string;
}

interface LoaderWrapperInterface {
  background?: string;
}

const Loader = ({ background }: LoaderInterface): JSX.Element => {
  return (
    <LoaderWrapper background={background}>
      <SpinnerWrapper id="spinner">
        <Spinner />
      </SpinnerWrapper>
    </LoaderWrapper>
  );
};

export default Loader;

const LoaderWrapper = styled(Box)(
  ({ background }: LoaderWrapperInterface): CSSProperties => ({
    zIndex: 5,
    width: "100%",
    height: "100%",
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: background ? background : "white",
  })
);

const SpinnerWrapper = styled(Box)(() => ({
  width: "250px",
  height: "250px",
}));

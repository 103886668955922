import { useContext } from "react";
import { ToggleButton } from "@mui/material";

import { DeleteIcon } from "../../../assets/icons/svgAssets";

import { ProjectContext } from "../../../context";

const DeleteCameraButton = (): JSX.Element => {
  const { isLoading, activeCameraId, handleDeleteCameraData } =
    useContext(ProjectContext);

  const handleOnClick = () => {
    handleDeleteCameraData(activeCameraId);
  };

  return (
    <ToggleButton
      color="blue"
      className="icon"
      value="delete"
      disabled={isLoading}
      selected={false}
      onClick={handleOnClick}
    >
      <DeleteIcon />
    </ToggleButton>
  );
};

export default DeleteCameraButton;

import { createContext } from "react";

export interface TowerSideDataInterface {
  towerId: string;
  sideIndex: string;
}

export interface AddCameraContextInterface {
  towerSideData: TowerSideDataInterface | null;
  openAddCameraModal: (data: TowerSideDataInterface) => void;
  closeAddCameraModal: () => void;
}

export const AddCameraContext = createContext<AddCameraContextInterface>(
  {} as AddCameraContextInterface
);

import { CSSInterpolation, ComponentsOverrides, Theme } from "@mui/material";

import { convertToPaletteColor } from "./Theme";

export const ThemeMuiButton: ComponentsOverrides<Theme>["MuiButton"] = {
  root: ({ theme }): CSSInterpolation => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "6px",
    minWidth: "64px",
    padding: "6px 16px",
    border: "1px solid transparent",

    ".MuiLoadingButton-loadingIndicator.MuiLoadingButton-loadingIndicatorEnd": {
      right: "6px",
    },

    ".MuiLoadingButton-loadingIndicator.MuiLoadingButton-loadingIndicatorStart":
      {
        left: "6px",
      },

    ".MuiButton-startIcon": {
      margin: "0px",
    },

    ".MuiButton-endIcon": {
      margin: "0px",
    },

    "&.Mui-disabled": {
      color: theme.palette["grey-dark"],

      "svg path": {
        fill: theme.palette["grey-dark"],
      },
    },
  }),
  primary: ({ theme, ownerState }): CSSInterpolation => {
    const paletteColor = convertToPaletteColor(ownerState.color as string);
    const color = theme.palette[paletteColor];

    return {
      backgroundColor: color.main,
      color: color.contrastText,

      "svg path": {
        fill: color.contrastText,
      },

      "&:hover": {
        backgroundColor: color.light,
      },

      "&:active": {
        backgroundColor: color.dark,
      },

      "&.Mui-disabled": {
        backgroundColor: theme.palette["grey-light"],
      },
    };
  },
  secondary: ({ theme, ownerState }): CSSInterpolation => {
    const paletteColor = convertToPaletteColor(ownerState.color as string);
    const color = theme.palette[paletteColor];

    return {
      backgroundColor: color.lighter,
      color: color.main,

      "svg path": {
        fill: color.main,
      },

      "&:hover": {
        backgroundColor: color.lighter,
        color: color.light,

        "svg path": {
          fill: color.light,
        },
      },

      "&:active": {
        backgroundColor: color.lighter,
        color: color.dark,

        "svg path": {
          fill: color.dark,
        },
      },

      "&.Mui-disabled": {
        backgroundColor: theme.palette["grey-light"],
      },
    };
  },
  outlined: ({ theme, ownerState }): CSSInterpolation => {
    const paletteColor = convertToPaletteColor(ownerState.color as string);
    const color = theme.palette[paletteColor];

    return {
      backgroundColor: color.lighter,
      borderColor: color.main,
      color: color.main,

      "svg path": {
        fill: color.main,
      },

      "&:hover": {
        backgroundColor: color.lighter,
        borderColor: color.light,
        color: color.light,

        "& svg path": {
          fill: color.light,
        },
      },

      "&:active": {
        backgroundColor: color.lighter,
        borderColor: color.dark,
        color: color.dark,

        "& svg path": {
          fill: color.dark,
        },
      },

      "&.Mui-disabled": {
        backgroundColor: theme.palette["grey-light"],
        borderColor: "transparent",
      },
    };
  },
  text: ({ theme, ownerState }): CSSInterpolation => {
    const paletteColor = convertToPaletteColor(ownerState.color as string);
    const color = theme.palette[paletteColor];

    return {
      color: color.main,

      "svg path": {
        fill: color.main,
      },

      "&:hover": {
        color: color.light,

        "svg path": {
          fill: color.light,
        },
      },

      "&:active": {
        color: color.dark,

        "svg path": {
          fill: color.dark,
        },
      },
    };
  },
};

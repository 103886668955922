import { Euler, MathUtils, Vector3, Quaternion } from "three";

import FacilityCameras from "./FacilityCameras";
import FacilityObjects from "./FacilityObjects";
import FacilityTowers from "./FacilityTowers";
import ObjectHighLight from "./ObjectHighLight";
import CamerasZones from "./CamerasZones";

import { createGroup } from "../SceneUtils/CreateMesh";
import { getLatLonFromCoordinates } from "../SceneUtils/CartographicUtils";

import { EntityTypeEnum } from "../../context/ProjectContext/ProjectEntitesTypes";

class SceneFacility3dObjects {
  constructor(
    scene,
    secondaryViewportDimensions,
    sceneOrbitCenter,
    cameraHelpers,
    hdrTexture,
    mapTiles,
    northVector
  ) {
    this.scene = scene;

    this.secondaryViewportDimensions = secondaryViewportDimensions;

    this.sceneOrbitCenter = sceneOrbitCenter;

    this.cameraHelpers = cameraHelpers;

    this.hdrTexture = hdrTexture;

    this.mapTiles = mapTiles;

    this.northVector = northVector;

    this.objectsGroup = createGroup("objectsGroup", this.scene);

    this.facilityObjects = new FacilityObjects(
      this.scene,
      this.objectsGroup,
      this.sceneOrbitCenter,
      this.hdrTexture
    );

    this.facilityTowers = new FacilityTowers(
      this.scene,
      this.objectsGroup,
      this.sceneOrbitCenter
    );

    this.facilityCameras = new FacilityCameras(
      this.scene,
      this.objectsGroup,
      this.cameraHelpers,
      this.secondaryViewportDimensions,
      this.northVector
    );

    this.camerasZones = new CamerasZones(
      this.scene,
      this.facilityCameras,
      this.northVector
    );

    this.objectHighLight = new ObjectHighLight();
  }

  setObjectQuaternionByAngle(id, value = 0) {
    const object = this.facilityObjects.getObjectById(id);
    const tower = this.facilityTowers.getTowerById(id);

    const activeObject = object || tower;

    if (!activeObject) {
      return;
    }

    const rotationAxis = new Vector3(0, 1, 0);

    activeObject.quaternion.copy(activeObject.initialQuaternion);

    activeObject.quaternion
      .multiply(
        new Quaternion().setFromAxisAngle(
          rotationAxis,
          MathUtils.degToRad(-value)
        )
      )
      .normalize();
  }

  getObjectState(object) {
    const tilesGroupMatrix = this.mapTiles.tiles.group.matrixWorld
      .clone()
      .invert();

    const objectWorldPosition = new Vector3();

    object.getWorldPosition(objectWorldPosition);

    const [latitude, longitude] = getLatLonFromCoordinates(
      objectWorldPosition.clone().applyMatrix4(tilesGroupMatrix)
    );

    const angle = this.getObjectAngleState(object);

    const state = {
      position: {
        x: objectWorldPosition.x,
        y: objectWorldPosition.y,
        z: objectWorldPosition.z,
      },
      rotation: {
        x: object.rotation.x,
        y: object.rotation.y,
        z: object.rotation.z,
      },
      coordinates: {
        latitude,
        longitude,
      },
      initialQuaternion: object.initialQuaternion.toArray(),
      angle,
    };

    return state;
  }

  getObjectAngleState(object) {
    const quaternion1 = object.initialQuaternion.clone();
    const quaternion2 = object.quaternion.clone();

    const angleInRad = quaternion1.angleTo(quaternion2);

    const euler = new Euler().setFromQuaternion(
      quaternion1.multiply(quaternion2)
    );

    const direction = euler.y > 0 ? -1 : 1;

    const angleInDeg = MathUtils.radToDeg(angleInRad * direction);

    return Math.round(angleInDeg);
  }

  toggleActiveObjectHighlight(
    activeObjectsListId,
    activeTowerSideIndex,
    activeCameraId,
    hoveredObjectId,
    hoveredTowerSideIndex,
    hoveredCameraId
  ) {
    // Selected objects.
    const selectedObjects = activeObjectsListId.map(id => {
      return (
        this.facilityObjects.getObjectById(id) ||
        this.facilityTowers.getTowerById(id)
      );
    });

    const isSinglTowerSelected =
      selectedObjects.length === 1 &&
      selectedObjects[0].EntityType === EntityTypeEnum.Tower;

    const selectedTowerSide =
      isSinglTowerSelected &&
      this.facilityCameras.getSideByIndexInTower(
        activeTowerSideIndex,
        selectedObjects[0]
      );
    const selectedCamera =
      selectedTowerSide && this.facilityCameras.getCameraById(activeCameraId);

    const selected = {
      selectedObjects,
      selectedTowerSide,
      selectedCamera,
    };

    // Hovered objects.
    const objectHov = this.facilityObjects.getObjectById(hoveredObjectId);
    const towerHov = this.facilityTowers.getTowerById(hoveredObjectId);

    const hoveredObject = objectHov || towerHov;
    const hoveredTowerSide =
      towerHov &&
      this.facilityCameras.getSideByIndexInTower(
        hoveredTowerSideIndex,
        towerHov
      );
    const hoveredCamera =
      hoveredTowerSide && this.facilityCameras.getCameraById(hoveredCameraId);

    const hovered = {
      hoveredObject,
      hoveredTowerSide,
      hoveredCamera,
    };

    this.objectHighLight.toggleHighlight(selected, hovered);
  }
}

export default SceneFacility3dObjects;

import { useMemo } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import MainPaperWrapper from "../../../../../common/components/item/MainPaperWrapper";
import BreadcrumbNavigation from "../../../../../common/components/tabs/BreadcrumbNavigation";
import usePlateManagementNavigationRoute from "../../../../../common/hooks/usePlateManagementNavigationRoute";
import { RouteEnum } from "../../../../../common/models/enums";
import StyledLoadingButton from "../../../../../common/providers/theme/design-tokens/LoadingButton/StyledLoadingButton";
import {
  errorNotification,
  successNotification,
} from "../../../../../common/variables/notification";
import { useCreateLicensePlate } from "../hooks/useCreateLicensePlate";
import { IPlateNumberForm } from "../variables/plateNumberData";
import PlateNumberForm from "./PlateNumberForm";

const CreatePlateNumberPage = (): JSX.Element => {
  const navigate = useNavigate();

  const { getNavigationRoute } = usePlateManagementNavigationRoute();

  const recentActivitesPlatesManagementNav = getNavigationRoute(
    RouteEnum.RecentActivitiesPlatesManagement,
    RouteEnum.SensoryRecentActivitiesPlatesManagement
  );

  const gateGuardNav = getNavigationRoute(RouteEnum.Agg, RouteEnum.SensoryAgg);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IPlateNumberForm>();

  const { createLicensePlate, loading } = useCreateLicensePlate();

  const handleCancel = (): void => {
    reset();

    navigate(recentActivitesPlatesManagementNav);
  };

  const onSubmit: SubmitHandler<IPlateNumberForm> = data => {
    reset();

    createLicensePlate(
      data.plateNumber,
      data.category?.value ?? "",
      data.companyName,
      data.driverName
    )
      .then((result): void => {
        if (result.errors) {
          console.error(result.errors);
          const { message } = result.errors as any;
          const didConditionalCheckFail = message.includes(
            "ConditionalCheckFailed"
          );

          if (didConditionalCheckFail) {
            errorNotification(`Duplicate Plate: ${data.plateNumber}`);
          } else {
            errorNotification("License plate failed to create");
          }

          return;
        }

        successNotification("License Plate Created!");

        navigate(recentActivitesPlatesManagementNav);
      })
      .catch((error: Error): void => {
        errorNotification(error.message);

        console.error(error);
      });
  };

  const breadcrumbItems = useMemo(
    () => [
      {
        label: "Recent events",
        path: recentActivitesPlatesManagementNav,
      },
      {
        label: "Gate Guard events",
        path: gateGuardNav,
      },
      {
        label: "Plates Management",
        path: recentActivitesPlatesManagementNav,
      },
      {
        label: "Add new plate number",
      },
    ],
    []
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <BreadcrumbNavigation items={breadcrumbItems} />

      <Typography variant="h3" sx={{ padding: "1em 0" }}>
        Add new plate number
      </Typography>

      <MainPaperWrapper>
        <PlateNumberForm control={control} errors={errors} />
      </MainPaperWrapper>

      <Stack direction="row-reverse" spacing={2} sx={{ paddingTop: 3 }}>
        <StyledLoadingButton
          sx={{
            marginTop: "1.5em",
          }}
          type="submit"
          loading={loading}
          variant="contained"
          color="primary"
        >
          Add new plate number
        </StyledLoadingButton>

        <StyledLoadingButton
          sx={{
            marginTop: "1.5em",
          }}
          loading={loading}
          variant="outlined"
          color="inherit"
          onClick={handleCancel}
        >
          Cancel
        </StyledLoadingButton>
      </Stack>
    </form>
  );
};

export default CreatePlateNumberPage;

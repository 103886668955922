import {
  Box,
  Button,
  List,
  ListItem,
  Stack,
  Typography,
  styled,
  useTheme,
} from "@mui/material";

import ModalWindow from "../../../common/components/ModalWindow";

import { WarningIcon } from "../../../common/assets/icons/svgAssets";

import { DTObjectDeletionRestrictionData } from "../context/DTObjectDeletionRestrictionModalContext/DTObjectDeletionRestrictionModalContext";

interface DTObjectDeletionRestrictionModalInterface {
  handleOnClose: () => void;
  data: DTObjectDeletionRestrictionData;
}

const DTObjectDeletionRestrictionModal = ({
  data,
  handleOnClose,
}: DTObjectDeletionRestrictionModalInterface): JSX.Element => {
  const theme = useTheme();

  const onClose = (): void => {
    handleOnClose();
  };

  return (
    <ModalWindow isOpen={true} onClose={onClose}>
      <ModalWrapper>
        <HeaderWrapper>
          <IconContainer>
            <WarningIcon fillColor={theme.palette.yellow.main} />
          </IconContainer>

          <Typography variant="h2">Deletion Restriction</Typography>
        </HeaderWrapper>

        <MessageWrapper>
          <Message variant="body14Regular">
            <ObjectName variant="body14Regular">{`"${data.objectData.objectName}"`}</ObjectName>{" "}
            is already used in project and could not be deleted.
          </Message>
        </MessageWrapper>

        <ProjectList>
          {data.alreadyUseInProjects.map(projectData => (
            <ProjectListItem key={projectData.projectId}>
              <ListItemText variant="body14Medium">
                {projectData.displayName}
              </ListItemText>
            </ProjectListItem>
          ))}
        </ProjectList>

        <Stack direction="row" spacing={1} justifyContent="flex-end" pt="16px">
          <Button variant="primary" color="blue" onClick={onClose}>
            Okay
          </Button>
        </Stack>
      </ModalWrapper>
    </ModalWindow>
  );
};

export default DTObjectDeletionRestrictionModal;

const ModalWrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "10px",
}));

const HeaderWrapper = styled(Box)(() => ({
  position: "relative",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  paddingLeft: "40px",
}));

const IconContainer = styled(Box)(() => ({
  position: "absolute",
  display: "flex",
  alignItems: "center",
  left: 0,
  width: "24px",
}));

const MessageWrapper = styled(Box)(() => ({
  display: "flex",
}));

const ObjectName = styled(Typography)(({ theme }) => ({
  display: "inline-block",
  color: theme.palette.blue.main,
}));

const Message = styled(Typography)(() => ({
  display: "inline-block",
}));

const ProjectList = styled(List)(() => ({
  padding: "4px 0px",
  maxHeight: "160px",
  overflowY: "auto",
}));

const ProjectListItem = styled(ListItem)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  padding: "8px 0px",

  borderTop: `1px solid ${theme.palette["grey-light"]}`,
  "&:last-child": {
    borderBottom: `1px solid ${theme.palette["grey-light"]}`,
  },
}));

const ListItemText = styled(Typography)(({ theme }) => ({
  color: theme.palette["base-label"],
}));

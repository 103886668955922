import { MouseEvent, useContext, useEffect, useState } from "react";
import { Box, Collapse, ListItem, styled } from "@mui/material";

import TowerPanelListItemHeader from "./TowerPanelListItemHeader";
import TowerSideList from "./TowerSideList";

import { ProjectContext } from "../../../context";

import {
  ModeTypeEnum,
  TowerDataInterface,
} from "../../../context/ProjectContext/ProjectEntitesTypes";

interface TowerListItemInterface {
  data: TowerDataInterface;
}

interface StyledListItemProps {
  withbackground: "true" | "false";
}

const TowerListItem = ({ data }: TowerListItemInterface): JSX.Element => {
  const {
    activeObjectsListId,
    sceneStarted,
    handleSetActiveObjectId,
    activeTowerSideIndex,
    handleSetHoveredObjectId,
    mode,
  } = useContext(ProjectContext);

  const [open, setOpen] = useState<boolean>(false);

  const handleOnItemClick = (e: MouseEvent<HTMLElement>) => {
    if (sceneStarted && mode === ModeTypeEnum.standard) {
      const isMultipleSelection = e.ctrlKey || e.metaKey;

      handleSetActiveObjectId(data.id, isMultipleSelection);
    }
  };

  const handleOnCollapseIconClick = (e: MouseEvent<HTMLElement>) => {
    if (!sceneStarted || mode !== ModeTypeEnum.standard) return;

    const isMultipleSelection = e.ctrlKey || e.metaKey;

    handleSetActiveObjectId(data.id, isMultipleSelection);

    setOpen(prev => (isMultipleSelection ? false : !prev));
  };

  const handleOnItemOver = () => {
    if (sceneStarted && mode === ModeTypeEnum.standard) {
      handleSetHoveredObjectId(data.id);
    }
  };

  const handleItemLeaved = () => {
    sceneStarted && handleSetHoveredObjectId(null);
  };

  const isActive =
    activeObjectsListId.length > 0 && activeObjectsListId.includes(data.id);
  const isSelected = isActive && !activeTowerSideIndex;

  useEffect(() => {
    if (!isActive || activeObjectsListId.length > 1) {
      setOpen(false);
    } else if (isActive && activeTowerSideIndex) {
      setOpen(true);
    }
  }, [isActive, activeTowerSideIndex, activeObjectsListId]);

  return (
    <StyledListItem
      onMouseOver={handleOnItemOver}
      onMouseLeave={handleItemLeaved}
      withbackground={isActive ? "true" : "false"}
    >
      <TowerPanelListItemHeader
        withCollapseIcon
        onCollapseIconClick={handleOnCollapseIconClick}
        open={open}
        selected={isSelected}
        text={data.name}
        onItemClick={handleOnItemClick}
      />
      <StyledCollapse in={open} timeout="auto">
        <ChildrenListWrapper>
          <TowerSideList towerData={data} />
        </ChildrenListWrapper>
      </StyledCollapse>
    </StyledListItem>
  );
};

export default TowerListItem;

const StyledListItem = styled(ListItem)<StyledListItemProps>(
  ({ theme, withbackground }) => ({
    width: "100%",
    display: "flex",
    flexDirection: "column",
    padding: "0px",
    borderRadius: "4px",

    background:
      withbackground === "true"
        ? theme.palette["tower-selected-background"]
        : "transparent",
  })
);

const StyledCollapse = styled(Collapse)(() => ({
  width: "100%",
}));

const ChildrenListWrapper = styled(Box)(() => ({
  padding: "9px 0 0 24px",
}));

import { Theme } from "@mui/material/styles";

export const circleArrowSvgIconStyles = {
  sx: {
    cursor: "pointer",
    "& circle": {
      color: "transparent",
      stroke: (theme: Theme) => theme.palette.otherOutlineBorder.main,
    },
    "& path": {
      fill: "none",
      stroke: (theme: Theme) => theme.palette.otherTextTertiary.main,
    },
    "&:hover circle": {
      stroke: (theme: Theme) => theme.palette.otherTextTertiary.main,
    },
    "&:hover path": {
      stroke: (theme: Theme) => theme.palette.text.secondary,
    },
  },
};

import { useMemo } from "react";
import { useQuery } from "@apollo/client";

import type {
  GetCustomerCountersQuery,
  GetCustomerCountersQueryVariables,
} from "../../API";
import { GET_CUSTOMER_COUNTERS } from "../../common/operations/queries";
import { useCustomerIdGuard } from "../../common/hooks/useCustomerIdGuard";

export const useGetCustomerCounters = () => {
  const customerId = useCustomerIdGuard();

  const { data, loading, error } = useQuery<
    GetCustomerCountersQuery,
    GetCustomerCountersQueryVariables
  >(GET_CUSTOMER_COUNTERS, {
    variables: {
      customerId,
    },
    skip: !customerId,
    fetchPolicy: "cache-and-network",
    onError: error => {
      console.error("GetCustomer", error);
    },
  });

  const customerCounters = useMemo(
    () => data?.getCustomerCounters,
    [data?.getCustomerCounters]
  );

  return { customerCounters, loading, error } as const;
};

import { MathUtils, Vector3 } from "three";
import { OrbitControls } from "three/addons/controls/OrbitControls.js";

import { getRaycastIntersectsPointByFromToVectors } from "../SceneUtils/RaycastUtils";

class CustomOrbitControl extends OrbitControls {
  constructor(camera, domElement) {
    super(camera, domElement);

    this.enableDamping = true;

    this.dampingFactor = 0.05;

    this.enablePan = true;

    this.screenSpacePanning = false;

    this.minZoom = 1;

    this.maxZoom = 5;

    const initTargetPosition = new Vector3(0, 0, 0);

    this.setDefaultCameraLimitation();

    this.setOrbitCameraTarget(initTargetPosition);

    this.setCameraPosition(300, 25, 75);
  }

  setDefaultCameraLimitation() {
    this.setMinMaxDistance(10, 500);

    this.setMinMaxAzimuthAngle(Infinity, Infinity);

    this.setMinMaxPolarAngle(0, 75);
  }

  setOrbitCameraTarget(targetPosition) {
    this.target.copy(targetPosition);
  }

  setCameraPosition(
    distance,
    azimuthInDeg,
    polarInDeg,
    withDefaultLimitation = true
  ) {
    this.setMinMaxDistance(distance, distance);

    this.setMinMaxAzimuthAngle(azimuthInDeg, azimuthInDeg);

    this.setMinMaxPolarAngle(polarInDeg, polarInDeg);

    this.update();

    withDefaultLimitation && this.setDefaultCameraLimitation();
  }

  setMinMaxPolarAngle(minPolarAngleInDeg, maxPolarAngleInDeg) {
    this.minPolarAngle = isFinite(minPolarAngleInDeg)
      ? MathUtils.degToRad(minPolarAngleInDeg)
      : minPolarAngleInDeg;

    this.maxPolarAngle = isFinite(maxPolarAngleInDeg)
      ? MathUtils.degToRad(maxPolarAngleInDeg)
      : maxPolarAngleInDeg;
  }

  setMinMaxAzimuthAngle(minAzimuthAngleInDeg, maxAzimuthAngleInDeg) {
    this.minAzimuthAngle = isFinite(minAzimuthAngleInDeg)
      ? MathUtils.degToRad(minAzimuthAngleInDeg)
      : minAzimuthAngleInDeg;

    this.maxAzimuthAngle = isFinite(maxAzimuthAngleInDeg)
      ? MathUtils.degToRad(maxAzimuthAngleInDeg)
      : maxAzimuthAngleInDeg;
  }

  setMinMaxDistance(minDistance, maxDistance) {
    this.minDistance = minDistance;

    this.maxDistance = maxDistance;
  }

  addControlTargetPositionLimitation(bbox, objectToIntersect) {
    const prevOrbitControlTarget = this.target.clone();

    const limitTargetPosition = () => {
      if (prevOrbitControlTarget.equals(this.target)) {
        return;
      }

      this.target.clamp(bbox.min, bbox.max);

      const fromPoint = this.target.clone();

      fromPoint.y += 1000;

      const toPoint = this.target.clone();

      toPoint.y -= 1000;

      const intersects = getRaycastIntersectsPointByFromToVectors(
        objectToIntersect,
        fromPoint,
        toPoint
      );

      if (intersects && intersects.length > 0) {
        const point = intersects.at(-1).point;

        this.target.copy(point);
      }

      prevOrbitControlTarget.copy(this.target);
    };

    this.addEventListener("change", limitTargetPosition);
  }
}

export default CustomOrbitControl;

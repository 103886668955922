import { useTheme } from "@mui/material";

const ToastErrorIcon = (): JSX.Element => {
  const theme = useTheme();

  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width="31"
        height="31"
        rx="7.5"
        fill={theme.palette.error.shades?.["4p"]}
      />
      <g clipPath="url(#clip0_125_39129)">
        <circle
          cx="16"
          cy="16.0003"
          r="8.33333"
          stroke={theme.palette.error.main}
          strokeWidth="1.5"
        />
        <path
          d="M16 11.833V16.833"
          stroke={theme.palette.error.main}
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <ellipse
          cx="16"
          cy="19.3333"
          rx="0.833333"
          ry="0.833333"
          fill={theme.palette.error.main}
        />
      </g>
      <rect
        x="0.5"
        y="0.5"
        width="31"
        height="31"
        rx="7.5"
        stroke={theme.palette.error.shades?.["30p"]}
      />
      <defs>
        <clipPath id="clip0_125_39129">
          <rect
            width="20"
            height="20"
            fill={theme.palette.otherBackgroundLight.main}
            transform="translate(6 6)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ToastErrorIcon;

import { FC } from "react";

import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useConfirm } from "material-ui-confirm";

import ExportToCsvButton from "../../../common/components/button/ExportToCsvButton";
import confirmDialogStyleOptions from "../../../common/helpers/confirmDialogParams";

import { DeleteButton } from "../../../common/components/button/DeleteButton";
import { useBatchDeletVideos } from "../../hooks/useBatchDeleteVideos";
import useCSVDownloader from "../../hooks/useCSVDownloader";
import useConvertArrayToCSV from "../../hooks/useConvertArrayToCSV";
import { useDeleteClientGroup } from "../../hooks/useDeleteClientGroup";
import { useGetAllVideosByGroup } from "../../hooks/useGetAllVideosByGroup";
import { VideosTableSelectionState } from "../../store/useVideosTableSelectionReducer";
import useDeleteS3File from "../../hooks/useDeleteS3File";

const columns = [
  { field: "videoName", headerName: "Video Name" },
  { field: "geometry", headerName: "Geometry" },
  { field: "fov", headerName: "FOV" },
  { field: "windspeed", headerName: "Windspeed" },
  { field: "outputMeasurement", headerName: "Output Measurement (MCF/d)" },
  { field: "videoMethod", headerName: "Video Method" },
  { field: "notes", headerName: "Notes" },
  { field: "groupName", headerName: "Group" },
  { field: "clientName", headerName: "Client" },
];

interface ISelectedRowsFooterProps {
  state: VideosTableSelectionState;
  deleteGroup: (groupId: string, clientId: string) => void;
}

const SelectedRowsFooter: FC<ISelectedRowsFooterProps> = ({
  state,
  deleteGroup,
}) => {
  const rows = state.selectedRows.flatMap(row => {
    return row.videos.map(video => {
      return {
        ...video,
        groupName: row.groupName,
        clientName: row.clientName,
      };
    });
  });

  const checked = rows.length > 0;

  const { convertArrayToCSV } = useConvertArrayToCSV();

  const { deleteFile } = useDeleteS3File();

  const { deleteSelectedVideos } = useBatchDeletVideos();
  const { deleteClientGroup } = useDeleteClientGroup();
  const { fetchAllVideosByGroup } = useGetAllVideosByGroup();

  const { downloadCSV } = useCSVDownloader();
  const confirm = useConfirm();

  const handleDownloadClick = () => {
    if (rows.length > 0) {
      const csvString = convertArrayToCSV(rows, columns);

      downloadCSV(csvString, "checked_rows.csv");
    } else {
      console.log("No rows selected");
    }
  };

  const isAllVideosBelongToOneGroup = rows.every(
    video => video.groupId === rows[0].groupId
  );

  const handleDeleteClick = () => {
    if (isAllVideosBelongToOneGroup) {
      const preparedVideosBeforeDeleting = [...rows].map(el => ({
        id: el.id,
        groupId: el.groupId,
        videoState: el.videoState,
        sourceS3Key: el.sourceS3Key,
        modelOutputS3Key: el.modelOutputS3Key,
      }));

      const deleteVideosOneByOne = () => {
        preparedVideosBeforeDeleting.forEach(video => {
          deleteFile(video.sourceS3Key);

          if (video.modelOutputS3Key) deleteFile(video.modelOutputS3Key);
        });
      };

      confirm({
        title: `Are you sure you want to delete ${rows.length} selected uploads?`,
        confirmationText: "Delete",
        cancellationText: "Cancel",
        ...confirmDialogStyleOptions,
        confirmationButtonProps: {
          ...confirmDialogStyleOptions.confirmationButtonProps,
        },
      }).then(async () => {
        const fatchResult = await fetchAllVideosByGroup(rows[0].groupId);

        const videos = fatchResult?.data?.getAllVideosByGroup.items;

        const videoLength = videos?.length;

        if (videoLength === rows.length) {
          deleteClientGroup({
            groupId: rows[0].groupId,
            clientId: rows[0].clientId,
          })
            .then(res => {
              if (!res.errors) {
                deleteGroup(rows[0].clientId, rows[0].groupId);
              } else {
                new Error(`${res.errors}`);
              }
            })
            .catch(err => {
              console.error("Error deleting group", err);
            });
        } else {
          await rows.forEach(async video => {
            await deleteFile(video.sourceS3Key);
          });

          const videosToDeleting = preparedVideosBeforeDeleting.map(el => ({
            id: el.id,
            groupId: el.groupId,
            videoState: el.videoState,
          }));

          await deleteSelectedVideos(videosToDeleting);

          deleteVideosOneByOne();
        }
      });
    }
  };

  return (
    <Box
      sx={{
        visibility: checked ? "visible" : "hidden",
        display: "flex",
        position: "fixed",
        bottom: 0,
        width: "100%",
        height: "40px",
        zIndex: 1000,
        marginLeft: "-2em",
      }}
    >
      <Fade
        in={checked}
        timeout={{
          enter: 500,
          exit: 500,
        }}
      >
        <Paper
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "1em",
            backgroundColor: theme => theme.palette.otherBackgroundLight.main,
            borderTop: theme =>
              `1px solid ${theme.palette.otherOutlineBorder.main}`,
          }}
        >
          <Typography variant="body2" color="text.secondary">
            {rows.length} selected uploads
          </Typography>
          <ExportToCsvButton
            disableRipple
            variant="text"
            sx={{
              color: theme => theme.palette.text.primary,
            }}
            onClick={handleDownloadClick}
          />
          {isAllVideosBelongToOneGroup && (
            <DeleteButton
              disableRipple
              variant="text"
              onClick={handleDeleteClick}
            />
          )}
        </Paper>
      </Fade>
    </Box>
  );
};

export default SelectedRowsFooter;

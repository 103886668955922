import { useState } from "react";

import Filter3dObjectsButton from "./Filter3dObjectsButton";
import Filter3dObjectsForm from "./Filter3dObjectsForm";

const Filter3dObjects = (): JSX.Element => {
  const [isOpenFilterForm, setIsOpenFilterForm] = useState(false);

  const handleOnOpenFilterForm = () => {
    setIsOpenFilterForm(true);
  };

  const handleOnCloseFilterForm = () => {
    setIsOpenFilterForm(false);
  };

  return (
    <>
      <Filter3dObjectsButton onClick={handleOnOpenFilterForm} />
      {isOpenFilterForm && (
        <Filter3dObjectsForm onClose={handleOnCloseFilterForm} />
      )}
    </>
  );
};

export default Filter3dObjects;

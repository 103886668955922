import {
  ConfiguratorPermissionContextProvider,
  ProjectContextProvider,
  ProjectObjects3dContextProvider,
  WeatherConditionsContextProvider,
} from "../../common/context";
import {
  AddCameraContextProvider,
  DTObjectDeletionRestrictionModalContextProvider,
  EditDTObjectContextProvider,
  SceneObject3dContextMenuContextProvider,
  SearchFilterGroupObjectsContextProvider,
} from "./context";

import ProjectEditor from "./components/ProjectEditor";

const WebglConfiguratorProjectEditorPage = (): JSX.Element => {
  return (
    <ConfiguratorPermissionContextProvider>
      <ProjectContextProvider>
        <WeatherConditionsContextProvider>
          <ProjectObjects3dContextProvider>
            <SearchFilterGroupObjectsContextProvider>
              <EditDTObjectContextProvider>
                <DTObjectDeletionRestrictionModalContextProvider>
                  <AddCameraContextProvider>
                    <SceneObject3dContextMenuContextProvider>
                      <ProjectEditor />
                    </SceneObject3dContextMenuContextProvider>
                  </AddCameraContextProvider>
                </DTObjectDeletionRestrictionModalContextProvider>
              </EditDTObjectContextProvider>
            </SearchFilterGroupObjectsContextProvider>
          </ProjectObjects3dContextProvider>
        </WeatherConditionsContextProvider>
      </ProjectContextProvider>
    </ConfiguratorPermissionContextProvider>
  );
};

export default WebglConfiguratorProjectEditorPage;

import { createContext } from "react";

export type LocationCoordinatesType = Array<number> | undefined;

export interface WeatherConditionsContextInterface {
  weatherVisibility: boolean;
  defaultLocation: LocationCoordinatesType;
  location: LocationCoordinatesType;
  setCurrentLocation: (center: LocationCoordinatesType) => void;
  toggleWeatherVisibility: () => void;
  data: WeatherDataInterface;
  weatherShortInfo: WeatherShortInfoInterface;
}

export interface WeatherDataInterface {
  temp: number;
  wind: {
    speed: number;
    deg: number;
    gust: number;
  };
  clouds: number;
  rain?: number;
  snow?: number;
  description: string;
  visibility: number;
}

export interface WeatherShortInfoInterface {
  temp: number;
  windSpeed: number;
}

export const WeatherConditionsContext =
  createContext<WeatherConditionsContextInterface>(
    {} as WeatherConditionsContextInterface
  );

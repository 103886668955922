import { useContext, useEffect } from "react";
import { Button } from "@mui/material";

import { ProjectContext } from "../../context";

import { EyeOffIcon, EyeOnIcon } from "../../assets/icons/svgAssets";

const SketchplanButton = () => {
  const {
    projectData,
    sceneStarted,
    isLoading,
    sketchplanState,
    setSketchplanState,
    toggleSketchplanState,
  } = useContext(ProjectContext);

  useEffect(() => {
    projectData &&
      setSketchplanState(
        !!projectData.configData.terrainData.shapeData ||
          !!projectData.kmzLayerURL
      );
  }, [projectData]);

  const disabled =
    !sceneStarted ||
    isLoading ||
    (!projectData?.configData.terrainData.shapeData &&
      !projectData?.kmzLayerURL);

  return (
    <Button
      sx={{
        "& svg": {
          width: "16px",
        },
      }}
      variant={sketchplanState ? "outlined" : "primary"}
      color="blue"
      onClick={toggleSketchplanState}
      disabled={disabled}
      startIcon={sketchplanState ? <EyeOffIcon /> : <EyeOnIcon />}
    >
      Sketchplan
    </Button>
  );
};

export default SketchplanButton;

import { Box } from "@mui/material";
import confirmDialogStyleOptions from "../../../common/helpers/confirmDialogParams";

import { Annotation } from "../../../API";
import ItemDetailTable, {
  DataTableRowItem,
} from "../../../common/components/item/ItemDetailTable";
import { useDeleteAnnotation } from "../hooks/useDeleteAnnotation";
import { useConfirm } from "material-ui-confirm";
import { successNotification } from "../../../common/variables/notification";
import { useSyncShadow } from "../../model-manager/hooks/useSyncShadow";

type AnnotationListProps = {
  handleOpenDialog: (item: Annotation) => void;
  annotationsData: Array<Annotation>;
  annotationsType: string;
  nodeId: string;
};

const AnnotationList: React.FC<AnnotationListProps> = ({
  annotationsData,
  annotationsType,
  handleOpenDialog,
  nodeId,
}) => {
  const { deleteAnnotation, loading } = useDeleteAnnotation();

  const confirm = useConfirm();
  const { syncShadowsForNode } = useSyncShadow();

  const deleteAnnotationConfirm = (exclusionData: Annotation): void => {
    confirm({
      title: `Are you sure you want to delete ${exclusionData.name}?`,
      confirmationText: "Delete",
      cancellationText: "Cancel",
      ...confirmDialogStyleOptions,
      confirmationButtonProps: {
        ...confirmDialogStyleOptions.confirmationButtonProps,
        disabled: loading,
      },
    }).then(async (): Promise<void> => {
      await deleteAnnotation({
        zoneId: exclusionData.zoneId,
        serviceId: exclusionData.serviceId,
        id: exclusionData.id ?? "",
      });

      successNotification(`${annotationsType} is deleted`);

      syncShadowsForNode(nodeId);
    });
  };

  const annotationsTableParse: DataTableRowItem[] = annotationsData.map(
    (item): DataTableRowItem => {
      switch (item.annotationType) {
        case "TANK_LEVEL":
          return {
            label: "TANK NAME",
            value: item.name,
            additionalLabel: "TANK HEIGHT (INCHES)",
            additionalValue: item.tankHeight,
            onEditHandler: (): void => handleOpenDialog(item),
            onDeleteHandler: (): void => deleteAnnotationConfirm(item),
          };
        // Add more cases here
        default:
          return {
            label: "NAME",
            value: item.name,
            onEditHandler: (): void => handleOpenDialog(item),
            onDeleteHandler: (): void => deleteAnnotationConfirm(item),
          };
      }
    }
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box>
        <ItemDetailTable
          withSeparateBackgroundRows
          data={annotationsTableParse}
        />
      </Box>
    </Box>
  );
};

export default AnnotationList;

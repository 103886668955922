import { List, styled } from "@mui/material";

import TowerSideListItem from "./TowerSideListItem";

import {
  SideDataInterface,
  TowerDataInterface,
} from "../../../context/ProjectContext/ProjectEntitesTypes";

interface TowerSideListInterface {
  towerData: TowerDataInterface;
}

const TowerSideList = ({ towerData }: TowerSideListInterface): JSX.Element => {
  return (
    <StyledList>
      {towerData.sides.map((sideData: SideDataInterface) => (
        <TowerSideListItem
          key={`${towerData.id}-${sideData.index}`}
          sideData={sideData}
          towerId={towerData.id}
        />
      ))}
    </StyledList>
  );
};

export default TowerSideList;

const StyledList = styled(List)(() => ({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  padding: "0px",
  gap: "15px",
}));

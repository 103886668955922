import Konva from "konva";
import { useEffect, useRef } from "react";
import { Annotation, AnnotationType } from "../../../API";
import {
  HEX_OPACITY_30,
  PointsFormat,
  colorOfAnnotationType,
  figureStyleLoyers,
} from "./utils";
import { useTheme } from "@mui/material";
import { SimpleAnnotation } from "../../../leak-finder/pages/UploadVideoGroupPage";

const useCreateFullAnnotationContainer = (
  annotationsData: Annotation[] | SimpleAnnotation[],
  scaleX: number,
  scaleY: number
) => {
  const theme = useTheme();
  const fullAnnContainerRef = useRef<Konva.Layer>(null);

  useEffect((): void => {
    if (fullAnnContainerRef.current) {
      fullAnnContainerRef.current.removeChildren();
    }

    annotationsData.forEach((annotation): void => {
      let points: PointsFormat = [];
      let annotationType = AnnotationType.FOCUS_AREA_LF;

      try {
        if ("polygon" in annotation) {
          const polygon = JSON.parse((annotation.polygon as string) ?? "");

          points = polygon.annotations.map((p: number[]) => [
            p[0] / (scaleX ? scaleX : 1),
            p[1] / (scaleY ? scaleX : 1),
          ]);

          annotationType = annotation.annotationType;
        }

        if ("points" in annotation) {
          points = annotation.points.map((p: number[]) => [
            p[0] / scaleX,
            p[1] / scaleY,
          ]);
        }
      } catch (error) {
        console.error(error);
      }

      if (!points.length) return;

      const fillColor = colorOfAnnotationType(annotationType, theme);

      const { solidFillLayer, patternLayer } = figureStyleLoyers(
        fillColor,
        points
      );

      const firstPoint = points[0];

      let annotationText = annotation.name;

      const limitOfAnnName = 20;

      if (annotation.name.length > limitOfAnnName) {
        annotationText = annotation.name.slice(0, limitOfAnnName) + "...";
      }

      const label = new Konva.Text({
        x: firstPoint[0],
        y: firstPoint[1],
        text: annotationText,
        fontSize: 12,
        fontFamily: "Calibri",
        fill: fillColor,
        padding: 3,
      });

      const labelBackground = new Konva.Rect({
        x: firstPoint[0],
        y: firstPoint[1],
        width: label.width(),
        height: label.height(),
        fill: "white",
        cornerRadius: 10,
        stroke: fillColor + HEX_OPACITY_30,
        strokeWidth: 1,
      });

      if (fullAnnContainerRef.current) {
        fullAnnContainerRef.current.add(solidFillLayer);

        fullAnnContainerRef.current.add(patternLayer);

        fullAnnContainerRef.current.add(labelBackground);

        fullAnnContainerRef.current.add(label);
      }
    });
  }, [annotationsData, scaleX, scaleY]);

  return { fullAnnContainerRef };
};

export default useCreateFullAnnotationContainer;

import {
  MathUtils,
  CircleGeometry,
  BufferGeometry,
  MeshBasicMaterial,
  Mesh,
  Vector3,
  Matrix4,
  DoubleSide,
  Quaternion,
  BufferAttribute,
} from "three";

import { EntityTypeEnum } from "../../context/ProjectContext/ProjectEntitesTypes";
import { CAMERA_ZONES_RENDER_ORDER, ZONE_RADIUS_IN_M } from "../Constants";

import {
  calculateHorizontalFOV,
  calculateVerticalFOV,
  getHorizontalAngleForObjectInDeg,
} from "../SceneUtils/MathUtils";

const _tempQuaternion = new Quaternion();

class CamerasZones {
  constructor(scene, facilityCameras, northVector) {
    this.scene = scene;

    this.facilityCameras = facilityCameras;

    this.northVector = northVector;

    this.zones = [];
  }

  add = (zones360, zoneList, cameras) => {
    this.createMinerva360Zones(zones360);

    this.createZones(zoneList, cameras);
  };

  remove = () => {
    const zoneList = this.scene.getObjectsByProperty(
      "EntityType",
      EntityTypeEnum.CameraZone
    );

    zoneList.forEach(zone => {
      zone.removeFromParent();
    });

    this.zones = [];
  };

  switchZoneVisibility = idList => {
    this.zones.forEach(zone => {
      zone.visible = idList.includes(zone.ID);
    });
  };

  createMinerva360Zones = cameras => {
    cameras.forEach(cameraData => {
      const camera = this.facilityCameras.getCameraById(cameraData.id);

      const fovCircleGeometry = new CircleGeometry(ZONE_RADIUS_IN_M, 32);
      const fovCircleMaterial = new MeshBasicMaterial({
        color: Math.random() * 0xffffff,
        opacity: 0.3,
        depthTest: false,
        depthWrite: false,
        transparent: true,
      });

      const fovCircle = new Mesh(fovCircleGeometry, fovCircleMaterial);

      fovCircle.lookAt(new Vector3(0, 1, 0));

      fovCircle.renderOrder = CAMERA_ZONES_RENDER_ORDER;

      fovCircle.EntityType = EntityTypeEnum.CameraZone;

      camera.add(fovCircle);

      fovCircle.ID = `zone-${cameraData.id}`;

      this.zones.push(fovCircle);
    });
  };

  createZones = (zoneList, cameras) => {
    zoneList.forEach((zoneListData, i) => {
      const camera = this.facilityCameras.getCameraById(zoneListData.cameraId);
      const cameraData = cameras.find(el => el.id === zoneListData.cameraId);

      const perspectiveCamera = camera.getObjectByName("SingleLensCamera");

      const vFOV = calculateVerticalFOV(
        cameraData.fov,
        perspectiveCamera.aspect
      );

      const fovGeometry = this.addCameraFOVsegmentGeometry(
        cameraData.fov,
        zoneListData.tilt,
        vFOV,
        cameraData.height
      );

      const fovMaterial = new MeshBasicMaterial({
        color: Math.random() * 0xff0000,
        side: DoubleSide,
        transparent: true,
        opacity: 0.7,
        depthTest: false,
        depthWrite: false,
      });

      const fovMesh = new Mesh(fovGeometry, fovMaterial);

      fovMesh.renderOrder = CAMERA_ZONES_RENDER_ORDER + 1 + i;

      fovMesh.EntityType = EntityTypeEnum.CameraZone;

      const worldPositionPerspectiveCamera = new Vector3();

      perspectiveCamera.updateMatrixWorld(true);

      worldPositionPerspectiveCamera.setFromMatrixPosition(
        perspectiveCamera.matrixWorld
      );

      const localPositionPerspectiveCamera = camera.worldToLocal(
        worldPositionPerspectiveCamera.clone()
      );

      fovMesh.position.copy(localPositionPerspectiveCamera);

      const cameraPolarInDeg = getHorizontalAngleForObjectInDeg(
        this.northVector,
        camera
      );

      const panRotationRelativeToNorth = zoneListData.pan - cameraPolarInDeg;

      fovMesh.rotateY(MathUtils.degToRad(panRotationRelativeToNorth) * -1);

      camera.add(fovMesh);

      fovMesh.ID = `zone-${zoneListData.id}`;

      this.zones.push(fovMesh);
    });
  };

  addCameraFOVsegmentGeometry = (hFOV, pitchDeg, vFOV, cameraHeight) => {
    const horizontalAlpha = MathUtils.degToRad(hFOV / 2);
    const halphVFov = vFOV / 2;

    const directionVector = new Vector3(0, 0, 1);
    const fovGeometry = new BufferGeometry();

    if (pitchDeg < 0) {
      const alpha = halphVFov + pitchDeg;

      const pitchMatrix = new Matrix4().makeRotationX(
        MathUtils.degToRad(alpha)
      );

      directionVector.applyMatrix4(pitchMatrix);

      const newDistance = cameraHeight / Math.sin(MathUtils.degToRad(-alpha));

      if (alpha < 0 && newDistance < ZONE_RADIUS_IN_M) {
        this.createTriangle(
          directionVector,
          horizontalAlpha,
          newDistance,
          fovGeometry
        );
      } else {
        this.createSector(
          directionVector,
          horizontalAlpha,
          ZONE_RADIUS_IN_M,
          fovGeometry
        );
      }

      return fovGeometry;
    } else {
      // find angle when donw plane on the ground
      const testAngle =
        (90 -
          MathUtils.radToDeg(Math.acos(cameraHeight / ZONE_RADIUS_IN_M)) -
          halphVFov) *
        -1;
      const alpha = pitchDeg < testAngle ? pitchDeg : pitchDeg - halphVFov;

      const pitchMatrix = new Matrix4().makeRotationX(
        MathUtils.degToRad(-alpha)
      );

      directionVector.applyMatrix4(pitchMatrix);

      this.createSector(
        directionVector,
        horizontalAlpha,
        ZONE_RADIUS_IN_M,
        fovGeometry
      );

      return fovGeometry;
    }
  };

  createTriangle = (directionVector, horizontalAlpha, distance, geometry) => {
    const vertexFront = new Vector3(0, 0, 0);
    const leftFOVPoint = this.calculateFOVEdgePoint(
      directionVector,
      -horizontalAlpha,
      distance
    );

    const rightFOVPoint = this.calculateFOVEdgePoint(
      directionVector,
      horizontalAlpha,
      distance
    );

    const triangleVertices = [
      vertexFront,
      leftFOVPoint,
      rightFOVPoint,
      vertexFront,
    ];

    geometry.setFromPoints(triangleVertices);
  };

  createSector = (directionVector, horizontalAlpha, distance, geometry) => {
    const vertexFront = new Vector3(0, 0, 0);
    const leftFOVPoint = this.calculateFOVEdgePoint(
      directionVector,
      -horizontalAlpha,
      distance
    );

    const rightFOVPoint = this.calculateFOVEdgePoint(
      directionVector,
      horizontalAlpha,
      distance
    );

    // sector
    const arcPoints = this.interpolateArcPoints(
      vertexFront,
      leftFOVPoint,
      rightFOVPoint,
      10
    );

    const vertices = [
      vertexFront,
      leftFOVPoint,
      ...arcPoints,
      rightFOVPoint,
      vertexFront,
    ];
    const triangleVertices = new Float32Array(
      vertices.flatMap(v => [v.x, v.y, v.z])
    );

    const indices = [];

    for (let i = 1; i <= arcPoints.length + 1; i++) {
      indices.push(0, i, i + 1);
    }

    geometry.setAttribute("position", new BufferAttribute(triangleVertices, 3));

    geometry.setIndex(indices);

    geometry.computeVertexNormals();
  };

  calculateFOVEdgePoint(directionVector, angle, distance) {
    const edgeVector = directionVector.clone();
    const rotationMatrix = new Matrix4().makeRotationY(angle);

    edgeVector.applyMatrix4(rotationMatrix);

    edgeVector.multiplyScalar(distance);

    edgeVector.y = 0;

    return edgeVector;
  }

  interpolateArcPoints(center, left, right, segments) {
    const points = [];

    const angle = left.angleTo(right);

    for (let i = 0; i <= segments; i++) {
      const t = i / segments;
      const interpolatedAngle = angle * t;

      _tempQuaternion.setFromAxisAngle(
        new Vector3().crossVectors(left, right).normalize(),
        interpolatedAngle
      );

      const point = left.clone().applyQuaternion(_tempQuaternion).add(center);

      points.push(point);
    }

    return points;
  }
}

export default CamerasZones;

import { useContext, useEffect, useState } from "react";
import { Box, Collapse, ListItem, styled } from "@mui/material";

import TowerPanelListItemHeader from "./TowerPanelListItemHeader";
import CameraZoneList from "./CameraZoneList";

import { ProjectContext } from "../../../context";

import {
  CameraDataInterface,
  ModeTypeEnum,
  ZoneDataInterface,
} from "../../../context/ProjectContext/ProjectEntitesTypes";

interface TowerCameraListItemInterface {
  data: CameraDataInterface;
}

const TowerCameraListItem = ({
  data,
}: TowerCameraListItemInterface): JSX.Element => {
  const {
    sceneStarted,
    activeCameraId,
    handleSetActiveCameraId,
    handleSetHoveredCameraId,
    activeZoneId,
    zoneList,
    mode,
  } = useContext(ProjectContext);

  const [open, setOpen] = useState<boolean>();

  const zoneData = zoneList.filter(
    (zone: ZoneDataInterface) => zone.cameraId === data.id
  );

  const handleOnItemClick = (): void => {
    if (sceneStarted && mode === ModeTypeEnum.standard) {
      handleSetActiveCameraId(data.id);
    }
  };

  const handleOnCollapseIconClick = () => {
    if (!sceneStarted || mode !== ModeTypeEnum.standard) return;

    handleSetActiveCameraId(data.id);

    setOpen(prev => !prev);
  };

  const handleOnItemOver = (): void => {
    if (!sceneStarted || mode !== ModeTypeEnum.standard) return;

    handleSetHoveredCameraId(data.id);
  };

  const handleItemLeaved = (): void => {
    sceneStarted && handleSetHoveredCameraId(null);
  };

  const isCameraHasZone = zoneData.length > 0;

  const isCameraHasActiveZone =
    isCameraHasZone &&
    zoneData.find((zone: ZoneDataInterface) => zone.id === activeZoneId);

  const isActive = activeCameraId === data.id;
  const isSelected = isActive && !activeZoneId;

  useEffect(() => {
    if (!isActive) {
      setOpen(false);
    } else if (isCameraHasActiveZone) {
      setOpen(true);
    }
  }, [isActive, isCameraHasActiveZone]);

  return (
    <StyledListItem
      onMouseOver={handleOnItemOver}
      onMouseLeave={handleItemLeaved}
    >
      <TowerPanelListItemHeader
        text={data.name}
        selected={isSelected}
        open={open}
        withCollapseIcon={isCameraHasZone}
        onCollapseIconClick={handleOnCollapseIconClick}
        onItemClick={handleOnItemClick}
      />
      <StyledCollapse in={isCameraHasZone && open} timeout="auto">
        <ChildrenListWrapper>
          <CameraZoneList zoneData={zoneData} />
        </ChildrenListWrapper>
      </StyledCollapse>
    </StyledListItem>
  );
};

export default TowerCameraListItem;

const StyledListItem = styled(ListItem)(() => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  background: "transparent",
  padding: "0px",
}));

const StyledCollapse = styled(Collapse)(() => ({
  width: "100%",
}));

const ChildrenListWrapper = styled(Box)(() => ({
  padding: "9px 0 0 24px",
}));

import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

import { circleArrowSvgIconStyles } from "./CircleSvgIcon.styles";

const CircleArrowUp = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon
      sx={{
        ...circleArrowSvgIconStyles.sx,
      }}
      fill="none"
      {...props}
    >
      <circle cx="12" cy="12" r="10" strokeWidth="1.5" />
      <path
        d="M15 13.5L12 10.5L9 13.5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default CircleArrowUp;

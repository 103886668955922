import * as THREE from "three";

import { loadFBXAsset, loadTexture } from "../SceneUtils/AssetLoaders";
import {
  setMaterial,
  setTexturesToMaterial,
} from "../SceneUtils/MaterilsUtils";

import {
  FACILITY_OBJECT_RENDER_ORDER,
  OBJECT_DEFAULT_COLOR,
} from "../Constants";

class SceneObjectBuilder {
  constructor() {
    this.objectTypes = {};
  }

  async getObjectInstance(objectData, envMap) {
    if (!this.objectTypes[objectData.modelAssets.assetId]) {
      const object = await this.loadModel(objectData.modelAssets.model3d.path);

      object.castShadow = true;

      object.receiveShadow = true;

      await this.updateModelMaterial(
        object,
        objectData.modelAssets.textures,
        envMap
      );

      this.objectTypes[objectData.modelAssets.assetId] = {
        objectToClone: object,
        materialToClone: object.children[0].material,
      };
    }

    return this.objectTypes[objectData.modelAssets.assetId];
  }

  async loadModel(path) {
    const object = await loadFBXAsset(path);

    return object;
  }

  async updateModelMaterial(object, textures, envMap) {
    const material = new THREE.MeshStandardMaterial();

    material.copy(object.children[0].material);

    material.metalness = 0.9;

    material.color = OBJECT_DEFAULT_COLOR;

    material.transparent = true;

    setMaterial(object, material);

    if (envMap) {
      material.envMap = envMap;

      material.envMapIntensity = 2.0;
    }

    if (textures && textures.length > 0) {
      const texturesData = await Promise.all(
        textures.map(async data => {
          const texture = await loadTexture(data.path);

          return {
            mapName: data.mapName,
            texture,
          };
        })
      );

      setTexturesToMaterial(object, texturesData);
    }
  }

  setRotation(object, rotation) {
    rotation && object.rotation.set(rotation.x, rotation.y, rotation.z);
  }

  setPosition(object, position, defaultPosition) {
    position
      ? object.position.copy(position)
      : object.position.copy(defaultPosition);
  }

  setObjectInitialQuaternion(object, initialQuaternion) {
    object.initialQuaternion = initialQuaternion
      ? new THREE.Quaternion().fromArray(initialQuaternion)
      : object.quaternion.clone();
  }

  setObjectRenderOrder(object) {
    object.renderOrder = FACILITY_OBJECT_RENDER_ORDER;

    object.traverse(
      child => (child.renderOrder = FACILITY_OBJECT_RENDER_ORDER)
    );
  }
}

export default SceneObjectBuilder;

import { useTheme } from "@mui/material/styles";

const UndoDrawPolygonIcon = (): JSX.Element => {
  const theme = useTheme();

  return (
    <svg
      width="12"
      height="9"
      viewBox="0 0 12 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 4.50008L4.33333 1.16675M1 4.50008L4.33333 7.83342M1 4.50008L7.66667 4.50008C8.77778 4.50008 11 5.16675 11 7.83342"
        stroke={theme.palette.text.primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default UndoDrawPolygonIcon;

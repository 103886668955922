import { IconButton } from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

interface ClearInputButtonInterface {
  onClick: (e: React.MouseEvent) => void;
}

const ClearInputButton = ({
  onClick,
}: ClearInputButtonInterface): JSX.Element => (
  <IconButton onClick={onClick}>
    <HighlightOffIcon
      sx={{
        width: "14px",
        height: "14px",
      }}
    />
  </IconButton>
);

export default ClearInputButton;

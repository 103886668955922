import { ApolloError, gql, useMutation } from "@apollo/client";

import { UpdateClientMutation, UpdateClientMutationVariables } from "../../API";
import { UPDATE_CLIENT } from "../../common/operations/mutations";
import {
  errorNotification,
  successNotification,
} from "../../common/variables/notification";
import { useCustomerIdGuard } from "../../common/hooks/useCustomerIdGuard";

export const useUpdateClient = () => {
  const selectedCustomerId = useCustomerIdGuard();

  const [updateClientMutation, { data, loading }] = useMutation<
    UpdateClientMutation,
    UpdateClientMutationVariables
  >(UPDATE_CLIENT);

  const updateClient = (clientName: string, id: string) => {
    return updateClientMutation({
      variables: {
        input: {
          clientName,
          customerId: selectedCustomerId,
          id,
        },
      },
      onCompleted: response => {
        if (response.updateClient) {
          successNotification();
        }
      },
      onError: (error: ApolloError): void => {
        errorNotification(error.message);

        console.error(error.message);
      },
      update: (cache, response): void => {
        cache.modify({
          fields: {
            getClientsByCustomer(existing) {
              const newClientRef = cache.writeFragment({
                data: response.data?.updateClient,
                fragment: gql`
                  fragment NewClient on Client {
                    id
                    clientName
                  }
                `,
              });

              if (!existing) {
                return response?.data;
              }

              return {
                ...existing,
                items: [...existing.items, newClientRef],
              };
            },
          },
        });
      },
    });
  };

  return { updateClient, data, loading };
};

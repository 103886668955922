import type { ComponentsOverrides, Theme } from "@mui/material";

export const MuiSelectDarkStyleOverrides: ComponentsOverrides<Theme>["MuiSelect"] =
  {
    root: ({ theme }) => ({
      ".MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.otherStandardInputLine.main,
        borderRadius: "8px",
      },
    }),
  };

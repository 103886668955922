import {
  useCallback,
  useEffect,
  useMemo,
  useState,
  type SyntheticEvent,
} from "react";

import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

import client from "../../../../configs/apolloClient";
import { selectedLocationDevicesVariable } from "../../../../pages/devices/variables/devices";
import { selectedLocationNodesVariable } from "../../../../pages/nodes/variables/nodes";
import { useCustomerIdGuard } from "../../../hooks/useCustomerIdGuard";
import { useGetCustomer } from "../../../hooks/useGetCustomer";
import { useGetCustomers } from "../../../hooks/useGetCustomers";
import { useToggle } from "../../../hooks/useToggle";
import type {
  AutocompleteOptionType,
  DisableClearableOptionsType,
  IDisableClearableOption,
} from "../../../models/autocomplete";
import { selectedCustomerVariable } from "../../../variables/selectedCustomer";
import { dateRangeVariable } from "../../datePicker/modelsDatePickers";
import withCustomPaper from "../../select/CustomPaperActionButton";
import AddCustomerDialog from "./AddCustomerDialog";

const defaultOption: IDisableClearableOption = {
  title: "Create new customer",
  value: "",
};

const getSelectedCustomerFromStorage = (): IDisableClearableOption => {
  try {
    const selectedCustomer = localStorage.getItem("customer");

    if (selectedCustomer !== "undefined" && selectedCustomer) {
      return JSON.parse(selectedCustomer);
    }

    const defaultOptionJson = JSON.stringify(defaultOption);

    return JSON.parse(defaultOptionJson);
  } catch (error) {
    console.error(error);

    return defaultOption;
  }
};

interface ICustomerSelectProps {
  size?: "small" | "medium";
  showCreateCustomer?: boolean;
  isAuditor?: boolean;
}

const CustomerSelect = ({
  size = "medium",
  showCreateCustomer,
  isAuditor,
}: ICustomerSelectProps): JSX.Element => {
  const customerId = useCustomerIdGuard();
  const { customers, loading } = useGetCustomers();
  const { customer } = useGetCustomer();

  const selectedCustomerFromStorageMemoized = useMemo(() => {
    if (!customers && loading) {
      return defaultOption;
    }

    return getSelectedCustomerFromStorage();
  }, [customers, loading]);

  const [selectedCustomer, setSelectedCustomer] =
    useState<IDisableClearableOption>(selectedCustomerFromStorageMemoized);
  const { state: isDialogOpened, setToggleState } = useToggle({
    initialState: false,
  });

  useEffect((): void => {
    const selectedCustomerFromStorage = getSelectedCustomerFromStorage();

    if (selectedCustomerFromStorage?.value !== selectedCustomer?.value) {
      setSelectedCustomer(selectedCustomerFromStorage);
    }
  }, [customerId]);

  const memoizedCloseDialogCallback = useCallback((): void => {
    setToggleState(false);
  }, []);

  const openToggle = (): void => {
    setToggleState(true);
  };

  const handleOnChange = async (
    _event: SyntheticEvent<Element, Event>,
    optionValue: IDisableClearableOption
  ): Promise<void> => {
    if (!optionValue) {
      setSelectedCustomer(defaultOption);

      selectedCustomerVariable("");

      localStorage.setItem("customer", JSON.stringify(defaultOption));

      // clear previous customer data from cache
      await client.cache.reset();

      return;
    }

    const { value } = optionValue;

    localStorage.setItem("customer", JSON.stringify(optionValue));

    setSelectedCustomer(optionValue);

    selectedCustomerVariable(value);

    selectedLocationNodesVariable(null);

    selectedLocationDevicesVariable(null);

    dateRangeVariable(null);

    await client.cache.reset();
  };

  const options =
    useMemo<DisableClearableOptionsType>((): IDisableClearableOption[] => {
      const customersOptions =
        customers?.items?.map((customer): IDisableClearableOption => {
          return {
            title: customer?.name ?? "",
            value: customer?.id ?? "",
          };
        }) ?? [];

      return customersOptions;
    }, [customers]);

  const auditorOptions =
    useMemo<DisableClearableOptionsType>((): IDisableClearableOption[] => {
      if (!customer) return [];

      if (!customer?.auditCustomers) return [];

      const customersOptions =
        JSON.parse(customer?.auditCustomers).map(
          (customer: { name: string; id: string }): IDisableClearableOption => {
            return {
              title: customer?.name ?? "",
              value: customer?.id ?? "",
            };
          }
        ) ?? [];

      return customersOptions;
    }, [customer]);

  useEffect((): void => {
    if (!selectedCustomerFromStorageMemoized?.value && options?.length > 0) {
      setSelectedCustomer(options[1]);

      selectedCustomerVariable(options[1]?.value);

      localStorage.setItem("customer", JSON.stringify(options[1]));
    } else {
      setSelectedCustomer(selectedCustomerFromStorageMemoized);

      selectedCustomerVariable(selectedCustomerFromStorageMemoized?.value);
    }
  }, [options, selectedCustomerFromStorageMemoized?.value]);

  // TODO: if no reactive variable customer, return null
  // call modal component to prompt user to select customer

  const customPaper = withCustomPaper({
    text: "New customer",
    onClick: openToggle,
  });

  return (
    <>
      <Autocomplete
        sx={{ minWidth: 200, maxWidth: 240, width: "100%" }}
        options={isAuditor ? auditorOptions : options}
        disableClearable
        size={size}
        getOptionLabel={(option: AutocompleteOptionType): string => {
          return option?.title ?? defaultOption.title;
        }}
        isOptionEqualToValue={(option, optionValue): boolean => {
          return option?.value === ""
            ? true
            : option?.value === optionValue?.value;
        }}
        value={selectedCustomer}
        onChange={handleOnChange}
        popupIcon={<ExpandMoreOutlinedIcon />}
        ListboxProps={{
          style: {
            maxHeight: "286px",
          },
        }}
        PaperComponent={showCreateCustomer ? customPaper : undefined}
        renderOption={(props, option): JSX.Element => {
          return (
            <Box {...props} component="li">
              {option?.title}
            </Box>
          );
        }}
        renderInput={(params): JSX.Element => (
          <TextField {...params} variant="outlined" size="small" fullWidth />
        )}
      />

      {isDialogOpened && (
        <AddCustomerDialog
          isOpened={isDialogOpened}
          closeDialog={memoizedCloseDialogCallback}
        />
      )}
    </>
  );
};

export default CustomerSelect;

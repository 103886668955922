import Button, { ButtonProps } from "@mui/material/Button";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

const ExportToCsvButton = (props: ButtonProps) => {
  return (
    <Button startIcon={<FileDownloadOutlinedIcon />} {...props}>
      Export CSV
    </Button>
  );
};

export default ExportToCsvButton;

import { useContext } from "react";
import { List, styled } from "@mui/material";

import { ProjectContext } from "../../../context";

import TowerListItem from "./TowerListItem";
import { TowerDataInterface } from "../../../context/ProjectContext/ProjectEntitesTypes";

const TowerList = (): JSX.Element => {
  const { towers } = useContext(ProjectContext);

  return (
    <StyledList>
      {towers.map((data: TowerDataInterface) => (
        <TowerListItem key={data.id} data={data} />
      ))}
    </StyledList>
  );
};

export default TowerList;

const StyledList = styled(List)(({ theme }) => ({
  overflowY: "scroll",
  width: "calc(100% + 11.5px)",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  padding: "0px",
  gap: "15px",

  paddingRight: "4px",

  "&::-webkit-scrollbar": {
    width: "7px",
    height: "7px",
  },

  "&::-webkit-scrollbar-track": {
    background: "transparent",
  },

  "&::-webkit-scrollbar-thumb": {
    backgroundColor: theme.palette["blue-scrollbar"],
    borderRadius: "30px",
  },
}));

import type { ComponentsOverrides, Theme } from "@mui/material";

export const MuiTextFieldDarkStyleOverrides: ComponentsOverrides<Theme>["MuiTextField"] =
  {
    root: ({ theme }) => ({
      "& input": {
        "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button": {
          "-webkit-appearance": "none",
        },
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderRadius: "8px",
          borderColor: theme.palette.otherStandardInputLine.main,
          color: theme.palette.otherTextTertiary.main,
        },
        "&:hover fieldset": {
          borderColor: `${theme.palette.action.hover}`,
        },
        "&.Mui-focused fieldset": {
          borderColor: `${theme.palette.primary.main}`,
        },
      },
    }),
  };

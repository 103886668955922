import { Box } from "@mui/material";
import { Image, Layer, Stage } from "react-konva";

import { Annotation } from "../../../API";
import KeyFrameMessage from "../media/NoKeyFrameMessage";
import CircularLoading from "../progress/CircularLoading";
import useCreateFullAnnotationContainer from "./useCreateFullAnnotationContainer";
import useCreateImageLayer from "./useCreateImageLayer";
import { KONVA_HEIGHT, KONVA_WIDTH } from "./utils";

type ViewKeyframeWithAnnotationsProps = {
  annotationsData: Annotation[];
  keyFrame: string;
  setKeyframeLoaded: (value: boolean) => void;
  isKeyframeLoaded: boolean;
};

const ViewKeyframeWithAnnotations: React.FC<
  ViewKeyframeWithAnnotationsProps
> = ({ annotationsData, keyFrame, setKeyframeLoaded, isKeyframeLoaded }) => {
  const { image, imageRef, scaleX, scaleY, skaleFactor, loading } =
    useCreateImageLayer(keyFrame, setKeyframeLoaded, true);

  const { fullAnnContainerRef } = useCreateFullAnnotationContainer(
    annotationsData,
    scaleX,
    scaleY
  );

  const showKeyFrame = isKeyframeLoaded && !loading;

  return (
    <>
      {showKeyFrame && (
        <Stage
          width={KONVA_WIDTH}
          height={loading ? KONVA_WIDTH : KONVA_WIDTH / skaleFactor}
          style={{
            borderRadius: "8px",
            overflow: "hidden",
          }}
        >
          <Layer>
            <Image
              ref={imageRef}
              image={image}
              x={0}
              y={0}
              width={KONVA_WIDTH}
              height={loading ? KONVA_WIDTH : KONVA_WIDTH / skaleFactor}
            />
          </Layer>

          <Layer ref={fullAnnContainerRef} />
        </Stage>
      )}

      {!showKeyFrame && (
        <Box
          sx={{
            width: KONVA_WIDTH,
            height: KONVA_HEIGHT,
            borderRadius: "8px",
            border: (theme): string =>
              `1px solid ${theme.palette.otherOutlineBorder.main}`,
          }}
        >
          {loading && <CircularLoading />}

          {!loading && (
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "8px",
                flexDirection: "column",
                gap: "1em",
              }}
            >
              <KeyFrameMessage
                message={"Please wait for the keyframe to retrieve"}
              />
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

export default ViewKeyframeWithAnnotations;

import uuid from "react-uuid";
import {
  Box,
  Checkbox,
  Chip,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  TypographyProps,
  styled,
} from "@mui/material";

import { HintInterface } from "./components/InputHint";
import InputContainer from "./components/InputContainer";

import {
  CheckboxCheckedIcon,
  CheckboxDefaultIcon,
} from "../../assets/icons/svgAssets";
import { SmallCloseIcon } from "../../assets/icons/SmallCloseIcon";
import { SelectInputIcon } from "../../assets/icons/SelectInputIcon";

interface MultiSelectInputPropsInterface {
  label?: string;
  labelFontVariant?: TypographyProps["variant"];
  value: string[];
  placeholder?: string;
  options: string[];
  type?: string;
  hint?: HintInterface;
  isError?: boolean;
  disabled?: boolean;
  required?: boolean;
  readonly?: boolean;
  variant?: "filled" | "outlined" | "standard";
  iconPosition?: "left" | "right";
  viewType?: "row" | "column";
  labelWidth?: string;
  inputWidth?: string;
  handleOnChange?: (value: string[]) => void;
  handleOnClick?: () => void;
}

const MultiSelectInput = ({
  label,
  labelFontVariant,
  value,
  placeholder = "Select...",
  hint,
  isError,
  disabled,
  required,
  readonly,
  options,
  variant = "outlined",
  iconPosition = "right",
  viewType = "column",
  labelWidth = "100%",
  inputWidth = "100%",
  handleOnChange,
  handleOnClick,
}: MultiSelectInputPropsInterface): JSX.Element => {
  const onChange = (event: SelectChangeEvent<unknown>) => {
    const value = event.target.value as string | string[];
    const valueToUpdate = typeof value === "string" ? value.split(",") : value;

    handleOnChange && handleOnChange(valueToUpdate);
  };

  const onChipDelete = (chipValue: string) => {
    const valueToUpdate = value.filter(valueItem => valueItem !== chipValue);

    handleOnChange && handleOnChange(valueToUpdate);
  };

  const id = label || uuid();

  return (
    <InputContainer
      id={id}
      label={label}
      labelFontVariant={labelFontVariant}
      hint={hint}
      required={required}
      viewType={viewType}
      labelWidth={labelWidth}
      inputWidth={inputWidth}
    >
      <StyledSelectInput
        variant={variant}
        displayEmpty
        isplaceholder={"true"}
        iconposition={iconPosition}
        fullWidth
        multiple
        readOnly={readonly}
        renderValue={() => (
          <Typography variant="body12Regular">{placeholder}</Typography>
        )}
        value={value}
        error={isError}
        disabled={disabled}
        onChange={onChange}
        onClick={handleOnClick}
        IconComponent={props => <SelectInputIcon {...props} />}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 152,
              padding: "4px",
            },
          },
          MenuListProps: {
            style: {
              padding: 0,
            },
          },
        }}
      >
        {options.map(optionItem => (
          <OptionMenuItem key={optionItem} value={optionItem} disableRipple>
            <StyledCheckbox
              checked={value.indexOf(optionItem) > -1}
              disableRipple
              disableFocusRipple
              icon={<CheckboxDefaultIcon />}
              checkedIcon={<CheckboxCheckedIcon />}
            />
            <Typography variant="body14Regular">{optionItem}</Typography>
          </OptionMenuItem>
        ))}
      </StyledSelectInput>
      <ChipWrapper>
        {value.map(valueItem => (
          <Chip
            key={valueItem}
            color="blue"
            variant="filled"
            label={<Typography variant="body12Medium">{valueItem}</Typography>}
            deleteIcon={<SmallCloseIcon />}
            onDelete={() => onChipDelete(valueItem)}
          />
        ))}
      </ChipWrapper>
    </InputContainer>
  );
};

export default MultiSelectInput;

const StyledSelectInput = styled(Select)<{
  isplaceholder: "true" | "false";
  iconposition: "left" | "right";
}>(({ theme, isplaceholder, iconposition }) => ({
  width: "100%",
  height: "29px",

  ...(isplaceholder === "true" && {
    "& .MuiSelect-select": {
      color: `${theme.palette["base-placeholder"]} !important`,
    },
  }),

  ...(iconposition === "left" && {
    "& .MuiSelect-icon": {
      left: "7px",
    },

    "& .MuiSelect-select": {
      padding: "6px 6px 6px 32px !important",
    },
  }),

  ...(iconposition === "right" && {
    "& .MuiSelect-icon": {
      right: "7px",
    },

    "& .MuiSelect-select": {
      padding: "6px 32px 6px 6px !important",
    },
  }),
}));

const OptionMenuItem = styled(MenuItem)(() => ({
  padding: "8px 16px",
  gap: "8px",
}));

const StyledCheckbox = styled(Checkbox)(() => ({
  padding: 0,
  width: "20px",
  height: "20px",
}));

const ChipWrapper = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  gap: "5px",
  flexWrap: "wrap",
}));

import { List, styled } from "@mui/material";

import CameraZoneListItem from "./CameraZoneListItem";

import { ZoneDataInterface } from "../../../context/ProjectContext/ProjectEntitesTypes";

interface CameraZoneListInterface {
  zoneData: ZoneDataInterface[];
}

const CameraZoneList = ({ zoneData }: CameraZoneListInterface): JSX.Element => {
  return (
    <StyledList>
      {zoneData.map(data => (
        <CameraZoneListItem key={data.id} data={data} />
      ))}
    </StyledList>
  );
};

export default CameraZoneList;

const StyledList = styled(List)(() => ({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  padding: "0px",
  gap: "15px",
}));

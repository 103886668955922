import { useContext } from "react";
import { Box, Button, Typography, styled } from "@mui/material";

import ModalWindow from "../../../common/components/ModalWindow";
import SelectInput from "../../../common/components/input/SelectInput";
import NumberInput from "../../../common/components/input/NumberInput";
import ReadOnlyField from "../../../common/components/input/ReadOnlyField";

import { TowerSideDataInterface } from "../context/AddCameraContext/AddCameraContext";
import { ProjectContext } from "../../../common/context";

import { useSelectInputState } from "../../../common/hooks/useSelectInputState";
import { useNumberInputState } from "../../../common/hooks/useNumberInputState";
import { useGetValueInCurrentMeasurementSystem } from "../../../common/hooks/useGetValueInCurrentMeasurementSystem";

import CamerasData, { CamerasType } from "../../../common/data/CamerasData";
import { TowerDataInterface } from "../../../common/context/ProjectContext/ProjectEntitesTypes";

import { numberValidationOnlyRangeCheck } from "../../../common/validation";

interface AddCameraModalInterface {
  onClose: () => void;
  towerSideData: TowerSideDataInterface;
}

const AddCameraModal = ({
  towerSideData,
  onClose,
}: AddCameraModalInterface) => {
  const { addCamera, towers, measurementSystem } = useContext(ProjectContext);

  const { getHeightValueFromMeters, getHeightValueFromFoot } =
    useGetValueInCurrentMeasurementSystem(measurementSystem);

  const selectedTower = towers.find(
    (towerData: TowerDataInterface) => towerData.id === towerSideData.towerId
  );

  const selectedTowerHeight = selectedTower ? selectedTower.height : 44;

  const cameraType = useSelectInputState({
    value: "",
    placeholder: "Select...",
    options: [...Object.values(CamerasType)],
    error: true,
    errorMessage: "",
    trackError: false,
  });

  const cameraHeight = useNumberInputState(
    {
      value: `${getHeightValueFromFoot(10)}`,
      step: getHeightValueFromFoot(1),
      min: getHeightValueFromFoot(5),
      max: getHeightValueFromMeters(selectedTowerHeight),
      error: false,
      errorMessage: "",
      trackError: true,
    },
    numberValidationOnlyRangeCheck
  );

  const cameraFieldOfView = useSelectInputState({
    value: "45",
    placeholder: "Select...",
    options: ["9", "20", "45", "75", "90"],
    error: false,
    errorMessage: "",
    trackError: true,
  });

  const cameraVerticalAngle = useNumberInputState(
    {
      value: "0",
      step: 0.5,
      min: -89.5,
      max: 89.5,
      error: false,
      errorMessage: "",
      trackError: true,
    },
    numberValidationOnlyRangeCheck
  );

  const cameraHorizontalAngle = useNumberInputState(
    {
      value: "0",
      step: 0.5,
      min: -180,
      max: 180,
      error: false,
      errorMessage: "",
      trackError: true,
    },
    numberValidationOnlyRangeCheck
  );

  const handleOnCameraTypeInputChange = (value: string) => {
    cameraType.onChange(value);
  };

  const handleOnCameraHeightInputChange = (value: string) => {
    cameraHeight.onChange(value);
  };

  const handleOnCameraFieldOfViewInputChange = (value: string) => {
    cameraFieldOfView.onChange(value);
  };

  const handleOnCameraVerticalAngleInputChange = (value: string) => {
    cameraVerticalAngle.onChange(value);
  };

  const handleOnCameraHorizontalAngleInputChange = (value: string) => {
    cameraHorizontalAngle.onChange(value);
  };

  const handleAddCamera = () => {
    const cameraTypeData = CamerasData.find(
      data => data.cameraType === cameraType.state.value
    );

    if (!cameraTypeData) {
      return;
    }

    const data = {
      ...cameraTypeData,
      height: cameraHeight.state.value,
      fov: cameraFieldOfView.state.value,
      verticalAngle: cameraVerticalAngle.state.value,
      horizontalAngle: cameraHorizontalAngle.state.value,
      zoom: 1,
      sideIndex: towerSideData.sideIndex,
      towerId: towerSideData.towerId,
    };

    addCamera(data);

    onClose();
  };

  const disabledAdding =
    cameraType.state.error ||
    cameraHeight.state.error ||
    (cameraType.state.value !== CamerasType.MinervaCamera &&
      (cameraFieldOfView.state.error ||
        cameraHorizontalAngle.state.error ||
        cameraVerticalAngle.state.error));

  return (
    <ModalWindow isOpen={true} onClose={onClose} modalWidth="388px">
      <Wrapper>
        <Title variant="h2">Add new Camera</Title>

        {cameraType.state.error && (
          <CameraTypeLabel variant="body12Regular">
            First select the camera type and then configure it
          </CameraTypeLabel>
        )}

        <SelectInput
          label="Type of camera:"
          labelFontVariant="body12Regular"
          labelWidth="40%"
          viewType="row"
          value={cameraType.state.value}
          placeholder={cameraType.state.placeholder}
          options={cameraType.state.options}
          isError={cameraType.state.trackError && cameraType.state.error}
          disabled={false}
          handleOnChange={handleOnCameraTypeInputChange}
        />

        {!cameraType.state.error &&
          cameraType.state.value !== CamerasType.RGBCamera && (
            <ReadOnlyField
              label="Field of view:"
              labelFontVariant="body12Regular"
              labelWidth="40%"
              inputWidth="40%"
              viewType="row"
              value="360°"
            />
          )}

        {!cameraType.state.error && (
          <NumberInput
            label="Height:"
            labelFontVariant="body12Regular"
            step={cameraHeight.state.step}
            min={cameraHeight.state.min}
            max={cameraHeight.state.max}
            viewType="row"
            labelWidth="40%"
            inputWidth="40%"
            value={cameraHeight.state.value}
            isError={cameraHeight.state.error}
            handleOnChange={handleOnCameraHeightInputChange}
          />
        )}

        {cameraType.state.value === CamerasType.MinervaGasCamera && (
          <CameraSettingsLabel variant="body14Medium">
            Gas camera settings
          </CameraSettingsLabel>
        )}

        {cameraType.state.value === CamerasType.RGBCamera && (
          <CameraSettingsLabel variant="body14Medium">
            RGB camera settings
          </CameraSettingsLabel>
        )}

        {!cameraType.state.error &&
          cameraType.state.value !== CamerasType.MinervaCamera && (
            <>
              <SelectInput
                label="Field of view:"
                labelFontVariant="body12Regular"
                labelWidth="40%"
                inputWidth="40%"
                viewType="row"
                value={cameraFieldOfView.state.value}
                placeholder={cameraFieldOfView.state.placeholder}
                options={cameraFieldOfView.state.options}
                isError={cameraFieldOfView.state.error}
                handleOnChange={handleOnCameraFieldOfViewInputChange}
              />
              <NumberInput
                label="Tilt:"
                labelFontVariant="body12Regular"
                step={cameraVerticalAngle.state.step}
                min={cameraVerticalAngle.state.min}
                max={cameraVerticalAngle.state.max}
                viewType="row"
                labelWidth="40%"
                inputWidth="40%"
                value={cameraVerticalAngle.state.value}
                isError={cameraVerticalAngle.state.error}
                handleOnChange={handleOnCameraVerticalAngleInputChange}
              />
              <NumberInput
                label="Pan:"
                labelFontVariant="body12Regular"
                step={cameraHorizontalAngle.state.step}
                min={cameraHorizontalAngle.state.min}
                max={cameraHorizontalAngle.state.max}
                viewType="row"
                labelWidth="40%"
                inputWidth="40%"
                value={cameraHorizontalAngle.state.value}
                isError={cameraHorizontalAngle.state.error}
                handleOnChange={handleOnCameraHorizontalAngleInputChange}
              />
            </>
          )}

        <ButtonSection>
          <Button onClick={onClose} variant="secondary" color="blue">
            Cancel
          </Button>
          <Button
            disabled={disabledAdding}
            onClick={handleAddCamera}
            variant="primary"
            color="blue"
          >
            Add
          </Button>
        </ButtonSection>
      </Wrapper>
    </ModalWindow>
  );
};

export default AddCameraModal;

const Wrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "10px",
}));

const Title = styled(Typography)(({ theme }) => ({
  padding: "0 0 6px",
  color: theme.palette["base-label"],
}));

const CameraTypeLabel = styled(Typography)(({ theme }) => ({
  padding: "10px 0px",
  color: theme.palette["base-label"],
}));

const CameraSettingsLabel = styled(Typography)(({ theme }) => ({
  padding: "6px 0px 0px",
  color: theme.palette["base-label"],
}));

const ButtonSection = styled(Box)(() => ({
  display: "flex",
  justifyContent: "flex-end",
  padding: "14px 0 0",
  gap: "8px",
}));

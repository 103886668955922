import { SvgIcon, SvgIconProps } from "@mui/material";
import { ReactElement } from "react";

export const SearchIcon = (props: SvgIconProps): ReactElement => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.41675 2.33366C4.16159 2.33366 2.33341 4.16183 2.33341 6.41699C2.33341 8.67215 4.16159 10.5003 6.41675 10.5003C8.67191 10.5003 10.5001 8.67215 10.5001 6.41699C10.5001 4.16183 8.67191 2.33366 6.41675 2.33366ZM1.16675 6.41699C1.16675 3.5175 3.51725 1.16699 6.41675 1.16699C9.31624 1.16699 11.6667 3.5175 11.6667 6.41699C11.6667 9.31649 9.31624 11.667 6.41675 11.667C3.51725 11.667 1.16675 9.31649 1.16675 6.41699Z"
        fill="#787878"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.3 9.29976C9.52781 9.07195 9.89716 9.07195 10.125 9.29976L12.6625 11.8373C12.8903 12.0651 12.8903 12.4344 12.6625 12.6622C12.4347 12.89 12.0653 12.89 11.8375 12.6622L9.3 10.1247C9.0722 9.89691 9.0722 9.52757 9.3 9.29976Z"
        fill="#787878"
      />
    </SvgIcon>
  );
};

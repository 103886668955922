import { makeVar, useReactiveVar } from "@apollo/client";

export const selectedStreamObjectVariable = makeVar<any>(null);

export const useSelectedStreamObject = () =>
  useReactiveVar(selectedStreamObjectVariable);

export const openPTConctollerVariable = makeVar(false);

export const useOpenPTConctoller = () =>
  useReactiveVar(openPTConctollerVariable);

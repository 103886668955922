import { Box, TypographyProps, styled } from "@mui/material";

import CustomInputLabel from "./CustomInputLabel";
import InputHint, { HintInterface } from "./InputHint";

interface WrapperInterface {
  viewtype: "row" | "column";
}

interface InputContainerInterface {
  id: string;
  label?: string;
  labelFontVariant?: TypographyProps["variant"];
  placeholder?: string;
  hint?: HintInterface;
  required?: boolean;
  viewType?: "row" | "column";
  labelWidth?: string;
  inputWidth?: string;
  children: React.ReactNode;
}

const InputContainer = ({
  id,
  label,
  labelFontVariant,
  hint,
  required,
  viewType = "column",
  labelWidth = "100%",
  inputWidth = "100%",
  children,
}: InputContainerInterface): JSX.Element => {
  return (
    <Wrapper viewtype={viewType}>
      {label && (
        <LabelWrapper width={labelWidth} viewtype={viewType}>
          <CustomInputLabel
            title={label}
            required={!!required}
            id={`${id}-lable`}
            labelFontVariant={labelFontVariant}
          />
        </LabelWrapper>
      )}
      <HintInputWrapper>
        <InputWrapper width={inputWidth}>{children}</InputWrapper>
        {hint && <InputHint type={hint.type} text={hint.text} />}
      </HintInputWrapper>
    </Wrapper>
  );
};

export default InputContainer;

const Wrapper = styled(Box)<WrapperInterface>(({ viewtype }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  gap: "4px",

  ...(viewtype === "row" && {
    gap: "10px",
    flexDirection: "row",
    justifyContent: "space-between",
  }),
}));

const HintInputWrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "4px",
  flex: 1,
}));

const InputWrapper = styled(Box)<{ width: string }>(({ width }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "4px",
  width: width || "100%",
}));

const LabelWrapper = styled(Box)<{
  width?: string;
  viewtype: "row" | "column";
}>(({ width, viewtype }) => ({
  height: "30px",
  display: "flex",
  alignItems: "center",

  width: width || "100%",

  ...(viewtype === "row" && {
    width: width || "50%",
  }),
}));

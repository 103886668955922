import { MathUtils, Vector3 } from "three";
import { WGS84_ELLIPSOID } from "3d-tiles-renderer";

export const getCoordinatesFromLatLng = (latitude, longitude, height) => {
  const vector = new Vector3();

  WGS84_ELLIPSOID.getCartographicToPosition(
    MathUtils.degToRad(latitude),
    MathUtils.degToRad(longitude),
    height,
    vector
  );

  return vector;
};

export const getLatLonFromCoordinates = coordinates => {
  const res = {};

  WGS84_ELLIPSOID.getPositionToCartographic(coordinates, res);

  const latitude = MathUtils.radToDeg(res.lat);
  const longitude = MathUtils.radToDeg(res.lon);

  return [latitude, longitude, res.height];
};

import { useContext, useEffect, useState } from "react";

import {
  LocationCoordinatesType,
  WeatherConditionsContext,
  WeatherDataInterface,
  WeatherShortInfoInterface,
} from "./WeatherConditionsContext";
import { MeasurementSystemMode } from "../../enums";
import { ModeTypeEnum } from "../ProjectContext/ProjectEntitesTypes";

import { useGetOpenWeatherData } from "../../hooks/useGetOpenWeatherData";

import { ProjectContext } from "../ProjectContext/ProjectContext";

export const WeatherConditionsContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const [weatherVisibility, setWeatherVisibility] = useState(false);
  const [prevVisibility, setPrevVisibility] = useState(weatherVisibility);

  const [defaultLocation, setDefaultLocation] =
    useState<LocationCoordinatesType>();
  const [location, setLocation] = useState<LocationCoordinatesType>();
  const [data, setData] = useState<WeatherDataInterface>({
    temp: 0,
    wind: {
      speed: 0,
      deg: 0,
      gust: 0,
    },
    clouds: 0,
    rain: undefined,
    snow: undefined,
    description: "",
    visibility: 0,
  });

  const [weatherShortInfo, setWeatherShortInfo] =
    useState<WeatherShortInfoInterface>({
      temp: 0,
      windSpeed: 0,
    });

  const { threeScene, projectData, measurementSystem, mode } =
    useContext(ProjectContext);

  const { getData } = useGetOpenWeatherData();

  const setCurrentLocation = (center: LocationCoordinatesType) => {
    setLocation(center);
  };

  const toggleWeatherVisibility = () => {
    setPrevVisibility(!weatherVisibility);

    setWeatherVisibility(!weatherVisibility);
  };

  const getTemperatureInProjectMeasurementSystem = (
    celsius: number
  ): number => {
    if (measurementSystem === MeasurementSystemMode.Metric) {
      return celsius;
    } else {
      const multiplyCoef = 1.8;
      const addCoef = 32;

      const fahrenheit = celsius * multiplyCoef + addCoef;

      return fahrenheit;
    }
  };

  const getSpeedInProjectMeasurementSystem = (mS: number): number => {
    if (measurementSystem === MeasurementSystemMode.Metric) {
      return mS;
    } else {
      const coef = 2.2369362921;
      const miH = mS * coef;

      return miH;
    }
  };

  useEffect(() => {
    if (!projectData) {
      return;
    }

    setDefaultLocation(projectData.configData.terrainData.locationCoordinates);
  }, [projectData]);

  useEffect(() => {
    if (!location) {
      setLocation(defaultLocation);
    } else {
      getData(location[0], location[1]).then(response => {
        setData({
          temp: response.main.temp,
          wind: {
            speed: response.wind.speed,
            deg: response.wind.deg,
            gust: response.wind.gust,
          },
          clouds: response.clouds.all,
          rain: response.rain?.["1h"],
          snow: response.snow?.["1h"],
          description: response.weather[0].description,
          visibility: response.visibility,
        });

        setWeatherShortInfo({
          temp: getTemperatureInProjectMeasurementSystem(response.main.temp),
          windSpeed: getSpeedInProjectMeasurementSystem(response.wind.speed),
        });
      });
    }
  }, [location, defaultLocation, measurementSystem]);

  useEffect(() => {
    if (mode === ModeTypeEnum.zoneView) {
      setWeatherVisibility(false);
    } else {
      setWeatherVisibility(prevVisibility);
    }
  }, [mode]);

  useEffect(() => {
    if (!threeScene) {
      return;
    }

    weatherVisibility
      ? threeScene.weatherConditions.setWeather(data)
      : threeScene.weatherConditions.removeWeather();
  }, [weatherVisibility, data]);

  return (
    <WeatherConditionsContext.Provider
      value={{
        weatherVisibility,
        defaultLocation,
        location,
        setCurrentLocation,
        toggleWeatherVisibility,
        data,
        weatherShortInfo,
      }}
    >
      {children}
    </WeatherConditionsContext.Provider>
  );
};

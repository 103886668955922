import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useConfirm } from "material-ui-confirm";
import { SubmitHandler, useForm } from "react-hook-form";

import FullPageLoader from "../../common/components/item/FullPageLoader";
import MainPaperWrapper from "../../common/components/item/MainPaperWrapper";
import BreadcrumbNavigation from "../../common/components/tabs/BreadcrumbNavigation";
import confirmDialogStyleOptions from "../../common/helpers/confirmDialogParams";
import { useCustomerIdGuard } from "../../common/hooks/useCustomerIdGuard";
import { RouteEnum } from "../../common/models/enums";
import StyledLoadingButton from "../../common/providers/theme/design-tokens/LoadingButton/StyledLoadingButton";
import {
  errorNotification,
  successNotification,
} from "../../common/variables/notification";
import { useGetGroupByClientId } from "../hooks/useGetGroupByClientId";
import { useUpdateClientGroup } from "../hooks/useUpdateClientGroup";
import EditGroupForm, { IGroupForm } from "./EditGroupForm";

const EditGroupPage = () => {
  const navigate = useNavigate();
  const confirm = useConfirm();

  const selectedCustomerId = useCustomerIdGuard();

  const [searchParams] = useSearchParams();

  const groupId = searchParams.get("groupId") ?? "";
  const clientId = searchParams.get("clientId") ?? "";

  const defaultValues = {
    groupName: "",
  };

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IGroupForm>({ defaultValues });

  const { updateClientGroup, loading: updateGroupLoading } =
    useUpdateClientGroup();

  const {
    data,
    refetch,
    loading: getGroupLoading,
  } = useGetGroupByClientId({
    groupId: groupId,
    clientId: clientId,
  });

  useEffect(() => {
    if (selectedCustomerId && groupId) {
      refetch();
    }
  }, [selectedCustomerId, groupId]);

  useEffect(() => {
    if (data?.getGroupByClientId && !getGroupLoading) {
      const groupName = data.getGroupByClientId?.groupName ?? "";

      setValue("groupName", groupName);
    }
  }, [data?.getGroupByClientId, getGroupLoading]);

  const onSubmit: SubmitHandler<IGroupForm> = formData => {
    if (!data) return;

    const group = data.getGroupByClientId;

    updateClientGroup({
      groupName: formData.groupName,
      clientId: group?.clientId ?? "",
      groupId: group?.id ?? "",
    })
      .then((response): void => {
        if (!response.errors) {
          successNotification("Changes are saved");

          navigate(RouteEnum.LeakFinderClientUploads);
        }
      })
      .catch((error: Error): void => {
        errorNotification(error.message);

        console.error(error);
      });
  };

  const confirmCancel = (path = RouteEnum.LeakFinderClientUploads): void => {
    confirm({
      title: "Are you sure you want to cancel editing?",
      description: "If you leave, the new informations won’t be saved",
      confirmationText: "Leave",
      cancellationText: "Cancel",
      ...confirmDialogStyleOptions,
      confirmationButtonProps: {
        ...confirmDialogStyleOptions.confirmationButtonProps,
        color: "primary",
      },
    }).then((): void => {
      navigate(path);
    });
  };

  const handleCancel = (): void => {
    confirmCancel();
  };

  const loading = getGroupLoading || updateGroupLoading;

  if (loading) {
    return <FullPageLoader />;
  }

  const group = data?.getGroupByClientId;

  const breadcrumbItems = [
    {
      label: "Client Uploads",
      onClick: () => confirmCancel(RouteEnum.LeakFinderClientUploads),
    },
    {
      label: `Edit ${group?.groupName}`,
    },
  ];

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <BreadcrumbNavigation items={breadcrumbItems} />

      <Typography variant="h3" sx={{ padding: "1em 0" }}>
        {`Edit ${group?.groupName}`}
      </Typography>

      <MainPaperWrapper>
        <EditGroupForm control={control} errors={errors} />
      </MainPaperWrapper>

      <Stack direction="row-reverse" spacing={2} sx={{ paddingTop: 3 }}>
        <StyledLoadingButton
          sx={{
            marginTop: "1.5em",
          }}
          type="submit"
          variant="contained"
          color="primary"
        >
          Save changes
        </StyledLoadingButton>
        <StyledLoadingButton
          sx={{
            marginTop: "1.5em",
          }}
          variant="outlined"
          color="inherit"
          onClick={handleCancel}
        >
          Back
        </StyledLoadingButton>
      </Stack>
    </form>
  );
};

export default EditGroupPage;

export const SecondaryViewTypes = {
  viewGas: "view-gas",
  viewRGB: "view-rgb",
  view360: "view-360",
};

export const SecondaryViewportTitleList = {
  [SecondaryViewTypes.view360]: "360° view",
  [SecondaryViewTypes.viewGas]: "Gas view",
  [SecondaryViewTypes.viewRGB]: "RGB view",
};

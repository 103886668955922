import { Vector3 } from "three";

import ObjectBuilder from "../SceneObjectBuilder/ObjectBuilder";
import { EntityTypeEnum } from "../../context/ProjectContext/ProjectEntitesTypes";

class FacilityObjects {
  constructor(scene, objectsGroup, sceneOrbitCenter, hdrTexture) {
    this.scene = scene;

    this.objectsGroup = objectsGroup;

    this.sceneOrbitCenter = sceneOrbitCenter;

    this.hdrTexture = hdrTexture;

    this.objectBuilder = new ObjectBuilder();
  }

  getAllObjects() {
    return this.objectsGroup.getObjectsByProperty(
      "EntityType",
      EntityTypeEnum.Object
    );
  }

  getObjectById(id) {
    return this.getAllObjects().find(obj => obj.ID === id);
  }

  async addObject(objectData) {
    const { objectToClone, materialToClone } =
      await this.objectBuilder.getObjectInstance(objectData, this.hdrTexture);

    const object = this.objectBuilder.createObjectInstance(
      objectToClone,
      materialToClone,
      objectData,
      this.sceneOrbitCenter.clone()
    );

    this.objectsGroup.add(object);

    return object;
  }

  deleteObject(object) {
    object.removeFromParent();
  }
}

export default FacilityObjects;

import { FC } from "react";

import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import RetryIcon from "../icons/RetryIcon";

interface IVideoNotFoundProps {
  onRetry: () => void;
}

const VideoNotFound: FC<IVideoNotFoundProps> = ({ onRetry }): JSX.Element => {
  return (
    <Paper
      variant="outlined"
      square={false}
      elevation={0}
      sx={{
        width: "100%",
        height: "100%",
        padding: "1.5em",
        backgroundColor: "#fbfbfb",
      }}
    >
      <Stack
        spacing={2}
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ height: "100%", textAlign: "center" }}
      >
        <Typography
          sx={{
            fontSize: "1rem",
          }}
          variant="inputLabel"
          component="div"
        >
          The video is not found. <br />
          Please press “retry” button to try again or reach out to
          <br />
          <Typography
            variant="inputLabel"
            sx={{ fontSize: "1rem", "& a": { color: "primary.dark" } }}
          >
            <a href="mailto:support@cleanconnect.ai">support@cleanconnect.ai</a>
          </Typography>
        </Typography>

        <Button
          sx={{
            color: "text.secondary",
            borderColor: "text.secondary",
            borderWidth: "2px",
            borderRadius: "40px",
            "&:hover": {
              color: "text.secondary",
              borderColor: "text.secondary",
            },
          }}
          variant="outlined"
          size="small"
          startIcon={<RetryIcon />}
          onClick={onRetry}
        >
          Retry
        </Button>
      </Stack>
    </Paper>
  );
};

export default VideoNotFound;

import { useContext } from "react";
import { Button } from "@mui/material";

import { UploadIcon } from "../../assets/icons/svgAssets";

import { ProjectContext, ProjectModalContext } from "../../context";
import { ModeTypeEnum } from "../../context/ProjectContext/ProjectEntitesTypes";

const Import3dModelButton = (): JSX.Element => {
  const { sceneStarted, isLoading, mode } = useContext(ProjectContext);
  const { setIsDisplayImport3dModelModal } = useContext(ProjectModalContext);

  const onClick = () => {
    setIsDisplayImport3dModelModal(true);
  };

  return (
    <Button
      variant="secondary"
      color="blue"
      onClick={onClick}
      disabled={!sceneStarted || isLoading || mode !== ModeTypeEnum.standard}
      startIcon={<UploadIcon />}
    >
      Import 3D model
    </Button>
  );
};

export default Import3dModelButton;

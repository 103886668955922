import { useMutation } from "@apollo/client";

import {
  AddClientGroupInput,
  AddClientGroupMutation,
  AddClientGroupMutationVariables,
} from "../../API";
import { useCustomerIdGuard } from "../../common/hooks/useCustomerIdGuard";
import { ADD_CLIENT_GROUP } from "../../common/operations/mutations";

export const useCreateClientGroup = () => {
  const selectedCustomerId = useCustomerIdGuard();

  const [addGroup, { data, loading }] = useMutation<
    AddClientGroupMutation,
    AddClientGroupMutationVariables
  >(ADD_CLIENT_GROUP);

  const createClientGroup = ({
    groupName,
    clientId,
    groupId,
  }: Pick<AddClientGroupInput, "clientId" | "groupName" | "groupId">) => {
    return addGroup({
      variables: {
        input: {
          customerId: selectedCustomerId,
          groupName: groupName.trim(),
          groupId,
          clientId,
        },
      },
    });
  };

  return { createClientGroup, data, loading };
};

import { useMemo } from "react";

import { Box, TextField, Typography } from "@mui/material";
import { Control, Controller, FieldErrors } from "react-hook-form";
import RequirementsPanel from "./RequirementsPanel";
import DroneIcon from "../../../../common/components/icons/DroneIcon";
import VideoCameraIcon from "../../../../common/components/icons/VideoCameraIcon";
import { VideoAnalysisFormState } from "../../UploadVideoGroupPage";

type NewGroupComponentProps = {
  control: Control<VideoAnalysisFormState>;
  errors: FieldErrors<VideoAnalysisFormState>;
};

const NewGroupComponent: React.FC<NewGroupComponentProps> = ({
  control,
  errors,
}) => {
  const videoDroneSettings = useMemo(
    () => [
      {
        main: "Native resolution | Do not upscale",
      },
      {
        main: "Disable zoom",
      },
      {
        main: "Set fps to 7 fps",
      },
      {
        main: "Disable any high sensitivity modes",
      },
      {
        main: "Set the color palette to “White Hot”",
      },
    ],
    []
  );

  const captureDataDroneProtocols = useMemo(
    () => [
      {
        main: "Ensure drone is set at a fixed distance from the leak source",
        subItems: [
          "Notate the fixed distance and do not deviate from the distance during the capture",
        ],
      },
      {
        main: "Record the leak for a minimum of 30 seconds",
        subItems: [
          "Do not zoom the video during data capture, or before, ensure nozoom is applied",
          "Do not move the drone at any point during the data capture",
        ],
      },
      { main: "Stop recording after 30 seconds" },
    ],
    []
  );

  const captureDataDandledVideoProtocols = useMemo(
    () => [
      {
        main: "Ensure the handheld camera is at a stable Mounted to a monopod or heldsecurely with minimal movement) fixed distance from the leak source",
        subItems: [
          "Notate the fixed distance and do not deviate from the distance duringthe capture",
        ],
      },
      {
        main: "Capture and notate the current wind speed",
        subItems: [
          "Do not zoom the video during data capture, or before, ensure nozoom is applied",
          "Do not move the drone at any point during the data capture",
        ],
      },
      { main: "Stop recording after 60 seconds" },
    ],
    []
  );

  const droneRequirementsInfo = useMemo(
    () => [
      {
        panelTitle: "Configure the drone's camera settings",
        protocols: videoDroneSettings,
      },
      {
        panelTitle: "Capture Data",
        panelDescription:
          "When a leak is spotted and is ready to be recorded follow the following protocol:",
        protocols: captureDataDroneProtocols,
      },
    ],
    []
  );

  const handledVideoSettings = useMemo(
    () => [
      {
        main: "Native resolution | Do not upscale",
      },
      {
        main: "Disable zoom",
      },
      {
        main: "Disable any high sensitivity modes",
      },
      {
        main: "Set the color palette to “White Hot”",
      },
    ],
    []
  );

  const enviromentslConditionsControl = useMemo(
    () => [
      {
        main: "When objects with significantly different temperatures are in close proximity, the thermal energy they emit can mix, leading to white out and inaccurate quantification results. White out looks like bright, featureless white areas in a thermal image. This causes key details to be lost. When recording, please ensure that the video is not displaying any signs of white out near the leak point.",
      },
      {
        main: "Ensure that the leak is not blending with shadows. In order for themodel to get the best quantification, gas should be differentiatedfrom the background. In other words, the gas should easily stand outin the video. This gives the model the most accurate data in which toquantify the leak with.",
      },
    ],
    []
  );

  const handledVideoRequirementsInfo = useMemo(
    () => [
      {
        panelTitle: "Configure the camera settings",
        protocols: handledVideoSettings,
      },
      {
        panelTitle: "Capture Data",
        panelDescription:
          "When a leak is spotted and is ready to be recorded follow the following protocol:",
        protocols: captureDataDandledVideoProtocols,
      },
      {
        panelTitle: "Environmental Conditions and Temperature Control",
        panelDescription: "Minimize Temperature Mixing:",
        protocols: enviromentslConditionsControl,
      },
    ],
    []
  );

  return (
    <Box>
      <Box>
        <Typography variant="body2Bold">Name upload group</Typography>

        <Controller
          name="groupName"
          control={control}
          rules={{ required: "This field is required" }}
          render={({ field }) => (
            <TextField
              {...field}
              value={field.value || ""}
              margin="dense"
              sx={{
                height: "68px",
                width: "100%",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "8px",
                },
              }}
              label={"Group name"}
              fullWidth
              error={!!errors.groupName}
              helperText={errors.groupName ? errors.groupName.message : ""}
            />
          )}
        />
      </Box>

      <Box>
        <Box sx={{ margin: "1em 0 0.5em 0" }}>
          <RequirementsPanel
            summary="Drone video requirements"
            summaryIcon={<DroneIcon />}
            detailsPanels={droneRequirementsInfo}
          />
        </Box>

        <Box sx={{ marginBottom: "0.5em" }}>
          <RequirementsPanel
            summary="Handheld video requirements"
            summaryIcon={<VideoCameraIcon />}
            detailsPanels={handledVideoRequirementsInfo}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default NewGroupComponent;

import { FC, useEffect, useState } from "react";

import { CardMedia } from "@mui/material";

import FullPageLoader from "../../../common/components/item/FullPageLoader";
import { S3Helper } from "../../../common/utils/s3helper";
import NoMedia from "./errors/NoMedia";

interface ImageViwerProps {
  s3Key: string;
}

const ImageViwer: FC<ImageViwerProps> = ({ s3Key }) => {
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [error, setError] = useState(false);

  useEffect((): void => {
    getImage();
  }, [s3Key, S3Helper.initialized]);

  const getImage = async (): Promise<void> => {
    if (!s3Key) {
      setImageUrl("");

      return;
    }

    setLoading(true);

    let mediaUrl = s3Key;

    try {
      mediaUrl = JSON.parse(mediaUrl);
    } catch (error) {
      console.log(`s3Key is already parsed`);
    }

    if (!S3Helper.initialized) {
      await S3Helper.setAWSConfig();
    }

    const response = await S3Helper.getObject(mediaUrl);

    setImageUrl(response);

    if (response === "") {
      setError(true);
    }

    setLoading(false);
  };

  if (loading) {
    return <FullPageLoader />;
  }

  if (error) {
    return <NoMedia />;
  }

  return (
    <CardMedia
      sx={{
        height: "100%",
        maxHeight: "100vh",
      }}
      src={imageUrl}
      component="img"
    />
  );
};

export default ImageViwer;

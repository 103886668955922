import { ReactNode } from "react";

import Paper from "@mui/material/Paper";
import { SxProps, Theme, useTheme } from "@mui/material/styles";

interface IMainPaperWrapperProps {
  children: ReactNode;
  sx?: SxProps<Theme>;
  withBorder?: boolean;
}

const MainPaperWrapper = ({
  children,
  sx = {},
  withBorder = true,
}: IMainPaperWrapperProps): JSX.Element => {
  const theme = useTheme();

  return (
    <Paper
      elevation={0}
      sx={{
        padding: "1.5em",
        display: "flex",
        borderRadius: "12px",
        border: withBorder
          ? `1px solid ${theme.palette.otherOutlineBorder.main}`
          : "none",
        justifyContent: "space-between",
        flexDirection: "row",
        alignItems: "flex-start",
        "@media (max-width: 600px)": {
          flexDirection: "column-reverse",
          alignItems: "center",
          width: "100%",
          padding: "1em",
        },
        ...sx,
      }}
    >
      {children}
    </Paper>
  );
};

export default MainPaperWrapper;

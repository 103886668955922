import { useLazyQuery } from "@apollo/client";
import { DTProject } from "../../../../API";
import { GET_DT_PROJECTS_BY_USED_DT_OBJECT } from "../../../../common/operations/queries";

export const useGetDTProjectByUsedDTObject = () => {
  const [getProjectsByObjectId, { data, loading, error }] = useLazyQuery(
    GET_DT_PROJECTS_BY_USED_DT_OBJECT,
    {
      fetchPolicy: "no-cache",
    }
  );

  const fetchProjectsByObjectId = async (
    customerId: string,
    objectId: string
  ): Promise<[DTProject] | null> => {
    try {
      const response = await getProjectsByObjectId({
        variables: {
          customerId,
          objectId,
        },
      });

      if (response.error) {
        console.error(response.error);

        return null;
      }

      return response.data.getDTProjectsByUsedDTObject;
    } catch (error) {
      console.error(error);

      return null;
    }
  };

  return { fetchProjectsByObjectId, data, loading, error };
};

import JSZip from "jszip";

export const createJsonFile = (
  data: Record<string, any>,
  fileName: string
): File => {
  const jsonString = JSON.stringify(data, null, 2);
  const blob = new Blob([jsonString], { type: "application/json" });
  const file = new File([blob], fileName, { type: "application/json" });

  return file;
};

export const createZipFile = (file: File, zipName: string) => {
  const zip = new JSZip();

  zip.file(zipName, file);

  return zip;
};

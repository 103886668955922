import { LeakFinderVideo, VideoMethod } from "../../API";
import {
  ClientUploadsTabsKeysEnum,
  useClientUploadsTabsVariable,
} from "../variables/clientUploads";
import { useGetAllVideosByGroup } from "./useGetAllVideosByGroup";

export type LeakFinderTableRow = Omit<LeakFinderVideo, "__typename">;

export const useGetVideosTableRows = (groupId: string) => {
  const { data, loading } = useGetAllVideosByGroup(groupId);
  const { selectedTab } = useClientUploadsTabsVariable();

  const mapVideoToRow = (item: LeakFinderTableRow) => ({
    rowId: item.id,
    id: item.id,
    videoName: item.videoName,
    videoState: item.videoState,
    videoMethod: item.videoMethod,
    windspeed: item.windspeed,
    outputMeasurement: item.outputMeasurement,
    sourceS3Key: item.sourceS3Key,
    modelOutputS3Key: item.modelOutputS3Key,
    isVideoProcessed: item.isVideoProcessed,
    groupId: item.groupId,
    clientId: item.clientId,
    customerId: item.customerId,
    geometry: item.geometry,
    fov: item.fov,
    notes: item.notes,
  });

  const filterAndMapVideos = (videoMethod: string) => {
    return (
      data?.getAllVideosByGroup.items
        .filter(item => item.videoMethod === videoMethod)
        .map(mapVideoToRow) ?? []
    );
  };

  let rows: LeakFinderTableRow[] = [];

  if (selectedTab === ClientUploadsTabsKeysEnum.DRONE_VIDEOS) {
    rows = filterAndMapVideos(VideoMethod.DRONE);
  } else if (selectedTab === ClientUploadsTabsKeysEnum.HANDHELD_VIDEOS) {
    rows = filterAndMapVideos(VideoMethod.HANDHELD);
  } else {
    rows = data?.getAllVideosByGroup.items.map(mapVideoToRow) ?? [];
  }

  return { rows, loading };
};

import { Theme } from "@mui/material";
import Konva from "konva";
import { Vector2d } from "konva/lib/types";

import { AnnotationType } from "../../../API";

export const HEX_OPACITY_30 = "4C";

export const VERTEX_RADIUS = 5;

export const initialRect = {
  x: 50,
  y: 50,
  width: 100,
  height: 100,
  fill: "",
};

export const KONVA_HEIGHT = 540;

export const KONVA_WIDTH = 627;

export type PointsFormat = number[][];

export const getAvaragePoint = (points: number[]): Vector2d => {
  let totalX = 0;
  let totalY = 0;

  for (let i = 0; i < points.length; i += 2) {
    totalX += points[i];

    totalY += points[i + 1];
  }

  return {
    x: totalX / (points.length / 2),
    y: totalY / (points.length / 2),
  };
};

export const getDistance = (node1: number[], node2: number[]): string => {
  const diffX = Math.abs(node1[0] - node2[0]);
  const diffY = Math.abs(node1[1] - node2[1]);

  const distaneInPixel = Math.sqrt(diffX * diffX + diffY * diffY);

  return Number.parseFloat(String(distaneInPixel)).toFixed(2);
};

export const dragBoundFunc = (
  stageWidth: number,
  stageHeight: number,
  pos: Vector2d
): Vector2d => {
  let x = pos.x;
  let y = pos.y;

  if (pos.x + VERTEX_RADIUS > stageWidth) x = stageWidth;

  if (pos.x - VERTEX_RADIUS < 0) x = 0;

  if (pos.y + VERTEX_RADIUS > stageHeight) y = stageHeight;

  if (pos.y - VERTEX_RADIUS < 0) y = 0;

  return { x, y };
};

export const minMax = (points: number[]): number[] => {
  return points.reduce((acc: number[], val: number) => {
    acc[0] = acc[0] === undefined || val < acc[0] ? val : acc[0];

    acc[1] = acc[1] === undefined || val > acc[1] ? val : acc[1];

    return acc;
  }, []);
};

export const colorOfAnnotationType = (
  type: AnnotationType,
  theme: Theme
): string => {
  let color = "";

  switch (type) {
    case AnnotationType.EXCLUSION_AREA_STATIC:
    case AnnotationType.EXCLUSION_AREA:
      color = theme.palette.error.main;
      break;

    case AnnotationType.EQUIPMENT_TAG:
    case AnnotationType.FOCUS_AREA_LF:
      color = theme.palette.warning.main;
      break;

    case AnnotationType.COMBUSTION_AREA:
    case AnnotationType.COMBUSTION_AREA_STATIC:
    case AnnotationType.INCLUSION_AREA:
    case AnnotationType.INCLUSION_AREA_STATIC:
    case AnnotationType.TANK_LEVEL:
    case AnnotationType.SEARCH_AREA:
      color = theme.palette.primary.main;
      break;

    default:
      break;
  }

  return color;
};

export const convertFloatsToIntegers = (arr: PointsFormat) => {
  for (let i = 0; i < arr.length; i++) {
    for (let j = 0; j < arr[i].length; j++) {
      arr[i][j] = Math.round(arr[i][j]); // Convert float to integer using Math.round()
    }
  }

  return arr;
};

export const figureStyleLoyers = (fillColor: string, points: PointsFormat) => {
  const createLinePattern = (): HTMLCanvasElement | any => {
    const patternCanvas = document.createElement("canvas");
    const patternContext = patternCanvas.getContext("2d");

    patternCanvas.width = 4;

    patternCanvas.height = 4;

    if (!patternContext) return;

    patternContext.strokeStyle = fillColor + HEX_OPACITY_30;

    patternContext.beginPath();

    patternContext.moveTo(0, 4);

    patternContext.lineTo(4, 0);

    patternContext.stroke();

    return patternCanvas;
  };

  const solidFillLayer = new Konva.Shape({
    sceneFunc: (context, shape) => {
      context.beginPath();

      context.moveTo(points[0][0], points[0][1]);

      for (let i = 1; i < points.length; i++) {
        context.lineTo(points[i][0], points[i][1]);
      }

      context.closePath();

      context.fillStrokeShape(shape);
    },
    fill: fillColor + HEX_OPACITY_30,
    stroke: fillColor,
    strokeWidth: 1,
  });

  const patternLayer = new Konva.Shape({
    sceneFunc: (context, shape) => {
      context.beginPath();

      context.moveTo(points[0][0], points[0][1]);

      for (let i = 1; i < points.length; i++) {
        context.lineTo(points[i][0], points[i][1]);
      }

      context.closePath();

      context.fillStrokeShape(shape);
    },
    fillPatternImage: createLinePattern(),
    stroke: fillColor,
    strokeWidth: 1,
  });

  return { solidFillLayer, patternLayer };
};

import { ChangeEvent, FC } from "react";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Switch, { SwitchProps } from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

const StatusSwitch = styled(
  (props: SwitchProps): JSX.Element => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  )
)(({ theme }) => ({
  width: 44,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 100,
    backgroundColor: theme.palette.otherStandardInputLine.main,
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

interface StatusToggleProps {
  checked: boolean;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  label?: string;
  loading?: boolean;
}

const StatusToggle: FC<StatusToggleProps> = ({
  checked,
  handleChange,
  disabled,
  label,
  loading,
}): JSX.Element => {
  const SwitchLoadingIcon = (): JSX.Element => (
    <div className={`circle`}>
      {loading && (
        <CircularProgress size={14} color="secondary" thickness={6} />
      )}
    </div>
  );

  return (
    <FormGroup>
      <Box
        sx={{
          display: "flex",
          padding: "0",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FormControlLabel
          control={
            <StatusSwitch
              disabled={disabled}
              sx={{
                m: 1,
                ".circle": {
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: 22,
                  height: 22,
                  borderRadius: "50%",
                  backgroundColor: (theme): string =>
                    theme.palette.otherBackgroundLight.main,
                },
                ".active": {
                  backgroundColor: "primary.main",
                },
              }}
              checked={checked}
              icon={<SwitchLoadingIcon />}
              checkedIcon={<SwitchLoadingIcon />}
              onChange={handleChange}
            />
          }
          sx={{ margin: "0" }}
          label={""}
        />
        <Typography sx={{ width: label ? "60px" : "0" }} variant="buttonMedium">
          {label}
        </Typography>
      </Box>
    </FormGroup>
  );
};

export default StatusToggle;

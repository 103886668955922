import { useMutation, type FetchResult } from "@apollo/client";

import type {
  UpdateServiceInput,
  UpdateServiceMutation,
  UpdateServiceMutationVariables,
} from "../../../API";
import { UPDATE_SERVICE } from "../../../common/operations/mutations";

interface UpdateServiceInterface {
  updateService: (
    input: UpdateServiceInput
  ) => Promise<FetchResult<UpdateServiceMutation>>;
  data?: UpdateServiceMutation | null;
  loading: boolean;
}

export const useUpdateService = (): UpdateServiceInterface => {
  const [putService, { data, loading }] = useMutation<
    UpdateServiceMutation,
    UpdateServiceMutationVariables
  >(UPDATE_SERVICE);

  const updateService = async (
    input: UpdateServiceInput
  ): Promise<FetchResult<UpdateServiceMutation>> => {
    return await putService({
      variables: {
        input,
      },
    });
  };

  return { updateService, data, loading };
};

import {
  BufferGeometry,
  Float32BufferAttribute,
  Object3D,
  Points,
  PointsMaterial,
} from "three";

import { loadTexture } from "../SceneUtils/AssetLoaders";
import { degToRad } from "three/src/math/MathUtils";

import texture from "../../assets/texture/snow.png";
import { GROUND_RADIUS_IN_M } from "../Constants";

class SnowSystem {
  constructor(scene) {
    this.scene = scene;

    this.snowCountMax = 100000;

    this.maxRange = GROUND_RADIUS_IN_M * 2;

    this.minRange = this.maxRange / 4;

    this.minHeight = 0;

    this.windAngle = 0;

    this.windGust = 8;

    this.windSpeed = 4;

    this.texture = null;
  }

  addSnow = async (snow, wind, center) => {
    this.removeSnow();

    this.windAngle = wind.deg;

    this.windSpeed = wind.speed;

    this.windGust = wind.gust;

    this.snowCount = this.snowCountMax * snow;

    this.center = center;

    const snowGeo = new BufferGeometry();

    const positions = [];
    const velocities = [];

    for (let i = 0; i < this.snowCount; i++) {
      positions.push(
        (Math.random() - 0.5) * (this.maxRange - this.minRange),
        (Math.random() - 0.5) * this.minRange + this.minHeight,
        (Math.random() - 0.5) * (this.maxRange - this.minRange)
      );

      velocities.push(
        (Math.random() - 3) * 0.1,
        (Math.random() + 0.12) * 0.18,
        (Math.random() - 3) * 0.1
      );
    }

    snowGeo.setAttribute("position", new Float32BufferAttribute(positions, 3));

    snowGeo.setAttribute("velocity", new Float32BufferAttribute(velocities, 3));

    if (!this.texture) {
      this.texture = await loadTexture(texture);
    }

    const material = new PointsMaterial({
      size: 0.7,
      color: "white",
      transparent: true,
      map: this.texture,
    });

    this.snowMesh = new Points(snowGeo, material);

    this.scene.add(this.snowMesh);

    this.snowMesh.position.copy(center);
  };

  removeSnow = () => {
    this.snowMesh && this.scene.remove(this.snowMesh);
  };

  snowAnimation = dt => {
    const Coef = 100;
    const CoefXZ = Coef / 10;
    const g = 9.8;

    const position = this.snowMesh.geometry.attributes.position.array;
    const velocity = this.snowMesh.geometry.attributes.velocity.array;

    for (let i = 0; i < this.snowCount * 3; i += 3) {
      const step = dt * dt * g * (Math.random() + 0.01) * Coef;

      position[i] +=
        (velocity[i] + this.windSpeed + Math.random(0, this.windGust)) *
        Math.sin(degToRad(this.windAngle)) *
        dt *
        CoefXZ;

      position[i + 1] -= step;

      position[i + 2] +=
        (velocity[i + 2] + this.windSpeed + Math.random(0, this.windGust)) *
        Math.cos(degToRad(this.windAngle)) *
        dt *
        CoefXZ;

      if (position[i + 1] < 0) {
        position[i] = (Math.random() - 0.5) * (this.maxRange - this.minRange);

        position[i + 1] =
          (Math.random() - 0.5) * this.minRange + this.minHeight;

        position[i + 2] =
          (Math.random() - 0.5) * (this.maxRange - this.minRange);
      }

      this.snowMesh.geometry.attributes.position.needsUpdate = true;
    }
  };

  update(dt) {
    this.snowMesh && this.snowAnimation(dt);
  }
}

export default SnowSystem;

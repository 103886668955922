import { useContext } from "react";
import { Box, Button, Typography, styled } from "@mui/material";

import ModalWindow from "../../ModalWindow";
import TextInput from "../../input/TextInput";
import NumberInput from "../../input/NumberInput";

import { ProjectContext } from "../../../context";

import { useTextInputState } from "../../../hooks/useTextInputState";
import { useNumberInputState } from "../../../hooks/useNumberInputState";
import { useGetValueInCurrentMeasurementSystem } from "../../../hooks/useGetValueInCurrentMeasurementSystem";

import {
  numberValidationRangeAndMultipleCheck,
  textNameValidation,
} from "../../../validation";

import TowersData from "../../../data/TowersData";

interface AddTowerModalInterface {
  onClose: () => void;
}

const AddTowerModal = ({ onClose }: AddTowerModalInterface): JSX.Element => {
  const { addTower, measurementSystem } = useContext(ProjectContext);
  const { getHeightValueFromFoot, getHeightValueForData } =
    useGetValueInCurrentMeasurementSystem(measurementSystem);

  const towerName = useTextInputState(
    {
      value: "New Tower",
      error: false,
      errorMessage: "",
      trackError: true,
    },
    textNameValidation
  );

  const towerHeight = useNumberInputState(
    {
      value: `${getHeightValueFromFoot(44)}`,
      step: getHeightValueFromFoot(1),
      min: getHeightValueFromFoot(20),
      max: getHeightValueFromFoot(60),
      error: false,
      errorMessage: "",
      trackError: true,
    },
    numberValidationRangeAndMultipleCheck
  );

  const handleOnAddTower = () => {
    addTower({
      ...TowersData[0],
      name: towerName.state.value,
      height: getHeightValueForData(parseFloat(towerHeight.state.value)),
    });

    onClose();
  };

  const handleOnTowerNameInputChange = (value: string) => {
    towerName.onChange(value);
  };

  const handleOnTowerHeightInputChange = (value: string) => {
    towerHeight.onChange(value);
  };

  const isTowerNameError = towerName.state.trackError && towerName.state.error;
  const isTowerHeightError =
    towerHeight.state.trackError && towerHeight.state.error;

  return (
    <ModalWindow isOpen={true} onClose={onClose} modalWidth="390px">
      <Wrapper>
        <Title variant="h2">Add new Tower</Title>

        <Main>
          <TextInput
            label="Name:"
            viewType="row"
            labelWidth="40%"
            value={towerName.state.value}
            isError={isTowerNameError}
            hint={{
              type: !isTowerNameError ? "default" : "error",
              text: !isTowerNameError
                ? "From 3 to 30 characters"
                : towerName.state.errorMessage,
            }}
            handleOnChange={handleOnTowerNameInputChange}
          />
          <NumberInput
            label="Height:"
            step={towerHeight.state.step}
            min={towerHeight.state.min}
            max={towerHeight.state.max}
            viewType="row"
            labelWidth="40%"
            inputWidth="100px"
            value={towerHeight.state.value}
            isError={isTowerHeightError}
            hint={{
              type: !isTowerHeightError ? "default" : "error",
              text: !isTowerHeightError
                ? `From ${towerHeight.state.min} to ${towerHeight.state.max} and multiple of ${towerHeight.state.step}`
                : towerHeight.state.errorMessage,
            }}
            handleOnChange={handleOnTowerHeightInputChange}
          />
        </Main>

        <Footer>
          <Button onClick={onClose} variant="secondary" color="blue">
            Cancel
          </Button>
          <Button
            disabled={isTowerNameError || isTowerHeightError}
            onClick={handleOnAddTower}
            variant="primary"
            color="blue"
          >
            Add
          </Button>
        </Footer>
      </Wrapper>
    </ModalWindow>
  );
};

export default AddTowerModal;

const Wrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "10px",
}));

const Title = styled(Typography)(({ theme }) => ({
  padding: "0 0 6px",
  color: theme.palette["base-label"],
}));

const Main = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "10px",
}));

const Footer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "flex-end",
  padding: "14px 0 0",
  gap: "8px",
}));

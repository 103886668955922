import { KeyboardEvent, useContext, useState } from "react";

import SceneObject3dContextMenu from "../../components/SceneObject3dContextMenu/SceneObject3dContextMenu";
import {
  SceneObject3dContextMenuAnchorInterface,
  SceneObject3dContextMenuContext,
} from "./SceneObject3dContextMenuContext";

import { ProjectContext } from "../../../../common/context";
import {
  EntityTypeEnum,
  ObjectDataInterface,
  TowerDataInterface,
} from "../../../../common/context/ProjectContext/ProjectEntitesTypes";

export const SceneObject3dContextMenuContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const {
    activeObjectsListId,
    addObject,
    addTower,
    objects,
    towers,
    handleDeleteObjectTowerData,
  } = useContext(ProjectContext);

  const [contextMenuAnchor, setContextMenuAnchor] =
    useState<SceneObject3dContextMenuAnchorInterface | null>(null);

  const [copiedData, setCopiedData] = useState<
    ObjectDataInterface | TowerDataInterface | null
  >(null);

  const handleOnCloseContextMenu = () => {
    setContextMenuAnchor(null);
  };

  const handleOnOpenContextMenu = (
    value: SceneObject3dContextMenuAnchorInterface
  ) => {
    setContextMenuAnchor(value);
  };

  const handleOnCopyObject = () => {
    const copiedEntityData =
      activeObjectsListId.length === 1 &&
      [...objects, ...towers].find(
        (item: ObjectDataInterface | TowerDataInterface) => {
          return item.id === activeObjectsListId[0];
        }
      );

    setCopiedData(copiedEntityData ? { ...copiedEntityData } : null);

    handleOnCloseContextMenu();
  };

  const handleOnPasteObject = () => {
    if (
      copiedData &&
      (copiedData.EntityType === EntityTypeEnum.Tower ||
        copiedData.EntityType === EntityTypeEnum.Object)
    ) {
      delete copiedData.position;

      delete copiedData.coordinates;

      copiedData.EntityType === EntityTypeEnum.Tower && addTower(copiedData);

      copiedData.EntityType === EntityTypeEnum.Object && addObject(copiedData);
    }

    handleOnCloseContextMenu();
  };

  const handleOnDeleteObject = () => {
    !!activeObjectsListId.length &&
      handleDeleteObjectTowerData(activeObjectsListId);

    handleOnCloseContextMenu();
  };

  const onContextMenuKeyPressed = (event: KeyboardEvent<HTMLDivElement>) => {
    const ctrlKeyIsPressed = event.ctrlKey || event.metaKey;

    if (ctrlKeyIsPressed && event.key === "c") {
      handleOnCopyObject();
    } else if (ctrlKeyIsPressed && event.key === "v") {
      handleOnPasteObject();
    } else if (
      (ctrlKeyIsPressed && event.key === "Backspace") ||
      event.key === "Delete"
    ) {
      handleOnDeleteObject();
    }
  };

  return (
    <SceneObject3dContextMenuContext.Provider
      value={{ handleOnOpenContextMenu, onContextMenuKeyPressed }}
    >
      {children}

      {contextMenuAnchor && (
        <SceneObject3dContextMenu
          contextMenuAnchor={contextMenuAnchor}
          onClose={handleOnCloseContextMenu}
          copyButtonSettings={{
            disabled: activeObjectsListId.length !== 1,
            onClick: handleOnCopyObject,
          }}
          pasteButtonSettings={{
            disabled: !copiedData,
            onClick: handleOnPasteObject,
          }}
          deleteButtonSettings={{
            disabled: !activeObjectsListId.length,
            onClick: handleOnDeleteObject,
          }}
        />
      )}
    </SceneObject3dContextMenuContext.Provider>
  );
};

import { Box } from "@mui/material";
import StyledLoadingButton from "../../../common/providers/theme/design-tokens/LoadingButton/StyledLoadingButton";

type DialogActionsProps = {
  onCloseDialogHandler: () => void;
  loading: boolean;
  cancelText: string;
  confirmText: string;
};

const DialogActions: React.FC<DialogActionsProps> = ({
  onCloseDialogHandler,
  loading,
  cancelText,
  confirmText,
}): JSX.Element => {
  return (
    <Box
      sx={{
        display: "flex",
        gap: "1em",
      }}
    >
      <StyledLoadingButton
        sx={{
          textTransform: "none",
        }}
        variant="outlined"
        onClick={onCloseDialogHandler}
        size="medium"
        color="inherit"
      >
        {cancelText}
      </StyledLoadingButton>

      <StyledLoadingButton
        sx={{
          textTransform: "none",
        }}
        type="submit"
        loading={loading}
        variant="contained"
        size="medium"
      >
        {confirmText}
      </StyledLoadingButton>
    </Box>
  );
};

export default DialogActions;

import { Outlet } from "react-router-dom";
import { Box, styled } from "@mui/material";
import { type CSSProperties } from "@mui/material/styles/createMixins";

import WebglConfiguratorHeaderTopBar from "../../../../common/components/layout/WebglConfiguratorHeaderTopBar";
import Loader from "../loader/Loader";

import WebglMuiThemeProvider from "../../theme/WebglMuiThemeProvider";
import {
  ConfirmationModalContextProvider,
  NotificationContextProvider,
  ProjectModalContextProvider,
} from "../../context";

import { useMapLocation } from "../../../pages/dashboard/hooks/useMapLocation";

const WebglConfiguratorLayout = () => {
  const { isGoogleMapLibLoaded } = useMapLocation();

  return (
    <Box>
      {isGoogleMapLibLoaded ? (
        <>
          <WebglConfiguratorHeaderTopBar />

          <MainWrapper component="main">
            <WebglMuiThemeProvider>
              <ProjectModalContextProvider>
                <ConfirmationModalContextProvider>
                  <NotificationContextProvider>
                    <Outlet />
                  </NotificationContextProvider>
                </ConfirmationModalContextProvider>
              </ProjectModalContextProvider>
            </WebglMuiThemeProvider>
          </MainWrapper>
        </>
      ) : (
        <Loader />
      )}
    </Box>
  );
};

export default WebglConfiguratorLayout;

const MainWrapper = styled(Box)(
  (): CSSProperties => ({
    height: "calc(100vh - 50px)",
    padding: "0 1.25em 0.5em",
    backgroundColor: "white",
  })
);

import { FogExp2 } from "three";

import RainSystem from "./WeatherConditions/RainSystem";
import SnowSystem from "./WeatherConditions/SnowSystem";

import winterTexturePath from "../assets/texture/sky/T_skydome_a01_winter_BC.png";
import rainTexturePath from "../assets/texture/sky/T_skydome_a01_rain_BC.png";
import cloudsTexturePath from "../assets/texture/sky/clouds.png";

import { loadTexture } from "./SceneUtils/AssetLoaders";

class WeatherConditionsAnimations {
  constructor(scene, sceneLocationCenter, skybox) {
    this.scene = scene;

    this.sceneLocationCenter = sceneLocationCenter;

    this.skybox = skybox;

    this.rain = new RainSystem(this.scene);

    this.snow = new SnowSystem(this.scene);

    this.scene.fog = new FogExp2(0xdfe9f3, 0);

    this.winterTexture = null;

    this.rainTexture = null;

    this.cloudsTexture = null;
  }

  setWeather = ({ rain, snow, wind, clouds, visibility }) => {
    this.rain.removeRain();

    this.snow.removeSnow();

    if (rain) {
      this.rain.addRain(rain, wind, this.sceneLocationCenter);

      this.setRainSky(rain, wind, clouds);
    } else if (snow) {
      this.snow.addSnow(snow, wind, this.sceneLocationCenter);

      this.setWinterSky(snow, wind, clouds);
    } else {
      this.setSky(wind, clouds);
    }

    this.scene.fog.density = (10000 / visibility) * 0.0001;
  };

  setWinterSky = async (snow, wind, clouds) => {
    const color = snow > 6 ? 0x86929b : 0xc7d9dc;

    if (clouds > 50 && !this.cloudsTexture) {
      this.cloudsTexture = await loadTexture(cloudsTexturePath);
    } else if (!this.winterTexture) {
      this.winterTexture = await loadTexture(winterTexturePath);
    }

    const texture = clouds > 50 ? this.cloudsTexture : this.winterTexture;

    this.skybox.changeSky(texture, clouds, color, wind);
  };

  setRainSky = async (rain, wind, clouds) => {
    const color = rain > 6 ? 0x192a36 : 0x81919c;

    if (!this.rainTexture) {
      this.rainTexture = await loadTexture(rainTexturePath);
    }

    this.skybox.changeSky(this.rainTexture, clouds, color, wind);
  };

  setSky = async (wind, clouds) => {
    if (clouds > 50 && !this.cloudsTexture) {
      this.cloudsTexture = await loadTexture(cloudsTexturePath);
    } else if (!this.winterTexture) {
      this.winterTexture = await loadTexture(winterTexturePath);
    }

    const texture = clouds > 50 ? this.cloudsTexture : this.winterTexture;

    const color = clouds > 50 ? 0x5f6b74 : "white";
    const cloudsIntensity = clouds > 50 ? clouds / 2 : clouds * 2;

    this.skybox.changeSky(texture, cloudsIntensity, color, wind);
  };

  removeWeather = () => {
    this.rain.removeRain();

    this.snow.removeSnow();

    this.skybox.setDefault();

    this.scene.fog.density = 0;
  };

  update(dt) {
    this.rain.update(dt);

    this.snow.update(dt);
  }
}

export default WeatherConditionsAnimations;

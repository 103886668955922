import { KeyboardEvent, createContext } from "react";

export interface SceneObject3dContextMenuAnchorInterface {
  left: number;
  top: number;
}

export interface SceneObject3dContextMenuContextInterface {
  handleOnOpenContextMenu: (
    value: SceneObject3dContextMenuAnchorInterface
  ) => void;
  onContextMenuKeyPressed: (event: KeyboardEvent<HTMLDivElement>) => void;
}

export const SceneObject3dContextMenuContext =
  createContext<SceneObject3dContextMenuContextInterface>(
    {} as SceneObject3dContextMenuContextInterface
  );

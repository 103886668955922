import { Button, SxProps, Theme, Typography } from "@mui/material";
import React from "react";

type SimpleActionButtonProps = {
  onClick?: () => void;
  sx?: SxProps<Theme>;
  icon: JSX.Element;
  text: string;
  disabled?: boolean;
};

const SimpleActionButton: React.FC<SimpleActionButtonProps> = ({
  onClick,
  icon,
  text,
  sx,
  disabled,
}) => {
  return (
    <Button
      variant="outlined"
      startIcon={icon}
      disabled={disabled}
      sx={{
        display: "flex",
        gap: "6px",
        padding: "8px 12px",
        alignItems: "center",
        textTransform: "none",
        borderRadius: "0.5em",
        height: "32px",
        ...sx,
      }}
      onClick={disabled ? undefined : onClick}
    >
      <Typography variant="subtitle1">{text}</Typography>
    </Button>
  );
};

export default SimpleActionButton;

import { MathUtils, Vector3 } from "three";

import CameraBuilder from "../SceneObjectBuilder/CameraBuilder";

import { EntityTypeEnum } from "../../context/ProjectContext/ProjectEntitesTypes";
import {
  calculateHorizontalFOV,
  calculateVerticalFOV,
  constrainAngle,
  feetToMeters,
  getHorizontalAngleForObjectInDeg,
} from "../SceneUtils/MathUtils";

class FacilityCameras {
  constructor(
    scene,
    objectsGroup,
    cameraHelpers,
    secondaryViewportDimensions,
    northVector
  ) {
    this.scene = scene;

    this.objectsGroup = objectsGroup;

    this.cameraHelpers = cameraHelpers;

    this.secondaryViewportDimensions = secondaryViewportDimensions;

    this.northVector = northVector;

    this.initCameraHeight = feetToMeters(10);

    this.cameraBuilder = new CameraBuilder();
  }

  getAllCamera() {
    return this.objectsGroup.getObjectsByProperty(
      "EntityType",
      EntityTypeEnum.Camera
    );
  }

  getCameraById(id) {
    return this.getAllCamera().find(obj => obj.ID === id);
  }

  getSideByIndexInTower(index, tower) {
    return tower.getObjectByProperty("SideIndex", index);
  }

  getCameraByIdInTower(id, tower) {
    return tower.getObjectByProperty("ID", id);
  }

  async addCamera(cameraData, towerSceneObject) {
    const { objectToClone, materialToClone } =
      await this.cameraBuilder.getObjectInstance(cameraData);

    const object = this.cameraBuilder.createCameraInstance(
      objectToClone,
      materialToClone,
      cameraData,
      this.initCameraHeight,
      this.secondaryViewportDimensions
    );

    const sideSceneObject = this.getSideByIndexInTower(
      cameraData.sideIndex,
      towerSceneObject
    );

    const sideCameraWrapper =
      sideSceneObject.getObjectByName("SideCameraWrapper");

    sideCameraWrapper.add(object);

    const singlePerspectiveCamera = object.getObjectByName("SingleLensCamera");

    if (singlePerspectiveCamera) {
      this.cameraBuilder.setPerspectiveCameraRotation(
        object,
        cameraData.horizontalAngle,
        cameraData.verticalAngle,
        this.northVector
      );

      this.cameraHelpers.add(singlePerspectiveCamera, object.ID);
    }

    return object;
  }

  deleteCamera(camera) {
    this.cameraHelpers.remove(camera.ID);

    camera.removeFromParent();
  }

  getCameraState(camera) {
    if (camera) {
      return {
        height: this.getCameraHeight(camera),
        relativeHorizontalAngle: this.getCameraRelativePolarRotation(camera),
        horizontalAngle: this.getCameraPolarRotation(camera),
        verticalAngle: this.getCameraAzimuthRotation(camera),
        fov: this.getCameraHorizontalFOV(camera),
        zoom: this.getCameraZoom(camera),
      };
    }

    return {
      height: this.initCameraHeight,
      relativeHorizontalAngle: 0,
      horizontalAngle: 0,
      verticalAngle: 0,
      fov: 45,
      zoom: 1,
    };
  }

  getCameraHeight(camera) {
    return camera.position.y;
  }

  getCameraRelativePolarRotation(camera) {
    const cameraVertRotation = camera.getObjectByName("SM_Camera_Hor");
    const relativePolarInRad = cameraVertRotation
      ? cameraVertRotation.rotation.z
      : 0;
    const relativePolarInDeg = constrainAngle(
      MathUtils.radToDeg(relativePolarInRad) * -1
    );

    return relativePolarInDeg;
  }

  getCameraPolarRotation(camera) {
    const perspectiveCamera = camera.getObjectByName("SingleLensCamera");

    if (perspectiveCamera) {
      const polarInDeg = getHorizontalAngleForObjectInDeg(
        this.northVector,
        perspectiveCamera
      );

      return polarInDeg;
    }

    return 0;
  }

  getCameraAzimuthRotation(camera) {
    const cameraVertRotation = camera.getObjectByName("SM_Camera_Vert");
    const azimuthInRad = cameraVertRotation ? cameraVertRotation.rotation.x : 0;
    const azimuthInDeg = MathUtils.radToDeg(azimuthInRad) * -1;

    return azimuthInDeg;
  }

  getCameraHorizontalFOV(camera) {
    const perspectiveCamera = camera.getObjectByName("SingleLensCamera");

    const hFOV = perspectiveCamera
      ? Math.round(
          calculateHorizontalFOV(
            perspectiveCamera.fov,
            perspectiveCamera.aspect
          )
        )
      : 45;

    return hFOV;
  }

  getCameraZoom(camera) {
    const perspectiveCamera = camera.getObjectByName("SingleLensCamera");

    return perspectiveCamera?.zoom || 1;
  }

  updateCameraHeight(id, height) {
    const camera = this.getCameraById(id);

    camera.position.y = height;
  }

  updateCameraRotation(id, type, value) {
    const camera = this.getCameraById(id);

    if (type === "horizontalAngle") {
      const cameraPolarInDeg = getHorizontalAngleForObjectInDeg(
        this.northVector,
        camera
      );

      const angleToSet = value - cameraPolarInDeg;

      const cameraHorRotation = camera.getObjectByName("SM_Camera_Hor");

      cameraHorRotation.rotation.z = MathUtils.degToRad(angleToSet) * -1;
    }

    if (type === "relativeHorizontalAngle") {
      const cameraVertRotation = camera.getObjectByName("SM_Camera_Hor");

      cameraVertRotation.rotation.z = MathUtils.degToRad(value) * -1;
    }

    if (type === "verticalAngle") {
      const cameraVertRotation = camera.getObjectByName("SM_Camera_Vert");

      cameraVertRotation.rotation.x = MathUtils.degToRad(value) * -1;
    }
  }

  updateCameraFov(id, fov) {
    const camera = this.getCameraById(id);

    const perspectiveCamera = camera.getObjectByName("SingleLensCamera");

    const fieldOfView = calculateVerticalFOV(fov, perspectiveCamera.aspect);

    perspectiveCamera.fov = fieldOfView;

    perspectiveCamera.updateProjectionMatrix();
  }
}

export default FacilityCameras;

import { Box, Typography, styled } from "@mui/material";

import SaveProjectButton from "./SaveProjectButton";
import EditProjectButton from "./EditProjectButton";
import ExitFromProjectButton from "./ExitFromProjectButton";
import ShareProjectButton from "./ShareProjectButton";

import { DTProjectInterface } from "../../../../common/interfaces";

interface ProjectPageHeaderInterface {
  dtProject: DTProjectInterface;
}

const ProjectEditorPageHeader = ({ dtProject }: ProjectPageHeaderInterface) => {
  const descriptionText =
    dtProject.lastModificationDate && dtProject.lastModifierUserName
      ? `Edited ${dtProject.lastModificationDate} by ${dtProject.lastModifierUserName}`
      : "";

  return (
    <Wrapper>
      <Box>
        <Typography variant="title" component="h1">
          {dtProject.displayName}
        </Typography>
        <Description variant="body16Medium">{descriptionText}</Description>
      </Box>
      <ButtonsWrapper>
        <SaveProjectButton dtProject={dtProject} />
        <EditProjectButton dtProject={dtProject} />
        <ShareProjectButton />
        <ExitFromProjectButton />
      </ButtonsWrapper>
    </Wrapper>
  );
};

export default ProjectEditorPageHeader;

const Wrapper = styled(Box)(() => ({
  width: "100%",
  height: "60px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "start",
  padding: "10px 0",
}));

const ButtonsWrapper = styled(Box)(() => ({
  display: "flex",
  gap: "12px",
}));

const Description = styled(Typography)(({ theme }) => ({
  color: theme.palette["base-placeholder"],
}));

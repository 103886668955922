import * as THREE from "three";

import { CSS2DRenderer } from "three/addons/renderers/CSS2DRenderer.js";

import { SecondaryViewTypes } from "../data/SecondaryViewData";

import MapTiles from "./MapTiles";
import SceneFacilitySketchplan from "./SceneFacilitySketchplan/SceneFacilitySketchplan";
import SceneFacility3dObjects from "./SceneFacilityObjects/SceneFacility3dObjects";
import SceneControls from "./Controls/SceneControls";
import CameraHelpers from "./CameraHelpers";
import PostEffects from "./PostEffects";
import DistanceMeasurementLabels from "./DistanceMeasurementLabels";
import SceneSkybox from "./SceneSkybox";

import { loadTexture } from "./SceneUtils/AssetLoaders";

import skyboxTexturePath from "../assets/texture/sky/T_skydome_a01_winter_BC.png";

import { GROUND_RADIUS_IN_M } from "./Constants";
import WeatherConditionsAnimations from "./WeatherConditionsAnimations";
import {
  calculateHorizontalFOV,
  calculateVerticalFOV,
} from "./SceneUtils/MathUtils";
import { getCoordinatesFromLatLng } from "./SceneUtils/CartographicUtils";

class ThreeScene {
  constructor(
    mainCanvasElement,
    secondaryCanvasElement,
    mainViewportElement,
    secondaryViewportElement,
    renderer2DElement
  ) {
    this.mainCanvasElement = mainCanvasElement;

    this.secondaryCanvasElement = secondaryCanvasElement;

    this.mainViewportElement = mainViewportElement;

    this.secondaryViewportElement = secondaryViewportElement;

    this.renderer2DElement = renderer2DElement;

    this.mainViewportDimensions = {
      width: this.mainViewportElement.clientWidth,
      height: this.mainViewportElement.clientHeight,
    };

    this.secondaryViewportDimensions = {
      width: this.secondaryViewportElement.clientWidth,
      height: this.secondaryViewportElement.clientHeight,
    };

    this.clock = new THREE.Clock();

    this.scene = this.initScene();

    this.mainRenderer = this.initRenderer(
      this.mainCanvasElement,
      this.mainViewportDimensions
    );

    this.secondaryRenderer = this.initRenderer(
      this.secondaryCanvasElement,
      this.secondaryViewportDimensions
    );

    this.distanceMeasurementRenderer = this.initCSS2DRenderer(
      this.renderer2DElement,
      this.secondaryViewportDimensions
    );

    this.initPerspectiveCamera();

    this.initOrthographicCamera();

    this.mainCamera = this.perspectiveCamera;

    this.secondaryCamera = null;

    this.secondaryViewType = null;

    this.distanceMeasurementsMode = null;

    this.postEffects = new PostEffects(this.secondaryRenderer, this.scene);

    this.postEffects.initComposer(this.mainCamera);

    this.cameraHelpers = new CameraHelpers(this.scene);

    this.distanceMeasurementLabels = new DistanceMeasurementLabels(this.scene);

    this.addLight(new THREE.Vector3(120, 80, 0));

    this.hdrTexture = null;

    this.sceneControls = new SceneControls(
      this.scene,
      this.mainCamera,
      this.mainViewportElement,
      this.secondaryViewportElement,
      this.sceneOrbitCenter
    );

    this.sceneOrbitCenter = this.sceneControls.orbitControl.target;

    this.sceneLocationCenter = new THREE.Vector3();

    this.northVector = new THREE.Vector3();

    this.facilityBbox = new THREE.Box3();

    this.mapTiles = new MapTiles(
      this.scene,
      this.mainCamera,
      this.mainRenderer,
      this.sceneControls.orbitControl,
      this.sceneLocationCenter
    );

    this.sceneFacilitySketchplan = new SceneFacilitySketchplan(
      this.scene,
      this.mapTiles
    );

    this.sceneFacility3dObjects = new SceneFacility3dObjects(
      this.scene,
      this.secondaryViewportDimensions,
      this.sceneOrbitCenter,
      this.cameraHelpers,
      this.hdrTexture,
      this.mapTiles,
      this.northVector
    );

    this.skybox = new SceneSkybox(this.scene, this.sceneLocationCenter);

    this.weatherConditions = new WeatherConditionsAnimations(
      this.scene,
      this.sceneLocationCenter,
      this.skybox
    );
  }

  dispose() {
    this.mapTiles.dispose();

    this.mainRenderer?.dispose();

    this.secondaryRenderer?.dispose();

    this.sceneControls.dispose();
  }

  initScene() {
    const scene = new THREE.Scene();

    scene.background = new THREE.Color(0xffffff);

    return scene;
  }

  initRenderer(canvasElement, viewportDimensions) {
    const { width, height } = viewportDimensions;
    const DPR = window.devicePixelRatio ? window.devicePixelRatio : 1;

    const renderer = new THREE.WebGLRenderer({
      antialias: true,
      canvas: canvasElement,
    });

    renderer.outputColorSpace = THREE.SRGBColorSpace;

    renderer.toneMapping = THREE.ACESFilmicToneMapping;

    renderer.toneMappingExposure = 0.6;

    renderer.shadowMap.enabled = true;

    renderer.shadowMap.type = THREE.PCFSoftShadowMap;

    renderer.useLegacyLights = false;

    renderer.setPixelRatio(DPR);

    renderer.setSize(width, height);

    return renderer;
  }

  initCSS2DRenderer(element, viewportDimensions) {
    const { width, height } = viewportDimensions;
    const renderer2d = new CSS2DRenderer({ element: element });

    renderer2d.setSize(width, height);

    return renderer2d;
  }

  initPerspectiveCamera() {
    const { width, height } = this.mainViewportDimensions;

    const fieldOfView = 45;
    const aspectRatio = width / height;
    const nearClip = 1;
    const farClip = 10000;

    this.perspectiveCamera = new THREE.PerspectiveCamera(
      fieldOfView,
      aspectRatio,
      nearClip,
      farClip
    );

    this.scene.add(this.perspectiveCamera);

    return this.perspectiveCamera;
  }

  initOrthographicCamera() {
    const { width, height } = this.mainViewportDimensions;
    const nearClip = 1;
    const farClip = 10000;

    this.orthographicCamera = new THREE.OrthographicCamera(
      width / -2,
      width / 2,
      height / 2,
      height / -2,
      nearClip,
      farClip
    );

    this.scene.add(this.orthographicCamera);

    return this.orthographicCamera;
  }

  addLight(position) {
    const light = new THREE.DirectionalLight(0xffffff, 1);

    light.position.set(position.x, position.y, position.z);

    this.scene.add(light);

    const light2 = new THREE.DirectionalLight(0xffffff, 3);

    light2.position.set(20, 50, 0);

    this.scene.add(light2);

    const hemiLight = new THREE.HemisphereLight(0xffffff, 0xffffff, 1);

    hemiLight.color.setHSL(0.6, 1, 0.6);

    hemiLight.groundColor.setHSL(0.095, 1, 0.75);

    hemiLight.position.set(0, 50, 0);

    this.scene.add(hemiLight);
  }

  calculateFacilityBbox() {
    this.facilityBbox.setFromCenterAndSize(
      this.sceneLocationCenter.clone(),
      new THREE.Vector3(GROUND_RADIUS_IN_M / 2, 5, GROUND_RADIUS_IN_M / 2)
    );
  }

  calculateNorthVector(latitude, longitude, elevation, elevationDiff) {
    const getVectorFromLatLon = (latitude, longitude) => {
      const vector = getCoordinatesFromLatLng(
        latitude,
        longitude,
        elevation + elevationDiff
      );

      const tilesGroupMatrix = this.mapTiles.tiles.group.matrixWorld.clone();

      vector.applyMatrix4(tilesGroupMatrix);

      return vector;
    };

    const vector1 = getVectorFromLatLon(latitude, longitude);
    const vector2 = getVectorFromLatLon(latitude + 0.0001, longitude);

    const direction = vector2.clone().sub(vector1).normalize();

    this.northVector.copy(direction);
  }

  async loadFacility(terrainData, kmzLayerURL) {
    const {
      shapeData,
      locationCoordinates,
      locationElevation,
      kmzLayerPlaneData,
    } = terrainData;
    const [latitude, longitude] = locationCoordinates;

    await this.loadSceneHdrTexture();

    await this.mapTiles.loadTiles(latitude, longitude);

    this.sceneControls.orbitControl.setCameraPosition(300, 25, 75);

    this.sceneControls.orbitControl.saveState();

    const elevationDiff = this.sceneOrbitCenter.y - locationElevation;

    this.calculateNorthVector(
      latitude,
      longitude,
      locationElevation,
      elevationDiff
    );

    this.calculateFacilityBbox();

    shapeData &&
      this.sceneFacilitySketchplan.loadSketchplanGeojsonLayer(
        shapeData,
        elevationDiff
      );

    kmzLayerURL &&
      kmzLayerPlaneData &&
      (await this.sceneFacilitySketchplan.loadSketchplanKMZLayer(
        kmzLayerURL,
        kmzLayerPlaneData,
        elevationDiff
      ));

    this.sceneControls.addSettings(
      this.facilityBbox,
      this.mapTiles.tiles.group,
      this.sceneFacility3dObjects.objectsGroup
    );

    this.skybox.defaultTexture = this.hdrTexture;

    await this.skybox.addSkybox(this.hdrTexture);
  }

  changeCortrolMode() {
    this.sceneControls.setActiveCortrols(
      this.secondaryCamera,
      this.secondaryViewType,
      this.distanceMeasurementsMode
    );
  }

  switchMainCamera(isOrthographic) {
    if (isOrthographic) {
      this.orthographicCamera.position.copy(this.perspectiveCamera.position);

      this.orthographicCamera.rotation.copy(this.perspectiveCamera.rotation);

      this.mainCamera = this.orthographicCamera;

      this.sceneControls.orbitControl.object = this.mainCamera;

      this.sceneControls.orbitControl.update();
    } else {
      this.perspectiveCamera.position.copy(this.orthographicCamera.position);

      this.perspectiveCamera.rotation.copy(this.orthographicCamera.rotation);

      this.mainCamera = this.perspectiveCamera;

      this.sceneControls.orbitControl.object = this.mainCamera;

      this.sceneControls.orbitControl.update();
    }
  }

  async loadSceneHdrTexture() {
    try {
      this.hdrTexture = await loadTexture(skyboxTexturePath);
    } catch (error) {
      console.error("Errorr loadSceneHdrTexture: ", error);
    }
  }

  onWindowResize(main, secondary) {
    const { width: mainWidth, height: mainHeight } = main;
    const { width: secondaryWidth, height: secondaryHeight } = secondary;

    this.mainViewportDimensions.width = mainWidth;

    this.mainViewportDimensions.height = mainHeight;

    this.secondaryViewportDimensions.width = secondaryWidth;

    this.secondaryViewportDimensions.height = secondaryHeight;

    const aspect = mainWidth / mainHeight;

    this.perspectiveCamera.aspect = aspect;

    this.perspectiveCamera.updateProjectionMatrix();

    const frustumHeight =
      this.orthographicCamera.top - this.orthographicCamera.bottom;

    this.orthographicCamera.left = (-frustumHeight * aspect) / 2;

    this.orthographicCamera.right = (frustumHeight * aspect) / 2;

    this.orthographicCamera.top = frustumHeight / 2;

    this.orthographicCamera.bottom = -frustumHeight / 2;

    this.orthographicCamera.updateProjectionMatrix();

    this.mainRenderer.setSize(mainWidth, mainHeight);

    this.postEffects.resize(mainWidth, mainHeight);

    if (this.secondaryCamera) {
      const camera = this.secondaryCamera.getObjectByName("SingleLensCamera");

      if (camera) {
        const newAspect = secondaryWidth / secondaryHeight;

        const fovInData = calculateHorizontalFOV(camera.fov, camera.aspect);

        const newFov = calculateVerticalFOV(fovInData, newAspect);

        camera.aspect = newAspect;

        camera.fov = newFov;

        camera.updateProjectionMatrix();
      }

      this.secondaryRenderer.setSize(secondaryWidth, secondaryHeight);

      this.distanceMeasurementRenderer.setSize(secondaryWidth, secondaryHeight);
    }
  }

  renderMainCameraViewport() {
    this.cameraHelpers?.setVisibility(true);

    this.sceneControls.distanceMeasurementControl?.setLazerVisibility(false);

    if (this.sceneControls.transformControl) {
      this.sceneControls.transformControl.toggleVisibility(true);
    }

    this.mapTiles.render(this.mainCamera, this.mainRenderer);

    this.mainRenderer.render(this.scene, this.mainCamera);
  }

  renderSecondaryCameraViewport() {
    this.cameraHelpers?.setVisibility(false);

    if (this.distanceMeasurementsMode) {
      this.sceneControls.distanceMeasurementControl.setLazerVisibility(true);
    }

    if (this.sceneControls.transformControl) {
      this.sceneControls.transformControl.toggleVisibility(false);
    }

    if (this.secondaryViewType === SecondaryViewTypes.viewGas) {
      const camera = this.secondaryCamera.getObjectByName("SingleLensCamera");

      this.secondaryRenderer.setScissorTest(false);

      this.secondaryRenderer.render(this.scene, camera);

      this.postEffects.render(camera);
    } else if (this.secondaryViewType === SecondaryViewTypes.viewRGB) {
      const camera = this.secondaryCamera.getObjectByName("SingleLensCamera");

      this.secondaryRenderer.setScissorTest(false);

      this.mapTiles.render(camera, this.secondaryRenderer);

      this.secondaryRenderer.render(this.scene, camera);
    } else if (this.secondaryViewType === SecondaryViewTypes.view360) {
      const view360Cameras = this.secondaryCamera.getObjectsByProperty(
        "name",
        "360LensCamera"
      );

      const { width: viewportWidth, height: viewportHeight } =
        this.secondaryViewportDimensions;

      view360Cameras.forEach(camera => {
        const index = camera.parent.name.slice(-1) - 1;

        const left = Math.floor(viewportWidth * 0.25 * index);
        const bottom = 0;
        const width = Math.floor(viewportWidth * 0.25);
        const height = viewportHeight;

        this.secondaryRenderer.setViewport(left, bottom, width, height);

        this.secondaryRenderer.setScissor(left, bottom, width, height);

        this.secondaryRenderer.setScissorTest(true);

        camera.aspect = width / height;

        camera.updateProjectionMatrix();

        this.mapTiles.render(camera, this.secondaryRenderer);

        this.secondaryRenderer.render(this.scene, camera);
      });
    }
  }

  renderDistanceMeasurementViewport() {
    const camera = this.secondaryCamera.getObjectByName("SingleLensCamera");

    this.distanceMeasurementRenderer?.render(this.scene, camera);
  }

  update() {
    const dt = this.clock.getDelta();

    this.sceneControls.update(dt);

    this.cameraHelpers.update();

    this.renderMainCameraViewport();

    if (this.secondaryCamera) {
      this.renderSecondaryCameraViewport();
    }

    if (this.secondaryCamera && this.distanceMeasurementsMode) {
      this.renderDistanceMeasurementViewport();
    }

    this.weatherConditions.update(dt);

    this.skybox.update(dt);
  }
}

export default ThreeScene;

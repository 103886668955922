import React, { useEffect, useMemo, useState } from "react";

import { Box, useTheme } from "@mui/material";
import { KonvaEventObject } from "konva/lib/Node";
import { Image, Layer, Stage } from "react-konva";

import { Annotation, AnnotationType } from "../../../API";
import { AnnotationTableItem } from "../../../pages/devices/annotations/AnnotationContainer";
import AnnotationLegend from "../../../pages/devices/annotations/AnnotationLegend";
import SimpleActionButton from "../button/SimpleActionButton";
import ResetDrawPolygonIcon from "../icons/ResetDrawPolygonIcon";
import UndoDrawPolygonIcon from "../icons/UndoDrawPolygonIcon";
import Polygon from "./Polygon";
import Rectangle from "./Rectangle";
import useCreateFullAnnotationContainer from "./useCreateFullAnnotationContainer";
import { KONVA_HEIGHT, PointsFormat } from "./utils";

export enum CanvasFigure {
  RECTANGLE,
  POLYGON,
}

type CanvasContainerProps = {
  points: PointsFormat;
  editMode: boolean;
  figureType: CanvasFigure;
  onSetPoints: (newPoints: PointsFormat) => void;
  annotationsData: Annotation[];
  typeOfAnnotation: AnnotationType;
  legendItems: AnnotationTableItem[];
  image?: HTMLImageElement;
  imageRef: React.MutableRefObject<any>;
  scaleX: number;
  scaleY: number;
  skaleFactor: number;
};

const CanvasContainer: React.FC<CanvasContainerProps> = ({
  points,
  editMode,
  figureType,
  onSetPoints,
  annotationsData,
  typeOfAnnotation,
  legendItems,
  image,
  imageRef,
  scaleX,
  scaleY,
  skaleFactor,
}) => {
  const [position, setPosition] = useState([0, 0]);
  const [flattenedPoints, setFlattenedPoints] = useState<number[]>([0]);
  const [isMouseOverPoint, setMouseOverPoint] = useState(false);
  const [isPolyComplete, setPolyComplete] = useState(editMode);

  const { fullAnnContainerRef } = useCreateFullAnnotationContainer(
    annotationsData,
    scaleX,
    scaleY
  );

  const theme = useTheme();

  const initialRect = useMemo(
    () => ({
      x: 50 * skaleFactor,
      y: 50 * skaleFactor,
      width: 100 * skaleFactor,
      height: 100 * skaleFactor,
      fill: "",
    }),
    []
  );

  const figureIsPolygon = figureType === CanvasFigure.POLYGON;
  const figureIsRectangle = figureType === CanvasFigure.RECTANGLE;
  const moreThanOnePointIsAdded = points.length > 0;

  const initialRectangle = () => {
    if (editMode && figureIsRectangle) {
      const topLeft = { x: points[0][0] / scaleX, y: points[0][1] / scaleY };
      const bottomRight = {
        x: points[2][0] / scaleX,
        y: points[2][1] / scaleY,
      };

      initialRect.x = topLeft.x;

      initialRect.y = topLeft.y;

      initialRect.width = bottomRight.x - topLeft.x;

      initialRect.height = bottomRight.y - topLeft.y;
    }

    return initialRect;
  };

  const [rectangle, setRectangle] = React.useState(initialRectangle());

  const getMousePos = (stage: any, ml = false) => {
    if (ml) {
      return [
        stage.getPointerPosition().x * scaleX,
        stage.getPointerPosition().y * scaleY,
      ];
    }

    return [stage.getPointerPosition().x, stage.getPointerPosition().y];
  };

  const handleMouseDown = (e: KonvaEventObject<MouseEvent>) => {
    if (figureType === CanvasFigure.RECTANGLE || isPolyComplete) return;

    const stage = e.target.getStage();
    const mousePos = getMousePos(stage, true);
    const currentMousePos = getMousePos(stage);

    if (isMouseOverPoint && points.length >= 3) {
      setPolyComplete(true);
    } else {
      onSetPoints([...points, mousePos]);

      setPosition(currentMousePos);
    }
  };

  const handleMouseMove = (e: KonvaEventObject<MouseEvent>) => {
    if (figureType === CanvasFigure.RECTANGLE) return;

    const preventOnEditMode = editMode && isPolyComplete;
    const preventOnCreateMode = !editMode && !points.length && !isPolyComplete;

    if (preventOnEditMode || preventOnCreateMode) return;

    const stage = e.target.getStage();
    const mousePos = getMousePos(stage);

    setPosition(mousePos);
  };

  const handleMouseOverStartPoint = (e: KonvaEventObject<MouseEvent>) => {
    if (isPolyComplete || points.length < 3) return;

    e.target.scale({ x: 3, y: 3 });

    setMouseOverPoint(true);
  };

  const handleMouseOutStartPoint = (e: KonvaEventObject<MouseEvent>) => {
    e.target.scale({ x: 1, y: 1 });

    setMouseOverPoint(false);
  };

  const handlePointDragMove = (e: KonvaEventObject<DragEvent>) => {
    const stage = e.target.getStage();

    if (!stage) return;

    const index = e.target.index - 1;
    const pos = [e.target._lastPos.x * scaleX, e.target._lastPos.y * scaleY];

    if (pos[0] < 0) pos[0] = 0;

    if (pos[1] < 0) pos[1] = 0;

    if (pos[0] > stage.width() * scaleX) pos[0] = stage.width() * scaleX;

    if (pos[1] > stage.height() * scaleY) pos[1] = stage.height() * scaleY;

    onSetPoints([...points.slice(0, index), pos, ...points.slice(index + 1)]);
  };

  const onChangeRectHandler = (newAttrs: typeof initialRect): void => {
    setRectangle(newAttrs);

    const topLeft = newAttrs;

    const topRight = {
      x: newAttrs.x + newAttrs.width,
      y: newAttrs.y,
    };

    const bottomRight = {
      x: newAttrs.x + newAttrs.width,
      y: newAttrs.y + newAttrs.height,
    };

    const bottomLeft = {
      x: newAttrs.x,
      y: newAttrs.y + newAttrs.height,
    };

    onSetPoints([
      [topLeft.x * scaleX, topLeft.y * scaleY],
      [topRight.x * scaleX, topRight.y * scaleY],
      [bottomRight.x * scaleX, bottomRight.y * scaleY],
      [bottomLeft.x * scaleX, bottomLeft.y * scaleY],
    ]);
  };

  useEffect(() => {
    if (figureIsRectangle) {
      onChangeRectHandler(initialRectangle());
    }
  }, []);

  useEffect(() => {
    if (!image) return;

    setFlattenedPoints(
      points
        .map(p => [p[0] / scaleX, p[1] / scaleY])
        .concat(isPolyComplete ? [] : position)
        .reduce((a, b) => a.concat(b), [])
    );
  }, [points, isPolyComplete, image, position]);

  const undo = () => {
    onSetPoints(points.slice(0, -1));

    setPolyComplete(false);

    setPosition(points[points.length - 1]);
  };

  const reset = () => {
    onSetPoints([]);

    setPolyComplete(false);
  };

  const handleGroupDragEnd = (e: KonvaEventObject<DragEvent>) => {
    if (e.target.name() === "polygon") {
      const result: number[][] = [];

      const copyPoints = [...points];

      copyPoints.map(point =>
        result.push([
          point[0] + e.target.x() * scaleX,
          point[1] + e.target.y() * scaleY,
        ])
      );

      e.target.position({ x: 0, y: 0 });

      onSetPoints(result);
    }
  };

  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      <Stage
        width={KONVA_HEIGHT * skaleFactor}
        height={KONVA_HEIGHT}
        onMouseMove={handleMouseMove}
        onMouseDown={handleMouseDown}
      >
        <Layer>
          <Image
            ref={imageRef}
            image={image}
            x={0}
            y={0}
            width={KONVA_HEIGHT * skaleFactor}
            height={KONVA_HEIGHT}
          />
        </Layer>

        <Layer ref={fullAnnContainerRef} />

        <Layer>
          {figureIsPolygon && (
            <Polygon
              points={points.map(p => [p[0] / scaleX, p[1] / scaleY])}
              typeOfAnnotation={typeOfAnnotation}
              flattenedPoints={flattenedPoints}
              handlePointDragMove={handlePointDragMove}
              handleGroupDragEnd={handleGroupDragEnd}
              handleMouseOverStartPoint={handleMouseOverStartPoint}
              handleMouseOutStartPoint={handleMouseOutStartPoint}
              isFinished={isPolyComplete}
            />
          )}

          {figureIsRectangle && (
            <Rectangle
              shapeProps={rectangle}
              typeOfAnnotation={typeOfAnnotation}
              onChange={onChangeRectHandler}
            />
          )}
        </Layer>
      </Stage>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          height: "48px",
          position: "relative",
          backgroundColor: theme.palette.otherDivider.main,
        }}
      >
        {figureIsPolygon && moreThanOnePointIsAdded && (
          <Box
            sx={{
              display: "flex",
              gap: "0.5em",
              margin: "0 1.5em",
            }}
          >
            <SimpleActionButton
              text="Undo"
              icon={<UndoDrawPolygonIcon />}
              onClick={undo}
            />

            <SimpleActionButton
              text="Reset"
              icon={<ResetDrawPolygonIcon />}
              onClick={reset}
            />
          </Box>
        )}

        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <AnnotationLegend items={legendItems} />
        </Box>
      </Box>
    </Box>
  );
};

export default React.memo(CanvasContainer);

import { ONLINE_STATUS_THRESHOLD_MS } from "../variables/common";

export const isNetworkHealthOnline = (
  healthData: string | null | undefined
): boolean => {
  let parsedHealthData;

  try {
    parsedHealthData = JSON.parse(healthData ?? "{}");
  } catch (err) {
    console.error("Failed to parse health data: " + err);
  }

  if (!parsedHealthData?.lastAlive) {
    return false;
  }

  const currentTime = new Date().getTime();
  const timeSincePrevLastAlive = currentTime - parsedHealthData?.lastAlive;

  return timeSincePrevLastAlive < ONLINE_STATUS_THRESHOLD_MS;
};

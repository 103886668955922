import { useContext } from "react";
import { Box, Button, Stack, Typography, styled } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import ModalWindow from "../../../../common/components/ModalWindow";
import DTProjectNameInput from "../settingFields/DTProjectNameInput";
import DTProjectMeasurementSelector from "../settingFields/DTProjectMeasurementSelector";
import DTProjectTypeSelector from "./DTProjectTypeSelector";
import DTProjectLocationInput from "./DTProjectLocationInput";
import DTProjectTerrainInput from "./DTProjectTerrainInput";

import { MeasurementSystemMode } from "../../../../common/enums";

import CreateDTProjectContext, {
  CreateDTProjectContextInterface,
  ProjectFormViewType,
} from "../../context/CreateDTProjectContext/CreateDTProjectContext";

const CreateDTProjectForm = (): JSX.Element => {
  const {
    isOpenModal,
    closeModal,
    formViewType,
    onCreateProjectButton,
    isCreatingDisabled,
    isCreatingLoading,
    projectNameState,
    onProjectNameInputChange,
    onProjectMeasurementSystemChange,
  } = useContext(CreateDTProjectContext) as CreateDTProjectContextInterface;

  return (
    <ModalWindow isOpen={isOpenModal} onClose={closeModal}>
      <FormWrapper>
        <Typography variant="h2">Create new project</Typography>
        <DTProjectNameInput
          projectNameState={projectNameState}
          onProjectNameInputChange={onProjectNameInputChange}
        />
        <DTProjectMeasurementSelector
          defaultValue={MeasurementSystemMode.Imperial}
          onProjectMeasurementSystemChange={onProjectMeasurementSystemChange}
        />
        <DTProjectTypeSelector />

        {formViewType === ProjectFormViewType.ViaFile && (
          <DTProjectTerrainInput />
        )}

        {formViewType === ProjectFormViewType.ViaMap && (
          <DTProjectLocationInput />
        )}

        <Stack direction="row" spacing={1} justifyContent="flex-end">
          <Button
            disabled={isCreatingLoading}
            onClick={closeModal}
            variant="secondary"
            color="blue"
          >
            Cancel
          </Button>
          <LoadingButton
            disabled={isCreatingDisabled || isCreatingLoading}
            loading={isCreatingLoading}
            onClick={onCreateProjectButton}
            variant="primary"
            color="blue"
            endIcon={<></>}
            loadingPosition="end"
          >
            Create
          </LoadingButton>
        </Stack>
      </FormWrapper>
    </ModalWindow>
  );
};

export default CreateDTProjectForm;

const FormWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "18px",
  "& *": {
    color: theme.palette["base-label"],
  },
}));

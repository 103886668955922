import { useContext } from "react";
import { Box, Button, Typography, useTheme } from "@mui/material";

import { ProjectContext } from "../../../common/context";

const ProjectViewPageHeader = ({ dtProject }) => {
  const theme = useTheme();

  const { sceneStarted, exitFromProjectConfigurator } =
    useContext(ProjectContext);

  const onCloseButton = () => {
    exitFromProjectConfigurator();
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "60px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "start",
        padding: "10px 0",
      }}
    >
      <Box>
        <Typography variant="title" component="h1">
          {dtProject.displayName}
        </Typography>
        <Typography
          variant="body16Medium"
          sx={{
            color: theme.palette["base-placeholder"],
          }}
        >
          {dtProject.lastModificationDate && dtProject.lastModifierUserName
            ? `Edited ${dtProject.lastModificationDate} by ${dtProject.lastModifierUserName}`
            : ""}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "12px",
        }}
      >
        <Button
          onClick={onCloseButton}
          variant="secondary"
          color="blue"
          disabled={!sceneStarted}
        >
          Close
        </Button>
      </Box>
    </Box>
  );
};

export default ProjectViewPageHeader;

import {
  ApolloError,
  gql,
  useMutation,
  type FetchResult,
} from "@apollo/client";

import type {
  SetIsRunningServiceStatusMutation,
  SetIsRunningServiceStatusMutationVariables,
  SetServiceStatusInput,
} from "../../../API";
import { SET_RUNNING_SERVICE_STATUS } from "../../../common/operations/mutations";
import { errorNotification } from "../../../common/variables/notification";

interface UpdateServiceInterface {
  setIsRunningServiceStatus: (
    input: SetServiceStatusInput
  ) => Promise<FetchResult<SetIsRunningServiceStatusMutation>>;
  data?: SetIsRunningServiceStatusMutation | null;
  loading: boolean;
}

export const useSetIsRunningServiceStatus = (): UpdateServiceInterface => {
  const [updateStatus, { data, loading }] = useMutation<
    SetIsRunningServiceStatusMutation,
    SetIsRunningServiceStatusMutationVariables
  >(SET_RUNNING_SERVICE_STATUS);

  const setIsRunningServiceStatus = async (
    input: SetServiceStatusInput
  ): Promise<FetchResult<SetIsRunningServiceStatusMutation>> => {
    return await updateStatus({
      variables: {
        input,
      },
      onError: (error: ApolloError): void => {
        console.error(error);

        errorNotification(error.message);
      },
      update(cache, { data }) {
        if (data && data.setIsRunningServiceStatus) {
          cache.modify({
            fields: {
              getServicesByDevice(existing) {
                const newServiceRef = cache.writeFragment({
                  data: data.setIsRunningServiceStatus,
                  fragment: gql`
                    fragment NewService on Service {
                      configuration
                      customerId
                      deviceId
                      healthData
                      id
                      isRunning
                      locationId
                      nodeId
                      serviceType
                    }
                  `,
                });

                return {
                  ...existing,
                  items: existing.items.map((item: { id: any }) => {
                    if (item?.id !== data.setIsRunningServiceStatus.id) {
                      return item;
                    }

                    return newServiceRef;
                  }),
                };
              },
            },
          });
        }
      },
    });
  };

  return { setIsRunningServiceStatus, data, loading };
};

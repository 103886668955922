const TowersData = [
  {
    name: "Tower",
    pathKey: "camera-tower",
    assetsList: [
      {
        name: "bottom",
        path: require("../assets/3dmodels/Tower/SM_Tower_01.fbx"),
      },
      {
        name: "mid",
        path: require("../assets/3dmodels/Tower/SM_Tower_02.fbx"),
      },
      {
        name: "top",
        path: require("../assets/3dmodels/Tower/SM_Tower_03.fbx"),
      },
    ],
  },
];

export default TowersData;

import { readFileAsArrayBuffer } from "../utils";

interface ValidationResponseInterface {
  error: boolean;
  errorMessage: string;
}

const isPNG = [0x89, 0x50, 0x4e, 0x47, 0x0d, 0x0a, 0x1a, 0x0a];
const isJPEG = [0xff, 0xd8, 0xff];

const checkIsImageFileTypeValid = (buffers: Uint8Array): boolean => {
  return [isPNG, isJPEG].some(headers => {
    const checkHead = headers.every(
      (header, index) => header === buffers[index]
    );

    return checkHead;
  });
};

export const textureImageValidation = async (
  file: File
): Promise<ValidationResponseInterface> => {
  const buffer = await readFileAsArrayBuffer(file, true, 0, 8);
  const fileHeaders = new Uint8Array(buffer as ArrayBuffer);

  const isImageFileTypeValid = checkIsImageFileTypeValid(fileHeaders);

  if (!isImageFileTypeValid) {
    return {
      error: true,
      errorMessage: "Invalid image file type",
    };
  }

  return { error: false, errorMessage: "" };
};

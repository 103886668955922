import { Box, Stack } from "@mui/material";

import ProjectCard from "./ProjectCard";
import NoDataBanner from "./NoDataBanner";
import Loader from "../../../common/components/loader/Loader";

import { useGetDTProjects } from "../hooks/useGetDTProjects";

const DashboardGrid = () => {
  const { data, loading } = useGetDTProjects();

  return (
    <Stack
      sx={{
        width: "100%",
        height: "85%",
        padding: "22px 0px 0px",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        overflowY: "auto",
      }}
    >
      {loading && <Loader />}
      {!loading && data?.getDTCustomerProjects?.items?.length > 0 && (
        <Box
          sx={{
            height: "100%",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: "20px",
            }}
          >
            {data?.getDTCustomerProjects?.items?.map(projectData => (
              <ProjectCard data={projectData} key={projectData.projectId} />
            ))}
          </Box>
        </Box>
      )}
      {!loading && data?.getDTCustomerProjects?.items?.length === 0 && (
        <NoDataBanner />
      )}
    </Stack>
  );
};

export default DashboardGrid;

import { useMemo } from "react";

import { ProjectObjects3dContext } from "./ProjectObject3dContext";

import { useGetDTObjects } from "../../../pages/project-editor/hooks/useGetDTObjects";
import { useGetCustomerDTObjects } from "../../../pages/project-editor/hooks/useGetCustomerDTObjects";

export const ProjectObjects3dContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const { data: commonDTObjectsData, loading: commonDTObjectsLoading } =
    useGetDTObjects();
  const { data: customerDTObjectsData, loading: customerDTObjectsLoading } =
    useGetCustomerDTObjects();

  const object3dData = useMemo(() => {
    if (
      customerDTObjectsData?.getCustomerDTObjects &&
      commonDTObjectsData?.getDTObjects
    ) {
      return [
        ...customerDTObjectsData.getCustomerDTObjects,
        ...commonDTObjectsData.getDTObjects,
      ];
    }

    return null;
  }, [customerDTObjectsData, commonDTObjectsData]);

  const loading = commonDTObjectsLoading || customerDTObjectsLoading;

  return (
    <ProjectObjects3dContext.Provider
      value={{
        object3dData,
        loading,
      }}
    >
      {children}
    </ProjectObjects3dContext.Provider>
  );
};

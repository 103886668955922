import { useContext } from "react";
import { Button } from "@mui/material";

import {
  ProjectContext,
  ProjectModalContext,
} from "../../../../common/context";

const ShareProjectButton = (): JSX.Element => {
  const { sceneStarted, isLoading } = useContext(ProjectContext);
  const { setIsDisplayShareModal } = useContext(ProjectModalContext);

  const onShareButton = () => {
    setIsDisplayShareModal(true);
  };

  return (
    <Button
      disabled={!sceneStarted || isLoading}
      onClick={onShareButton}
      variant="primary"
      color="blue"
    >
      Share
    </Button>
  );
};

export default ShareProjectButton;

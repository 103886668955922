import { IFormValidationState } from "../../common/models/formValidation";

export interface IClientUploadsForm {
  clientName: string;
}

export const defaultClientUploadsFormVariables = {
  clientName: "",
};

interface IClientUploadsFormValidationState {
  clientName: IFormValidationState;
}

export const defaultClientUploadsFormValidationState: IClientUploadsFormValidationState =
  {
    clientName: {
      hasError: false,
      errorMessage: "",
    },
  };

export const clientUploadsFormRules = {
  clientName: {
    required: true,
    pattern: /^(?!^ +$)[a-zA-Z0-9]+(?:\s[a-zA-Z0-9]+)*$/,
  },
};

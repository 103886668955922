import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import { useTheme } from "@mui/material/styles";

const SearchIcon = (props: SvgIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon {...props}>
      <svg
        width={props.width || "20px"}
        height={props.width || "20px"}
        viewBox={props.viewBox || "0 0 20 20"}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_9644_93221)">
          <circle
            cx="9.58463"
            cy="9.58332"
            r="7.91667"
            stroke={theme.palette.otherTextTertiary.main}
            strokeWidth="1.5"
          />
          <path
            d="M15.418 15.4167L18.3346 18.3333"
            stroke={theme.palette.otherTextTertiary.main}
            strokeWidth="1.5"
            strokeLinecap="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_9644_93221">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};

export default SearchIcon;

import { FC } from "react";

import TextField from "@mui/material/TextField";
import { Control, Controller, FieldErrors } from "react-hook-form";

import { plateNumberFormRules } from "../../variables/plateNumberData";

export interface IPlateCategoryForm {
  category: string;
}

interface ICategoryFormProps {
  control: Control<IPlateCategoryForm, any>;
  errors: FieldErrors<IPlateCategoryForm>;
}

const CategoryForm: FC<ICategoryFormProps> = ({ control, errors }) => {
  return (
    <Controller
      name="category"
      control={control}
      rules={{
        required: "This field is required",
        pattern: {
          value: plateNumberFormRules.category.pattern,
          message: "Valid characters: A-Z, a-z, 0-9",
        },
      }}
      render={({ field }) => (
        <TextField
          {...field}
          value={field.value ?? ""}
          sx={{ height: "60px" }}
          size="medium"
          label="Category name"
          fullWidth
          error={!!errors.category}
          helperText={errors.category ? errors.category.message : ""}
        />
      )}
    />
  );
};

export default CategoryForm;

import { useQuery } from "@apollo/client";

import { GetClientByIdQuery, GetClientByIdQueryVariables } from "../../API";
import { GET_CLIENT_BY_ID } from "../../common/operations/queries";
import { useCustomerIdGuard } from "../../common/hooks/useCustomerIdGuard";

export const useGetClient = (clientId: string) => {
  const selectedCustomerId = useCustomerIdGuard();

  const { data, loading, refetch } = useQuery<
    GetClientByIdQuery,
    GetClientByIdQueryVariables
  >(GET_CLIENT_BY_ID, {
    variables: {
      input: {
        clientId,
        customerId: selectedCustomerId,
      },
    },
    skip: !clientId || !selectedCustomerId,
  });

  return { data, loading, refetch };
};

import { WeatherDataInterface } from "../../../../context/WeatherConditionsContext/WeatherConditionsContext";

import {
  Clouds,
  RainLight,
  RainNormal,
  RainShower,
  SnowBlizzard,
  SnowLight,
  SnowNormal,
  Sunny,
} from "../../../../assets/icons/WeatherIcons";

type Props = {
  data: WeatherDataInterface;
};

const WeatherIcon = ({ data }: Props): JSX.Element => {
  if (!data.snow && !data.rain) {
    if (data.clouds < 10) {
      return <Sunny />;
    } else {
      return <Clouds />;
    }
  } else {
    if (data.snow && data.snow < 6) {
      return <SnowLight />;
    } else if (data.snow && data.snow < 18) {
      return <SnowNormal />;
    } else if (data.snow && data.snow > 18) {
      return <SnowBlizzard />;
    } else if (data.rain && data.rain < 6) {
      return <RainLight />;
    } else if (data.rain && data.rain < 18) {
      return <RainNormal />;
    } else {
      return <RainShower />;
    }
  }
};

export default WeatherIcon;

import { FC } from "react";

import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";

import HLSStreamPlayer from "../../../pages/data-hub/components/liveview/components/cameraView/HLSStreamPlayer";
import StyledLoadingButton from "../../providers/theme/design-tokens/LoadingButton/StyledLoadingButton";
import S3Image from "../media/S3Image";
import PTControllerButton from "../stream/PTControllerButton";
import { ViewModeEnum } from "./LiveViewContainer";
import { useTheme } from "@mui/material/styles";
import useCreateImageLayer from "../canvas/useCreateImageLayer";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import ExtendButton from "./MaximizeButton";

interface LiveViewExpandedDialogProps {
  s3Key: string;
  viewMode: ViewModeEnum;
  expanded: boolean;
  isKeyframeLoaded: boolean;
  setKeyframeLoaded: (value: boolean) => void;
  changeViewMode?: () => void;
  changeExpandedMode: () => void;
  openPtController?: () => void;
}

const LiveViewExpandedDialog: FC<LiveViewExpandedDialogProps> = ({
  s3Key,
  viewMode,
  expanded,
  changeViewMode,
  changeExpandedMode,
  openPtController,
  isKeyframeLoaded,
  setKeyframeLoaded,
}) => {
  const theme = useTheme();
  const { width: screenWidth } = useWindowDimensions();

  const { size } = useCreateImageLayer(s3Key);
  const container = document.getElementById("#LiveViewExpandedDialog");

  const containerWidth = container?.offsetWidth ?? 0;
  const containerHeight = container?.offsetHeight ?? 0;

  const imageWidth = size.width;
  const imageHeight = size.height;

  const widthRatio = containerWidth / imageWidth;
  const heightRatio = containerHeight / imageHeight;

  const scaleFactor = Math.min(widthRatio, heightRatio);

  const height = imageHeight * scaleFactor;

  const getMaxWidth = (): string => {
    if (viewMode === ViewModeEnum.KEYFRAME && !isKeyframeLoaded) {
      return theme.breakpoints.values.md + "px";
    }

    if (viewMode === ViewModeEnum.KEYFRAME && isKeyframeLoaded) {
      return "fit-content";
    }

    if (
      viewMode === ViewModeEnum.KEYFRAME_WITH_ANNOTATIONS &&
      !isKeyframeLoaded
    ) {
      return theme.breakpoints.values.md + "px";
    }

    if (
      viewMode === ViewModeEnum.KEYFRAME_WITH_ANNOTATIONS &&
      isKeyframeLoaded
    ) {
      return "fit-content";
    }

    if (
      viewMode === ViewModeEnum.LIVE &&
      screenWidth > theme.breakpoints.values.xxl
    ) {
      return theme.breakpoints.values.xl + "px";
    }

    if (viewMode === ViewModeEnum.LIVE) {
      return theme.breakpoints.values.lg + "px";
    }

    return "fit-content";
  };

  const getWidth = (): string => {
    if (viewMode === ViewModeEnum.KEYFRAME) {
      return isKeyframeLoaded ? "fit-content" : "100%";
    }

    if (viewMode === ViewModeEnum.LIVE) {
      return "100%";
    }

    return "100%";
  };

  return (
    <Dialog
      id="LiveViewExpandedDialog"
      PaperProps={{
        sx: {
          width: getWidth(),
          height: "100%",
          maxWidth: getMaxWidth(),
          borderRadius: "8px",
        },
      }}
      fullWidth
      open={expanded}
      onClose={changeExpandedMode}
    >
      <ExtendButton title="Minimize" changeExpandedMode={changeExpandedMode} />

      {(viewMode === ViewModeEnum.KEYFRAME ||
        viewMode === ViewModeEnum.KEYFRAME_WITH_ANNOTATIONS) && (
        <S3Image
          sx={{
            borderRadius: "8px",
          }}
          s3Key={s3Key}
          isKeyframeLoaded={isKeyframeLoaded}
          setKeyframeLoaded={setKeyframeLoaded}
          height={height}
        />
      )}

      {viewMode === ViewModeEnum.LIVE && <HLSStreamPlayer setDialogStyles />}

      {changeViewMode && (
        <Box
          sx={{
            position: "fixed",
            left: "50%",
            bottom: "20px",
            transform: "translate(-50%, -50%)",
            margin: "0 auto",
            display: "flex",
            gap: "1em",
          }}
        >
          <StyledLoadingButton
            variant="contained"
            color="primary"
            size="small"
            onClick={changeViewMode}
          >
            {viewMode !== ViewModeEnum.LIVE ? "Activate" : "Stop"} live view
          </StyledLoadingButton>
          {openPtController && (
            <PTControllerButton onPtOpen={openPtController} />
          )}
        </Box>
      )}
    </Dialog>
  );
};

export default LiveViewExpandedDialog;

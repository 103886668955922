import { Grid, TextField } from "@mui/material";
import { Control, Controller, FieldErrors } from "react-hook-form";
import {
  clientUploadsFormRules,
  IClientUploadsForm,
} from "../variables/clientUploadsData";

interface IClientUploadsFormProps {
  control: Control<IClientUploadsForm, any>;
  errors: FieldErrors<IClientUploadsForm>;
}

const AddNewClientForm = ({
  control,
  errors,
}: IClientUploadsFormProps): JSX.Element => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <Controller
          name="clientName"
          control={control}
          rules={{
            required: "This field is required",
            pattern: {
              value: clientUploadsFormRules.clientName.pattern,
              message: "Valid characters: A-Z, a-z, 0-9",
            },
          }}
          render={({ field }) => (
            <TextField
              {...field}
              size="medium"
              id="clientName"
              label="Client name"
              fullWidth
              error={!!errors.clientName}
              helperText={errors.clientName ? errors.clientName.message : ""}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default AddNewClientForm;

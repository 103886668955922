import { useLazyQuery } from "@apollo/client";

import {
  GetDeviceMakeByModelQuery,
  GetDeviceMakeByModelQueryVariables,
} from "../../../API";
import { GET_DEVICE_MAKE_BY_MODEL } from "../../../common/operations/queries";
import { errorNotification } from "../../../common/variables/notification";
import client from "../../../configs/apolloClient";

const useGetDeviceMakeByModel = () => {
  const [getDeviceMake, { data, loading }] = useLazyQuery<
    GetDeviceMakeByModelQuery,
    GetDeviceMakeByModelQueryVariables
  >(GET_DEVICE_MAKE_BY_MODEL, {
    fetchPolicy: "cache-and-network",
  });

  const handleRequestError = (message?: string) => {
    client.cache
      .reset()
      .then(() => {
        errorNotification(message);
      })
      .catch(error => {
        console.error(error);
      });
  };

  const fetchDeviceMakeByModel = (makeModelName: string) => {
    return getDeviceMake({
      variables: {
        makeModelName,
      },
    })
      .then(response => {
        if (response.error) {
          if (response.error.message.includes("FieldUndefined")) {
            handleRequestError("Device make is not found.");
          } else {
            handleRequestError();
          }
        }

        return response;
      })
      .catch(e => {
        console.error(e);

        handleRequestError();
      });
  };

  return { data, fetchDeviceMakeByModel, loading };
};

export default useGetDeviceMakeByModel;

import { Button, ButtonProps, Typography, useTheme } from "@mui/material";
import DeleteIcon from "../icons/DeleteIcon";

export const DeleteButton: React.FC<
  ButtonProps & { label?: string }
> = props => {
  const theme = useTheme();

  return (
    <Button
      size="small"
      sx={{
        borderColor: theme.palette.error.main,
        color: theme.palette.error.main,
      }}
      startIcon={<DeleteIcon />}
      {...props}
    >
      <Typography variant="buttonMedium">{props.label ?? "Delete"}</Typography>
    </Button>
  );
};

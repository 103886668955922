import { useContext } from "react";
import { Box, Stack, Typography, styled } from "@mui/material";
import { type CSSProperties } from "@mui/material/styles/createMixins";

import WeatherIcon from "./components/WeatherIcon";
import WeatherDescription from "./components/WeatherDescription";
import WindIcon from "./components/WindIcon";
import { ProjectContext, WeatherConditionsContext } from "../../../context";
import { MeasurementSystemMode } from "../../../enums";

const WeatherConditions = () => {
  const { measurementSystem } = useContext(ProjectContext);
  const { weatherVisibility, weatherShortInfo, data } = useContext(
    WeatherConditionsContext
  );

  return (
    <Wrapper visibilitystate={weatherVisibility ? 1 : 0}>
      <TemperatureBlock>
        <Stack justifyContent={"center"} alignItems={"center"}>
          <WeatherIcon data={data} />
        </Stack>
        <TextBlock>
          <Typography fontSize={"28px"} color={"white"}>
            {Math.round(weatherShortInfo.temp)}
          </Typography>
          <Stack>
            <Typography variant={"body8Medium"} color={"white"}>
              {measurementSystem === MeasurementSystemMode.Metric ? "°C" : "°F"}
            </Typography>
            {data.description && (
              <WeatherDescription description={data.description} />
            )}
          </Stack>
        </TextBlock>
      </TemperatureBlock>
      <WindBlock>
        <Stack justifyContent={"center"} alignItems={"center"}>
          <WindIcon windSpeed={data.wind.speed} />
        </Stack>
        <TextBlock>
          <Typography fontSize={"28px"} color={"white"}>
            {Math.round(weatherShortInfo.windSpeed)}
          </Typography>
          <Stack>
            <Typography variant={"body8Medium"} color={"white"}>
              {measurementSystem === "metric" ? "m/s" : "mi/h"}
            </Typography>
            <Typography variant={"body12Medium"} color={"white"}>
              Wind
            </Typography>
          </Stack>
        </TextBlock>
      </WindBlock>
    </Wrapper>
  );
};

export default WeatherConditions;

const Wrapper = styled(Box)<{ visibilitystate: number }>(
  ({ visibilitystate }): CSSProperties => ({
    position: "absolute",
    top: "0",
    left: 0,
    padding: "10px",

    display: visibilitystate ? "flex" : "none",
    alignItems: "center",
    gap: "20px",
    "&  *": {
      color: "white",
    },
  })
);

const TemperatureBlock = styled(Box)(() => ({
  display: "flex",
  gap: "10px",
}));

const WindBlock = styled(Box)(() => ({
  display: "flex",
  gap: "10px",
}));

const TextBlock = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
}));

import { ApolloError, useMutation } from "@apollo/client";

import {
  DeleteAllAnnotationsMutation,
  DeleteAllAnnotationsMutationVariables,
} from "../../../API";
import { DELETE_ALL_ANNOTATIONS } from "../../../common/operations/mutations";
import { errorNotification } from "../../../common/variables/notification";

export const useDeleteAllAnnotations = () => {
  const [deleteAllAnnotationsMutation, { data, loading }] = useMutation<
    DeleteAllAnnotationsMutation,
    DeleteAllAnnotationsMutationVariables
  >(DELETE_ALL_ANNOTATIONS);

  const deleteAllAnnotations = async (serviceId: string) => {
    return await deleteAllAnnotationsMutation({
      variables: {
        input: {
          serviceId,
        },
      },
      update: (cache, { data }) => {
        if (data?.deleteAllAnnotations) {
          const id = serviceId.slice(serviceId.indexOf("Z#"));
          const normalizedId = cache.identify({ id, __typename: "Annotation" });

          cache.modify({
            id: normalizedId,
            fields: {
              getAnnotations(existing) {
                return existing.filter(
                  (annotation: { id: string | null | undefined }): boolean =>
                    annotation.id !== data?.deleteAllAnnotations?.id
                );
              },
            },
          });
        }
      },
      onError: (error: ApolloError): void => {
        errorNotification(error.message);

        console.error(error.message);
      },
      refetchQueries: ["GetAnnotations"],
    });
  };

  return { deleteAllAnnotations, data, loading };
};

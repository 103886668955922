import { FC, memo } from "react";

import { Box, Paper, Typography } from "@mui/material";

import LiveBadge from "../../../../../../common/components/icons/LiveBadge";
import CircularLoading from "../../../../../../common/components/progress/CircularLoading";
import VideoNotFound from "../../../../../../common/components/stream/VideoNotFound";
import useHlsStream from "../../../../../../common/hooks/useHLSStream";
import LiveStreamPlayer from "./LiveStreamPlayer";

interface HLSStreamComponentProps {
  setDialogStyles?: boolean;
}

const TryingToConnect = (): JSX.Element => {
  return (
    <Paper
      variant="outlined"
      square={false}
      elevation={0}
      sx={{
        width: "100%",
        height: "100%",
        minHeight: "378px",
        backgroundColor: "#fbfbfb",
        borderColor: "#e1e6ef",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography
        sx={{
          fontSize: "1rem",
        }}
        component="div"
        variant="inputLabel"
      >
        Trying to connect...
        <CircularLoading />
      </Typography>
    </Paper>
  );
};

const HLSStreamComponent: FC<HLSStreamComponentProps> = ({
  setDialogStyles,
}): JSX.Element => {
  const { loading, hlsUrl, retryCount, needRetry, retry, setLoading } =
    useHlsStream();

  const handleRetry = (): void => {
    setLoading(true);

    retry();
  };

  if (!needRetry && !hlsUrl && !loading && retryCount < 10) {
    return <TryingToConnect />;
  }

  if (loading) {
    return <TryingToConnect />;
  }

  if (!needRetry && !hlsUrl && !loading && retryCount >= 10) {
    return <VideoNotFound onRetry={handleRetry} />;
  }

  if (needRetry) {
    return <VideoNotFound onRetry={handleRetry} />;
  }

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: "100%",
        overflow: "hidden",
      }}
    >
      {!loading && !needRetry && (
        <LiveBadge
          sx={{
            position: "absolute",
            top: "8px",
            left: "8px",
          }}
        />
      )}

      <LiveStreamPlayer streamUrl={hlsUrl} setDialogStyles={setDialogStyles} />
    </Box>
  );
};

const HLSStreamPlayer = memo(HLSStreamComponent);

export default HLSStreamPlayer;

import type { ComponentsOverrides, Theme } from "@mui/material";

export const MuiDividerDarkStyleOverrides: ComponentsOverrides<Theme>["MuiDivider"] =
  {
    root: ({ theme }) => {
      return {
        borderColor: theme.palette.otherOutlineBorder.main,
        borderBottomWidth: "2px",
      };
    },
  };

import { useContext } from "react";
import { Box, InputAdornment, styled } from "@mui/material";

import TextInput from "../../input/TextInput";

import { SearchIcon } from "../../../assets/icons/SearchIcon";

import { ProjectContext } from "../../../context";
import { SearchFilterGroupObjectsContext } from "../../../../pages/project-editor/context";
import { ModeTypeEnum } from "../../../context/ProjectContext/ProjectEntitesTypes";

const Search3dObjectsInput = (): JSX.Element => {
  const { handleSetSearchQuery } = useContext(SearchFilterGroupObjectsContext);
  const { sceneStarted, isLoading, mode } = useContext(ProjectContext);

  const onSearchByInputChange = (value: string) => {
    handleSetSearchQuery({ value });
  };

  return (
    <Wrapper>
      <TextInput
        handleOnChange={onSearchByInputChange}
        disabled={!sceneStarted || isLoading || mode !== ModeTypeEnum.standard}
        placeholder="Search"
        endAdornment={
          <StyledInputAdornment position="end">
            <SearchIcon />
          </StyledInputAdornment>
        }
      />
    </Wrapper>
  );
};

export default Search3dObjectsInput;

const Wrapper = styled(Box)(() => ({
  width: "256px",

  "& .MuiInputBase-root": {
    height: "34px",
  },

  "@media (max-width: 1500px)": {
    width: "200px",
  },
}));

const StyledInputAdornment = styled(InputAdornment)(() => ({
  padding: "6px",
  marginLeft: "0px",

  "& svg": {
    width: "14px",
    height: "14px",
  },
}));

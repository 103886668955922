import type { ComponentsOverrides, Theme } from "@mui/material";

export const MuiAppBarDarkStyleOverrides: ComponentsOverrides<Theme>["MuiAppBar"] =
  {
    root: ({ theme }) => {
      return {
        backgroundColor: theme.palette.otherBackground.main,
        backgroundImage: "none",
        boxShadow: "none",
        borderBottom: `2px solid ${theme.palette.otherOutlineBorder.main}`,
        color: theme.palette.text.primary,
      };
    },
  };

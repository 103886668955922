import { useListenToAddZone } from "./useListenToAddZone";
import { useListenToDeleteZone } from "./useListenToDeleteZone";
import { useListenToUpdateZone } from "./useListenToUpdateZone";

export const useListenToZoneUpdates = () => {
  useListenToAddZone();

  useListenToUpdateZone();

  useListenToDeleteZone();
};

import { FC } from "react";

import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { CSSObject, styled } from "@mui/material/styles";

import {
  StyledArrowLessIcon,
  StyledArrowMoreIcon,
} from "../chip/DeviceModelChipDropdown";
import { IConfigurationParameterMenuItem } from "./ConfigurationComponent";
import { StyledMenuBox } from "../chip/ChipComponent";

const StyledMenu = styled(Menu)(
  ({ theme }): CSSObject => ({
    "& .MuiPaper-root": {
      border: `1px solid ${theme.palette.otherOutlineBorder.main}`,
      borderRadius: "8px",
      marginTop: "5px",
      boxShadow: "0px 4px 20px 0px #0816331A",
    },
  })
);

interface AdditonalConfigurationParametersSelectProps {
  anchorEl: null | HTMLElement;
  open: boolean;
  parameters: IConfigurationParameterMenuItem[];
  handleClose: () => void;
  openMenuWithParameters: (event: React.MouseEvent<HTMLElement>) => void;
  handleMenuItemClick: (parameter: IConfigurationParameterMenuItem) => void;
}

const AdditonalConfigurationParametersSelect: FC<
  AdditonalConfigurationParametersSelectProps
> = ({
  anchorEl,
  open,
  handleClose,
  openMenuWithParameters,
  parameters,
  handleMenuItemClick,
}): JSX.Element => {
  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      <StyledMenuBox
        onClick={openMenuWithParameters}
        sx={{
          height: "32px",
        }}
      >
        <Stack direction="row" alignItems="center">
          <Typography
            sx={{
              marginRight: "6px",
              display: "flex",
              justifyContent: "center",
              alignItem: "center",
            }}
            variant="buttonMedium"
          >
            Add parameters
          </Typography>
        </Stack>
      </StyledMenuBox>

      <Box
        className="arrow"
        onClick={openMenuWithParameters}
        sx={{
          position: "absolute",
          display: "flex",
          justifyContent: "center",
          right: "10px",
          top: "50%",
          transform: "translateY(-50%)",
        }}
      >
        {open ? <StyledArrowLessIcon /> : <StyledArrowMoreIcon />}
      </Box>

      <StyledMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
      >
        <>
          {parameters.map(
            (parameter): JSX.Element => (
              <MenuItem
                key={parameter.name}
                sx={{
                  cursor: "pointer",
                }}
                onClick={(): void => handleMenuItemClick(parameter)}
              >
                <Checkbox checked={parameter.checked} />
                <ListItemText primary={parameter.name} />
              </MenuItem>
            )
          )}
        </>
      </StyledMenu>
    </Box>
  );
};

export default AdditonalConfigurationParametersSelect;

import { useMemo } from "react";

import {
  MEDIA_TYPE_VALIDATION_RESULTS,
  validateMediaFileExtension,
} from "../../../common/helpers/validateMediaFileExtension";

export const useMediaValidation = (media: string) => {
  const typeError = useMemo(() => {
    const { validationResult, extension } = validateMediaFileExtension(media);

    if (
      validationResult !== MEDIA_TYPE_VALIDATION_RESULTS.VALID ||
      !extension
    ) {
      return {
        validationResult: false,
        extension,
      };
    }

    return {
      validationResult: true,
      extension,
    };
  }, [media]);

  return {
    isValidFileExtension: typeError.validationResult,
    extension: typeError.extension,
  };
};

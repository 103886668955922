import { Box, Dialog, DialogActions, DialogContent } from "@mui/material";

import { ReactNode } from "react";

type AnnotationDialogProps = {
  dialogActions: ReactNode;
  children: ReactNode;
  boardChildren: ReactNode;
  isDialogOpen: boolean;
};

const AnnotationDialog: React.FC<AnnotationDialogProps> = ({
  isDialogOpen,
  boardChildren,
  dialogActions,
  children,
}): JSX.Element => {
  return (
    <Dialog
      open={isDialogOpen}
      PaperProps={{
        elevation: 0,
        style: {
          maxWidth: "none",
          maxHeight: "none",
          borderRadius: "12px",
        },
      }}
    >
      <DialogContent
        sx={{
          display: "flex",
          padding: 0,
          flexDirection: "row",
          overflowY: "hidden",
        }}
      >
        {boardChildren}

        <Box
          sx={{
            maxWidth: "400px",
            width: "100%",
            margin: "1.5em",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {children}
          </Box>

          <DialogActions sx={{ justifyContent: "flex-start" }}>
            {dialogActions}
          </DialogActions>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AnnotationDialog;

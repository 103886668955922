import { useCallback } from "react";
import { S3BucketKeys } from "../utils/s3helper";

export const useBase64 = () => {
  const encodeToBase64 = useCallback((str: string | null): string => {
    if (typeof str !== "string" || str.trim().length === 0) {
      return "";
    }

    try {
      return btoa(str);
    } catch (error) {
      console.error("Error encoding to base64", error);

      return "";
    }
  }, []);

  const decodeFromBase64 = useCallback((base64: string | null): string => {
    if (typeof base64 !== "string" || base64.trim().length === 0) {
      return "";
    }

    try {
      return atob(base64);
    } catch (error) {
      console.error("Error decoding from base64", error);

      return "";
    }
  }, []);

  const getBase64MediaViewerPageLink = useCallback(
    (s3Key: string, bucketKey: string = S3BucketKeys.NODE_FILES) => {
      if (!s3Key) {
        return "";
      }

      try {
        const parsedS3Key =
          s3Key && s3Key.startsWith('"') ? JSON.parse(s3Key) : s3Key;

        const base64Url = `${encodeToBase64(parsedS3Key)}/${encodeToBase64(
          bucketKey
        )}`;

        return `${location.origin}/media/${base64Url}`;
      } catch (error) {
        console.error("Error encoding to base64 link", error);

        return "";
      }
    },
    []
  );

  return { encodeToBase64, decodeFromBase64, getBase64MediaViewerPageLink };
};

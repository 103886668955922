import { UserSettingsInput } from "../../API";
import { useAuthenticatedUser } from "./useAuthenticatedUser";
import useUserFromCache from "./useUserFromCache";

const useUserSettingsFromCache = () => {
  const { user, userSettings } = useAuthenticatedUser();

  const { getCachedUser } = useUserFromCache();

  const getUserSettings = (userId?: string) => {
    if (!userId) {
      return null;
    }

    let leakFinderSettings: UserSettingsInput | null = null;

    const normalizedId = `U#${user?.attributes?.sub as string}`;

    const cachedUser = getCachedUser(normalizedId);

    try {
      if (cachedUser?.userSettings) {
        const settings = JSON.parse(cachedUser.userSettings);

        leakFinderSettings = {
          defaultCameraFov:
            +settings?.defaultCameraFov ?? userSettings?.defaultCameraFov,
          defaultVideoSpeed:
            +settings?.defaultVideoSpeed ?? userSettings?.defaultVideoSpeed,
          defaultWindspeedUnit:
            settings?.defaultWindspeedUnit ??
            userSettings?.defaultWindspeedUnit,
        };

        return leakFinderSettings;
      }
    } catch (error) {
      console.error(error);

      return null;
    }
  };

  const userSettingsFromCache = getUserSettings(user?.username);

  return { userSettings: userSettingsFromCache };
};

export default useUserSettingsFromCache;

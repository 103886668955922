import uuid from "react-uuid";
import {
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  TypographyProps,
  styled,
} from "@mui/material";

import { HintInterface } from "./components/InputHint";
import InputContainer from "./components/InputContainer";

import { SelectInputIcon } from "../../assets/icons/SelectInputIcon";

interface SelectInputPropsInterface {
  label?: string;
  labelFontVariant?: TypographyProps["variant"];
  value: string;
  placeholder?: string;
  options: string[];
  type?: string;
  hint?: HintInterface;
  isError?: boolean;
  disabled?: boolean;
  required?: boolean;
  readonly?: boolean;
  variant?: "filled" | "outlined" | "standard";
  iconPosition?: "left" | "right";
  viewType?: "row" | "column";
  labelWidth?: string;
  inputWidth?: string;
  handleOnChange?: (value: string) => void;
  handleOnClick?: () => void;
}

const SelectInput = ({
  label,
  labelFontVariant,
  value,
  placeholder = "Select...",
  hint,
  isError,
  disabled,
  required,
  readonly,
  options,
  variant = "outlined",
  iconPosition = "right",
  viewType = "column",
  labelWidth = "100%",
  inputWidth = "100%",
  handleOnChange,
  handleOnClick,
}: SelectInputPropsInterface): JSX.Element => {
  const onChange = (event: SelectChangeEvent<unknown>) => {
    const value = event.target.value as string;

    handleOnChange && handleOnChange(value);
  };

  const id = label || uuid();

  return (
    <InputContainer
      id={id}
      label={label}
      labelFontVariant={labelFontVariant}
      hint={hint}
      required={required}
      viewType={viewType}
      labelWidth={labelWidth}
      inputWidth={inputWidth}
    >
      <StyledSelectInput
        variant={variant}
        displayEmpty
        isplaceholder={!value ? "true" : "false"}
        iconposition={iconPosition}
        fullWidth
        readOnly={readonly}
        value={value}
        renderValue={() => (
          <Typography variant="body12Regular">
            {value || placeholder}
          </Typography>
        )}
        error={isError}
        disabled={disabled}
        onChange={onChange}
        onClick={handleOnClick}
        IconComponent={props => <SelectInputIcon {...props} />}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 152,
              padding: "4px",
            },
          },
          MenuListProps: {
            style: {
              padding: 0,
            },
          },
        }}
      >
        {options.map(value => (
          <MenuItem key={value} value={value}>
            {value}
          </MenuItem>
        ))}
      </StyledSelectInput>
    </InputContainer>
  );
};

export default SelectInput;

const StyledSelectInput = styled(Select)<{
  isplaceholder: "true" | "false";
  iconposition: "left" | "right";
}>(({ theme, isplaceholder, iconposition }) => ({
  width: "100%",
  height: "29px",

  ...(isplaceholder === "true" && {
    "& .MuiSelect-select": {
      color: `${theme.palette["base-placeholder"]} !important`,
    },
  }),

  ...(iconposition === "left" && {
    "& .MuiSelect-icon": {
      left: "7px",
    },

    "& .MuiSelect-select": {
      padding: "6px 6px 6px 32px !important",
    },
  }),

  ...(iconposition === "right" && {
    "& .MuiSelect-icon": {
      right: "7px",
    },

    "& .MuiSelect-select": {
      padding: "6px 32px 6px 6px !important",
    },
  }),
}));

import { useEffect } from "react";

import { QueryResult, useLazyQuery } from "@apollo/client";

import {
  GetAllVideosByGroupQuery,
  GetAllVideosByGroupQueryVariables,
} from "../../API";
import { useCustomerIdGuard } from "../../common/hooks/useCustomerIdGuard";
import { GET_ALL_VIDEOS_BY_GROUP } from "../../common/operations/queries";
import { errorNotification } from "../../common/variables/notification";
import client from "../../configs/apolloClient";

export const useGetAllVideosByGroup = (groupID?: string) => {
  const selectedCustomerId = useCustomerIdGuard();

  const [getAllVideosByGroup, { data, loading }] = useLazyQuery<
    GetAllVideosByGroupQuery,
    GetAllVideosByGroupQueryVariables
  >(GET_ALL_VIDEOS_BY_GROUP, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (selectedCustomerId && groupID) {
      fetchAllVideosByGroup(groupID);
    }
  }, [selectedCustomerId, groupID]);

  const handleRequestError = async (): Promise<void> => {
    await client.cache.reset();

    errorNotification();
  };

  const fetchAllVideosByGroup = (
    groupId: string,
    nextToken?: string
  ): Promise<void | QueryResult<
    GetAllVideosByGroupQuery,
    GetAllVideosByGroupQueryVariables
  >> => {
    return getAllVideosByGroup({
      variables: {
        input: {
          groupId,
          nextToken,
        },
      },
    })
      .then(
        async (
          response
        ): Promise<
          QueryResult<
            GetAllVideosByGroupQuery,
            GetAllVideosByGroupQueryVariables
          >
        > => {
          if (response.data?.getAllVideosByGroup.nextToken) {
            fetchAllVideosByGroup(
              groupId,
              response.data?.getAllVideosByGroup.nextToken
            );
          }

          if (response.error) {
            await handleRequestError();
          }

          return response;
        }
      )
      .catch(async (): Promise<void> => {
        await handleRequestError();
      });
  };

  return { data, loading, fetchAllVideosByGroup };
};

import { useEffect } from "react";
import { useLazyQuery } from "@apollo/client";

import { GET_DT_CUSTOMER_PROJECTS } from "../../../../common/operations/queries";
import { useCustomerIdGuard } from "../../../../common/hooks/useCustomerIdGuard";

const DT_PROJECTS_LIMIT = 20;

export const useGetDTProjects = () => {
  const selectedCustomerId = useCustomerIdGuard();

  const [getProjects, { data, loading }] = useLazyQuery(
    GET_DT_CUSTOMER_PROJECTS,
    {
      fetchPolicy: "network-only",
      refetchWritePolicy: "merge",
    }
  );

  const handleRequestError = error => {
    console.error(error);
  };

  useEffect(() => {
    if (selectedCustomerId) {
      fetchDTProjects(DT_PROJECTS_LIMIT);
    }
  }, [selectedCustomerId]);

  const fetchDTProjects = (limit, nextToken) => {
    getProjects({
      variables: {
        customerId: selectedCustomerId,
        limit,
        nextToken,
      },
    })
      .then(async response => {
        if (response.data?.getDTCustomerProjects.nextToken) {
          fetchDTProjects(
            DT_PROJECTS_LIMIT,
            response.data?.getDTCustomerProjects.nextToken
          );
        }

        if (response.error) {
          handleRequestError(response.error);
        }
      })
      .catch(async error => {
        handleRequestError(error);
      });
  };

  return { data, loading };
};

import { useState, useCallback } from "react";

import axios from "axios";

import { S3Helper } from "../../common/utils/s3helper";

const useDeleteS3File = () => {
  const [error, setError] = useState<Error | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const deleteFile = useCallback(async (uploadLink: string) => {
    setIsLoading(true);

    try {
      const signedURLPathToDelete = await S3Helper.getSignedUrl(
        uploadLink,
        "deleteObject"
      );

      await axios.delete(signedURLPathToDelete);

      setError(null);
    } catch (err) {
      setError(
        err instanceof Error ? err : new Error("Unknown error occurred")
      );

      console.error(err);
    } finally {
      setIsLoading(false);
    }
  }, []);

  return { deleteFile, error, loading: isLoading };
};

export default useDeleteS3File;

import { FC, useEffect, useState } from "react";

import Box from "@mui/material/Box";

import CircularLoading from "../../../common/components/progress/CircularLoading";
import {
  S3BucketKeys,
  S3BucketKeyType,
  S3Helper,
} from "../../../common/utils/s3helper";
import CardMediaVideoPlayerWithSpeedControls from "./player/CardMediaVideoPlayerWithSpeedControls";

interface IVideoViewerProps {
  s3Key: string;
  s3BucketKey: S3BucketKeyType;
}

const VideoViewer: FC<IVideoViewerProps> = ({ s3Key, s3BucketKey }) => {
  const [loading, setLoading] = useState(false);
  const [mediaUrl, setMediaUrl] = useState("");

  useEffect((): void => {
    if (s3Key) {
      setLoading(true);

      const isLeakFinderUrl = S3BucketKeys.LEAK_FINDER === s3BucketKey;

      const getMediaObject = isLeakFinderUrl
        ? S3Helper.getSignedUrl(s3Key, "getObject")
        : S3Helper.getObject(s3Key);

      getMediaObject
        .then((result: string): void => {
          setMediaUrl(result);

          setLoading(false);
        })
        .catch((error: Error): void => {
          setLoading(false);

          console.error("S3Video", error.message);
        });
    }
  }, [s3Key, s3BucketKey]);

  if (loading) {
    return (
      <Box
        sx={{
          width: "100%",
          height: "100vh",
        }}
      >
        <CircularLoading />
      </Box>
    );
  }

  return (
    <>
      {!loading && mediaUrl && (
        <CardMediaVideoPlayerWithSpeedControls
          sx={{
            maxHeight: "100vh",
          }}
          controls
          src={mediaUrl}
          autoPlay
        />
      )}
    </>
  );
};

export default VideoViewer;

import isArray from "lodash/isArray";

export const getProject3dModelAssetsKeys = objectsData => {
  if (!objectsData || !isArray(objectsData)) {
    console.error(
      `objectsData is not provided in getProject3dModelAssetsKeys or objectsData is not an Array`
    );

    return [];
  }

  const assetsKeys = objectsData
    .map(({ textures, pathKey }) => {
      if (!textures || !pathKey) {
        return null;
      }

      if (isArray(textures)) {
        const texturesKeyList = textures
          .map(({ pathKey }) => pathKey)
          .filter(pathKey => pathKey);

        return [...texturesKeyList, pathKey];
      }

      return pathKey;
    })
    .flat()
    .filter(key => key);

  return [...new Set(assetsKeys)];
};

export const updateProject3dModelAssetsUrl = async (
  objectsData,
  model3dFiles
) => {
  if (!objectsData || !isArray(objectsData)) {
    console.error(
      `objectsData param is not provided in updateProject3dModelAssetsUrl or objectsData is not an Array`
    );

    return;
  }

  if (!model3dFiles || !isArray(model3dFiles)) {
    console.error(
      `model3dFiles param is not provided in updateProject3dModelAssetsUrl or model3dFiles is not an Array`
    );

    return;
  }

  objectsData.forEach(data => {
    data.path = model3dFiles.find(file => file.key === data.pathKey)?.url;

    data.textures.forEach(texture => {
      texture.path = model3dFiles.find(
        file => file.key === texture.pathKey
      )?.url;
    });
  });
};

export const getObjectPreviewKey = filesInfo => {
  if (!filesInfo || !isArray(filesInfo)) {
    return [];
  }

  return filesInfo.find(data => data.useType === "preview")?.key;
};

export const getObjectModel3dKey = keys => {
  if (!keys || !isArray(keys)) {
    return [];
  }

  return keys.find(key => key && key.includes("model3d"));
};

export const getObjectTexturesKeys = keys => {
  if (!keys || !isArray(keys)) {
    return [];
  }

  return keys.filter(
    key => key && !key.includes("model3d") && !key.includes("preview")
  );
};

export const getObject3dAssetsKeys = filesInfo => {
  if (!filesInfo || !isArray(filesInfo)) {
    return [];
  }

  return filesInfo
    .filter(data => data.useType !== "preview")
    .map(({ key }) => key);
};

export const getPreviewFileData = files => {
  if (!files || !isArray(files)) {
    throw Error("files must be provided");
  }

  const previewData = files.find(({ key }) => key && key.includes("preview"));

  return {
    path: previewData?.url,
    pathKey: previewData?.key,
  };
};

export const getModel3dFileData = files => {
  if (!files || !isArray(files)) {
    throw Error("files must be provided");
  }

  const model3dData = files.find(({ key }) => key && key.includes("model3d"));

  return { path: model3dData?.url };
};

export const getTexturesFileData = files => {
  if (!files || !isArray(files)) {
    throw Error("files must be provided");
  }

  const texturesData = files.filter(
    ({ key }) => key && !key.includes("model3d") && !key.includes("preview")
  );

  return texturesData
    .map(({ key, url }) => {
      if (!key || !url) {
        return null;
      }

      return {
        mapName: key.split("/").at(-1),
        path: url,
      };
    })
    .filter(tData => tData);
};

export const getPreviouslyUploadedFile = (filesInfo, useType) => {
  const fileData = filesInfo.find(info => info.useType === useType);

  return fileData
    ? new File(
        [`${fileData.originalFileName}`],
        `${fileData.originalFileName}`,
        {
          type: fileData.contentType,
        }
      )
    : undefined;
};

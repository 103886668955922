import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";

const DeleteIconButton = ({
  disabled,
  onClick,
}: {
  disabled?: boolean;
  onClick?: () => void;
}): JSX.Element => {
  const theme = useTheme();

  const iconStyle = {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    gap: "8px",
  };

  return (
    <div style={iconStyle} onClick={!disabled ? onClick : undefined}>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={`deleteIcon ${disabled ? "disabled" : ""}`}
      >
        <path
          d="M23.0832 11H8.9165"
          stroke={
            disabled ? theme.palette.text.disabled : theme.palette.error.main
          }
          strokeWidth="1.5"
          strokeLinecap="round"
          className="iconPath"
        />
        <path
          d="M21.6946 13.0833L21.3113 18.8325C21.1638 21.0449 21.09 22.1511 20.3692 22.8255C19.6483 23.4999 18.5397 23.4999 16.3223 23.4999H15.6779C13.4605 23.4999 12.3519 23.4999 11.631 22.8255C10.9102 22.1511 10.8364 21.0449 10.6889 18.8325L10.3057 13.0833"
          stroke={
            disabled ? theme.palette.text.disabled : theme.palette.error.main
          }
          strokeWidth="1.5"
          strokeLinecap="round"
          className="iconPath"
        />
        <path
          d="M13.6426 9.33341C13.9858 8.36242 14.9118 7.66675 16.0003 7.66675C17.0888 7.66675 18.0149 8.36242 18.3581 9.33341"
          stroke={
            disabled ? theme.palette.text.disabled : theme.palette.error.main
          }
          strokeWidth="1.5"
          strokeLinecap="round"
          className="iconPath"
        />
      </svg>
      <Typography
        variant="buttonMedium"
        color={disabled ? theme.palette.text.disabled : "error"}
      >
        Delete
      </Typography>
    </div>
  );
};

export default DeleteIconButton;

import { useContext } from "react";
import {
  Alert,
  AlertColor,
  Snackbar,
  Stack,
  Typography,
  styled,
  useTheme,
} from "@mui/material";

import { NotificationContext } from "../context";

import {
  CheckCircleOutlineOutlined,
  InfoIconBlue,
  WarningIcon,
} from "../assets/icons/svgAssets";

export interface NotificationInterface {
  title: string;
  message: string;
  severity: AlertColor;
}

const Notification = ({
  title,
  message,
  severity,
}: NotificationInterface): JSX.Element => {
  const theme = useTheme();
  const { notificationData, closeNotification } =
    useContext(NotificationContext);

  return (
    <Snackbar
      open={!!notificationData}
      autoHideDuration={6000}
      onClose={closeNotification}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <AlertContainer
        onClose={closeNotification}
        severity={severity}
        iconMapping={{
          info: <InfoIconBlue />,
          success: <CheckCircleOutlineOutlined />,
          error: <WarningIcon fillColor={theme.palette.red.lighter} />,
          warning: <WarningIcon fillColor={theme.palette.yellow.main} />,
        }}
      >
        <Stack direction="column" spacing={2}>
          <Typography variant="body20Medium">{title}</Typography>

          <Typography variant="body14Regular">{message}</Typography>
        </Stack>
      </AlertContainer>
    </Snackbar>
  );
};

export default Notification;

const AlertContainer = styled(Alert)(({ theme, severity }) => ({
  backgroundColor: severity && theme.palette[`${severity}-alert-background`],
  border: `1px solid ${severity && theme.palette[`${severity}-alert-border`]}`,
  boxShadow: `0px 6px 12px 0px ${theme.palette["box-shadow"]}`,
  width: "472px",
  padding: "12px 25px",
  svg: {
    width: "24px",
    height: "24px",
  },
}));

import { CSSObject, styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import { IAlertProps } from "../../alert/AlertFilled";
import AlertWithIcon from "../../alert/AlertWithIcon";
import ToastErrorIcon from "./ToastErrorIcon";

const StyledAlertWithIcon = styled(AlertWithIcon)(
  ({ theme }): CSSObject => ({
    "&": {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.otherBackgroundLight.main,
      border: `1px solid ${theme.palette.error.shades?.["30p"]}`,
      borderLeft: `4px solid ${theme.palette.error.main}`,
      borderRadius: "10px",
      borderTopLeftRadius: "12px",
      borderBottomLeftRadius: "12px",
      paddingLeft: "16px",
    },
  })
);

const AlertError = ({
  message = "",
  children,
  ...props
}: IAlertProps): JSX.Element => {
  return (
    <StyledAlertWithIcon
      severity="success"
      message={message}
      icon={<ToastErrorIcon />}
      {...props}
    >
      {children ?? <Typography variant="body2Regular">{message}</Typography>}
    </StyledAlertWithIcon>
  );
};

export default AlertError;

import { Box, Button, Typography, useTheme } from "@mui/material";

import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

import CircleWithText from "../../../common/components/icons/CircleWithText";
import { AnnotationTableItem } from "./AnnotationContainer";
import { colorOfAnnotationType } from "../../../common/components/canvas/utils";

type AnnotationTableHeaderProps = {
  item: AnnotationTableItem;
  isKeyframeLoaded: boolean;
  hideAddAnnotationButton?: boolean;
  disableButton?: boolean;
  openCreateAnnDialog: () => void;
};

const AnnotationTableHeader: React.FC<AnnotationTableHeaderProps> = ({
  isKeyframeLoaded,
  hideAddAnnotationButton,
  disableButton,
  item,
  openCreateAnnDialog,
}): JSX.Element => {
  const theme = useTheme();

  const count = item.correspondingAnnotations.length;
  const title = item.title;
  const addButtonTitle = item.additionTitle;

  const colorOfPoint = colorOfAnnotationType(item.type, theme);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "baseline",
        justifyContent: "space-between",
        margin: "1em 0",
      }}
    >
      <CircleWithText color={colorOfPoint} title={title} count={count} />

      {!hideAddAnnotationButton && (
        <Button
          variant="outlined"
          sx={{ textTransform: "none" }}
          size="small"
          startIcon={<AddOutlinedIcon />}
          color="success"
          onClick={openCreateAnnDialog}
          disabled={!isKeyframeLoaded || disableButton}
        >
          <Typography variant="buttonMedium">{addButtonTitle}</Typography>
        </Button>
      )}
    </Box>
  );
};

export default AnnotationTableHeader;

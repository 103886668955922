import { useTheme } from "@mui/material/styles";

const DroneIcon = (): JSX.Element => {
  const theme = useTheme();

  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 5L8.5 8.5M8.5 8.5H13.5M8.5 8.5V13.5M17 17L13.5 13.5M13.5 13.5V8.5M13.5 13.5H8.5M17 5L13.5 8.5M5 17L8.5 13.5M17 8.5C17.6922 8.5 18.3689 8.29473 18.9445 7.91015C19.5201 7.52556 19.9687 6.97894 20.2336 6.33939C20.4985 5.69985 20.5678 4.99612 20.4328 4.31719C20.2977 3.63825 19.9644 3.01461 19.4749 2.52513C18.9854 2.03564 18.3618 1.7023 17.6828 1.56725C17.0039 1.4322 16.3002 1.50152 15.6606 1.76642C15.0211 2.03133 14.4744 2.47993 14.0899 3.05551C13.7053 3.63108 13.5 4.30777 13.5 5M17 13.5C17.6922 13.5 18.3689 13.7053 18.9445 14.0899C19.5201 14.4744 19.9687 15.0211 20.2336 15.6606C20.4985 16.3002 20.5678 17.0039 20.4328 17.6828C20.2977 18.3618 19.9644 18.9854 19.4749 19.4749C18.9854 19.9644 18.3618 20.2977 17.6828 20.4328C17.0039 20.5678 16.3002 20.4985 15.6606 20.2336C15.0211 19.9687 14.4744 19.5201 14.0899 18.9445C13.7053 18.3689 13.5 17.6922 13.5 17M5 13.5C4.30777 13.5 3.63108 13.7053 3.05551 14.0899C2.47993 14.4744 2.03133 15.0211 1.76642 15.6606C1.50152 16.3002 1.4322 17.0039 1.56725 17.6828C1.7023 18.3618 2.03564 18.9854 2.52513 19.4749C3.01461 19.9644 3.63825 20.2977 4.31719 20.4328C4.99612 20.5678 5.69985 20.4985 6.33939 20.2336C6.97894 19.9687 7.52556 19.5201 7.91015 18.9445C8.29473 18.3689 8.5 17.6922 8.5 17M5 8.5C4.30777 8.5 3.63108 8.29473 3.05551 7.91015C2.47993 7.52556 2.03133 6.97894 1.76642 6.33939C1.50152 5.69985 1.4322 4.99612 1.56725 4.31719C1.7023 3.63825 2.03564 3.01461 2.52513 2.52513C3.01461 2.03564 3.63825 1.7023 4.31719 1.56725C4.99612 1.4322 5.69985 1.50152 6.33939 1.76642C6.97894 2.03133 7.52556 2.47993 7.91015 3.05551C8.29473 3.63108 8.5 4.30777 8.5 5"
        stroke={theme.palette.warning.main}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DroneIcon;

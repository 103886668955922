import { FC } from "react";

import TextField from "@mui/material/TextField";
import { Control, Controller, FieldErrors } from "react-hook-form";

import { groupFormRules } from "../variables/groupData";

export interface IGroupForm {
  groupName: string;
}

interface IGroupFormProps {
  control: Control<IGroupForm, any>;
  errors: FieldErrors<IGroupForm>;
}

const EditGroupForm: FC<IGroupFormProps> = ({ control, errors }) => {
  return (
    <Controller
      name="groupName"
      control={control}
      rules={{
        required: "This field is required",
        pattern: {
          value: groupFormRules.groupName.pattern,
          message: "Valid characters: A-Z, a-z, 0-9",
        },
      }}
      render={({ field }) => (
        <TextField
          {...field}
          value={field.value ?? ""}
          sx={{ height: "60px" }}
          size="medium"
          label="Group name"
          fullWidth
          error={!!errors.groupName}
          helperText={errors.groupName ? errors.groupName.message : ""}
        />
      )}
    />
  );
};

export default EditGroupForm;

import { useLazyQuery } from "@apollo/client";

import { GET_NODE_BY_ID } from "../../../common/operations/queries";
import { errorNotification } from "../../../common/variables/notification";
import type {
  GetNodeByIdQuery,
  GetNodeByIdQueryVariables,
} from "./../../../API";

export const useGetNodeById = () => {
  const [getNodes, { data, loading }] = useLazyQuery<
    GetNodeByIdQuery,
    GetNodeByIdQueryVariables
  >(GET_NODE_BY_ID, {
    fetchPolicy: "network-only",
  });

  const fetchNode = (nodeId: string): void => {
    getNodes({
      variables: {
        nodeId,
      },
    }).catch((): void => {
      errorNotification();
    });
  };

  return { data: data?.getNodeById, fetchNode, loading };
};

import { gql } from "@apollo/client";

import { Location } from "../../API";
import client from "../../configs/apolloClient";

const useLocationFromCache = () => {
  const getCachedLocationName = (locationId?: string | null): string => {
    if (!locationId) {
      return "";
    }

    const locationName = locationId.includes("#L#")
      ? getCachedLocation(locationId ?? "")?.name ?? ""
      : locationId;

    return locationName;
  };

  const getCachedLocation = (locationId: string): Location | null => {
    const normalizedId =
      client.cache.identify({
        id: locationId,
        __typename: "Location",
      }) ?? "";

    const location = client.cache.readFragment<Location>({
      id: normalizedId,
      fragment: gql`
        fragment LocationFragment on Location {
          id
          name
        }
      `,
    });

    return location;
  };

  return { getCachedLocation, getCachedLocationName };
};

export default useLocationFromCache;

import { MathUtils, Matrix4, Quaternion, Vector3, Box3 } from "three";
import { TransformControls } from "three/addons/controls/TransformControls.js";

import { getRaycastIntersectsPointByFromToVectors } from "../SceneUtils/RaycastUtils";

import { ObjectMode } from "../../enums";
import { EntityTypeEnum } from "../../context/ProjectContext/ProjectEntitesTypes";

import { TRANSFORM_CONTROLS_RENDER_ORDER } from "../Constants";
import { createGroup } from "../SceneUtils/CreateMesh";

const _tempQuaternion = new Quaternion();

class CustomTransformControl extends TransformControls {
  constructor(camera, domElement) {
    super(camera, domElement);

    this.enabled = false;

    this.selectedObjectQuaternion = _tempQuaternion.clone();

    this.towerRotationStep = MathUtils.degToRad(10);

    this.objectRotationStep = MathUtils.degToRad(5);

    this.transformedGroup = null;

    this.objectsGroup = null;

    this.setSpace("world");

    this.setRenderOrder();
  }

  toggleVisibility(isVisible) {
    if (!this.enabled) {
      return;
    }

    this.visible = isVisible && !!this.object;
  }

  attach(objects) {
    this.transformedGroup.position.copy(objects.at(-1).position);

    this.transformedGroup.matrixWorldNeedsUpdate = true;

    objects.forEach(obj => this.transformedGroup.attach(obj));

    super.attach(this.transformedGroup);
  }

  detach() {
    this.objectsGroup.matrixWorldNeedsUpdate = true;

    const selectedObjects = [...this.transformedGroup.children];

    selectedObjects.forEach(obj => {
      obj.updateMatrixWorld();

      const position = new Vector3().setFromMatrixPosition(obj.matrixWorld);
      const quaternion = new Quaternion().setFromRotationMatrix(
        obj.matrixWorld
      );
      const scale = new Vector3().setFromMatrixScale(obj.matrixWorld);

      obj.position.copy(position);

      obj.quaternion.copy(quaternion);

      obj.scale.copy(scale);

      this.objectsGroup.add(obj);
    });

    super.detach();
  }

  toggleControlledObject(objects) {
    if (!this.enabled || !this.transformedGroup) {
      return;
    }

    if (objects) {
      this.detach();

      this.attach(objects);

      this.setControlledObjectRotationStep(objects);
    } else {
      this.detach();
    }
  }

  setControlledObjectRotationStep(objects) {
    const isActiveObjectsIncludesTower =
      !!objects.length &&
      objects.find(item => item.EntityType === EntityTypeEnum.Tower);

    this.setRotationSnap(
      isActiveObjectsIncludesTower
        ? this.towerRotationStep
        : this.objectRotationStep
    );
  }

  setMode(mode) {
    if (mode === ObjectMode.rotateObject) {
      this.showX = false;

      this.showY = true;

      this.showZ = false;
    } else if (mode === ObjectMode.translateObject) {
      this.showX = true;

      this.showY = false;

      this.showZ = true;
    }

    super.setMode(mode);
  }

  addSettings(bbox, ground, objectsGroup, onMouseDownCB, onMouseUpCB) {
    this.addTransformedGroup(objectsGroup);

    this.addEventListener("objectChange", () => {
      this.onTransformControlObjectChange(bbox, ground);
    });

    this.addEventListener("mouseDown", () => {
      this.onTransformControlMouseDown();

      onMouseDownCB();
    });

    this.addEventListener("mouseUp", () => {
      onMouseUpCB();
    });
  }

  addTransformedGroup(objectsGroup) {
    this.objectsGroup = objectsGroup;

    this.transformedGroup = createGroup("transformedGroup", objectsGroup);
  }

  onTransformControlMouseDown = () => {
    const selectedObjects = [...this.transformedGroup.children];

    this.selectedObjectQuaternion.copy(selectedObjects.at(-1).quaternion);
  };

  onTransformControlObjectChange(bbox, ground) {
    if (this.mode === ObjectMode.translateObject) {
      this.onObjectPostionChange(bbox, ground);
    } else if (this.mode === ObjectMode.rotateObject) {
      this.onObjectRotationChange();
    }
  }

  onObjectPostionChange(bbox, ground) {
    const getLimitedObjectPosition = positionVector => {
      const clonedPosition = positionVector.clone();

      clonedPosition.clamp(bbox.min, bbox.max);

      const fromPoint = clonedPosition.clone();

      fromPoint.y += 1000;

      const toPoint = clonedPosition.clone();

      toPoint.y -= 1000;

      const intersects = getRaycastIntersectsPointByFromToVectors(
        ground,
        fromPoint,
        toPoint
      );

      if (intersects && intersects.length > 0) {
        const point = intersects.at(-1).point;

        clonedPosition.copy(point);
      }

      return clonedPosition;
    };

    this.object.position.copy(getLimitedObjectPosition(this.object.position));

    const parentWorldPosition = new Vector3();

    this.transformedGroup.getWorldPosition(parentWorldPosition);

    const selectedObjects = [...this.transformedGroup.children];

    selectedObjects.forEach(obj => {
      const objectWorldPosition = new Vector3();

      obj.getWorldPosition(objectWorldPosition);

      const limitedObjectWorldPosition =
        getLimitedObjectPosition(objectWorldPosition);

      const localPosition = new Vector3()
        .copy(limitedObjectWorldPosition)
        .sub(parentWorldPosition);

      obj.position.copy(localPosition);
    });
  }

  onObjectRotationChange() {
    const selectedObjects = [...this.transformedGroup.children];

    this.transformedGroup.quaternion.copy(this._quaternionStart).normalize();

    selectedObjects.forEach(obj => {
      obj.quaternion.copy(
        _tempQuaternion.setFromAxisAngle(this.rotationAxis, this.rotationAngle)
      );

      obj.quaternion.multiply(this.selectedObjectQuaternion).normalize();

      if (this.rotationSnap === this.towerRotationStep) {
        const quaternion1 = obj.initialQuaternion.clone();
        const quaternion2 = obj.quaternion.clone();

        const angleInRad = quaternion1.angleTo(quaternion2);

        const roundedRotationYAngelValueInDeg = Math.round(
          MathUtils.radToDeg(angleInRad)
        );
        const roundedRotationStepInDeg = Math.round(
          MathUtils.radToDeg(this.towerRotationStep)
        );

        if (roundedRotationYAngelValueInDeg % roundedRotationStepInDeg !== 0) {
          obj.quaternion.copy(
            _tempQuaternion.setFromAxisAngle(
              this.rotationAxis,
              this.rotationAngle + MathUtils.degToRad(5)
            )
          );

          obj.quaternion.multiply(this.selectedObjectQuaternion).normalize();
        }
      }
    });
  }

  setRenderOrder() {
    this.renderOrder = TRANSFORM_CONTROLS_RENDER_ORDER;

    this.traverse(
      child => (child.renderOrder = TRANSFORM_CONTROLS_RENDER_ORDER)
    );
  }
}

export default CustomTransformControl;

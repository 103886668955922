import React, { useState } from "react";
import uuid from "react-uuid";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  List,
  ListItem,
  Typography,
  useTheme,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface ProtocolItem {
  main: string;
  subItems?: string[];
}

type Panel = {
  panelTitle: string;
  panelDescription?: string;
  protocols: ProtocolItem[];
};

type RequirementsPanelProps = {
  summary: string;
  summaryIcon: JSX.Element;
  detailsPanels: Panel[];
};

const RequirementsPanel: React.FC<RequirementsPanelProps> = ({
  summary,
  summaryIcon,
  detailsPanels,
}) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(prevState => !prevState);
  };

  const theme = useTheme();

  return (
    <Box sx={{ position: "relative" }}>
      <Accordion
        elevation={0}
        expanded={expanded}
        onChange={handleExpandClick}
        sx={{
          maxHeight: "400px",
          overflowY: "auto",
          borderRadius: "12px !important",
          border: `1px solid ${theme.palette.warning.shades?.["12p"]}`,
          backgroundColor: theme.palette.warning.shades?.["4p"],
          "& .MuiAccordionSummary-expandIconWrapper": {
            position: "sticky",
            right: "10px",
            top: "10px",
            width: "2em",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "2em",
            color: expanded ? theme.palette.primary.main : "",
            border: `1px solid ${theme.palette.otherOutlineBorder.main}`,
            backgroundColor: theme.palette.otherBackgroundLight.main,
            borderRadius: "8px",
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: "0.7em" }}>
            {summaryIcon}

            <Typography
              sx={{
                color: theme.palette.warning.main,
              }}
              variant="body2Bold"
            >
              {summary}
            </Typography>
          </Box>
        </AccordionSummary>

        <AccordionDetails>
          {detailsPanels.map(panel => {
            const key = uuid();

            return (
              <Box key={key}>
                <Typography variant="body2Bold">{panel.panelTitle}</Typography>

                {panel.panelDescription && (
                  <Box>
                    <Typography variant="body2Regular">
                      {panel.panelDescription}
                    </Typography>
                  </Box>
                )}

                <List aria-label="protocol details">
                  {panel.protocols.map((item, index) => {
                    const key = uuid();

                    return (
                      <React.Fragment key={key}>
                        <ListItem sx={{ margin: "4px", padding: "2px" }}>
                          <Typography variant="body2Regular">{`${index + 1}. ${
                            item.main
                          }`}</Typography>
                        </ListItem>

                        {item.subItems && (
                          <List sx={{ pl: 2, pt: 0, pb: 0 }}>
                            {item.subItems.map((subItem, subIndex) => {
                              const key = uuid();

                              return (
                                <ListItem
                                  key={key}
                                  sx={{ margin: "4px", padding: "2px" }}
                                >
                                  <Typography variant="body2Regular">{`${String.fromCharCode(
                                    97 + subIndex
                                  )}. ${subItem}`}</Typography>
                                </ListItem>
                              );
                            })}
                          </List>
                        )}
                      </React.Fragment>
                    );
                  })}
                </List>
              </Box>
            );
          })}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default RequirementsPanel;

import { memo, useContext } from "react";
import { Box, styled } from "@mui/material";

import ThreeCanvas from "./threeCanvas/ThreeCanvas";
import ConfiguratorLoader from "./ConfiguratorLoader";

import ObjectsPanel from "./objectsPanel/ObjectsPanel";
import TowersPanel from "./towersPanel/TowersPanel";

import ObjectTowerControlPanel from "./objectTowerControlPanel/ObjectTowerControlPanel";
import CameraControlPanel from "./camerasPanel/CameraControlPanel";
import ZoneControlPanel from "./zonePanel/ZoneControlPanel";

import TopDownViewButton from "./TopDownViewButton";
import ResetPositionButton from "./ResetPositionButton";

import SketchplanButton from "./SketchplanButton";
import FieldOfCamerasViewButton from "./fieldOfCamerasView/FieldOfCamerasViewButton";
import FieldOfZonesViewButton from "./fieldOfZonesView/FieldOfZonesViewButton";
import Import3dModelButton from "./Import3dModelButton";
import Filter3dObjects from "./objectsFilter/Filter3dObjects";
import Group3dObjectsInput from "./objectsGroup/Group3dObjectsInput";
import Search3dObjectsInput from "./objectsSearch/Search3dObjectsInput";

import WeatherConditionButton from "./weatherConditions/WeatherConditionButton";
import WeatherConditions from "./weatherConditions/WeatherConditions";

import { ConfiguratorPermissionContext, ProjectContext } from "../../context";
import { ModeTypeEnum } from "../../context/ProjectContext/ProjectEntitesTypes";
import FPVmodeButton from "./FPVmodeButton";

const ConfiguratorContainer = (): JSX.Element => {
  const { isAllowEditing } = useContext(ConfiguratorPermissionContext);

  const { mode, isFPVModeUnlocked } = useContext(ProjectContext);

  return (
    <Wrapper>
      <LeftPanel>
        <TowersPanel />
      </LeftPanel>

      <RightPanel>
        <ViewWrapper>
          <ConfiguratorLoader />
          <ThreeCanvas />
          <CameraControlPanel />
          <ZoneControlPanel />
          {mode !== ModeTypeEnum.fpvMode && <ObjectTowerControlPanel />}
          <ViewPortSettingsPanel>
            {mode !== ModeTypeEnum.fpvMode && <WeatherConditionButton />}
            {!isFPVModeUnlocked && <FPVmodeButton />}
            {mode !== ModeTypeEnum.fpvMode && <TopDownViewButton />}
            {mode !== ModeTypeEnum.fpvMode && <ResetPositionButton />}
          </ViewPortSettingsPanel>
          <WeatherConditions />
        </ViewWrapper>

        <SettingsPanel>
          {isAllowEditing && <Import3dModelButton />}
          {isAllowEditing && <Search3dObjectsInput />}
          {isAllowEditing && <Filter3dObjects />}
          {isAllowEditing && <Group3dObjectsInput />}
          <Separator />
          <SketchplanButton />
          {mode !== ModeTypeEnum.zoneView ? (
            <FieldOfCamerasViewButton />
          ) : (
            <FieldOfZonesViewButton />
          )}
        </SettingsPanel>

        {isAllowEditing && <ObjectsPanel />}
      </RightPanel>
    </Wrapper>
  );
};

export default memo(ConfiguratorContainer);

const Wrapper = styled(Box)(() => ({
  display: "flex",
  width: "100%",
  height: "calc(100% - 70px)",
  gap: "20px",
}));

const LeftPanel = styled(Box)(() => ({
  width: "350px",
  height: "100%",
  display: "flex",
}));

const RightPanel = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  height: "100%",
  gap: "10px",
  overflow: "hidden",
}));

const ViewWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "calc(100% - 66px - 34px - 20px)",
  display: "flex",
  position: "relative",
  border: `1px solid ${theme.palette.base.black}`,
  borderRadius: "4px",
  overflow: "hidden",
}));

const ViewPortSettingsPanel = styled(Box)(() => ({
  position: "absolute",
  right: "10px",
  top: "10px",
  width: "calc(100% - 20px)",
  display: "flex",
  justifyContent: "flex-end",
  gap: "8px",
}));

const SettingsPanel = styled(Box)(() => ({
  display: "flex",
  justifyContent: "flex-end",
  width: "100%",
  height: "34px",
  gap: "10px",
}));

const Separator = styled(Box)(() => ({
  flex: 1,
}));

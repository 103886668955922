import { CameraHelper } from "three";
import { CAMERA_HELPERS_RENDER_ORDER } from "./Constants";

class CameraHelpers {
  constructor(scene) {
    this.scene = scene;
  }

  add(camera, id) {
    const cameraHelper = new CameraHelper(camera);

    cameraHelper.ID = `camera-helper-${id}`;

    this.setRenderOrder(cameraHelper);

    cameraHelper.layers.disable();

    this.scene.add(cameraHelper);
  }

  remove(id) {
    const cameraHelper = this.scene.getObjectByProperty(
      "ID",
      `camera-helper-${id}`
    );

    if (cameraHelper) {
      this.scene.remove(cameraHelper);
    }
  }

  toggleEnableState(visibleHelperIdList) {
    this.scene.getObjectsByProperty("type", "CameraHelper").forEach(object => {
      if (object instanceof CameraHelper) {
        const isVisible = visibleHelperIdList.some(id =>
          object.ID.includes(id)
        );

        isVisible ? object.layers.enable() : object.layers.disable();
      }
    });
  }

  setVisibility(visible) {
    this.scene.getObjectsByProperty("type", "CameraHelper").forEach(object => {
      if (object instanceof CameraHelper) {
        object.visible = visible;
      }
    });
  }

  setRenderOrder(cameraHelper) {
    cameraHelper.material.transparent = true;

    cameraHelper.renderOrder = CAMERA_HELPERS_RENDER_ORDER;
  }

  update() {
    this.scene.getObjectsByProperty("type", "CameraHelper").forEach(object => {
      object instanceof CameraHelper && object.update();
    });
  }
}

export default CameraHelpers;

import { Box, CardMedia, CircularProgress } from "@mui/material";
import { useState } from "react";

type ImageCardProps = {
  src: string;
  alt: string;
};

const ImageCard: React.FC<ImageCardProps> = ({ src, alt }) => {
  const [isLoading, setLoading] = useState(true);

  return (
    <Box sx={{ marginBottom: "1em", position: "relative" }}>
      {isLoading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 140,
          }}
        >
          <CircularProgress />
        </Box>
      )}

      <CardMedia
        component="img"
        image={src}
        alt={alt}
        onLoad={() => setLoading(false)}
        onError={() => setLoading(false)}
        style={{ display: isLoading ? "none" : "block" }}
      />
    </Box>
  );
};

export default ImageCard;

import { useCallback, useState } from "react";

export interface ToggleProps {
  initialState: boolean;
}

export interface ToggleReturnInterface {
  state: boolean;
  toggle: () => void;
  setToggleState: (newState: boolean) => void;
}

export const useToggle = ({
  initialState = false,
}: ToggleProps): ToggleReturnInterface => {
  const [state, setState] = useState<boolean>(initialState);

  const toggle = useCallback(
    (): void => setState((state): boolean => !state),
    []
  );
  const setToggleState = useCallback(
    (newState: boolean): void => setState(newState),
    []
  );

  return { state, toggle, setToggleState };
};

import { useContext, useEffect, useMemo } from "react";
import { Box, Typography, styled } from "@mui/material";

import NumberInput from "../../input/NumberInput";
import SelectInput from "../../input/SelectInput";
import ReadOnlyField from "../../input/ReadOnlyField";

import {
  ConfiguratorPermissionContext,
  ProjectContext,
} from "../../../context";

import { useNumberInputState } from "../../../hooks/useNumberInputState";
import { useSelectInputState } from "../../../hooks/useSelectInputState";
import { useGetValueInCurrentMeasurementSystem } from "../../../hooks/useGetValueInCurrentMeasurementSystem";

import { CamerasType } from "../../../data/CamerasData";
import { SecondaryViewTypes } from "../../../data/SecondaryViewData";

import {
  CameraDataInterface,
  TowerDataInterface,
} from "../../../context/ProjectContext/ProjectEntitesTypes";

import { numberValidationOnlyRangeCheck } from "../../../validation";
import { formatNumber } from "../../../utils/numberUtils";

const AmountNumbersAfterDecimalPointInInputs = 4;

interface CameraControlPanelCameraSettingsInterface {
  activeCamera: CameraDataInterface;
}

const CameraControlPanelCameraSettings = ({
  activeCamera,
}: CameraControlPanelCameraSettingsInterface): JSX.Element => {
  const {
    isLoading,
    threeScene,
    towers,
    secondaryViewType,
    updateCameraProperties,
    activeCameraId,
    distanceMeasurementsMode,
    measurementSystem,
  } = useContext(ProjectContext);

  const { isAllowEditing } = useContext(ConfiguratorPermissionContext);

  const selectedTowerHeight = useMemo(() => {
    const towerWhereCameraIsMounted = towers.find(
      (towerData: TowerDataInterface) => towerData.id === activeCamera.towerId
    );

    return towerWhereCameraIsMounted ? towerWhereCameraIsMounted.height : 44;
  }, [activeCameraId]);

  const {
    getHeightValueForData,
    getHeightValueFromMeters,
    getHeightValueFromFoot,
  } = useGetValueInCurrentMeasurementSystem(measurementSystem);

  const cameraHeight = useNumberInputState(
    {
      value: `${getHeightValueFromMeters(activeCamera.height)}`,
      step: getHeightValueFromFoot(1),
      min: getHeightValueFromFoot(5),
      max: getHeightValueFromMeters(selectedTowerHeight),
      error: false,
      errorMessage: "",
      trackError: true,
    },
    numberValidationOnlyRangeCheck
  );

  const cameraFieldOfView = useSelectInputState({
    value: `${activeCamera.fov}`,
    placeholder: "Select...",
    options: ["9", "20", "45", "75", "90"],
    error: false,
    errorMessage: "",
    trackError: true,
  });

  const cameraVerticalAngle = useNumberInputState(
    {
      value: `${formatNumber(
        activeCamera.verticalAngle,
        AmountNumbersAfterDecimalPointInInputs
      )}`,
      step: 0.5,
      min: -89.5,
      max: 89.5,
      error: false,
      errorMessage: "",
      trackError: true,
    },
    numberValidationOnlyRangeCheck
  );

  const cameraHorizontalAngle = useNumberInputState(
    {
      value: `${formatNumber(
        activeCamera.horizontalAngle,
        AmountNumbersAfterDecimalPointInInputs
      )}`,
      step: 0.5,
      min: -180,
      max: 180,
      error: false,
      errorMessage: "",
      trackError: true,
    },
    numberValidationOnlyRangeCheck
  );

  const cameraRelativeHorizontalAngle = useNumberInputState(
    {
      value: `${formatNumber(
        activeCamera.relativeHorizontalAngle,
        AmountNumbersAfterDecimalPointInInputs
      )}`,
      step: 0.5,
      min: -180,
      max: 180,
      error: false,
      errorMessage: "",
      trackError: true,
    },
    numberValidationOnlyRangeCheck
  );

  const handleOnCameraHeightInputChange = (value: string) => {
    const isError = cameraHeight.onChange(value);

    if (!isError) {
      const haightValue = getHeightValueForData(+value);

      threeScene.sceneFacility3dObjects.facilityCameras.updateCameraHeight(
        activeCameraId,
        haightValue
      );

      updateCameraProperties([activeCameraId], null);
    }
  };

  const handleOnCameraFieldOfViewInputChange = (value: string) => {
    const isError = cameraFieldOfView.onChange(value);

    if (!isError) {
      threeScene.sceneFacility3dObjects.facilityCameras.updateCameraFov(
        activeCameraId,
        value
      );

      updateCameraProperties([activeCameraId], null);
    }
  };

  const handleOnCameraVerticalAngleInputChange = (value: string) => {
    const isError = cameraVerticalAngle.onChange(value);

    if (!isError) {
      threeScene.sceneFacility3dObjects.facilityCameras.updateCameraRotation(
        activeCameraId,
        "verticalAngle",
        value
      );

      updateCameraProperties([activeCameraId], null);
    }
  };

  const handleOnCameraHorizontalAngleInputChange = (value: string) => {
    const isError = cameraHorizontalAngle.onChange(value);

    if (!isError) {
      threeScene.sceneFacility3dObjects.facilityCameras.updateCameraRotation(
        activeCameraId,
        "horizontalAngle",
        value
      );

      updateCameraProperties([activeCameraId], null);
    }
  };

  const handleOnCameraRelativeHorizontalAngleInputChange = (value: string) => {
    const isError = cameraRelativeHorizontalAngle.onChange(value);

    if (!isError) {
      threeScene.sceneFacility3dObjects.facilityCameras.updateCameraRotation(
        activeCameraId,
        "relativeHorizontalAngle",
        value
      );

      updateCameraProperties([activeCameraId], null);
    }
  };

  useEffect(() => {
    cameraHeight.resetState({
      value: `${getHeightValueFromMeters(activeCamera.height)}`,
      step: getHeightValueFromFoot(1),
      min: getHeightValueFromFoot(5),
      max: getHeightValueFromMeters(selectedTowerHeight),
      error: false,
      errorMessage: "",
      trackError: true,
    });

    cameraFieldOfView.resetState({
      value: `${activeCamera.fov}`,
      placeholder: "Select...",
      options: ["9", "20", "45", "75", "90"],
      error: false,
      errorMessage: "",
      trackError: true,
    });
  }, [activeCameraId, measurementSystem]);

  useEffect(() => {
    if (
      Number(cameraVerticalAngle.state.value).toFixed(4) !==
      activeCamera.verticalAngle.toFixed(4)
    ) {
      cameraVerticalAngle.resetState({
        value: `${formatNumber(
          activeCamera.verticalAngle,
          AmountNumbersAfterDecimalPointInInputs
        )}`,
        step: 0.5,
        min: -89.5,
        max: 89.5,
        error: false,
        errorMessage: "",
        trackError: true,
      });
    }

    if (
      Number(cameraHorizontalAngle.state.value).toFixed(4) !==
      activeCamera.horizontalAngle.toFixed(4)
    ) {
      cameraHorizontalAngle.resetState({
        value: `${formatNumber(
          activeCamera.horizontalAngle,
          AmountNumbersAfterDecimalPointInInputs
        )}`,
        step: 0.5,
        min: -180,
        max: 180,
        error: false,
        errorMessage: "",
        trackError: true,
      });
    }

    if (
      Number(cameraRelativeHorizontalAngle.state.value).toFixed(4) !==
      activeCamera.relativeHorizontalAngle.toFixed(4)
    ) {
      cameraRelativeHorizontalAngle.resetState({
        value: `${formatNumber(
          activeCamera.relativeHorizontalAngle,
          AmountNumbersAfterDecimalPointInInputs
        )}`,
        step: 0.5,
        min: -180,
        max: 180,
        error: false,
        errorMessage: "",
        trackError: true,
      });
    }
  }, [activeCameraId, activeCamera, measurementSystem]);

  const disabledInputs =
    distanceMeasurementsMode || isLoading || !isAllowEditing;

  const disabledGasRGBCameraInputs =
    disabledInputs || secondaryViewType === SecondaryViewTypes.view360;

  return (
    <CameraSettingsWrapper>
      {activeCamera.cameraType !== CamerasType.RGBCamera && (
        <ReadOnlyField
          label="Field of view:"
          labelFontVariant="body12Regular"
          labelWidth="60%"
          viewType="row"
          value="360°"
        />
      )}

      <NumberInput
        label="Height:"
        labelFontVariant="body12Regular"
        step={cameraHeight.state.step}
        min={cameraHeight.state.min}
        max={cameraHeight.state.max}
        viewType="row"
        labelWidth="60%"
        value={cameraHeight.state.value}
        placeholder={`${getHeightValueFromMeters(activeCamera.height)}`}
        isError={cameraHeight.state.error}
        disabled={disabledInputs}
        handleOnChange={handleOnCameraHeightInputChange}
      />

      {activeCamera.cameraType === CamerasType.MinervaGasCamera && (
        <Title variant="body14Medium">Gas camera settings</Title>
      )}

      {activeCamera.cameraType === CamerasType.RGBCamera && (
        <Title variant="body14Medium">RGB camera settings</Title>
      )}

      {activeCamera.cameraType !== CamerasType.MinervaCamera && (
        <>
          <SelectInput
            label="Field of view:"
            labelFontVariant="body12Regular"
            labelWidth="60%"
            viewType="row"
            value={cameraFieldOfView.state.value}
            placeholder={cameraFieldOfView.state.placeholder}
            options={cameraFieldOfView.state.options}
            isError={cameraFieldOfView.state.error}
            disabled={disabledGasRGBCameraInputs}
            handleOnChange={handleOnCameraFieldOfViewInputChange}
          />
          <NumberInput
            label="Tilt:"
            labelFontVariant="body12Regular"
            step={cameraVerticalAngle.state.step}
            min={cameraVerticalAngle.state.min}
            max={cameraVerticalAngle.state.max}
            viewType="row"
            labelWidth="60%"
            value={cameraVerticalAngle.state.value}
            placeholder={`${formatNumber(
              activeCamera.verticalAngle,
              AmountNumbersAfterDecimalPointInInputs
            )}`}
            isError={cameraVerticalAngle.state.error}
            disabled={disabledGasRGBCameraInputs}
            handleOnChange={handleOnCameraVerticalAngleInputChange}
          />
          <NumberInput
            label="Pan:"
            labelFontVariant="body12Regular"
            step={cameraHorizontalAngle.state.step}
            min={cameraHorizontalAngle.state.min}
            max={cameraHorizontalAngle.state.max}
            viewType="row"
            labelWidth="60%"
            value={cameraHorizontalAngle.state.value}
            placeholder={`${formatNumber(
              activeCamera.horizontalAngle,
              AmountNumbersAfterDecimalPointInInputs
            )}`}
            isError={cameraHorizontalAngle.state.error}
            disabled={disabledGasRGBCameraInputs}
            handleOnChange={handleOnCameraHorizontalAngleInputChange}
          />
          <NumberInput
            label="Relative pan:"
            labelFontVariant="body12Regular"
            step={cameraRelativeHorizontalAngle.state.step}
            min={cameraRelativeHorizontalAngle.state.min}
            max={cameraRelativeHorizontalAngle.state.max}
            viewType="row"
            labelWidth="60%"
            value={cameraRelativeHorizontalAngle.state.value}
            placeholder={`${formatNumber(
              activeCamera.relativeHorizontalAngle,
              AmountNumbersAfterDecimalPointInInputs
            )}`}
            isError={cameraRelativeHorizontalAngle.state.error}
            disabled={disabledGasRGBCameraInputs}
            handleOnChange={handleOnCameraRelativeHorizontalAngleInputChange}
          />
        </>
      )}
    </CameraSettingsWrapper>
  );
};

export default CameraControlPanelCameraSettings;

const CameraSettingsWrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "8px",
}));

const Title = styled(Typography)(({ theme }) => ({
  paddingTop: "6px",
  color: theme.palette["base-label"],
}));

import { useEffect, useMemo } from "react";

import { useLazyQuery } from "@apollo/client";

import {
  GetCategoriesByCustomerQuery,
  GetCategoriesByCustomerQueryVariables,
  PaginatedCategories,
} from "../../../../../API";
import { useCustomerIdGuard } from "../../../../../common/hooks/useCustomerIdGuard";
import { GET_CATEGORIES_BY_CUSTOMER } from "../../../../../common/operations/queries";
import { errorNotification } from "../../../../../common/variables/notification";
import client from "../../../../../configs/apolloClient";

export const useGetPaginatedPlateCategories = () => {
  const selectedCustomerId = useCustomerIdGuard();

  const [getCategories, { data, loading }] = useLazyQuery<
    GetCategoriesByCustomerQuery,
    GetCategoriesByCustomerQueryVariables
  >(GET_CATEGORIES_BY_CUSTOMER, {
    fetchPolicy: "network-only",
  });

  useEffect((): void => {
    if (selectedCustomerId) {
      fetchPlateCategories(selectedCustomerId);
    }
  }, [selectedCustomerId]);

  const handleRequestError = async (): Promise<void> => {
    await client.cache.reset();

    errorNotification();
  };

  const fetchPlateCategories = (
    customerId?: string,
    nextToken?: string
  ): void => {
    getCategories({
      variables: {
        input: {
          customerId,
          nextToken,
        },
      },
    })
      .then(async (response): Promise<void> => {
        if (response.data?.getCategoriesByCustomer.nextToken) {
          fetchPlateCategories(
            customerId,
            response.data?.getCategoriesByCustomer.nextToken
          );
        }

        if (response.error) {
          await handleRequestError();
        }
      })
      .catch(async (): Promise<void> => {
        await handleRequestError();
      });
  };

  const categories = useMemo(
    () => data?.getCategoriesByCustomer as PaginatedCategories,
    [data?.getCategoriesByCustomer]
  ) as PaginatedCategories;

  return { categories, loading };
};

import type { ComponentsOverrides, Theme } from "@mui/material";

export const MuiFormHelperTextDarkStyleOverrides: ComponentsOverrides<Theme>["MuiFormHelperText"] =
  {
    root: ({ theme }) => {
      return {
        color: theme.palette.otherTextTertiary.main,
      };
    },
  };

import { useMutation } from "@apollo/client";

import { DELETE_DT_PROJECT } from "../../../../common/operations/mutations";

export const useDeleteDTProject = () => {
  const [deleteDTProjectMutation, { data, loading, error }] =
    useMutation(DELETE_DT_PROJECT);

  const deleteDTProject = async (projectId, customerId) => {
    return await deleteDTProjectMutation({
      variables: {
        input: { projectId, customerId },
      },
      update(cache) {
        const normalizedId = cache.identify({
          id: customerId + "#" + projectId,
          __typename: "DTProject",
        });

        cache.evict({ id: normalizedId });

        cache.gc();
      },
    });
  };

  return { deleteDTProject, data, error, loading };
};

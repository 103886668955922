import { useContext, useEffect, useState } from "react";
import { Box, Button, styled } from "@mui/material";

import { ChevronsUpDown } from "../../../assets/icons/svgAssets";
import FieldOfCamerasViewControlPanel from "./FieldOfCamerasViewControlPanel";

import { ProjectContext } from "../../../context";

import {
  CameraDataInterface,
  CameraHelpersInterface,
  ModeTypeEnum,
} from "../../../context/ProjectContext/ProjectEntitesTypes";
import { CamerasType } from "../../../data/CamerasData";

const FieldOfCamerasViewButton = (): JSX.Element => {
  const { threeScene, sceneStarted, isLoading, cameras, mode } =
    useContext(ProjectContext);

  const [isShowPanel, setIsShowPanel] = useState<boolean>(false);
  const [cameraHelpersList, setCameraHelpersList] = useState<
    CameraHelpersInterface[]
  >([]);

  const handleOnFieldOfCamerasViewClick = () => {
    setIsShowPanel(!isShowPanel);
  };

  const handleOnShowHideAllClick = (isVisibleFieldOfView: boolean) => {
    setCameraHelpersList(prev =>
      prev.map(data => ({ ...data, isVisibleFieldOfView }))
    );
  };

  const handleOnItemClick = (id: string) => {
    setCameraHelpersList(prev =>
      prev.map(data => {
        if (data.id === id) {
          return {
            ...data,
            isVisibleFieldOfView: !data.isVisibleFieldOfView,
          };
        }

        return data;
      })
    );
  };

  useEffect(() => {
    const isAllVisible = cameraHelpersList.every(
      data => data.isVisibleFieldOfView
    );

    const newCameraHelpersList = cameras
      .filter(
        (camera: CameraDataInterface) =>
          camera.cameraType === CamerasType.MinervaGasCamera ||
          camera.cameraType === CamerasType.RGBCamera
      )
      .map((camera: CameraDataInterface) => {
        const existingData = cameraHelpersList.find(
          data => data.id === camera.id
        );

        const isVisibleFieldOfView = existingData
          ? existingData.isVisibleFieldOfView
          : isAllVisible;

        return {
          ...camera,
          isVisibleFieldOfView: isVisibleFieldOfView,
        };
      });

    setCameraHelpersList(newCameraHelpersList);

    !newCameraHelpersList.length && setIsShowPanel(false);
  }, [cameras]);

  useEffect(() => {
    if (threeScene) {
      const visibleFieldOfViewIdList = cameraHelpersList
        .filter(data => data.isVisibleFieldOfView)
        .map(data => data.id);

      threeScene.cameraHelpers.toggleEnableState(visibleFieldOfViewIdList);
    }
  }, [cameraHelpersList, threeScene, sceneStarted]);

  useEffect(() => {
    return () => {
      if (threeScene) {
        threeScene.cameraHelpers.toggleEnableState([]);
      }
    };
  }, [threeScene]);

  const disabled =
    !cameraHelpersList.length ||
    !sceneStarted ||
    isLoading ||
    mode === ModeTypeEnum.fpvMode;

  return (
    <Wrapper>
      <Button
        variant={isShowPanel ? "primary" : "outlined"}
        color="blue"
        disabled={disabled}
        onClick={handleOnFieldOfCamerasViewClick}
        startIcon={<ChevronsUpDown size={16} />}
      >
        Field of cameras view
      </Button>
      {isShowPanel &&
        cameraHelpersList.length &&
        mode !== ModeTypeEnum.fpvMode && (
          <FieldOfCamerasViewControlPanel
            cameraHelpersList={cameraHelpersList}
            onButtonToggleClick={handleOnShowHideAllClick}
            onItemclick={handleOnItemClick}
          />
        )}
    </Wrapper>
  );
};

export default FieldOfCamerasViewButton;

const Wrapper = styled(Box)(() => ({
  position: "relative",
}));

import { ApolloError, useMutation } from "@apollo/client";

import { UPLOAD_DT_FILES } from "../../../../common/operations/mutations";
import { DTFile, UploadFileInput } from "../../../../API";

export interface UploadDTFilesDataInterface {
  uploadDTFiles: DTFile[];
}

export interface UseUploadDTFilesInterface {
  addDTFiles: (
    objectId: string,
    customerId: string | null,
    files: UploadFileInput[]
  ) => Promise<UploadDTFilesDataInterface>;
  data: UploadDTFilesDataInterface;
  loading: boolean;
  error: ApolloError | undefined;
}

export const useUploadDTFiles = (): UseUploadDTFilesInterface => {
  const [uploadDTFiles, { data, loading, error }] =
    useMutation(UPLOAD_DT_FILES);

  const addDTFiles = async (
    objectId: string,
    customerId: string | null,
    files: UploadFileInput[]
  ) => {
    const response = await uploadDTFiles({
      variables: {
        customerId,
        objectId,
        files,
      },
    });

    return response.data;
  };

  return { addDTFiles, data, loading, error };
};

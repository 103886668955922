import * as togpx from "@tmcw/togeojson";

import { readZipFile } from "../utils";

export const convertKmzToGeojson = async (file: File) => {
  const zip = await readZipFile(file);

  const kmlFile = zip.file(/.kml$/i)[0];

  if (!kmlFile) {
    throw new Error("No KML file found in the KMZ archive.");
  }

  const kmlContent = await kmlFile.async("string");
  const kmldom = new DOMParser().parseFromString(kmlContent, "text/xml");
  const geojson = togpx.kml(kmldom);

  return geojson;
};

import { useSubscription } from "@apollo/client";
import { LISTEN_DELETE_ZONE } from "../../../../common/operations/subscriptions";
import { ListenToDeleteZoneSubscription } from "../../../../API";
import { successNotification } from "../../../../common/variables/notification";

export const useListenToDeleteZone = (zoneId?: string) => {
  const { data, loading, error } =
    useSubscription<ListenToDeleteZoneSubscription>(LISTEN_DELETE_ZONE, {
      fetchPolicy: "no-cache",
      variables: {
        id: zoneId,
      },
      shouldResubscribe: true,
      onSubscriptionData: ({ client, subscriptionData }) => {
        if (subscriptionData?.data?.listenToDeleteZone) {
          const deletedZone = subscriptionData.data.listenToDeleteZone;

          client.cache.evict({
            id: `Zone:${deletedZone.deviceId}#${deletedZone.id}`,
          });

          client.cache.gc();

          successNotification(`Zone ${deletedZone.name} has been deleted.`);
        }
      },
    });

  if (error) {
    console.error("Subscription error:", error);
  }

  return { data, loading, error } as const;
};

import { useContext, useState } from "react";
import { Box, Button, ButtonGroup, Typography, styled } from "@mui/material";
import { type CSSProperties } from "@mui/material/styles/createMixins";

import WeatherConditonSettings from "./WeatherConditonSettings";

import { ProjectContext, WeatherConditionsContext } from "../../../context";

import { ModeTypeEnum } from "../../../context/ProjectContext/ProjectEntitesTypes";

const WeatherConditionButton = () => {
  const { threeScene, isLoading, mode } = useContext(ProjectContext);

  const { weatherVisibility, toggleWeatherVisibility } = useContext(
    WeatherConditionsContext
  );

  const [modalWindowState, setModalWindowState] = useState(false);

  const handleOnClick = () => {
    if (!threeScene) {
      return;
    }

    setModalWindowState(true);
  };

  const onClose = (): void => {
    setModalWindowState(false);
  };

  const disabled = isLoading || mode !== ModeTypeEnum.standard;

  return (
    <>
      <ButtonGroup variant="outlined" disabled={disabled}>
        <Button color="blue" onClick={handleOnClick} disableRipple>
          Set weather condition
        </Button>

        <Button color="blue" onClick={toggleWeatherVisibility} disableRipple>
          <ToggleWrapper
            state={weatherVisibility ? 1 : 0}
            disabled={disabled ? 1 : 0}
          >
            <Typography
              component={"p"}
              variant="body12Medium"
              color={weatherVisibility ? "base-background-lighter" : "blue"}
            >
              {weatherVisibility ? "On" : "Off"}
            </Typography>

            <Circle
              disabled={disabled ? 1 : 0}
              state={weatherVisibility ? 1 : 0}
            />
          </ToggleWrapper>
        </Button>
      </ButtonGroup>

      {modalWindowState && <WeatherConditonSettings onClose={onClose} />}
    </>
  );
};

export default WeatherConditionButton;

const ToggleWrapper = styled(Box)<{ state: number; disabled: number }>(
  ({ theme, state, disabled }): CSSProperties => ({
    display: "flex",
    alignItems: "center",
    flexDirection: state ? "row" : "row-reverse",
    gap: "6px",
    padding: "2px 3px",
    background: state
      ? theme.palette.blue.main
      : theme.palette["base-background-lighter"],
    borderRadius: "338px",
    border: `1px solid ${theme.palette.blue.main}`,
    borderColor: !disabled
      ? theme.palette.blue.main
      : theme.palette["grey-dark"],

    "& p": {
      width: "22px",
      color: state
        ? theme.palette["base-background-lighter"]
        : theme.palette.blue,
    },
    transition: "all 0.5s",
  })
);

const Circle = styled(Box)<{ state: number; disabled: number }>(
  ({ theme, state, disabled }) => ({
    width: "12px",
    height: "12px",
    borderRadius: "100%",
    background: state
      ? theme.palette["base-background-lighter"]
      : theme.palette.blue.main,

    ...(disabled && {
      background: theme.palette["grey-dark"],
    }),

    transition: "all 0.5s",
  })
);

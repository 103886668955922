import { FC } from "react";

import TextField from "@mui/material/TextField";
import { Control, Controller, FieldErrors } from "react-hook-form";

import { clientUploadsFormRules } from "../variables/clientUploadsData";

export interface IClientUploadsForm {
  clientName: string;
}

interface IClientFormProps {
  control: Control<IClientUploadsForm, any>;
  errors: FieldErrors<IClientUploadsForm>;
}

const EditClientForm: FC<IClientFormProps> = ({ control, errors }) => {
  return (
    <Controller
      name="clientName"
      control={control}
      rules={{
        required: "This field is required",
        pattern: {
          value: clientUploadsFormRules.clientName.pattern,
          message: "Valid characters: A-Z, a-z, 0-9",
        },
      }}
      render={({ field }) => (
        <TextField
          {...field}
          value={field.value ?? ""}
          sx={{ height: "60px" }}
          size="medium"
          label="Client name"
          fullWidth
          error={!!errors.clientName}
          helperText={errors.clientName ? errors.clientName.message : ""}
        />
      )}
    />
  );
};

export default EditClientForm;

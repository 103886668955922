import { forwardRef, MediaHTMLAttributes } from "react";

import CardMedia, { CardMediaProps } from "@mui/material/CardMedia";

type Video = MediaHTMLAttributes<HTMLVideoElement> & CardMediaProps;

export interface ICardMediaVideoPlayerProps extends Video {}

const CardMediaVideoPlayer = forwardRef<
  HTMLVideoElement,
  ICardMediaVideoPlayerProps
>((props, ref) => {
  return (
    <CardMedia
      sx={props.sx}
      id={props.id ?? "video"}
      component="video"
      ref={ref}
      {...props}
    />
  );
});

export default CardMediaVideoPlayer;

import { createContext } from "react";

import { NotificationInterface } from "../../components/Notification";

export interface NotificationContextInterface {
  notificationData: NotificationInterface | null;
  openNotification: (data: NotificationInterface) => void;
  closeNotification: () => void;
}

export const NotificationContext = createContext<NotificationContextInterface>(
  {} as NotificationContextInterface
);

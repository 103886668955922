import { useContext } from "react";
import { Box, styled } from "@mui/material";

import SelectInput from "../../input/SelectInput";

import { useSelectInputState } from "../../../hooks/useSelectInputState";

import {
  DTObjectsLibraryGroupTypeEnum,
  SearchFilterGroupObjectsContext,
} from "../../../../pages/project-editor/context/SearchFilterGroupObjectsContext/SearchFilterGroupObjectsContext";
import { ProjectContext } from "../../../context";
import { ModeTypeEnum } from "../../../context/ProjectContext/ProjectEntitesTypes";

const Group3dObjectsInput = (): JSX.Element => {
  const { handleSetGroupQuery } = useContext(SearchFilterGroupObjectsContext);
  const { sceneStarted, isLoading, mode } = useContext(ProjectContext);

  const groupBy = useSelectInputState({
    value: DTObjectsLibraryGroupTypeEnum.Name,
    placeholder: "Select...",
    options: [...Object.values(DTObjectsLibraryGroupTypeEnum)],
    error: true,
    errorMessage: "",
    trackError: false,
  });

  const onGroupByInputChange = (value: string) => {
    groupBy.onChange(value);

    const groupByQueryValue = value as DTObjectsLibraryGroupTypeEnum;

    handleSetGroupQuery({ value: groupByQueryValue });
  };

  return (
    <Wrapper>
      <SelectInput
        value={groupBy.state.value}
        options={groupBy.state.options}
        handleOnChange={onGroupByInputChange}
        variant="filled"
        iconPosition="left"
        disabled={!sceneStarted || isLoading || mode !== ModeTypeEnum.standard}
      />
    </Wrapper>
  );
};

export default Group3dObjectsInput;

const Wrapper = styled(Box)(() => ({
  width: "205px",

  "& .MuiInputBase-root": {
    height: "34px",
  },

  "@media (max-width: 1500px)": {
    width: "185px",
  },
}));

import {
  PlaneGeometry,
  MeshBasicMaterial,
  Mesh,
  Matrix4,
  DoubleSide,
} from "three";

import { createGroup } from "../SceneUtils/CreateMesh";

class TowerSide {
  constructor(sideData, size) {
    this.sideData = sideData;

    this.size = size;

    this.object = createGroup(this.sideData.name);

    this.object.EntityType = this.sideData.EntityType;

    this.object.SideIndex = this.sideData.index;

    this.moveSideToPosition();

    this.addChildrenWrapper();

    this.addModelPart();
  }

  moveSideToPosition() {
    this.object.rotation.y = this.sideData.angleInRad;

    this.object.translateZ(this.size.x / 5);
  }

  addChildrenWrapper() {
    createGroup("SideCameraWrapper", this.object);
  }

  addModelPart() {
    const modelWrapper = createGroup(
      `${this.object.EntityType}-ModelWrapper`,
      this.object
    );
    const sideModelGroup = createGroup("Side", modelWrapper);

    const geometry = new PlaneGeometry(this.size.x / 3, this.size.y);

    geometry.applyMatrix4(new Matrix4().makeTranslation(0, this.size.y / 2, 0));

    const material = new MeshBasicMaterial({
      side: DoubleSide,
      transparent: true,
      opacity: 0,
      depthWrite: false,
      depthTest: false,
    });

    const model = new Mesh(geometry, material);

    sideModelGroup.add(model);
  }
}

export default TowerSide;

import { KeyboardEvent, MouseEvent, useContext, useRef } from "react";
import { Box, styled } from "@mui/material";
import isEqual from "lodash/isEqual";

import { ConfiguratorPermissionContext, ProjectContext } from "../../context";
import { SceneObject3dContextMenuContext } from "../../../pages/project-editor/context";

import { ModeTypeEnum } from "../../context/ProjectContext/ProjectEntitesTypes";

interface ConfiguratorViewWrapperInterface {
  children: React.ReactNode;
}

const ViewportWrapper = ({
  children,
}: ConfiguratorViewWrapperInterface): JSX.Element => {
  const { isAllowEditing } = useContext(ConfiguratorPermissionContext);
  const { mode } = useContext(ProjectContext);
  const { handleOnOpenContextMenu, onContextMenuKeyPressed } = useContext(
    SceneObject3dContextMenuContext
  );

  const mouseDownPositionRef = useRef<{ x: number; y: number } | null>(null);

  const handleOnMouseDown = (event: MouseEvent<HTMLDivElement>): void => {
    if (!isAllowEditing || event.button !== 2) {
      return;
    }

    event.preventDefault();

    mouseDownPositionRef.current = { x: event.clientX, y: event.clientY };
  };

  const handleOnMouseUp = (event: MouseEvent<HTMLDivElement>): void => {
    if (!isAllowEditing || event.button !== 2) {
      return;
    }

    event.preventDefault();

    const mouseUpPosition = { x: event.clientX, y: event.clientY };

    if (
      mode === ModeTypeEnum.standard &&
      isEqual(mouseDownPositionRef.current, mouseUpPosition)
    ) {
      handleOnOpenContextMenu({ left: event.clientX, top: event.clientY });
    }
  };

  const handleOnKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (!isAllowEditing) {
      return;
    }

    event.preventDefault();

    if (mode === ModeTypeEnum.standard) {
      onContextMenuKeyPressed(event);
    }
  };

  return (
    <Wrapper
      tabIndex={-1}
      onMouseDown={handleOnMouseDown}
      onMouseUp={handleOnMouseUp}
      onKeyDown={handleOnKeyDown}
      onContextMenu={e => e.preventDefault()}
    >
      {children}
    </Wrapper>
  );
};

export default ViewportWrapper;

const Wrapper = styled(Box)(() => ({
  position: "relative",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "100%",
}));

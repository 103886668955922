import { useState } from "react";

import { Line, Circle, Group } from "react-konva";
import { KonvaEventObject } from "konva/lib/Node";
import { Stage } from "konva/lib/Stage";
import { useTheme } from "@mui/material";

import {
  minMax,
  dragBoundFunc,
  colorOfAnnotationType,
  HEX_OPACITY_30,
  VERTEX_RADIUS,
  PointsFormat,
} from "./utils";
import { AnnotationType } from "../../../API";
import { Vector2d } from "konva/lib/types";

type PolygonProps = {
  points: PointsFormat;
  flattenedPoints: number[];
  isFinished: boolean;
  handlePointDragMove: (evt: KonvaEventObject<DragEvent>) => void;
  handleGroupDragEnd: (evt: KonvaEventObject<DragEvent>) => void;
  handleMouseOverStartPoint: (evt: KonvaEventObject<MouseEvent>) => void;
  handleMouseOutStartPoint: (evt: KonvaEventObject<MouseEvent>) => void;
  typeOfAnnotation: AnnotationType;
};

const Polygon: React.FC<PolygonProps> = props => {
  const {
    points,
    flattenedPoints,
    isFinished,
    handlePointDragMove,
    handleGroupDragEnd,
    handleMouseOverStartPoint,
    handleMouseOutStartPoint,
    typeOfAnnotation,
  } = props;

  const theme = useTheme();

  const [stage, setStage] = useState<Stage>();

  const [minMaxX, setMinMaxX] = useState([0, 0]); // min and max in x axis
  const [minMaxY, setMinMaxY] = useState([0, 0]); // min and max in y axis

  const handleGroupMouseOver = (e: KonvaEventObject<MouseEvent>) => {
    const stage: Stage | null = e.target.getStage();

    if (stage) {
      stage.container().style.cursor = "move";

      setStage(stage);
    }
  };

  const handleGroupMouseOut = (e: KonvaEventObject<MouseEvent>): void => {
    const stage = e.target.getStage();

    if (stage) {
      stage.container().style.cursor = "default";
    }
  };

  const handleGroupDragStart = (): void => {
    const arrX = points.map(p => p[0]);
    const arrY = points.map(p => p[1]);

    setMinMaxX(minMax(arrX));

    setMinMaxY(minMax(arrY));
  };

  const groupDragBound = (pos: Vector2d): Vector2d => {
    let { x, y } = pos;

    if (!stage) return { x, y };

    const sw = stage.width();
    const sh = stage.height();

    if (minMaxY[0] + y < 0) y = -1 * minMaxY[0];

    if (minMaxX[0] + x < 0) x = -1 * minMaxX[0];

    if (minMaxY[1] + y > sh) y = sh - minMaxY[1];

    if (minMaxX[1] + x > sw) x = sw - minMaxX[1];

    return { x, y };
  };

  const fillColor = colorOfAnnotationType(typeOfAnnotation, theme);

  return (
    <Group
      name="polygon"
      draggable={isFinished}
      onDragStart={handleGroupDragStart}
      onDragEnd={handleGroupDragEnd}
      dragBoundFunc={groupDragBound}
      onMouseOver={handleGroupMouseOver}
      onMouseOut={handleGroupMouseOut}
    >
      <Line
        points={flattenedPoints}
        stroke={fillColor}
        strokeWidth={1}
        closed={isFinished}
        fill={fillColor + HEX_OPACITY_30}
      />

      {points.map((point, index) => {
        const x = point[0] - VERTEX_RADIUS / 2;
        const y = point[1] - VERTEX_RADIUS / 2;

        const startPointAttr =
          index === 0
            ? {
                hitStrokeWidth: 12,
                onMouseOver: handleMouseOverStartPoint,
                onMouseOut: handleMouseOutStartPoint,
              }
            : null;

        return (
          <Circle
            key={index}
            x={x}
            y={y}
            radius={VERTEX_RADIUS}
            fill={fillColor}
            stroke="white"
            strokeWidth={1}
            draggable
            onDragMove={handlePointDragMove}
            dragBoundFunc={pos => {
              return dragBoundFunc(
                stage?.width() ?? 0,
                stage?.height() ?? 0,
                pos
              );
            }}
            {...startPointAttr}
          />
        );
      })}
    </Group>
  );
};

export default Polygon;

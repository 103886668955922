import { Box, useTheme } from "@mui/material";
import { colorOfAnnotationType } from "../../../common/components/canvas/utils";
import CircleWithText from "../../../common/components/icons/CircleWithText";
import { AnnotationTableItem } from "./AnnotationContainer";
import { AnnotationType } from "../../../API";

type AnnotationLegendProps = {
  items: AnnotationTableItem[];
};

const AnnotationLegend: React.FC<AnnotationLegendProps> = ({ items }) => {
  const theme = useTheme();

  return (
    <Box sx={{ display: "flex", gap: "1em" }}>
      {items.map(elem => {
        const colorOfPoint = colorOfAnnotationType(elem.type, theme);
        const hideMarginForTL = elem.type === AnnotationType.TANK_LEVEL;

        return (
          <CircleWithText
            key={elem.title}
            boxStyle={hideMarginForTL ? { margin: "0.5em 0 0 0" } : undefined}
            color={colorOfPoint}
            title={elem.title}
          />
        );
      })}
    </Box>
  );
};

export default AnnotationLegend;

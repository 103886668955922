import { ApolloError, gql, useMutation } from "@apollo/client";

import {
  AddCategoryMutation,
  AddCategoryMutationVariables,
} from "../../../../../API";
import { useCustomerIdGuard } from "../../../../../common/hooks/useCustomerIdGuard";
import { ADD_CATEGORY } from "../../../../../common/operations/mutations";
import {
  errorNotification,
  successNotification,
} from "../../../../../common/variables/notification";

export const useCreateCategory = () => {
  const selectedCustomerId = useCustomerIdGuard();

  const [addCategory, { data, loading }] = useMutation<
    AddCategoryMutation,
    AddCategoryMutationVariables
  >(ADD_CATEGORY);

  const createCategory = (categoryName: string) => {
    return addCategory({
      variables: {
        input: {
          customerId: selectedCustomerId,
          categoryName: categoryName.trim(),
        },
      },
      onCompleted: response => {
        if (response.addCategory) {
          successNotification("New category added");
        }
      },
      onError: (error: ApolloError): void => {
        errorNotification(error.message);

        console.error(error.message);
      },
      update: (cache, response): void => {
        cache.modify({
          fields: {
            getCategoriesByCustomer(existing) {
              const newCategoryRef = cache.writeFragment({
                data: response.data?.addCategory,
                fragment: gql`
                  fragment NewCategory on Category {
                    id
                    categoryName
                  }
                `,
              });

              const doesExistsInCache = existing.items.some(
                (item: { __ref: string }) =>
                  item.__ref === newCategoryRef?.__ref
              );

              if (!existing) {
                return response?.data;
              }

              if (doesExistsInCache) {
                return {
                  ...existing,
                  items: [...existing.items],
                };
              } else {
                return {
                  ...existing,
                  items: [...existing.items, newCategoryRef],
                };
              }
            },
          },
        });
      },
    });
  };

  return { createCategory, data, loading };
};

import { useLazyQuery } from "@apollo/client";

import { GET_SERVICE_BY_ID } from "../../../common/operations/queries";
import { errorNotification } from "../../../common/variables/notification";
import type {
  GetServiceByIdQuery,
  GetServiceByIdQueryVariables,
  GetServiceInput,
} from "../../../API";

export const useGetServiceById = () => {
  const [getService, { data, loading }] = useLazyQuery<
    GetServiceByIdQuery,
    GetServiceByIdQueryVariables
  >(GET_SERVICE_BY_ID, {
    fetchPolicy: "network-only",
  });

  const fetchService = (input: GetServiceInput): void => {
    getService({
      variables: {
        input,
      },
    }).catch((): void => {
      errorNotification();
    });
  };

  return { data: data?.getServiceById, fetchService, loading };
};

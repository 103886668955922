import { FC } from "react";

import CircleIcon from "@mui/icons-material/Circle";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { CSSObject } from "@mui/styled-engine";

interface TitleWithIconProps {
  title: string;
  color: string;
  count?: number;
  boxStyle?: CSSObject;
}

const CircleWithText: FC<TitleWithIconProps> = ({
  title,
  color,
  count,
  boxStyle,
}): JSX.Element => {
  return (
    <Box
      display="flex"
      alignItems="center"
      gap={1}
      sx={{ margin: "1em 0", ...boxStyle }}
    >
      <CircleIcon
        htmlColor={color}
        sx={{
          fontSize: "14px",
        }}
      />

      <Typography variant="subtitle1" component="h2">
        {title}
        {count && count > 0 ? " (" + count + ")" : ""}
      </Typography>
    </Box>
  );
};

export default CircleWithText;

import { MathUtils, Vector3 } from "three";

export const constrainAngle = angle => {
  if (angle > 180 || angle < -180) {
    return angle - 360 * Math.floor((angle + 180) / 360);
  }

  return angle;
};

export const metersToFeet = value => {
  return +(value / 0.3048).toFixed(4);
};

export const feetToMeters = value => {
  return +(value * 0.3048).toFixed(4);
};

export const calculateVerticalFOV = (horizontalFOVInDegrees, aspectRatio) => {
  // Convert horizontal FOV from degrees to radians
  const horizontalFOVRadians = horizontalFOVInDegrees * (Math.PI / 180);

  // Calculate vertical FOV in radians
  const verticalFOVRadians =
    2 * Math.atan(Math.tan(horizontalFOVRadians / 2) / aspectRatio);

  // Convert vertical FOV back to degrees
  const verticalFOVInDegrees = verticalFOVRadians * (180 / Math.PI);

  return verticalFOVInDegrees;
};

export const calculateHorizontalFOV = (verticalFOVInDegrees, aspectRatio) => {
  // Convert vertical FOV from degrees to radians
  const verticalFOVRadians = verticalFOVInDegrees * (Math.PI / 180);

  // Calculate horizontal FOV in radians
  const horizontalFOVRadians =
    2 * Math.atan(Math.tan(verticalFOVRadians / 2) * aspectRatio);

  // Convert horizontal FOV back to degrees
  const horizontalFOVInDegrees = horizontalFOVRadians * (180 / Math.PI);

  return horizontalFOVInDegrees;
};

export const getHorizontalAngleForObjectInDeg = (fromVector, object) => {
  const objectWorldDirection = new Vector3();

  object.getWorldDirection(objectWorldDirection);

  const fromVectorHorizontal = fromVector.clone();

  fromVectorHorizontal.y = 0;

  fromVectorHorizontal.normalize();

  const objectWorldDirectionHorizontal = objectWorldDirection.clone();

  objectWorldDirectionHorizontal.y = 0;

  objectWorldDirectionHorizontal.normalize();

  const horizontalAngleInRad = Math.atan2(
    fromVectorHorizontal.z * objectWorldDirectionHorizontal.x -
      fromVectorHorizontal.x * objectWorldDirectionHorizontal.z,
    fromVectorHorizontal.x * objectWorldDirectionHorizontal.x +
      fromVectorHorizontal.z * objectWorldDirectionHorizontal.z
  );

  const horizontalAngleInDeg = MathUtils.radToDeg(horizontalAngleInRad) * -1;

  return horizontalAngleInDeg;
};

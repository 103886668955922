import { FC } from "react";

import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Control, Controller, FieldErrors } from "react-hook-form";

import { UnitsEnum } from "../../UploadVideoGroupPage";
import { ILeakFinderUserPreferencesForm } from "../LeakFinderUserPreferencesPage";

export interface ILeakFinderUserPreferencesFormProps {
  control: Control<ILeakFinderUserPreferencesForm>;
  errors: FieldErrors<ILeakFinderUserPreferencesForm>;
}

const LeakFinderUserPreferencesForm: FC<
  ILeakFinderUserPreferencesFormProps
> = ({ control, errors }) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          gap: "1em",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="body2Regular">Camera FOV</Typography>
        <Controller
          name="cameraFov"
          control={control}
          rules={{
            required: "This field is required",
          }}
          render={({ field }) => (
            <TextField
              {...field}
              sx={{
                width: "100px",
              }}
              size="small"
              id="cameraFov"
              error={!!errors.cameraFov}
              helperText={errors.cameraFov ? errors.cameraFov.message : ""}
              InputProps={{
                endAdornment: <InputAdornment position="end">°</InputAdornment>,
              }}
            />
          )}
        />
      </Box>
      <Divider sx={{ margin: "1em 0" }} />
      <Box
        sx={{
          display: "flex",
          gap: "1em",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="body2Regular">Windspeed Unit</Typography>
        <Controller
          name="windspeedUnit"
          control={control}
          rules={{
            required: "This field is required",
          }}
          render={({ field }) => (
            <Select
              {...field}
              id="windspeedUnit"
              size="small"
              variant="standard"
              disableUnderline
              value={field.value ?? ""}
              onChange={field.onChange}
              IconComponent={ExpandMoreRoundedIcon}
            >
              <MenuItem value={UnitsEnum.MPH}>{UnitsEnum.MPH}</MenuItem>
              <MenuItem value={UnitsEnum.KPH}>{UnitsEnum.KPH}</MenuItem>
            </Select>
          )}
        />
      </Box>
    </>
  );
};

export default LeakFinderUserPreferencesForm;

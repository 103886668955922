import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

const NoSearchResults = () => {
  return (
    <Paper
      elevation={0}
      sx={{
        padding: "1.25em 1.5em",
        borderRadius: "12px",
      }}
    >
      <Typography
        variant="body2Regular"
        component="div"
        color="otherTextTertiary.main"
      >
        No results are found
      </Typography>
    </Paper>
  );
};

export default NoSearchResults;

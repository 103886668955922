import * as THREE from "three";

export const REAL_EARTH_RADIUS_IN_KM = 6371 * 1000;

export const TERRAIN_MATERIAL_COLOR = new THREE.Color(0.588, 0.588, 0.588);

export const SHAPE_MATERIAL_COLOR = new THREE.Color(1, 1, 1);

export const OBJECT_HIGHLIGHT_COLOR = new THREE.Color("#0038FF");

export const OBJECT_DEFAULT_COLOR = new THREE.Color("#FFFFFF");

export const GROUND_RADIUS_IN_M = 1200;

export const ZONE_RADIUS_IN_M = 120;

export const DRACO_DECODER_PATH =
  "https://unpkg.com/three@0.153.0/examples/jsm/libs/draco/gltf/";

export const FACILITY_SKETCHPLAN_RENDER_ORDER = 1;

export const FACILITY_OBJECT_RENDER_ORDER = 100;

export const CAMERA_HELPERS_RENDER_ORDER = 200;

export const TRANSFORM_CONTROLS_RENDER_ORDER = 200;

export const CAMERA_ZONES_RENDER_ORDER = 300;

import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useConfirm } from "material-ui-confirm";
import { SubmitHandler, useForm } from "react-hook-form";

import FullPageLoader from "../../common/components/item/FullPageLoader";
import MainPaperWrapper from "../../common/components/item/MainPaperWrapper";
import BreadcrumbNavigation from "../../common/components/tabs/BreadcrumbNavigation";
import confirmDialogStyleOptions from "../../common/helpers/confirmDialogParams";
import { RouteEnum } from "../../common/models/enums";
import StyledLoadingButton from "../../common/providers/theme/design-tokens/LoadingButton/StyledLoadingButton";
import {
  errorNotification,
  successNotification,
} from "../../common/variables/notification";
import { useGetClient } from "../hooks/useGetClient";
import { useUpdateClient } from "../hooks/useUpdateClient";
import { IClientUploadsForm } from "../variables/clientUploadsData";
import EditClientForm from "./EditClientForm";

const EditClientPage = () => {
  const navigate = useNavigate();
  const confirm = useConfirm();

  const [searchParams] = useSearchParams();

  const clientId = searchParams.get("clientId") ?? "";

  const defaultValues = {
    clientName: "",
  };

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IClientUploadsForm>({ defaultValues });

  const { updateClient, loading: updateClientLoading } = useUpdateClient();

  const { data, loading: getClientLoading } = useGetClient(clientId);

  useEffect(() => {
    if (data) {
      const clientName = data.getClientById?.clientName ?? "";

      setValue("clientName", clientName);
    }
  }, [data?.getClientById]);

  const onSubmit: SubmitHandler<IClientUploadsForm> = formData => {
    if (!data) return;

    const client = data.getClientById;

    updateClient(formData.clientName, client?.id ?? "")
      .then((response): void => {
        if (!response.errors) {
          successNotification("Changes are saved");

          navigate(RouteEnum.LeakFinderClientUploads);
        }
      })
      .catch((error: Error): void => {
        errorNotification(error.message);

        console.error(error);
      });
  };

  const confirmCancel = (path = RouteEnum.LeakFinderClientUploads): void => {
    confirm({
      title: "Are you sure you want to cancel editing?",
      description: "If you leave, the new informations won’t be saved",
      confirmationText: "Leave",
      cancellationText: "Cancel",
      ...confirmDialogStyleOptions,
      confirmationButtonProps: {
        ...confirmDialogStyleOptions.confirmationButtonProps,
        color: "primary",
      },
    }).then((): void => {
      navigate(path);
    });
  };

  const handleCancel = (): void => {
    confirmCancel();
  };

  const loading = getClientLoading || updateClientLoading;

  if (loading) {
    return <FullPageLoader />;
  }

  const client = data?.getClientById;

  const breadcrumbItems = [
    {
      label: "Client Uploads",
      onClick: () => confirmCancel(RouteEnum.LeakFinderClientUploads),
    },
    {
      label: `Edit ${client?.clientName}`,
    },
  ];

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <BreadcrumbNavigation items={breadcrumbItems} />

      <Typography variant="h3" sx={{ padding: "1em 0" }}>
        {`Edit ${client?.clientName}`}
      </Typography>

      <MainPaperWrapper>
        <EditClientForm control={control} errors={errors} />
      </MainPaperWrapper>

      <Stack direction="row-reverse" spacing={2} sx={{ paddingTop: 3 }}>
        <StyledLoadingButton
          sx={{
            marginTop: "1.5em",
          }}
          type="submit"
          variant="contained"
          color="primary"
        >
          Save changes
        </StyledLoadingButton>
        <StyledLoadingButton
          sx={{
            marginTop: "1.5em",
          }}
          variant="outlined"
          color="inherit"
          onClick={handleCancel}
        >
          Back
        </StyledLoadingButton>
      </Stack>
    </form>
  );
};

export default EditClientPage;

import { useContext, useEffect } from "react";
import { Box, styled, useTheme } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

import ConfiguratorContainer from "../../../common/components/configurator/ConfiguratorContainer";
import ProjectEditorPageHeader from "./ProjectEditorHeader/ProjectEditorPageHeader";
import ShareProjectModal from "./ShareProjectModal";
import ImportDTObjectForm from "./ImportDTObjectForm";
import Loader from "../../../common/components/loader/Loader";

import { useCustomerIdGuard } from "../../../../common/hooks/useCustomerIdGuard";
import { useGetDTProject } from "../../../common/hooks/useGetDTProject";
import { useReadDataFromS3 } from "../../../common/hooks/useReadDataFromS3";
import { useSetObject3dModelAssets } from "../../../common/hooks/useSetObject3dModelAssets";
import { useUpdateDTProject } from "../hooks/useUpdateDTProject";
import { useUploadDataToS3 } from "../../../common/hooks/useUploadDataToS3";
import { useAmplifyUser } from "../../../../common/hooks/useAmplifyUser";

import {
  getDate,
  getUniqueValuesFromArray,
  getUserNameFromEmailAddress,
} from "../../../common/utils";

import {
  ConfirmationModalContext,
  NotificationContext,
  ProjectContext,
  ProjectObjects3dContext,
} from "../../../common/context";

import { DTObject, DTProject, UpdateDTProjectInput } from "../../../../API";
import { ObjectDataInterface } from "../../../common/context/ProjectContext/ProjectEntitesTypes";
import { MeasurementSystemMode } from "../../../common/enums";
import { WarningIcon } from "../../../common/assets/icons/svgAssets";

const ProjectEditor = (): JSX.Element => {
  const theme = useTheme();
  const navigate = useNavigate();

  const selectedCustomerId = useCustomerIdGuard();
  const { projectId } = useParams();

  const { setConfirmationModalData } = useContext(ConfirmationModalContext);
  const { openNotification } = useContext(NotificationContext);

  const { projectData, setProjectData, clearProjectState } =
    useContext(ProjectContext);

  const { object3dData, loading: object3dLoading } = useContext(
    ProjectObjects3dContext
  );

  const { user } = useAmplifyUser();

  const {
    fetchProject,
    data,
    loading,
    error,
    refetch: refetchProject,
  } = useGetDTProject();
  const { getDataFromS3, loading: getDataFromS3Loading } = useReadDataFromS3();

  const { updateDTProject, loading: updateDTProjectLoading } =
    useUpdateDTProject();
  const { uploadDataToS3, loading: uploadDataToS3Loading } =
    useUploadDataToS3();

  const {
    getObject3dWithUpdatedModelAssets,
    loading: updateModelAssetsLoading,
  } = useSetObject3dModelAssets();

  const handleOpenProjectError = () => {
    navigate("/webgl-dashboard");
  };

  const resaveProject = async (configData: any, settings: DTProject) => {
    const usedObjects = getUniqueValuesFromArray(
      configData.obj.map(
        (item: ObjectDataInterface) => item.modelAssets.assetId
      )
    );

    const input = {
      projectId: settings.projectId,
      customerId: settings.customerId,
      displayName: settings.displayName,
      lastModifierUserName:
        user.username && getUserNameFromEmailAddress(user.username),
      lastModificationDate: getDate(),
      withKMZLayer: settings.withKMZLayer,
      measurementSystem: settings.measurementSystem,
      usedObjects: usedObjects,
    };

    const response = await updateDTProject(input as UpdateDTProjectInput);

    if (response.data) {
      await uploadDataToS3(
        response.data.updateDTProject.configURL,
        JSON.stringify(configData),
        "json"
      );
    }
  };

  const handleSetConfirmationResaveProjectData = (
    configData: any,
    settings: DTProject
  ) => {
    const action = async () => {
      await resaveProject(configData, settings);

      openNotification({
        title: "Saved",
        message: "Your project was successfully saved",
        severity: "success",
      });

      refetchProject();
    };

    const actionOnClose = () => {
      handleOpenProjectError();
    };

    setConfirmationModalData({
      icon: <WarningIcon fillColor={theme.palette.red.lighter} />,
      title: "Resave the project",
      message: `
        Another user deleted the 3D assets used in the "${data.getDTProject.displayName}" project. Please, re-save your project to see the latest updates.
        \nThe "Resave" button will remove the deleted 3D assets from the scene.
      `,
      actionButton: {
        color: "blue",
        title: "Resave",
        action: action,
      },
      actionOnClose: actionOnClose,
    });
  };

  const handleSetProjectData = async (
    data: { getDTProject: DTProject },
    allDTObjects: DTObject[]
  ) => {
    try {
      const projectConfigResponse = await getDataFromS3(
        data.getDTProject.configURL
      );

      if (projectConfigResponse?.data) {
        const objectsWithUpdatedAssets =
          await getObject3dWithUpdatedModelAssets(
            projectConfigResponse.data.obj,
            allDTObjects
          );

        const isHasBrokenAssets = objectsWithUpdatedAssets.some(
          (item: ObjectDataInterface) => !item.modelAssets
        );

        projectConfigResponse.data.obj = objectsWithUpdatedAssets.filter(
          (item: ObjectDataInterface) => item.modelAssets
        );

        if (isHasBrokenAssets) {
          handleSetConfirmationResaveProjectData(
            projectConfigResponse.data,
            data.getDTProject
          );
        } else {
          setProjectData({
            configData: { ...projectConfigResponse.data },
            settings: {
              ...data.getDTProject,
              measurementSystem:
                data.getDTProject.measurementSystem ||
                MeasurementSystemMode.Imperial,
            },
          });
        }
      } else {
        handleOpenProjectError();
      }
    } catch (error) {
      handleOpenProjectError();
    }
  };

  useEffect(() => {
    if (projectId && selectedCustomerId) {
      fetchProject(selectedCustomerId, projectId.replace("_", "#"));
    } else {
      handleOpenProjectError();
    }

    return () => {
      clearProjectState();
    };
  }, [projectId]);

  useEffect(() => {
    if (data && object3dData && !projectData) {
      handleSetProjectData(data, object3dData);
    }
  }, [data, object3dData]);

  useEffect(() => {
    if (error) {
      handleOpenProjectError();
    }
  }, [error]);

  const projectLoaded =
    !loading &&
    !getDataFromS3Loading &&
    !updateModelAssetsLoading &&
    !object3dLoading &&
    data &&
    !updateDTProjectLoading &&
    !uploadDataToS3Loading &&
    projectData;

  return (
    <Wrapper>
      {projectLoaded ? (
        <>
          <ProjectEditorPageHeader dtProject={projectData.settings} />
          <ConfiguratorContainer />
          <ShareProjectModal />
          <ImportDTObjectForm />
        </>
      ) : (
        <Loader />
      )}
    </Wrapper>
  );
};

export default ProjectEditor;

const Wrapper = styled(Box)(() => ({
  position: "relative",
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  gap: "10px",
}));

import { Dispatch, Reducer, useReducer } from "react";

import { LeakFinderTableRow } from "../hooks/useGetVideosTableRows";

export interface VideoTableRow {
  clientId: string;
  clientName: string;
  groupId: string;
  groupName: string;
  videos: LeakFinderTableRow[];
}

export interface VideosTableSelectionState {
  selectedRows: VideoTableRow[];
}

export type VideosTableSelectionDispatch = Dispatch<VideosTableSelectionAction>;

export enum VideosTableSelectionActions {
  SET_VIDEOS = "SET_VIDEOS",
  REMOVE_GROUP = "REMOVE_GROUP",
  RESET_STATE = "RESET_STATE",
}

export type VideosTableSelectionAction = {
  type: string;
  payload: any;
};

export const setVideosAction = (payload: {
  clientId: string;
  clientName: string;
  groupId: string;
  groupName: string;
  videos: LeakFinderTableRow[];
}): VideosTableSelectionAction => ({
  type: VideosTableSelectionActions.SET_VIDEOS,
  payload,
});

export const removeGroupAction = (payload: {
  clientId: string;
  groupId: string;
}): VideosTableSelectionAction => ({
  type: VideosTableSelectionActions.REMOVE_GROUP,
  payload,
});

export const resetVideosTableSelectionStateAction =
  (): VideosTableSelectionAction => ({
    type: VideosTableSelectionActions.RESET_STATE,
    payload: null,
  });

const videosTableSelectionReducer = (
  state: VideosTableSelectionState,
  action: VideosTableSelectionAction
): VideosTableSelectionState => {
  const { type, payload } = action;

  switch (type) {
    case VideosTableSelectionActions.SET_VIDEOS: {
      const { clientId, clientName, groupId, groupName, videos } = payload as {
        clientId: string;
        clientName: string;
        groupId: string;
        groupName: string;
        videos: LeakFinderTableRow[];
      };

      const selectedGroupIndex = state.selectedRows.findIndex(
        row => row.clientId === clientId && row.groupId === groupId
      );

      if (selectedGroupIndex === -1) {
        return {
          ...state,
          selectedRows: [
            ...state.selectedRows,
            {
              clientId,
              clientName,
              groupId,
              groupName,
              videos,
            },
          ],
        };
      }

      const selectedGroup = state.selectedRows[selectedGroupIndex];

      return {
        ...state,
        selectedRows: [
          ...state.selectedRows.slice(0, selectedGroupIndex),
          {
            ...selectedGroup,
            videos,
          },
          ...state.selectedRows.slice(selectedGroupIndex + 1),
        ],
      };
    }

    case VideosTableSelectionActions.REMOVE_GROUP: {
      const { clientId, groupId } = payload as {
        clientId: string;
        groupId: string;
      };

      return {
        ...state,
        selectedRows: state.selectedRows.filter(
          row => row.clientId !== clientId && row.groupId !== groupId
        ),
      };
    }

    case VideosTableSelectionActions.RESET_STATE: {
      return createInitialClientCheckboxReducerState();
    }

    default:
      return state;
  }
};

function createInitialClientCheckboxReducerState(): VideosTableSelectionState {
  return {
    selectedRows: [],
  };
}

interface IVideosTableSelectionReducer {
  state: VideosTableSelectionState;
  dispatch: VideosTableSelectionDispatch;
}

export function useVideosTableSelectionReducer(): IVideosTableSelectionReducer {
  const [state, dispatch] = useReducer<
    Reducer<VideosTableSelectionState, VideosTableSelectionAction>
  >(videosTableSelectionReducer, createInitialClientCheckboxReducerState());

  return { state, dispatch };
}

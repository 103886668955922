import { useContext, useEffect } from "react";
import { Box, Typography, styled } from "@mui/material";
import ViewInArIcon from "@mui/icons-material/ViewInAr";

import Loader from "../../loader/Loader";

import { ProjectContext } from "../../../context";

import { useGetDTFile } from "../../../hooks/useGetDTFile";
import { useGetDTFiles } from "../../../hooks/useGetDTFiles";

import {
  getModel3dFileData,
  getTexturesFileData,
  getObject3dAssetsKeys,
  getObjectPreviewKey,
} from "../../../utils/dtProjectUtils/dtProjectUtils";

import { DTObject } from "../../../../../API";
import {
  ModeTypeEnum,
  ObjectDataInterface,
} from "../../../context/ProjectContext/ProjectEntitesTypes";
import ObjectCardContextMenu from "../objectContextMenu/ObjectCardContextMenu";

interface ObjectCardInterface {
  objectData: DTObject;
}

const ObjectCard = ({ objectData }: ObjectCardInterface) => {
  const { sceneStarted, isLoading, mode, objects, addObject } =
    useContext(ProjectContext);

  const { fetchDTFile: fetchPreviewFile, data: previewPathData } =
    useGetDTFile();
  const { fetchDTFiles, loading: fetchDTFilesLoading } = useGetDTFiles();

  const previewKey = getObjectPreviewKey(objectData.filesInfo);

  const getObjectAssetsData = async () => {
    const alreadyLoadedModel =
      objects &&
      objects.find(
        (obj: ObjectDataInterface) =>
          obj?.modelAssets.assetId === objectData.objectId
      );

    if (alreadyLoadedModel) {
      return {
        name: objectData.objectName,
        modelAssets: alreadyLoadedModel.modelAssets,
      };
    }

    const assets3dFilesKeys = getObject3dAssetsKeys(objectData.filesInfo);
    const assets3dFiles = await fetchDTFiles(assets3dFilesKeys);

    const textures = getTexturesFileData(assets3dFiles);
    const model3d = getModel3dFileData(assets3dFiles);

    return {
      name: objectData.objectName,
      modelAssets: {
        assetId: objectData.objectId,
        model3d,
        textures,
      },
    };
  };

  const onAddObjectButton = async (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    if (
      sceneStarted &&
      !isLoading &&
      !fetchDTFilesLoading &&
      mode === ModeTypeEnum.standard
    ) {
      const model3dData = await getObjectAssetsData();

      addObject(model3dData);
    }
  };

  useEffect(() => {
    previewKey && fetchPreviewFile(previewKey);
  }, [objectData]);

  const disabled =
    !sceneStarted ||
    isLoading ||
    fetchDTFilesLoading ||
    mode !== ModeTypeEnum.standard;

  return (
    <Wrapper disabled={disabled ? 1 : 0} onClick={onAddObjectButton}>
      {fetchDTFilesLoading && (
        <LoaderContainer>
          <Loader background="transparent" />
        </LoaderContainer>
      )}

      {objectData.isCustomerObject && (
        <ObjectCardContextMenu
          disabledButton={disabled}
          objectData={objectData}
        />
      )}

      <ImageContainer>
        {previewKey && previewPathData?.getDTFile?.url ? (
          <img src={previewPathData.getDTFile.url} />
        ) : (
          <ViewInArIcon />
        )}
      </ImageContainer>

      <TitleContainer>
        <Title variant="body10Medium">{objectData.objectName}</Title>
      </TitleContainer>
    </Wrapper>
  );
};

export default ObjectCard;

const Wrapper = styled(Box)<{ disabled: number }>(({ theme, disabled }) => ({
  position: "relative",
  width: "100px",
  display: "flex",
  flexDirection: "column",
  border: `1px solid ${theme.palette.divider}`,
  overflow: "hidden",
  borderRadius: "4px",
  padding: 0,
  gap: 0,

  "&:hover": {
    borderColor: !disabled ? theme.palette.blue.light : theme.palette.divider,
  },
}));

const LoaderContainer = styled(Box)(() => ({
  width: "100%",
  height: "100%",
  position: "absolute",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  "& svg path": {
    fill: "#1FC559 !important",
  },
}));

const ImageContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "60px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: theme.palette.blue.lighter,
  borderBottom: `1px solid ${theme.palette.divider}`,

  "& img": {
    width: "100%",
    height: "100%",
  },

  "& svg path": {
    fill: `${theme.palette["primary-text"]} !important`,
  },
}));

const TitleContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "43px",
  display: "flex",
  background: theme.palette["base-background-lighter"],
  color: theme.palette["primary-text"],
  padding: "1px 4px",
}));

const Title = styled(Typography)(() => ({
  textAlign: "left",
  textOverflow: "ellipsis",
  overflow: "hidden",
}));

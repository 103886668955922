import type { ComponentsOverrides, Theme } from "@mui/material";

export const MuiAutocompleteDarkStyleOverrides: ComponentsOverrides<Theme>["MuiAutocomplete"] =
  {
    root: ({ theme }) => ({
      "& .MuiInputLabel-root": {
        "&.Mui-disabled": {
          color: theme.palette.otherTextTertiary.main,
        },
      },
      "& .MuiFormHelperText-root": {
        "&.Mui-disabled": {
          color: theme.palette.otherTextTertiary.main,
        },
      },
    }),
    inputRoot: ({ theme }) => ({
      "&.Mui-disabled .MuiInputBase-input": {
        opacity: 1,
        color: theme.palette.text.primary,
        WebkitTextFillColor: theme.palette.text.primary,
      },
      "&.MuiInputBase-root": {
        "&.Mui-disabled": {
          backgroundColor: theme.palette.otherDisabledBackground.main,
          borderRadius: "8px",

          "&:hover": {
            backgroundColor: "none",
          },
        },
      },
    }),
    option: ({ theme }) => ({
      color: theme.palette.text.primary,
      fontSize: "0.875rem",
      fontWeight: 400,
      lineHeight: "135%",

      "&:hover, &.Mui-focused": {
        paddingLeft: "14px",
        backgroundColor: `${theme.palette.primary.shades?.["8p"]} !important`,
        borderLeft: `2px solid ${theme.palette.primary.main} !important`,
      },
      "&[aria-selected ='true']": {
        paddingLeft: "14px",
        backgroundColor: `${theme.palette.primary.shades?.["8p"]} !important`,
        borderLeft: `2px solid ${theme.palette.primary.main}`,
      },
    }),
  };

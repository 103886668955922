import { FC } from "react";

import FullPageLoader from "../../common/components/item/FullPageLoader";
import ClientCards from "./ClientCards";
import NoClientsAdded from "./NoClientsAdded";
import { PaginatedClients, PaginatedGroups } from "../../API";

interface IClientUploadsTabsProps {
  clients: PaginatedClients | null;
  groups: PaginatedGroups[];
  deleteGroup: (clientId: string, groupId: string) => void;
  pageLoading: boolean;
}

const ClientsUploadTabPanel: FC<IClientUploadsTabsProps> = ({
  clients,
  groups,
  deleteGroup,
  pageLoading,
}) => {
  const items = clients?.items ?? [];

  return (
    <>
      {pageLoading && <FullPageLoader />}
      {!pageLoading && items.length === 0 && <NoClientsAdded />}
      {!pageLoading && items.length > 0 && (
        <ClientCards
          deleteGroup={deleteGroup}
          clients={items}
          groups={groups}
        />
      )}
    </>
  );
};

export default ClientsUploadTabPanel;

import { useContext, useEffect } from "react";
import { Button } from "@mui/material";

import { TopDownViewIcon } from "../../assets/icons/svgAssets";

import { ProjectContext } from "../../context";
import { CameraTypeDataInterface, CamerasType } from "../../data/CamerasData";
import { ModeTypeEnum } from "../../context/ProjectContext/ProjectEntitesTypes";

const TopDownViewButton = () => {
  const {
    threeScene,
    sceneStarted,
    isLoading,
    handleSetActiveObjectId,
    secondaryCameraId,
    cameras,
    zoneList,
    mode,
    setMode,
  } = useContext(ProjectContext);

  const disabled = !!secondaryCameraId || !sceneStarted || isLoading;

  const handleOnClick = () => {
    handleSetActiveObjectId(null);

    setMode(
      mode === ModeTypeEnum.zoneView
        ? ModeTypeEnum.standard
        : ModeTypeEnum.zoneView
    );
  };

  const showZones = () => {
    if (threeScene && threeScene.sceneControls.orbitControl) {
      const zones360 = cameras.filter(
        (el: CameraTypeDataInterface) =>
          el.cameraType.includes(CamerasType.MinervaCamera) ||
          el.cameraType.includes(CamerasType.MinervaGasCamera)
      );

      threeScene.switchMainCamera(true);

      threeScene.sceneControls.orbitControl.reset();

      threeScene.sceneControls.orbitControl.setCameraPosition(500, 0, 0);

      threeScene.sceneControls.orbitControl.setMinMaxPolarAngle(0, 0);

      threeScene.sceneControls.orbitControl.update();

      threeScene.sceneFacility3dObjects.camerasZones.add(
        zones360,
        zoneList,
        cameras
      );
    }
  };

  const hideZones = () => {
    if (threeScene) {
      threeScene.switchMainCamera(false);

      threeScene.sceneFacility3dObjects.camerasZones.remove();

      threeScene.sceneControls.orbitControl.setMinMaxPolarAngle(0, 75);

      threeScene.sceneControls.orbitControl.reset();

      threeScene.sceneControls.orbitControl.update();
    }
  };

  useEffect(() => {
    mode === ModeTypeEnum.zoneView ? showZones() : hideZones();
  }, [mode]);

  return (
    <Button
      variant={mode === ModeTypeEnum.zoneView ? "primary" : "outlined"}
      color="blue"
      disabled={disabled}
      onClick={handleOnClick}
      startIcon={<TopDownViewIcon />}
    >
      Top-down view
    </Button>
  );
};

export default TopDownViewButton;

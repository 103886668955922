import { Vector3 } from "three";

import TowerBuilder from "../SceneObjectBuilder/TowerBuilder";
import { EntityTypeEnum } from "../../context/ProjectContext/ProjectEntitesTypes";

class FacilityTowers {
  constructor(scene, objectsGroup, sceneOrbitCenter) {
    this.scene = scene;

    this.objectsGroup = objectsGroup;

    this.sceneOrbitCenter = sceneOrbitCenter;

    this.towerBuilder = new TowerBuilder();
  }

  getAllTowers() {
    return this.objectsGroup.getObjectsByProperty(
      "EntityType",
      EntityTypeEnum.Tower
    );
  }

  getTowerById(id) {
    return this.getAllTowers().find(obj => obj.ID === id);
  }

  async addTower(towerData) {
    const listOfObjectToClone = await this.towerBuilder.getObjectInstance(
      towerData
    );

    const object = this.towerBuilder.createObjectInstance(
      listOfObjectToClone,
      towerData,
      this.sceneOrbitCenter.clone()
    );

    this.objectsGroup.add(object);

    return object;
  }

  deleteTower(tower) {
    tower.removeFromParent();
  }
}

export default FacilityTowers;

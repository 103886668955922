import { useTheme } from "@mui/material/styles";

const VideoCameraIcon = (): JSX.Element => {
  const theme = useTheme();

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 11.5C2 8.21252 2 6.56878 2.90796 5.46243C3.07418 5.25989 3.25989 5.07418 3.46243 4.90796C4.56878 4 6.21252 4 9.5 4C12.7875 4 14.4312 4 15.5376 4.90796C15.7401 5.07418 15.9258 5.25989 16.092 5.46243C17 6.56878 17 8.21252 17 11.5V12.5C17 15.7875 17 17.4312 16.092 18.5376C15.9258 18.7401 15.7401 18.9258 15.5376 19.092C14.4312 20 12.7875 20 9.5 20C6.21252 20 4.56878 20 3.46243 19.092C3.25989 18.9258 3.07418 18.7401 2.90796 18.5376C2 17.4312 2 15.7875 2 12.5V11.5Z"
        stroke={theme.palette.warning.main}
        strokeWidth="1.5"
      />
      <path
        d="M17 9.50001L17.6584 9.17083C19.6042 8.19789 20.5772 7.71141 21.2886 8.15108C22 8.59076 22 9.67854 22 11.8541V12.1459C22 14.3215 22 15.4093 21.2886 15.8489C20.5772 16.2886 19.6042 15.8021 17.6584 14.8292L17 14.5V9.50001Z"
        stroke={theme.palette.warning.main}
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default VideoCameraIcon; // theme.palette.warning.main

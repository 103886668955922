import { useLazyQuery } from "@apollo/client";

import { GET_DT_PROJECT } from "../../../common/operations/queries";

export const useGetDTProject = () => {
  const [getProject, { data, loading, error, refetch }] = useLazyQuery(
    GET_DT_PROJECT,
    {
      fetchPolicy: "no-cache",
    }
  );

  const fetchProject = async (customerId, projectId) => {
    getProject({
      variables: {
        customerId,
        projectId,
      },
    })
      .then(async response => {
        if (response.error) {
          console.error(response.error);
        }
      })
      .catch(async error => {
        console.error(error);
      });
  };

  return { fetchProject, data, loading, error, refetch };
};

import { makeVar, useReactiveVar } from "@apollo/client";

import { RoleEnum } from "../../common/models/enums";

export enum ClientUploadsTabsKeysEnum {
  ALL_VIDEOS = "ALL_VIDEOS",
  DRONE_VIDEOS = "DRONE_VIDEOS",
  HANDHELD_VIDEOS = "HANDHELD_VIDEOS",
}

interface IClientUploadsTabsState {
  selectedTab: string;
}

export const AdminRoles = [RoleEnum.ROOT, RoleEnum.LEAKFINDER_DIRECTOR];

export const ClientUploadsTabsAllowedRoles = [
  RoleEnum.ROOT,
  RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
  RoleEnum.LEAKFINDER_DIRECTOR,
  RoleEnum.CC_SALES,
];

export const ClientUploadsTabsList = [
  {
    id: ClientUploadsTabsKeysEnum.ALL_VIDEOS,
    label: "All videos",
    allowedRoles: ClientUploadsTabsAllowedRoles,
  },
  {
    id: ClientUploadsTabsKeysEnum.DRONE_VIDEOS,
    label: "Drone videos",
    allowedRoles: ClientUploadsTabsAllowedRoles,
  },
  {
    id: ClientUploadsTabsKeysEnum.HANDHELD_VIDEOS,
    label: "Handheld videos",
    allowedRoles: ClientUploadsTabsAllowedRoles,
  },
];

export const defaultClientUploadsTabsState = {
  selectedTab: ClientUploadsTabsKeysEnum.ALL_VIDEOS,
};

export const clientUploadsTabsVariable = makeVar<IClientUploadsTabsState>(
  defaultClientUploadsTabsState
);

export const useClientUploadsTabsVariable = () =>
  useReactiveVar(clientUploadsTabsVariable);

export const clientUploadsSearchVariable = makeVar<string>("");

export const useClientUploadsSearchVariable = () =>
  useReactiveVar(clientUploadsSearchVariable);

import { useContext } from "react";
import { useTheme } from "@mui/material";

import Loader from "../loader/Loader";

import { ProjectContext } from "../../context";

const ConfiguratorLoader = (): JSX.Element => {
  const theme = useTheme();

  const { isLoading, sceneStarted } = useContext(ProjectContext);

  const loaderBackgroundColor = sceneStarted
    ? "transparent"
    : theme.palette.base.white;

  return <>{isLoading && <Loader background={loaderBackgroundColor} />}</>;
};

export default ConfiguratorLoader;

import { MathUtils, PerspectiveCamera } from "three";

import SceneObjectBuilder from "./SceneObjectBuilder";

import { setMaterial } from "../SceneUtils/MaterilsUtils";
import { createGroup } from "../SceneUtils/CreateMesh";
import {
  calculateVerticalFOV,
  constrainAngle,
  getHorizontalAngleForObjectInDeg,
} from "../SceneUtils/MathUtils";

import { CamerasType } from "../../data/CamerasData";

import { ZONE_RADIUS_IN_M } from "../Constants";

class CameraBuilder extends SceneObjectBuilder {
  createCameraInstance(
    objectClone,
    materialClone,
    cameraData,
    defaultCameraHeight,
    viewportDimensions
  ) {
    const object = createGroup(cameraData.name);

    object.ID = cameraData.id;

    object.EntityType = cameraData.EntityType;

    object.CameraType = cameraData.cameraType;

    object.name = cameraData.name;

    this.addModelPart(object, objectClone, materialClone);

    if (
      object.CameraType === CamerasType.RGBCamera ||
      object.CameraType === CamerasType.MinervaGasCamera
    ) {
      this.buildSingleLensCamera(object, cameraData, viewportDimensions);
    }

    if (
      object.CameraType === CamerasType.MinervaCamera ||
      object.CameraType === CamerasType.MinervaGasCamera
    ) {
      this.build360LensCamera(object, viewportDimensions);
    }

    this.setCameraHeight(object, cameraData.height, defaultCameraHeight);

    this.setObjectRenderOrder(object);

    return object;
  }

  addModelPart(object, objectClone, materialClone) {
    const modelWrapper = createGroup(
      `${object.EntityType}-ModelWrapper`,
      object
    );

    const model = objectClone.clone(true);

    setMaterial(model, materialClone.clone());

    modelWrapper.add(model);
  }

  buildSingleLensCamera(object, cameraData, viewportDimensions) {
    const lensParent = object.getObjectByName("SM_CameraLens");
    const cameraName = "SingleLensCamera";

    this.createCamera(
      lensParent,
      viewportDimensions,
      cameraData.fov,
      cameraData.zoom,
      cameraName
    );
  }

  build360LensCamera(object, viewportDimensions) {
    const minervaLensParent = object.getObjectByName("SM_Minerva");
    const cameraName = "360LensCamera";

    minervaLensParent.children.forEach(lensParent => {
      this.createCamera(lensParent, viewportDimensions, 90, 1, cameraName);
    });
  }

  createCamera(object, viewportDimensions, fov, zoom, name) {
    const { width, height } = viewportDimensions;

    const aspectRatio = width / height;

    const fieldOfView = calculateVerticalFOV(fov, aspectRatio);

    const nearClip = 0.1;
    const farClip = 10000;

    const camera = new PerspectiveCamera(
      fieldOfView,
      aspectRatio,
      nearClip,
      farClip
    );

    camera.zoom = zoom || 1;

    camera.name = name;

    object.add(camera);

    return camera;
  }

  setPerspectiveCameraRotation(
    object,
    horizontalAngle,
    verticalAngle,
    northVector
  ) {
    const objectHorInDeg = getHorizontalAngleForObjectInDeg(
      northVector,
      object
    );

    const horizontalAngleBaseOnParent = horizontalAngle - objectHorInDeg;

    const cameraHorRotation = object.getObjectByName("SM_Camera_Hor");

    cameraHorRotation.rotation.z =
      MathUtils.degToRad(constrainAngle(horizontalAngleBaseOnParent)) * -1;

    const cameraVertRotation = object.getObjectByName("SM_Camera_Vert");

    cameraVertRotation.rotation.x = MathUtils.degToRad(verticalAngle) * -1;
  }

  setCameraHeight(object, height, defaultCameraHeight) {
    object.position.y = height === undefined ? defaultCameraHeight : height;
  }
}

export default CameraBuilder;

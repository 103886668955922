import { ApolloError, useMutation } from "@apollo/client";

import { DELETE_CUSTOMER_DT_OBJECT } from "../../../../common/operations/mutations";
import { DTObject, DeleteDTObjectInput } from "../../../../API";

export interface UseDeleteCustomerDTObjectDataInterface {
  deleteCustomerDTObject: DTObject | null;
}

export interface UseDeleteCustomerDTObjectInterface {
  deleteCustomerDTObject: (input: DeleteDTObjectInput) => Promise<any>;
  data: UseDeleteCustomerDTObjectDataInterface;
  loading: boolean;
  error: ApolloError | undefined;
}

export const useDeleteCustomerDTObject =
  (): UseDeleteCustomerDTObjectInterface => {
    const [deleteCustomerDTObjectMutation, { data, loading, error }] =
      useMutation(DELETE_CUSTOMER_DT_OBJECT);

    const deleteCustomerDTObject = async (input: DeleteDTObjectInput) => {
      return await deleteCustomerDTObjectMutation({
        variables: {
          input,
        },
        update(cache) {
          const normalizedId = cache.identify({
            id: `DT_Object#${input.objectId}`,
            __typename: "DTObject",
          });

          cache.evict({ id: normalizedId });

          cache.gc();
        },
      });
    };

    return { deleteCustomerDTObject, data, error, loading };
  };

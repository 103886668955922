import CloseRounded from "@mui/icons-material/CloseRounded";
import IconButton from "@mui/material/IconButton";
import { Theme } from "@mui/material/styles";

interface ICloseDialogIconProps {
  onClose: () => void;
}

const CloseDialogIcon = ({ onClose }: ICloseDialogIconProps): JSX.Element => {
  return (
    <IconButton
      disableRipple
      aria-label="close"
      onClick={onClose}
      sx={{
        position: "absolute",
        right: 8,
        top: 8,
        color: (theme: Theme): string => theme.palette.text.primary,
      }}
    >
      <CloseRounded />
    </IconButton>
  );
};

export default CloseDialogIcon;

import { Box, styled } from "@mui/material";

const View360GridDivider = (): JSX.Element => {
  return (
    <Wrapper>
      <Grid360Item></Grid360Item>
      <Grid360Item></Grid360Item>
      <Grid360Item></Grid360Item>
      <Grid360Item></Grid360Item>
    </Wrapper>
  );
};

export default View360GridDivider;

const Wrapper = styled(Box)(() => ({
  position: "absolute",
  top: 0,
  display: "flex",
  width: "100%",
  height: "100%",
}));

const Grid360Item = styled(Box)(({ theme }) => ({
  width: "25%",
  height: "100%",
  border: `2px solid ${theme.palette["navy-blue"]}`,
}));

import { List, styled } from "@mui/material";

import TowerCameraListItem from "./TowerCameraListItem";

import { CameraDataInterface } from "../../../context/ProjectContext/ProjectEntitesTypes";

interface TowerCameraListInterface {
  camerasData: CameraDataInterface[];
}

const TowerCameraList = ({
  camerasData,
}: TowerCameraListInterface): JSX.Element => {
  return (
    <StyledList>
      {camerasData.map(data => (
        <TowerCameraListItem key={data.id} data={data} />
      ))}
    </StyledList>
  );
};

export default TowerCameraList;

const StyledList = styled(List)(() => ({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  padding: "0px",
  gap: "15px",
}));

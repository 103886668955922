import { useContext } from "react";
import { Button, styled } from "@mui/material";

import { ProjectContext } from "../../../context";

const SaveZoneButton = () => {
  const { activeZoneId, setZoneListSaveState } = useContext(ProjectContext);

  const handleOnClick = () => {
    setZoneListSaveState({ zoneId: activeZoneId, isSaved: true });
  };

  return (
    <StyledButton variant="primary" color="blue" onClick={handleOnClick}>
      Apply changes
    </StyledButton>
  );
};

export default SaveZoneButton;

const StyledButton = styled(Button)(() => ({
  flex: 1,
}));

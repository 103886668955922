import { Box, Modal, IconButton, styled } from "@mui/material";

import { CloseIcon } from "../assets/icons/svgAssets";

interface ModalWindowInterface {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  modalWidth?: string;
}

interface ChildrenWrapperProps {
  width?: string;
}

const ModalWindow = ({
  isOpen,
  onClose,
  children,
  modalWidth,
}: ModalWindowInterface): JSX.Element => {
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ChildrenWrapper width={modalWidth}>
        {children}

        <CloseButton onClick={onClose}>
          <CloseIcon />
        </CloseButton>
      </ChildrenWrapper>
    </Modal>
  );
};

export default ModalWindow;

const ChildrenWrapper = styled(Box)<ChildrenWrapperProps>(
  ({ theme, width }) => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.base.white,
    boxShadow: `0px 6px 12px ${theme.palette["box-shadow"]}`,
    border: "none",
    borderRadius: "4px",
    width: width || "472px",
    padding: "20px 24px 24px 24px",
    outline: "none",
  })
);

const CloseButton = styled(IconButton)(() => ({
  position: "absolute",
  top: "16px",
  right: "16px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "& svg": {
    width: "24px",
    height: "24px",
  },
}));

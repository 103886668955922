import type { ThemeOptions } from "@mui/material/styles";
import { MuiAppBarLightStyleOverrides } from "./mui/AppBar/light";
import { MuiAutocompleteLightStyleOverrides } from "./mui/Autocomplete/light";
import { MuiButtonLightStyleOverrides } from "./mui/Button/light";
import { MuiDividerLightStyleOverrides } from "./mui/Divider/light";
import { MuiInputLabelLightStyleOverrides } from "./mui/InputLabel/light";
import { MuiTextFieldLightStyleOverrides } from "./mui/TextField/light";
import { MuiToolBarLightStyleOverrides } from "./mui/Toolbar/light";
import { MuiFormHelperTextLightStyleOverrides } from "./mui/FormHelperText/light";
import { MuiSelectLightStyleOverrides } from "./mui/Select/light";

/**
 * NOTE: the list of links which can help with customization:
 * 1. https://mui.com/material-ui/customization/how-to-customize/
 * 2. https://mui.com/material-ui/customization/theme-components/
 */
export const lightThemeToken: ThemeOptions = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      xxl: 1980,
    },
  },
  typography: {
    fontFamily: "Inter, sans-serif",
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontWeight: 700,
      fontSize: "2.875rem",
      lineHeight: "135%",
    },
    h2: {
      fontWeight: 700,
      fontSize: "2.25rem",
      lineHeight: "135%",
    },
    h3: {
      fontWeight: 700,
      fontSize: "1.875rem",
      lineHeight: "135%",
    },
    h4: {
      fontWeight: 700,
      fontSize: "1.625rem",
      lineHeight: "135%",
    },
    h5: {
      fontWeight: 700,
      fontSize: "1.25rem",
      lineHeight: "135%",
    },
    h6: {
      fontWeight: 700,
      fontSize: "1rem",
      lineHeight: "135%",
    },
    buttonMedium: {
      fontWeight: 600,
      fontSize: "0.875rem",
      lineHeight: "135%",
    },
    subtitle1: {
      fontWeight: 600,
      fontSize: "0.875rem",
      lineHeight: "135%",
    },
    subtitle2: {
      fontWeight: 500,
      fontSize: "0.75rem",
      lineHeight: "135%",
    },
    body1Bold: {
      fontWeight: 700,
      fontSize: "1rem",
      lineHeight: "135%",
    },
    body1Regular: {
      fontWeight: 400,
      fontSize: "1rem",
      lineHeight: "135%",
    },
    body2Bold: {
      fontWeight: 700,
      fontSize: "0.875rem",
      lineHeight: "135%",
    },
    body2Regular: {
      fontWeight: 400,
      fontSize: "0.875rem",
      lineHeight: "135%",
    },
    inputText: {
      fontWeight: 400,
      fontSize: "1rem",
      lineHeight: "135%",
    },
    inputLabel: {
      fontWeight: 400,
      fontSize: "0.75rem",
      lineHeight: "135%",
      color: "#798EAF",
    },
    helperText: {
      fontWeight: 400,
      fontSize: "0.75rem",
      lineHeight: "135%",
    },
    caption: {
      fontWeight: 400,
      fontSize: "0.75rem",
      lineHeight: "135%",
    },
    tooltip: {
      fontWeight: 400,
      fontSize: "0.75rem",
      lineHeight: "135%",
    },
    avatarLetter: {
      fontWeight: 700,
      fontSize: "1rem",
      lineHeight: "135%",
    },
    overline: {
      fontWeight: 400,
      fontSize: "0.75rem",
      lineHeight: "135%",
    },
    tag: {
      fontWeight: 500,
      fontSize: "0.75rem",
      lineHeight: "135%",
    },
    tagXs: {
      fontWeight: 500,
      fontSize: "0.5rem",
      lineHeight: "135%",
    },
  },
  palette: {
    mode: "light",
    text: {
      primary: "#02060A",
      secondary: "#384A65",
      disabled: "rgba(0, 0, 0, 0.38)",
    },
    primary: {
      main: "#4CAF50",
      dark: "#087F23",
      light: "#80E27E",
      contrastText: "#FFFFFF",
      shades: {
        "4p": "#F8FCF8",
        "8p": "#F2FBF8",
        "12p": "rgba(76, 175, 80, 0.12)",
        "30p": "#D2EBD3",
        "50p": "rgba(76, 175, 80, 0.5)",
      },
    },
    secondary: {
      main: "#9C27B0",
      dark: "#7B1FA2",
      light: "#BA68C8",
      contrastText: "#FFFFFF",
      shades: {
        "4p": "rgba(156, 39, 176, 0.04)",
        "8p": "rgba(156, 39, 176, 0.08)",
        "12p": "rgba(156, 39, 176, 0.12)",
        "30p": "rgba(156, 39, 176, 0.3)",
        "50p": "rgba(156, 39, 176, 0.5)",
      },
    },
    error: {
      main: "#D32F2F",
      dark: "#C62828",
      light: "#EA7272",
      contrastText: "#FFFFFF",
      shades: {
        "4p": "#FEF4F6",
        "8p": "rgba(211, 47, 47, 0.08)",
        "12p": "rgba(211, 47, 47, 0.12)",
        "30p": "#F8CCD5",
        "50p": "rgba(211, 47, 47, 0.5)",
      },
    },
    warning: {
      main: "#EF7300",
      dark: "#E65100",
      light: "#FF9800",
      contrastText: "#FFFFFF",
      shades: {
        "4p": "#FFF7EF",
        "8p": "rgba(237, 108, 2, 0.08)",
        "12p": "#FFE4CB",
        "30p": "rgba(237, 108, 2, 0.3)",
        "50p": "rgba(237, 108, 2, 0.5)",
      },
    },
    info: {
      main: "#0288D1",
      dark: "#01579B",
      light: "#03A9F4",
      contrastText: "#FFFFFF",
      shades: {
        "4p": "rgba(2, 136, 209, 0.04)",
        "8p": "rgba(2, 136, 209, 0.08)",
        "12p": "rgba(2, 136, 209, 0.12)",
        "30p": "rgba(2, 136, 209, 0.3)",
        "50p": "rgba(2, 136, 209, 0.5)",
      },
    },
    success: {
      main: "#2E7D32",
      dark: "#1B5E20",
      light: "#4CAF50",
      contrastText: "#FFFFFF",
      shades: {
        "4p": "rgba(46, 125, 50, 0.04)",
        "8p": "rgba(46, 125, 50, 0.08)",
        "12p": "rgba(46, 125, 50, 0.12)",
        "30p": "rgba(46, 125, 50, 0.3)",
        "50p": "rgba(46, 125, 50, 0.5)",
      },
    },
    action: {
      active: "rgba(0, 0, 0, 0.54)",
      hover: "#D2EBD3",
      selected: "rgba(0, 0, 0, 0.08)",
      disabled: "#E1E6EF",
      disabledBackground: "#F7F8F9",
      focus: "rgba(0, 0, 0, 0.12)",
    },
    divider: "#EDEFF7",
    otherBackground: {
      main: "#F5F6F9",
    },
    otherDisabledBackground: {
      main: "#F7F8F9",
    },
    otherBackgroundLight: {
      main: "#FFFFFF",
    },
    otherTextTertiary: {
      main: "#798EAF",
    },
    otherDivider: {
      main: "#EDEFF7",
    },
    otherOutlineBorder: {
      main: "#E1E6EF",
    },
    otherStandardInputLine: {
      main: "#BFCADF",
    },
    otherBackdropOverlay: {
      main: "#E1E6EF",
    },
    otherSnackbarBackground: {
      main: "#323232",
    },
    otherElevation: {
      main: "#111E2A",
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: MuiAppBarLightStyleOverrides,
    },
    MuiToolbar: {
      styleOverrides: MuiToolBarLightStyleOverrides,
    },
    MuiDivider: {
      styleOverrides: MuiDividerLightStyleOverrides,
    },
    MuiSelect: {
      styleOverrides: MuiSelectLightStyleOverrides,
    },
    MuiButton: {
      styleOverrides: MuiButtonLightStyleOverrides,
    },
    MuiAutocomplete: {
      styleOverrides: MuiAutocompleteLightStyleOverrides,
    },
    MuiTextField: {
      styleOverrides: MuiTextFieldLightStyleOverrides,
    },
    MuiInputLabel: {
      styleOverrides: MuiInputLabelLightStyleOverrides,
    },
    MuiFormHelperText: {
      styleOverrides: MuiFormHelperTextLightStyleOverrides,
    },
  },
};

import {
  Typography,
  InputLabel,
  styled,
  useTheme,
  TypographyProps,
} from "@mui/material";

interface CustomInputLabelInterface {
  title: string;
  required: boolean;
  id: string;
  labelFontVariant?: TypographyProps["variant"];
}

const CustomInputLabel = ({
  title,
  required,
  id,
  labelFontVariant = "body14Regular",
}: CustomInputLabelInterface): JSX.Element => {
  const theme = useTheme();

  return (
    <StyledInputLabel id={id}>
      <Typography
        variant={labelFontVariant}
        color={theme.palette["base-label"]}
      >{`${title} ${required ? "*" : ""}`}</Typography>

      {required && (
        <Typography
          variant="body12RegularItalic"
          color={theme.palette["base-placeholder"]}
        >
          Required
        </Typography>
      )}
    </StyledInputLabel>
  );
};

export default CustomInputLabel;

const StyledInputLabel = styled(InputLabel)(() => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  gap: "10px",
}));

import { ChangeEvent, MouseEvent, useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { CSSObject, styled } from "@mui/material/styles";

import { ModelType } from "../../../API";
import {
  fireSmokeDefaultModelsValues,
  gasLeakDefaultModelValues,
  gateGuardDefualtModelValues,
  hardHatDefaultModelValues,
  liquidLeakDefaultModelValues,
  tankLevelDefaultModelValues,
} from "../../../pages/model-manager/variables/modelManager";
import CloseIcon from "../../components/icons/CloseIcon";
import { formatJsonString } from "../../helpers/formatJsonString";
import StyledLoadingButton from "../../providers/theme/design-tokens/LoadingButton/StyledLoadingButton";
import AdditonalConfigurationParametersSelect from "./AdditonalConfigurationParametersSelect";

export interface IConfigurationParameterMenuItem {
  name: string;
  value: any;
  checked: boolean;
}

const glAdditionalPrameters: IConfigurationParameterMenuItem[] = Object.entries(
  gasLeakDefaultModelValues
).map((gl): IConfigurationParameterMenuItem => {
  return {
    name: gl[0],
    value: gl[1],
    checked: false,
  };
});

const llAdditionalPrameters: IConfigurationParameterMenuItem[] = Object.entries(
  liquidLeakDefaultModelValues
).map((ll): IConfigurationParameterMenuItem => {
  return {
    name: ll[0],
    value: ll[1],
    checked: false,
  };
});

const fireSmokeAdditionalParameters: IConfigurationParameterMenuItem[] =
  Object.entries(fireSmokeDefaultModelsValues).map(
    (fs): IConfigurationParameterMenuItem => {
      return {
        name: fs[0],
        value: fs[1],
        checked: false,
      };
    }
  );

const hhAdditionalParameters: IConfigurationParameterMenuItem[] =
  Object.entries(hardHatDefaultModelValues).map(
    (hh): IConfigurationParameterMenuItem => {
      return {
        name: hh[0],
        value: hh[1],
        checked: false,
      };
    }
  );

const tlmAdditionalParameters: IConfigurationParameterMenuItem[] =
  Object.entries(tankLevelDefaultModelValues).map(
    (tlm): IConfigurationParameterMenuItem => {
      return {
        name: tlm[0],
        value: tlm[1],
        checked: false,
      };
    }
  );

const aggAdditionalParameters: IConfigurationParameterMenuItem[] =
  Object.entries(gateGuardDefualtModelValues).map(
    (agg): IConfigurationParameterMenuItem => {
      return {
        name: agg[0],
        value: agg[1],
        checked: false,
      };
    }
  );

const additionalParameters = {
  [ModelType.gasleak]: glAdditionalPrameters,
  [ModelType.liquidleak]: llAdditionalPrameters,
  [ModelType.fire]: fireSmokeAdditionalParameters,
  [ModelType.smoke]: fireSmokeAdditionalParameters,
  firesmoke: fireSmokeAdditionalParameters,
  [ModelType.hardhat]: hhAdditionalParameters,
  [ModelType.tlm]: tlmAdditionalParameters,
  [ModelType.agg]: aggAdditionalParameters,
};

const StyledPaper = styled(Paper)(
  ({ theme }): CSSObject => ({
    borderRadius: "12px",
    border: "none",
    backgroundColor: theme.palette.otherBackground.main,
  })
);

type ConfigurationComponentProps = {
  model: string;
  params: string;
  onCancelHandler: () => void;
  onSaveHandler: (newValue: string) => void;
};

const ConfigurationComponent: React.FC<ConfigurationComponentProps> = ({
  model,
  params,
  onCancelHandler,
  onSaveHandler,
}): JSX.Element => {
  const [value, setValue] = useState(formatJsonString(params));
  const [isError, setIsError] = useState(false);

  const [parameters, setParameters] = useState<
    IConfigurationParameterMenuItem[]
  >(additionalParameters[model as keyof typeof additionalParameters]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  useEffect((): void => {
    const initialParams = JSON.parse(params);

    setParameters((prevState): IConfigurationParameterMenuItem[] =>
      prevState?.map((parameter): IConfigurationParameterMenuItem => {
        const checked =
          initialParams[parameter?.name] !== undefined ? true : false;

        return {
          ...parameter,
          checked,
        };
      })
    );

    setValue(formatJsonString(params));
  }, [params]);

  const openMenuWithParameters = (event: MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const saveNewParams = (): void => {
    onSaveHandler(value);
  };

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>): void => {
    const targetValue = e.target.value;

    try {
      JSON.parse(targetValue);

      setIsError(false);
    } catch (error) {
      setIsError(true);
    }

    setValue(targetValue);
  };

  const handleMenuItemClick = (
    newValue: IConfigurationParameterMenuItem
  ): void => {
    setParameters((prevState): IConfigurationParameterMenuItem[] =>
      prevState.map((parameter): IConfigurationParameterMenuItem => {
        if (parameter.name === newValue.name) {
          return {
            ...parameter,
            checked: !parameter.checked,
          };
        }

        return parameter;
      })
    );

    try {
      const initialJSON = JSON.parse(value);

      const parameter = {
        [newValue.name]: newValue.value,
      };

      const newJSON = initialJSON[newValue.name]
        ? delete initialJSON[newValue.name] && initialJSON
        : {
            ...initialJSON,
            ...parameter,
          };

      const newJSONString = formatJsonString(JSON.stringify(newJSON));

      setValue(newJSONString);

      setIsError(false);
    } catch (error) {
      setIsError(true);
    }
  };

  const handleRemoveParameterClick = (
    item: IConfigurationParameterMenuItem
  ): void => {
    setParameters((prevState): IConfigurationParameterMenuItem[] =>
      prevState.map((parameter): IConfigurationParameterMenuItem => {
        if (parameter.name === item.name) {
          return {
            ...parameter,
            checked: false,
          };
        }

        return parameter;
      })
    );

    try {
      const initialJSON = JSON.parse(value);

      const newJSON =
        initialJSON[item.name] && delete initialJSON[item.name] && initialJSON;

      const newJSONString = formatJsonString(JSON.stringify(newJSON));

      setValue(newJSONString);

      setIsError(false);
    } catch (error) {
      setIsError(true);
    }
  };

  return (
    <StyledPaper elevation={0}>
      <Box padding={2}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="buttonMedium">
            Edit configuration parameters
          </Typography>

          {parameters && (
            <AdditonalConfigurationParametersSelect
              anchorEl={anchorEl}
              open={open}
              parameters={parameters}
              openMenuWithParameters={openMenuWithParameters}
              handleClose={handleClose}
              handleMenuItemClick={handleMenuItemClick}
            />
          )}
        </Stack>
        <Box sx={{ width: "100%", padding: "1em 1em 0 0em" }}>
          {parameters?.map((parameter): JSX.Element => {
            if (!parameter.checked) {
              return <></>;
            }

            return (
              <Chip
                key={parameter.name}
                sx={{
                  mr: "4px",
                  mb: "4px",
                  border: (theme): string =>
                    `1px solid ${theme.palette.otherOutlineBorder.main}`,
                  "& .MuiChip-deleteIcon": {
                    fontSize: "1.5em",
                    mr: "8px",
                  },
                }}
                label={parameter.name}
                variant="outlined"
                deleteIcon={<CloseIcon />}
                onDelete={(): void => handleRemoveParameterClick(parameter)}
              />
            );
          })}
        </Box>
      </Box>

      <Box padding={2} sx={{ width: "100%" }}>
        <TextField
          id="outlined-multiline-static"
          label="Configuration parameters"
          multiline
          maxRows={8}
          sx={{
            width: "100%",
            whiteSpace: "pre-wrap",
            wordBreak: "break-word",
            "& .MuiOutlinedInput-root": {
              borderRadius: "8px",
            },
          }}
          onChange={onChangeHandler}
          error={isError}
          helperText={isError ? "Invalid JSON format." : " "}
          value={value}
        />
      </Box>

      <Box
        sx={{
          padding: 1,
          display: "flex",
          justifyContent: "flex-end",
          gap: "1em",
        }}
      >
        <StyledLoadingButton
          loading={false}
          variant="outlined"
          color="inherit"
          onClick={onCancelHandler}
        >
          Cancel
        </StyledLoadingButton>

        <StyledLoadingButton
          loading={false}
          variant="contained"
          color="primary"
          onClick={saveNewParams}
          disabled={isError}
        >
          Save changes
        </StyledLoadingButton>
      </Box>
    </StyledPaper>
  );
};

export default ConfigurationComponent;

import { MeasurementSystemMode } from "../enums";
import { feetToMeters, metersToFeet } from "../threeWebGl/SceneUtils/MathUtils";

export const useGetValueInCurrentMeasurementSystem = (
  measurementSystem: MeasurementSystemMode
) => {
  const getHeightValueFromFoot = (value: number) => {
    if (measurementSystem === MeasurementSystemMode.Metric) {
      return feetToMeters(value);
    } else {
      return value;
    }
  };

  const getHeightValueFromMeters = (value: number) => {
    if (measurementSystem === MeasurementSystemMode.Metric) {
      return value;
    } else {
      return metersToFeet(value);
    }
  };

  const getHeightValueForData = (value: number) => {
    if (measurementSystem === MeasurementSystemMode.Metric) {
      return value;
    } else {
      return feetToMeters(value);
    }
  };

  const getHeightValueFromData = (value: number) => {
    if (measurementSystem === MeasurementSystemMode.Metric) {
      return value;
    } else {
      return metersToFeet(value);
    }
  };

  return {
    getHeightValueFromFoot,
    getHeightValueFromMeters,
    getHeightValueForData,
    getHeightValueFromData,
  };
};

import { useContext } from "react";
import { Button, useTheme } from "@mui/material";

import { WarningIcon } from "../../../../common/assets/icons/svgAssets";

import {
  ConfirmationModalContext,
  ProjectContext,
} from "../../../../common/context";

const ExitFromProjectButton = (): JSX.Element => {
  const theme = useTheme();

  const {
    sceneStarted,
    isLoading,
    isConfigDataChanged,
    exitFromProjectConfigurator,
  } = useContext(ProjectContext);
  const { setConfirmationModalData } = useContext(ConfirmationModalContext);

  const handleOnClick = () => {
    if (isConfigDataChanged()) {
      setConfirmationModalData({
        icon: <WarningIcon fillColor={theme.palette.yellow.main} />,
        title: "Discard changes",
        message: "Are you sure you want to discard all changes?",
        actionButton: {
          color: "blue",
          title: "Discard",
          action: exitFromProjectConfigurator,
        },
      });
    } else {
      exitFromProjectConfigurator();
    }
  };

  return (
    <Button
      onClick={handleOnClick}
      variant="secondary"
      color="blue"
      disabled={!sceneStarted || isLoading}
    >
      Exit
    </Button>
  );
};

export default ExitFromProjectButton;

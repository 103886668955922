import { GoogleTilesRenderer } from "3d-tiles-renderer";

import { MathUtils, Vector3 } from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader.js";

import { getRaycastIntersectsPointByFromToVectors } from "./SceneUtils/RaycastUtils";

import { DRACO_DECODER_PATH } from "./Constants";

class MapTiles {
  constructor(
    scene,
    mainCamera,
    mainRenderer,
    orbitControl,
    sceneLocationCenter
  ) {
    this.scene = scene;

    this.mainCamera = mainCamera;

    this.mainRenderer = mainRenderer;

    this.orbitControl = orbitControl;

    this.sceneLocationCenter = sceneLocationCenter;

    this.intersectPointFrom = new Vector3(0, 15000, 0);

    this.intersectPointTo = new Vector3(0, -15000, 0);

    this.tiles = null;
  }

  dispose() {
    if (this.tiles) {
      this.scene.remove(this.tiles.group);

      this.tiles.dispose();

      this.tiles = null;
    }
  }

  loadTiles(lat, lon) {
    this.tiles = new GoogleTilesRenderer(process.env.REACT_APP_GOOGLE_API_KEY);

    this.tiles.optimizeRaycast = true;

    this.tiles.setLatLonToYUp(MathUtils.degToRad(lat), MathUtils.degToRad(lon));

    const dracoLoader = new DRACOLoader();

    dracoLoader.setDecoderPath(DRACO_DECODER_PATH);

    const loader = new GLTFLoader(this.tiles.manager);

    loader.setDRACOLoader(dracoLoader);

    this.tiles.manager.addHandler(/\.gltf$/, loader);

    this.scene.add(this.tiles.group);

    this.tiles.setResolutionFromRenderer(this.mainCamera, this.mainRenderer);

    this.tiles.setCamera(this.mainCamera);

    return new Promise(resolve => {
      this.timeoutId = null;

      this.tiles.manager.onStart = () => {
        this.timeoutId && clearTimeout(this.timeoutId);
      };

      this.tiles.manager.onLoad = () => {
        this.updateOrbitControlTarget();

        this.updateSceneLocationCenter();

        this.timeoutId = setTimeout(() => {
          resolve();
        }, 1000);
      };
    });
  }

  updateOrbitControlTarget() {
    if (!this.tiles) {
      return;
    }

    const fromPoint = this.orbitControl.target.clone();

    fromPoint.y = this.intersectPointFrom.y;

    const toPoint = this.orbitControl.target.clone();

    toPoint.y = this.intersectPointTo.y;

    const intersects = getRaycastIntersectsPointByFromToVectors(
      this.tiles.group,
      fromPoint,
      toPoint
    );

    if (intersects && intersects.length > 0) {
      const point = intersects.at(-1).point;

      this.orbitControl.target.copy(point);
    }
  }

  updateSceneLocationCenter() {
    if (!this.tiles) {
      return;
    }

    const fromPoint = this.intersectPointFrom.clone();
    const toPoint = this.intersectPointTo.clone();

    const intersects = getRaycastIntersectsPointByFromToVectors(
      this.tiles.group,
      fromPoint,
      toPoint
    );

    if (intersects && intersects.length > 0) {
      const point = intersects.at(-1).point;

      this.sceneLocationCenter.copy(point);
    }
  }

  render(camera, renderer) {
    if (this.tiles) {
      this.tiles.setResolutionFromRenderer(camera, renderer);

      this.tiles.setCamera(camera);

      camera.updateMatrixWorld();

      this.tiles.update();
    }
  }
}

export default MapTiles;

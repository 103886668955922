import { useTheme } from "@mui/material/styles";

const DeleteIconButton = ({
  disabled,
  onClick,
}: {
  disabled?: boolean;
  onClick?: () => void;
}): JSX.Element => {
  const theme = useTheme();

  const stroke = disabled
    ? theme.palette.error.shades?.["30p"]
    : theme.palette.error.main;

  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      style={{ cursor: "pointer" }}
      xmlns="http://www.w3.org/2000/svg"
      className={`deleteIcon ${disabled ? "disabled" : ""}`}
      onClick={onClick}
    >
      <rect
        x="0.5"
        y="0.5"
        width="31"
        height="31"
        rx="7.5"
        fill={theme.palette.otherBackgroundLight.main}
      />
      <path
        d="M23.0832 11H8.9165"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        className="iconPath"
      />
      <path
        d="M21.6946 13.0833L21.3113 18.8325C21.1638 21.0449 21.09 22.1511 20.3692 22.8255C19.6483 23.4999 18.5397 23.4999 16.3223 23.4999H15.6779C13.4605 23.4999 12.3519 23.4999 11.631 22.8255C10.9102 22.1511 10.8364 21.0449 10.6889 18.8325L10.3057 13.0833"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        className="iconPath"
      />
      <path
        d="M13.6426 9.33341C13.9858 8.36242 14.9118 7.66675 16.0003 7.66675C17.0888 7.66675 18.0149 8.36242 18.3581 9.33341"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        className="iconPath"
      />
      <rect
        x="0.5"
        y="0.5"
        width="31"
        height="31"
        rx="7.5"
        stroke={theme.palette.otherOutlineBorder.main}
        className="deleteIconBorder"
      />
    </svg>
  );
};

export default DeleteIconButton;

import { ReactNode } from "react";

import { ErrorOutline } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";

type WarningMessageProps = {
  children: ReactNode;
  showWarningIcon?: boolean;
  color?: string;
};

const WarningMessage: React.FC<WarningMessageProps> = ({
  children,
  showWarningIcon,
  color = "warning.main",
}): JSX.Element => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      whiteSpace: "pre-wrap",
      gap: "0.5em",
    }}
  >
    {showWarningIcon && <ErrorOutline sx={{ color }} />}
    <Typography
      sx={{
        textAlign: "left",
        color,
        "& p": { margin: 0 },
      }}
      variant="body2Regular"
      component="span"
    >
      {children}
    </Typography>
  </Box>
);

export default WarningMessage;

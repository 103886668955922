import { LoadingButton, LoadingButtonProps } from "@mui/lab";
import { styled } from "@mui/material/styles";

const StyledLoadingButton = styled(LoadingButton)<LoadingButtonProps>(
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  ({ theme }) => ({
    // backgroundColor: theme.palette.primary.main,
    textTransform: "none",
    "&.Mui-disabled": {
      color: theme.palette.otherBackgroundLight.main,
    },
  })
);

export default StyledLoadingButton;

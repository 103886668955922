import { ApolloError, gql, useMutation } from "@apollo/client";

import {
  AnnotationInput,
  UpdateAnnotationMutation,
  UpdateAnnotationMutationVariables,
} from "../../../API";
import { UPDATE_ANNOTATION } from "../../../common/operations/mutations";
import { successNotification } from "../../../common/variables/notification";

export const useUpdateAnnotation = () => {
  const [updateAnnotationMutation, { data, loading }] = useMutation<
    UpdateAnnotationMutation,
    UpdateAnnotationMutationVariables
  >(UPDATE_ANNOTATION);

  const updateAnnotation = async (input: AnnotationInput) => {
    return await updateAnnotationMutation({
      variables: {
        input,
      },
      update: (cache, response): void => {
        cache.modify({
          fields: {
            getAnnotations(existing) {
              const newAnnotationRef = cache.writeFragment({
                data: response.data?.updateAnnotation,
                fragment: gql`
                  fragment NewAnnotation on Annotation {
                    id
                    name
                    polygon
                    zoneId
                    serviceId
                    annotationType
                  }
                `,
              });

              if (!existing) {
                return response?.data;
              }

              return {
                ...existing,
                items: existing.items.map((item: { id: any }) => {
                  if (item?.id !== response.data?.updateAnnotation?.zoneId) {
                    return item;
                  }

                  return newAnnotationRef;
                }),
              };
            },
          },
        });
      },
      onCompleted: response => {
        if (response.updateAnnotation) {
          successNotification("Changes are saved");
        }
      },
      onError: (error: ApolloError): void => {
        console.error(error);
      },
    });
  };

  return { updateAnnotation, data, loading };
};

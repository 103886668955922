import { useEffect } from "react";

const useBeforeUnload = (shouldPreventUnload = false) => {
  useEffect(() => {
    if (shouldPreventUnload) return;

    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      e.preventDefault();

      e.returnValue = true;
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [shouldPreventUnload]);
};

export default useBeforeUnload;

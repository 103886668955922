import {
  Box,
  CSSObject,
  SxProps,
  Theme,
  Typography,
  styled,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import RouterLink from "../RouterLink";
import { IDeviceTableRow } from "../../../pages/devices/hooks/useDeviceTableRows";

export const StyledMenuBox = styled(Box)(
  ({ theme, sx }): CSSObject => ({
    "&": {
      cursor: "pointer",
      padding: "2px 32px 2px 10px",
      borderRadius: "8px",
      display: "flex",
      justifyContent: "center",
      height: "28px",
      color: theme.palette.text.primary,
      backgroundColor: "transparent",
      border: `1px solid ${theme.palette.otherOutlineBorder.main}`,
      "&:hover": {
        backgroundColor: theme.palette.primary.shades?.["8p"],
        borderColor: theme.palette.primary.shades?.["30p"],
        color: theme.palette.primary.dark,
      },
      "&.inActive": {
        backgroundColor: "transparent",
        color: theme.palette.otherStandardInputLine.main,
      },
      ...sx,
    },
  })
);

type ChipComponentProps = {
  activeState?: boolean;
  menuBoxStyles?: SxProps<Theme>;
  label: string;
  routeLink?: string;
  routeData?: IDeviceTableRow;
  onClick?: () => void;
};

const ChipComponent: React.FC<ChipComponentProps> = ({
  activeState = true,
  label,
  routeData = {},
  menuBoxStyles,
  routeLink = "",
  onClick,
}) => {
  return (
    <RouterLink
      onClick={onClick}
      to={routeLink}
      state={{ deviceDatum: routeData }}
    >
      <StyledMenuBox
        className={activeState ? "" : "inActive"}
        sx={menuBoxStyles}
      >
        <Stack direction="row" alignItems="center">
          <Typography
            sx={{
              marginRight: "6px",
              display: "flex",
              justifyContent: "center",
              alignItem: "center",
            }}
            variant="buttonMedium"
          >
            {label}
          </Typography>
        </Stack>
      </StyledMenuBox>
    </RouterLink>
  );
};

export default ChipComponent;

import React, { useEffect } from "react";

import { useTheme } from "@mui/material";
import { Rect, Transformer } from "react-konva";

import {
  HEX_OPACITY_30,
  VERTEX_RADIUS,
  colorOfAnnotationType,
  initialRect,
} from "./utils";
import { AnnotationType } from "../../../API";

type RectangleProps = {
  shapeProps: typeof initialRect;
  typeOfAnnotation: AnnotationType;
  onChange: (newAttrs: typeof initialRect) => void;
};

const Rectangle: React.FC<RectangleProps> = ({
  shapeProps,
  typeOfAnnotation,
  onChange,
}) => {
  const shapeRef = React.useRef<any>();
  const trRef = React.useRef<any>();

  const theme = useTheme();
  const fillColor = colorOfAnnotationType(typeOfAnnotation, theme);

  useEffect(() => {
    shapeProps.fill = fillColor + HEX_OPACITY_30;

    if (!trRef.current) return;

    if (!trRef.current?.nodes) return;

    trRef.current.nodes([shapeRef.current]);

    trRef.current.getLayer().batchDraw();
  }, []);

  return (
    <React.Fragment>
      <Rect
        ref={shapeRef}
        {...shapeProps}
        draggable
        dragBoundFunc={pos => {
          const stage = shapeRef.current.getStage();
          const stageWidth = stage.width();
          const stageHeight = stage.height();

          const newX = Math.max(
            0,
            Math.min(pos.x, stageWidth - shapeProps.width)
          );
          const newY = Math.max(
            0,
            Math.min(pos.y, stageHeight - shapeProps.height)
          );

          return {
            x: newX,
            y: newY,
          };
        }}
        onDragEnd={e => {
          onChange({
            ...shapeProps,
            x: e.target.x(),
            y: e.target.y(),
          });
        }}
        onTransformEnd={() => {
          const node = shapeRef.current;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();

          node.scaleX(1);

          node.scaleY(1);

          onChange({
            ...shapeProps,
            x: node.x(),
            y: node.y(),
            width: Math.max(VERTEX_RADIUS, node.width() * scaleX),
            height: Math.max(VERTEX_RADIUS, node.height() * scaleY),
          });
        }}
      />

      <Transformer
        ref={trRef}
        keepRatio={false}
        anchorFill={fillColor}
        flipEnabled={false}
        rotateEnabled={false}
        anchorCornerRadius={50}
        anchorStroke="white"
        anchorStrokeWidth={1}
        boundBoxFunc={(oldBox, newBox) => {
          const stage = trRef.current.getStage();
          const stageWidth = stage.width();
          const stageHeight = stage.height();

          if (
            Math.abs(newBox.width) < VERTEX_RADIUS ||
            Math.abs(newBox.height) < VERTEX_RADIUS
          ) {
            return oldBox;
          }

          const newX = Math.max(0, newBox.x);
          const newY = Math.max(0, newBox.y);

          const deltaX = newBox.x - newX;
          const deltaY = newBox.y - newY;

          const adjustedWidth = Math.min(
            newBox.width + deltaX,
            stageWidth - newX
          );
          const adjustedHeight = Math.min(
            newBox.height + deltaY,
            stageHeight - newY
          );

          return {
            ...newBox,
            x: newX,
            y: newY,
            width: adjustedWidth,
            height: adjustedHeight,
          };
        }}
      />
    </React.Fragment>
  );
};

export default Rectangle;

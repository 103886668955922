import { useContext, useState } from "react";
import {
  Box,
  Button,
  ButtonOwnProps,
  Typography,
  Stack,
  styled,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import ModalWindow from "../../common/components/ModalWindow";

import { ConfirmationModalContext } from "../context";

interface ActionButtonInterface {
  color: ButtonOwnProps["color"];
  title: string;
  action: () => Promise<void> | void;
}

export interface ConfirmationModalInterface {
  icon: JSX.Element;
  title: string;
  message: string;
  actionButton: ActionButtonInterface;
  actionOnClose?: () => void;
}

const ConfirmationModal = ({
  icon,
  title,
  message,
  actionButton,
  actionOnClose,
}: ConfirmationModalInterface): JSX.Element => {
  const { setConfirmationModalData } = useContext(ConfirmationModalContext);

  const [isLoading, setIsLoading] = useState(false);

  const onAction = async (): Promise<void> => {
    setIsLoading(true);

    await actionButton.action();

    setIsLoading(false);

    setConfirmationModalData(null);
  };

  const onClose = (): void => {
    if (isLoading) return;

    if (actionOnClose) {
      actionOnClose();
    }

    setConfirmationModalData(null);
  };

  return (
    <ModalWindow isOpen={true} onClose={onClose}>
      <HeaderWrapper>
        <IconContainer>{icon}</IconContainer>

        <Typography variant="h2">{title}</Typography>
      </HeaderWrapper>

      <MessageWrapper>
        {message.split("\n").map((line, index) => (
          <Message key={index} variant="body14Regular">
            {line}
            <br />
          </Message>
        ))}
      </MessageWrapper>

      <Stack direction="row" spacing={1} justifyContent="flex-end">
        <Button
          onClick={onClose}
          variant="secondary"
          color="blue"
          disabled={isLoading}
        >
          Cancel
        </Button>
        <LoadingButton
          variant="primary"
          color={actionButton.color}
          loading={isLoading}
          disabled={isLoading}
          endIcon={<></>}
          loadingPosition="end"
          onClick={onAction}
        >
          {actionButton.title}
        </LoadingButton>
      </Stack>
    </ModalWindow>
  );
};

export default ConfirmationModal;

const HeaderWrapper = styled(Box)(() => ({
  position: "relative",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  paddingLeft: "40px",
}));

const IconContainer = styled(Box)(() => ({
  position: "absolute",
  display: "flex",
  alignItems: "center",
  left: 0,
  width: "24px",
}));

const MessageWrapper = styled(Box)(() => ({
  paddingLeft: "40px",
  margin: "16px 0 24px",
}));

const Message = styled(Typography)(() => ({
  display: "inline-block",
  padding: "4px 0",
}));

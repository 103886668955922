import { ReactNode } from "react";
import { Link, LinkProps } from "react-router-dom";

import { styled, SxProps, Theme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

const StyledRouterLink = styled(Link)({
  cursor: "pointer",
  textDecoration: "none",
  overflow: "hidden",
  whiteSpace: "nowrap",
  color: "inherit",
});

interface IRouterLinkProps extends LinkProps {
  title?: string;
  noWrap?: boolean;
  children?: ReactNode;
  sx?: SxProps<Theme>;
}

const RouterLink = ({
  title = "",
  noWrap,
  children,
  ...props
}: IRouterLinkProps): JSX.Element => {
  return (
    <StyledRouterLink {...props}>
      {!!children && children}
      {!children && (
        <Typography
          variant="buttonMedium"
          noWrap={noWrap}
          sx={{
            "&:hover, &:active": {
              color: (theme: Theme): string => theme.palette.primary.dark,
            },
          }}
        >
          {title}
        </Typography>
      )}
    </StyledRouterLink>
  );
};

export default RouterLink;

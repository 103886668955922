import { useCheckForRoot } from "./useCheckForRoot"; // Assuming this is your custom hook for checking root role

const usePlateManagementNavigationRoute = () => {
  const isRootRole = useCheckForRoot();

  const getNavigationRoute = (rootPath: string, userPath: string): string => {
    let navRoute: string;

    if (isRootRole) {
      navRoute = rootPath;
    } else {
      navRoute = userPath;
    }

    return navRoute;
  };

  return { getNavigationRoute };
};

export default usePlateManagementNavigationRoute;

import { Box, Button } from "@mui/material";
import {
  Control,
  FieldErrors,
  SubmitHandler,
  UseFieldArrayAppend,
  UseFormHandleSubmit,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";

import MainPaperWrapper from "../../../../common/components/item/MainPaperWrapper";
import { errorNotification } from "../../../../common/variables/notification";
import VideoDropzone from "../../../components/dropzone/VideoDropzone";
import {
  VideoAnalysisFormState,
  VideoProcessingSteps,
} from "../../UploadVideoGroupPage";
import NewGroupComponent from "./NewGroupComponent";

type UploadVideoStepProps = {
  watch: UseFormWatch<VideoAnalysisFormState>;
  setStepHandler: (step: VideoProcessingSteps) => void;
  setFormValue: UseFormSetValue<VideoAnalysisFormState>;
  addNewVideoField: UseFieldArrayAppend<VideoAnalysisFormState, "videos">;
  removeVideoField: (index: number) => Promise<void>;
  handleCancel: () => void;
  preventUpload: (state: boolean) => void;
  handleSubmit: UseFormHandleSubmit<VideoAnalysisFormState>;
  errors: FieldErrors<VideoAnalysisFormState>;
  control: Control<VideoAnalysisFormState>;
};

const UploadVideoStepComponent: React.FC<UploadVideoStepProps> = ({
  setStepHandler,
  handleCancel,
  preventUpload,
  addNewVideoField,
  watch,
  removeVideoField,
  handleSubmit,
  setFormValue,
  control,
  errors,
}) => {
  const onNextStepHandler = () =>
    setStepHandler(VideoProcessingSteps.VIDEO_DETAILS_STEP);

  const onSubmit: SubmitHandler<VideoAnalysisFormState> = () => {
    const videoFiles = watch("videos");

    const checkVideoContent = () => {
      if (videoFiles.length === 0) {
        errorNotification("Please add at least one video");

        return;
      }

      onNextStepHandler();
    };

    checkVideoContent();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <MainPaperWrapper>
        <Box
          gap="1.5em"
          sx={{
            width: "100%",
            "@media (min-width: 1440px)": {
              display: "grid",
              alignItems: "flex-start",
              gridTemplateColumns: "1fr 1fr",
            },
          }}
        >
          <NewGroupComponent errors={errors} control={control} />

          <Box>
            <VideoDropzone
              preventUpload={preventUpload}
              addNewVideoField={addNewVideoField}
              removeVideoField={removeVideoField}
              setFormValue={setFormValue}
              watch={watch}
            />
          </Box>
        </Box>
      </MainPaperWrapper>

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "1em",
        }}
      >
        <Button
          sx={{
            marginTop: "1.5em",
          }}
          variant="outlined"
          color="inherit"
          onClick={handleCancel}
        >
          Cancel
        </Button>

        <Button
          sx={{
            marginTop: "1.5em",
          }}
          type="submit"
          variant="contained"
          color="primary"
        >
          Continue
        </Button>
      </Box>
    </form>
  );
};

export default UploadVideoStepComponent;

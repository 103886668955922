import { FC } from "react";
import { Helmet } from "react-helmet";

import env from "../../../environment.json";

const hosts = ["ccai", "dev", "prod", "localhost", "test"];

const SetFavicon: FC = (): JSX.Element | null => {
  const envName = env.name;

  // Determine the favicon URL based on the environment
  let faviconEnv = "favicon.ico";
  let faviconEnvSVG = "favicon.svg";
  let faviconEnvApple = "apple-touch-icon.png";
  let envManifest = "manifest.json";

  if (!envName) {
    console.error("env.name is not defined");
  } else {
    faviconEnv = `${envName}/favicon.ico`;

    faviconEnvSVG = `${envName}/favicon.svg`;

    faviconEnvApple = `${envName}/apple-touch-icon.png`;

    envManifest = `${envName}/manifest.json`;
  }

  // If the environment is not in the 'hosts' array, use the localhost favicon
  if (!hosts.includes(envName)) {
    faviconEnv = "localhost/favicon.ico";

    faviconEnvSVG = "localhost/favicon.svg";

    faviconEnvApple = "localhost/apple-touch-icon.png";

    envManifest = "localhost/manifest.json";
  }

  return (
    <Helmet>
      <link rel="icon" type="image/x-icon" href={faviconEnv} />
      <link rel="icon" type="image/svg+xml" href={faviconEnvSVG} />
      <link rel="apple-touch-icon" href={faviconEnvApple} />
      <link rel="manifest" href={envManifest} />
    </Helmet>
  );
};

export default SetFavicon;

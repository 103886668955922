import { FC } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import StyledDialog from "../../../../../../common/components/dialog/StyledDialog";
import StyledLoadingButton from "../../../../../../common/providers/theme/design-tokens/LoadingButton/StyledLoadingButton";
import { useCreateCategory } from "../../hooks/useCreateCategory";
import CategoryForm, { IPlateCategoryForm } from "./CategoryForm";
import { errorNotification } from "../../../../../../common/variables/notification";
import useCategoryFromCache from "../../../../../../common/hooks/useGetCategoryFromCache";

interface ICreateCategoryDialogProps {
  isOpened: boolean;
  closeDialog: () => void;
}

const CreateCategoryDialog: FC<ICreateCategoryDialogProps> = ({
  isOpened,
  closeDialog,
}) => {
  const { createCategory, loading } = useCreateCategory();
  const { getCachedCategoryFromName } = useCategoryFromCache();

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IPlateCategoryForm>();
  const handleDialogClose = (): void => {
    reset();

    closeDialog();
  };

  const onSubmit: SubmitHandler<IPlateCategoryForm> = async (
    data
  ): Promise<void> => {
    if (data.category && data.category.trim().toLowerCase() === "unknown") {
      errorNotification("Cannot use name, 'UNKNOWN'");

      handleDialogClose();

      return;
    }

    const category = getCachedCategoryFromName(data.category);

    // if category doesnt already exist in cache, move forward with DDB create
    if (!category) {
      await createCategory(data.category);
    } else {
      errorNotification("Category with same name already exists");
    }

    handleDialogClose();
  };

  return (
    <StyledDialog
      open={isOpened}
      title="Add new category "
      onClose={handleDialogClose}
      showSubmitButton={false}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <CategoryForm control={control} errors={errors} />

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: "2em",
            gap: "1em",
          }}
        >
          <Button
            disabled={loading}
            variant="outlined"
            color="secondary"
            onClick={handleDialogClose}
          >
            Cancel
          </Button>
          <StyledLoadingButton
            loading={loading}
            type={"submit"}
            variant="contained"
            color="primary"
            onClick={handleSubmit(onSubmit)}
          >
            Add new category
          </StyledLoadingButton>
        </Box>
      </form>
    </StyledDialog>
  );
};

export default CreateCategoryDialog;

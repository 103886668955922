import { useContext } from "react";
import { ToggleButton, Typography } from "@mui/material";

import { RotateIcon } from "../../../assets/icons/svgAssets";

import { ProjectContext } from "../../../context";

import { ObjectMode } from "../../../enums";

const TurnOnRotateModeButton = () => {
  const {
    isLoading,
    activeObjectsListId,
    transformControlMode,
    setTransformControlMode,
    distanceMeasurementsMode,
  } = useContext(ProjectContext);

  const handleOnClick = () => {
    setTransformControlMode(ObjectMode.rotateObject);
  };

  const disabled =
    !activeObjectsListId.length || distanceMeasurementsMode || isLoading;
  const selected = transformControlMode === ObjectMode.rotateObject;

  return (
    <ToggleButton
      color="blue"
      className="icon-text"
      value="rotate"
      disabled={disabled}
      selected={selected}
      onClick={handleOnClick}
    >
      <RotateIcon />
      <Typography variant="body8Medium" component="p">
        Rotate
      </Typography>
    </ToggleButton>
  );
};

export default TurnOnRotateModeButton;

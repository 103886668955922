import { FC, Fragment, ReactNode } from "react";
import uuid from "react-uuid";

import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { CSSObject, useTheme } from "@mui/material/styles";

import { formatJsonString } from "../../helpers/formatJsonString";
import EditIconButton from "../icons/EditIconButton";
import DeleteIconButton from "../icons/DeleteIconButton";
import { Stack } from "@mui/material";

export interface DataTableRowItem {
  label?: string;
  additionalLabel?: string;
  value?: string | ReactNode;
  children?: ReactNode;
  additionalValue?: number | undefined | null;
  jsonFormat?: boolean;
  labelStyles?: CSSObject;
  rowStyles?: CSSObject;
  onEditHandler?: () => void;
  onDeleteHandler?: () => void;
}

interface ReusableComponentProps {
  data: DataTableRowItem[];
  withBackground?: boolean;
  withSeparateBackgroundRows?: boolean;
}

const ItemDetailTable: FC<ReusableComponentProps> = ({
  data,
  withBackground,
  withSeparateBackgroundRows,
}): JSX.Element => {
  const theme = useTheme();
  const backgroundStyles = withBackground
    ? {
        backgroundColor: theme.palette.otherBackground.main,
        borderRadius: "0.5em",
      }
    : {};

  const separateRows = withSeparateBackgroundRows
    ? {
        backgroundColor: theme.palette.otherBackground.main,
        borderRadius: "0.5em",
        padding: "1em",
        marginBottom: "0.25em",
      }
    : {};

  return (
    <Box
      sx={{
        display: "flex",
        ...backgroundStyles,
      }}
    >
      <Table size="small">
        <TableBody>
          {data.map(
            (item: DataTableRowItem): JSX.Element => (
              <Fragment key={uuid()}>
                {item.value ? (
                  <TableRow
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      ...separateRows,
                      ...item.rowStyles,
                    }}
                  >
                    <TableCell
                      variant="head"
                      sx={{
                        border: 0,
                        width: "220px",
                        display: "flex",
                        paddingLeft: "0",
                      }}
                    >
                      <Stack spacing={1.5}>
                        {item.label && (
                          <Typography
                            sx={item.labelStyles}
                            variant="inputLabel"
                            component="span"
                          >
                            {item.label}
                          </Typography>
                        )}
                        {item.additionalLabel && (
                          <Typography
                            sx={item.labelStyles}
                            variant="inputLabel"
                            component="span"
                          >
                            {item.additionalLabel}
                          </Typography>
                        )}
                      </Stack>
                    </TableCell>

                    <TableCell
                      sx={{
                        border: 0,
                        padding: 0,
                        width: "100%",
                      }}
                    >
                      <Stack>
                        {!item.jsonFormat && (
                          <Typography variant="body2Regular" component="span">
                            {item.value}
                          </Typography>
                        )}

                        {item.jsonFormat && typeof item.value === "string" && (
                          <Typography
                            sx={{
                              whiteSpace: "pre-wrap",
                              wordBreak: "break-word",
                            }}
                            variant="body2Regular"
                            component="span"
                          >
                            {formatJsonString(item.value)}
                          </Typography>
                        )}

                        {item.jsonFormat &&
                          typeof item.value === "object" &&
                          item.value}

                        {!item.jsonFormat && (
                          <Typography variant="body2Regular" component="span">
                            {item.additionalValue}
                          </Typography>
                        )}

                        {item.jsonFormat &&
                          typeof item.additionalValue === "number" && (
                            <Typography
                              sx={{
                                whiteSpace: "pre-wrap",
                                wordBreak: "break-word",
                              }}
                              variant="body2Regular"
                              component="span"
                            >
                              {item.additionalValue}
                            </Typography>
                          )}

                        {item.jsonFormat &&
                          typeof item.additionalValue === "object" &&
                          item.additionalValue}
                      </Stack>
                    </TableCell>

                    {(item.onEditHandler || item.onDeleteHandler) && (
                      <TableCell sx={{ display: "flex", gap: "0.5em" }}>
                        {item.onEditHandler && (
                          <EditIconButton onClick={item.onEditHandler} />
                        )}

                        {item.onDeleteHandler && (
                          <DeleteIconButton onClick={item.onDeleteHandler} />
                        )}
                      </TableCell>
                    )}
                  </TableRow>
                ) : (
                  <TableRow>
                    <TableCell>{item.children}</TableCell>
                  </TableRow>
                )}
              </Fragment>
            )
          )}
        </TableBody>
      </Table>
    </Box>
  );
};

export default ItemDetailTable;

import { useContext } from "react";
import { ToggleButton } from "@mui/material";

import { ProjectContext } from "../../../context";

import { SecondaryViewTypes } from "../../../data/SecondaryViewData";

const TurnOnCameraGasViewButton = (): JSX.Element => {
  const {
    isLoading,
    activeCameraId,
    setSecondaryCameraId,
    secondaryViewType,
    setSecondaryViewType,
  } = useContext(ProjectContext);

  const handleOnClick = () => {
    setSecondaryCameraId(activeCameraId);

    setSecondaryViewType(SecondaryViewTypes.viewGas);
  };

  return (
    <ToggleButton
      color="blue"
      className="outlined-text"
      selected={secondaryViewType === SecondaryViewTypes.viewGas}
      value="gas-view"
      disabled={isLoading}
      onClick={handleOnClick}
    >
      Gas view
    </ToggleButton>
  );
};

export default TurnOnCameraGasViewButton;

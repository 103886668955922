import { Theme } from "@mui/material";
import { themeMode } from "../../MuiThemeProvider";

export const dataGridServerStyles = {
  sx: {
    border: "none",
    borderRadius: "12px !important",

    ".MuiDataGrid-root": {
      borderRadius: "12px !important",
      borderWidth: 0,
    },
    "& .MuiDataGrid-withBorderColor": {
      borderColor: (theme: Theme) => theme.palette.otherOutlineBorder.main,
    },
    "& .MuiDataGrid-container--top [role=row]": {
      background: (theme: Theme) => theme.palette.otherBackdropOverlay.main,
      color: (theme: Theme) => theme.palette.text.secondary,
      alignItems: "center",
    },
    "& .MuiDataGrid-columnHeaders": {
      borderRadius: "12px 12px 0 0",
      fontSize: "12px",
      textTransform: "uppercase",
    },
    ".MuiDataGrid-footerContainer": {
      backgroundColor: "transparent",
    },
    ".MuiDataGrid-virtualScrollerContent": {
      backgroundColor: (theme: Theme) =>
        theme.palette.mode === themeMode.light
          ? theme.palette.otherBackgroundLight.main
          : theme.palette.otherElevation.main,
    },
    "& .MuiDataGrid-row .actions-cell": {
      display: "flex",
      justifyContent: " flex-end",
    },
    "& .MuiDataGrid-cell": {
      display: "flex",
      alignItems: "center",
    },
    "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
      outline: "none !important",
    },
    "& .MuiDataGrid-columnHeader:focus": {
      outline: "none !important",
    },
    "& .MuiDataGrid-columnSeparator": {
      display: "none",
    },

    "& .MuiDataGrid-row--borderBottom .MuiDataGrid-columnHeader": {
      borderBottom: "none",
    },
    "& .MuiDataGrid-filler, & .MuiDataGrid-row--borderBottom .MuiDataGrid-filler":
      {
        borderBottom: "none",
      },
    "& .MuiDataGrid-row--borderBottom .MuiDataGrid-scrollbarFiller": {
      borderBottom: "none",
    },

    ".actionButton": {
      opacity: 0,
    },

    ".addModelButton": {
      opacity: 0,
      display: "none",
    },
    "& .MuiDataGrid-row": {
      borderBottom: (theme: Theme) =>
        `1px solid ${theme.palette.otherDivider.main}`,
      "&:hover, &.Mui-hovered": {
        backgroundColor: (theme: Theme) => theme.palette.otherBackground.main,
        ".addModelButton": {
          opacity: 1,
          display: "flex",
        },

        ".actionButton": {
          opacity: 1,
        },

        "& .MuiIconButton-root": {
          "&:hover": {
            backgroundColor: "transparent",
          },
        },
      },

      "& .MuiIconButton-root": {
        cursor: "pointer",
        "& .editIcon:hover .editIconBorder": {
          stroke: (theme: Theme) => theme.palette.primary.main,
        },
        "& .deleteIcon:hover .deleteIconBorder": {
          stroke: (theme: Theme) => theme.palette.error.main,
        },
        "& .deleteIcon.disabled": {
          pointerEvents: "none",
          "& .deleteIconBorder, & .iconPath": {
            stroke: (theme: Theme) => theme.palette.primary.shades?.["30p"],
          },
          "& .deleteIconBorder": {
            stroke: (theme: Theme) => theme.palette.otherDivider.main,
          },
        },
      },
    },
  },
};

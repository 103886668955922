import { createContext } from "react";
import { DTObject, DTProject } from "../../../../../API";

export interface DTObjectDeletionRestrictionData {
  objectData: DTObject;
  alreadyUseInProjects: DTProject[];
}

export interface DTObjectDeletionRestrictionModalContextInterface {
  dtObjectDeletionRestrictionData: DTObjectDeletionRestrictionData;
  setDTObjectDeletionRestrictionData: (
    value: React.SetStateAction<DTObjectDeletionRestrictionData | null>
  ) => void;
  handleOnClose: () => void;
}

export const DTObjectDeletionRestrictionModalContext =
  createContext<DTObjectDeletionRestrictionModalContextInterface>(
    {} as DTObjectDeletionRestrictionModalContextInterface
  );

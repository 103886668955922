import { useMutation, type FetchResult } from "@apollo/client";

import type {
  DeleteServiceInput,
  DeleteServiceMutation,
  DeleteServiceMutationVariables,
} from "../../../API";
import { DELETE_SERVICE } from "../../../common/operations/mutations";
import { useSyncShadow } from "./useSyncShadow";

interface DeleteServiceInterface {
  deleteService: (
    input: DeleteServiceInput
  ) => Promise<FetchResult<DeleteServiceMutation>>;
  data?: DeleteServiceMutation | null;
  loading: boolean;
}

export const useDeleteService = (): DeleteServiceInterface => {
  const { syncShadowsForNode } = useSyncShadow();

  const [removeService, { data, loading }] = useMutation<
    DeleteServiceMutation,
    DeleteServiceMutationVariables
  >(DELETE_SERVICE);

  const deleteService = async (
    input: DeleteServiceInput
  ): Promise<FetchResult<DeleteServiceMutation>> => {
    return await removeService({
      variables: {
        input,
      },
      update(cache): void {
        const id = input.serviceId;
        const normalizedId = cache.identify({ id, __typename: "Service" });

        cache.evict({ id: normalizedId });

        cache.gc();

        syncShadowsForNode(input.nodeId);
      },
      refetchQueries: [
        "GetServices",
        "GetServicesByLocation",
        "GetZonesByDevice",
      ],
    });
  };

  return { deleteService, data, loading };
};

import { FC } from "react";

import { FileRejection, ErrorCode } from "react-dropzone";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";

import RejectedCircleIcon from "../../../common/components/icons/RejectedCircleIcon";

interface IRejectedFilesDialogProps {
  open: boolean;
  rejectedFiles: FileRejection[];
  handleClose: () => void;
}

const RejectedFilesDialog: FC<IRejectedFilesDialogProps> = ({
  open,
  rejectedFiles,
  handleClose,
}) => {
  return (
    <Dialog
      maxWidth="sm"
      open={open}
      onClose={handleClose}
      sx={{
        "& .MuiPaper-root": {
          padding: "32px 40px",
        },
      }}
    >
      <DialogTitle textAlign="center">
        <RejectedCircleIcon />
        <Typography variant="h4" component="h4" fontSize="26px">
          These files can’t be uploaded
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText variant="body2Regular" textAlign="center" pb="1em">
          Make sure the video format and the weight fits requirements and try
          again. Video should be MP4 and have max 100MB
        </DialogContentText>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "12px",
            borderRadius: "8px",
            padding: "1em",
            border: theme =>
              `1px solid ${theme.palette.otherOutlineBorder.main}`,
            backgroundColor: theme => theme.palette.otherBackground.main,
          }}
        >
          {rejectedFiles.map((rejected, index) => {
            let message = "";

            if (rejected.errors[0].code === ErrorCode.FileInvalidType) {
              message = "File type must be .mp4";
            }

            if (rejected.errors[0].code === ErrorCode.FileTooLarge) {
              message = "File is larger than 100MB";
            }

            return (
              <Box
                key={index}
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr",
                  gap: "8px",
                  alignItems: "center",
                }}
              >
                <Typography variant="body2Bold" fontSize="14px">
                  {rejected.file.name}
                </Typography>
                <Typography
                  variant="caption"
                  fontSize="12px"
                  color="otherTextTertiary.main"
                >
                  {Math.round(rejected.file.size / 1000000)} MB
                </Typography>
                <Typography color="error" variant="caption" fontSize="14px">
                  {message}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: "center",
        }}
      >
        <Button variant="outlined" onClick={handleClose} color="inherit">
          Got it
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RejectedFilesDialog;

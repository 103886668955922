import { useContext } from "react";
import { Box, Tab, Tabs, styled } from "@mui/material";

import CreateDTProjectContext, {
  CreateDTProjectContextInterface,
  ProjectFormViewType,
} from "../../context/CreateDTProjectContext/CreateDTProjectContext";

const DTProjectTypeSelector = (): JSX.Element => {
  const { formViewType, handleSetFormViewType } = useContext(
    CreateDTProjectContext
  ) as CreateDTProjectContextInterface;

  const onTypeChange = (
    _event: React.SyntheticEvent,
    type: ProjectFormViewType
  ) => {
    handleSetFormViewType(type);
  };

  return (
    <Wrapper>
      <StyledTabs
        value={formViewType}
        onChange={onTypeChange}
        variant="fullWidth"
      >
        <StyledTab
          label="Use specific location"
          value={ProjectFormViewType.ViaMap}
        />
        <StyledTab label="Upload terrain" value={ProjectFormViewType.ViaFile} />
      </StyledTabs>
    </Wrapper>
  );
};

export default DTProjectTypeSelector;

const Wrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  borderRadius: "4px",
  backgroundColor: theme.palette["base-background"],
  overflow: "hidden",
}));

const StyledTabs = styled(Tabs)(() => ({
  width: "100%",
  minHeight: "unset",

  "& .MuiTabs-indicator": { display: "none" },
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  width: "50%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "8px 12px",

  fontFamily: "Inter",
  fontSize: "14px !important",
  fontStyle: "normal",
  fontWeight: "500",
  lineHeight: "20px !important",

  minHeight: "unset",

  color: theme.palette["navy-blue"],

  "&.Mui-selected": {
    backgroundColor: theme.palette["base-label"],
    color: theme.palette.base.white,
    border: "unset",
  },
}));

import { useTheme } from "@mui/material/styles";

const RetryIcon = (): JSX.Element => {
  const theme = useTheme();

  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="retry-icon-path"
        d="M12.7427 5.36688L12.2713 4.89547C10.1885 2.81268 6.81164 2.81268 4.72885 4.89547C2.64605 6.97827 2.64605 10.3552 4.72885 12.4379C6.81164 14.5207 10.1885 14.5207 12.2713 12.4379C13.4825 11.2267 13.9894 9.57788 13.7919 8.00039M12.7427 5.36688H9.91429M12.7427 5.36688V2.53845"
        stroke={theme.palette.text.secondary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.7427 5.36688L12.2713 4.89547C10.1885 2.81268 6.81164 2.81268 4.72885 4.89547C2.64605 6.97827 2.64605 10.3552 4.72885 12.4379C6.81164 14.5207 10.1885 14.5207 12.2713 12.4379C13.4825 11.2267 13.9894 9.57788 13.7919 8.00039M12.7427 5.36688H9.91429M12.7427 5.36688V2.53845"
        stroke="black"
        strokeOpacity="0.2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RetryIcon;

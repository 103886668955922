import { LineBasicMaterial } from "three";

import { getPointsFromMultilinestring } from "../SceneUtils/MultiLineStringUtils";
import { createGroup, createLineSegments } from "../SceneUtils/CreateMesh";

import {
  FACILITY_SKETCHPLAN_RENDER_ORDER,
  SHAPE_MATERIAL_COLOR,
} from "../Constants";

class SketchplanGeojsonLayer {
  constructor(parentObject, mapTiles) {
    this.parentObject = parentObject;

    this.mapTiles = mapTiles;

    this.geojsonLayerGroup = createGroup(
      "geojsonLayerGroup",
      this.parentObject
    );
  }

  load(multilinestring, elevationDiff) {
    const tilesGroupMatrix = this.mapTiles.tiles.group.matrixWorld.clone();

    for (const mesh of multilinestring) {
      const points = getPointsFromMultilinestring(mesh, elevationDiff);

      const pointsWithAppliedMatrix = points.map(vec => {
        return vec.clone().applyMatrix4(tilesGroupMatrix);
      });

      this.addShape(pointsWithAppliedMatrix);
    }
  }

  addShape(points) {
    const shape = createLineSegments(
      points,
      new LineBasicMaterial({
        color: SHAPE_MATERIAL_COLOR,
        transparent: true,
        depthWrite: false,
        depthTest: false,
      })
    );

    shape.renderOrder = FACILITY_SKETCHPLAN_RENDER_ORDER;

    this.geojsonLayerGroup.add(shape);
  }
}

export default SketchplanGeojsonLayer;

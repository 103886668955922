import { useCallback } from "react";

const useCSVDownloader = () => {
  const downloadCSV = useCallback((csvString: BlobPart, fileName: string) => {
    const blob = new Blob([csvString], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");

    a.style.display = "none";

    a.href = url;

    a.download = fileName;

    document.body.appendChild(a);

    a.click();

    window.URL.revokeObjectURL(url);

    document.body.removeChild(a);
  }, []);

  return { downloadCSV };
};

export default useCSVDownloader;

import { useState } from "react";
import { useNavigate } from "react-router-dom";

import CreateDTProjectContext, {
  ProjectCreatingType,
  ProjectFormViewType,
} from "./CreateDTProjectContext";

import { useCustomerIdGuard } from "../../../../../common/hooks/useCustomerIdGuard";
import { useAmplifyUser } from "../../../../../common/hooks/useAmplifyUser";
import { useUploadFileState } from "../../../../common/hooks/useUploadFileState";
import { useTextInputState } from "../../../../common/hooks/useTextInputState";
import { useCreateDTProject } from "../../hooks/useCreateDTProject";
import { useGetProjectConfig } from "../../hooks/useGetProjectConfig";
import { useUploadDataToS3 } from "../../../../common/hooks/useUploadDataToS3";

import { MapCenterCoordinateInterface } from "../../hooks/useMapLocation";

import {
  geojsonValidation,
  textNameValidation,
} from "../../../../common/validation";

import { getDate, getUserNameFromEmailAddress } from "../../../../common/utils";

import { MeasurementSystemMode } from "../../../../common/enums";

const DEFAULT_PROJECT_NAME_DATA = {
  value: "",
  error: true,
  trackError: false,
  errorMessage: "",
};

interface CreateDTProjectContextProviderInterface {
  children: React.ReactNode;
}

const CreateDTProjectContextProvider = ({
  children,
}: CreateDTProjectContextProviderInterface): JSX.Element => {
  const selectedCustomerId = useCustomerIdGuard();
  const { user } = useAmplifyUser();
  const navigate = useNavigate();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isCreatingLoading, setIsCreatingLoading] = useState(false);

  const { createDTProject } = useCreateDTProject();
  const { getProjectInitialConfig } = useGetProjectConfig();
  const { uploadDataToS3 } = useUploadDataToS3();

  const [formViewType, setFormViewType] = useState(ProjectFormViewType.ViaMap);
  const [creatingType, setCreatingType] = useState(
    ProjectCreatingType.Location
  );
  const geojsonLayerFileState = useUploadFileState(geojsonValidation);
  const kmzLayerFileState = useUploadFileState();

  const projectName = useTextInputState(
    DEFAULT_PROJECT_NAME_DATA,
    textNameValidation
  );

  const [locationCoordinates, setLocationCoordinates] =
    useState<MapCenterCoordinateInterface | null>(null);

  const [measurementSystem, setMeasurementSystem] =
    useState<MeasurementSystemMode>(MeasurementSystemMode.Metric);

  const isCreatingDisabledGeojsonLayer =
    creatingType === ProjectCreatingType.GeojsonLayer &&
    (projectName.state.error ||
      !geojsonLayerFileState.state.file ||
      (geojsonLayerFileState.state.file && geojsonLayerFileState.state.error) ||
      geojsonLayerFileState.loading);

  const isCreatingDisabledKMZLayer =
    creatingType === ProjectCreatingType.KMZLayer &&
    (projectName.state.error ||
      !kmzLayerFileState.state.file ||
      (kmzLayerFileState.state.file && kmzLayerFileState.state.error) ||
      kmzLayerFileState.loading);

  const isCretingDisabledViaLocation =
    creatingType === ProjectCreatingType.Location &&
    (!locationCoordinates || projectName.state.error);

  const isCreatingDisabled =
    isCreatingDisabledGeojsonLayer ||
    isCreatingDisabledKMZLayer ||
    isCretingDisabledViaLocation;

  const openModal = () => {
    setIsOpenModal(true);
  };

  const closeModal = () => {
    if (isCreatingLoading) return;

    setIsOpenModal(false);

    handleSetFormViewType(ProjectFormViewType.ViaMap);

    projectName.resetState();

    geojsonLayerFileState.resetState();

    kmzLayerFileState.resetState();

    setLocationCoordinates(null);
  };

  const handleSetFormViewType = (type: ProjectFormViewType) => {
    geojsonLayerFileState.resetState();

    kmzLayerFileState.resetState();

    setLocationCoordinates(null);

    handleSetCreatingType(
      type === ProjectFormViewType.ViaMap
        ? ProjectCreatingType.Location
        : ProjectCreatingType.GeojsonLayer
    );

    setFormViewType(type);
  };

  const handleSetCreatingType = (type: ProjectCreatingType): void => {
    setCreatingType(type);
  };

  const onProjectNameInputChange = (value: string): void => {
    projectName.onChange(value);
  };

  const onProjectGeojsonLayerFileInputChange = (file: File) => {
    handleSetCreatingType(ProjectCreatingType.GeojsonLayer);

    kmzLayerFileState.resetState();

    geojsonLayerFileState.onLoadFile(file);
  };

  const onProjectKMZLayerFileInputChange = (file: File) => {
    handleSetCreatingType(ProjectCreatingType.KMZLayer);

    geojsonLayerFileState.resetState();

    kmzLayerFileState.onLoadFile(file);
  };

  const onProjectLocationCoordinatesChange = (
    coordinates: MapCenterCoordinateInterface
  ) => {
    setLocationCoordinates(coordinates);
  };

  const onProjectMeasurementSystemChange = (system: MeasurementSystemMode) => {
    setMeasurementSystem(system);
  };

  const openCreatedProject = (projectId: string) => {
    const projectIdPath = projectId.replace("#", "_");

    navigate(`/webgl-project-editor/${projectIdPath}`);
  };

  const onCreateProjectButton = async (): Promise<void> => {
    setIsCreatingLoading(true);

    const initialConfig = await getProjectInitialConfig({
      geojsonLayerFileData: geojsonLayerFileState.state.fileData,
      kmzLayerFile: kmzLayerFileState.state.file,
      locationCoordinates,
      creatingType,
    });
    const input = {
      customerId: selectedCustomerId,
      displayName: projectName.state.value,
      lastModifierUserName:
        user.username && getUserNameFromEmailAddress(user.username),
      lastModificationDate: getDate(),
      withKMZLayer: creatingType === ProjectCreatingType.KMZLayer,
      measurementSystem: measurementSystem,
      usedObjects: [],
    };

    const response = await createDTProject(input);

    if (response.errors) {
      console.error(response.errors);
    }

    if (response.data) {
      await Promise.all([
        uploadDataToS3(
          response.data.addDTProject.configURL,
          JSON.stringify(initialConfig),
          "json"
        ),
        response.data.addDTProject.kmzLayerURL &&
          uploadDataToS3(
            response.data.addDTProject.kmzLayerURL,
            kmzLayerFileState.state.file,
            kmzLayerFileState.state.file?.type || "application/vnd"
          ),
      ]);

      setIsCreatingLoading(false);

      closeModal();

      openCreatedProject(response.data.addDTProject.projectId);
    }
  };

  return (
    <CreateDTProjectContext.Provider
      value={{
        isOpenModal,
        openModal,
        closeModal,
        creatingType,
        handleSetCreatingType,
        formViewType,
        handleSetFormViewType,
        projectNameState: projectName.state,
        onProjectNameInputChange,
        geojsonLayerFileState,
        onProjectGeojsonLayerFileInputChange,
        kmzLayerFileState,
        onProjectKMZLayerFileInputChange,
        onProjectLocationCoordinatesChange,
        measurementSystem,
        onProjectMeasurementSystemChange,
        onCreateProjectButton,
        isCreatingDisabled,
        isCreatingLoading,
      }}
    >
      {children}
    </CreateDTProjectContext.Provider>
  );
};

export default CreateDTProjectContextProvider;

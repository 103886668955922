import { useContext, useEffect, useMemo } from "react";
import { Box, Button, Typography, styled } from "@mui/material";

import ModalWindow from "../../ModalWindow";
import MultiSelectInput from "../../input/MultiSelectInput";

import { useMultiSelectInputState } from "../../../hooks/useMultiSelectInputState";

import {
  DTObjectsLibraryType,
  SearchFilterGroupObjectsContext,
} from "../../../../pages/project-editor/context/SearchFilterGroupObjectsContext/SearchFilterGroupObjectsContext";
import { ProjectObjects3dContext } from "../../../context";

import { DTObject } from "../../../../../API";

import { getUniqueValuesFromArray } from "../../../utils";

interface Filter3dObjectsFormInterface {
  onClose: () => void;
}

const Filter3dObjectsForm = ({
  onClose,
}: Filter3dObjectsFormInterface): JSX.Element => {
  const { queries, handleSetFilterQuery } = useContext(
    SearchFilterGroupObjectsContext
  );
  const { object3dData } = useContext(ProjectObjects3dContext);

  const manufacturerOptions = useMemo(() => {
    if (object3dData) {
      const manufacturerList = object3dData?.map(
        (data: DTObject) => data.objectManufacturerName
      );

      return getUniqueValuesFromArray(manufacturerList);
    }

    return [];
  }, [object3dData]);

  const typeOptions = useMemo(() => {
    if (object3dData) {
      const typeList = object3dData?.map((data: DTObject) => data.objectType);

      return getUniqueValuesFromArray(typeList);
    }

    return [];
  }, [object3dData]);

  const object3dType = useMultiSelectInputState({
    value: [],
    placeholder: "Select...",
    options: [...typeOptions],
    error: true,
    errorMessage: "",
    trackError: false,
  });

  const object3dManufacturer = useMultiSelectInputState({
    value: [],
    placeholder: "Select...",
    options: [...manufacturerOptions],
    error: true,
    errorMessage: "",
    trackError: false,
  });

  const object3dLibrary = useMultiSelectInputState({
    value: [],
    placeholder: "Select...",
    options: [...Object.values(DTObjectsLibraryType)],
    error: true,
    errorMessage: "",
    trackError: false,
  });

  const handleOnResetButton = () => {
    object3dType.resetState();

    object3dManufacturer.resetState();

    object3dLibrary.resetState();
  };

  const handleOnApplyButton = () => {
    handleSetFilterQuery({
      type: [...object3dType.state.value],
      manufacturer: [...object3dManufacturer.state.value],
      library: [...object3dLibrary.state.value],
    });

    onClose();
  };

  useEffect(() => {
    object3dType.onChange(queries.filterQuery.type);

    object3dManufacturer.onChange(queries.filterQuery.manufacturer);

    object3dLibrary.onChange(queries.filterQuery.library);
  }, []);

  return (
    <ModalWindow isOpen={true} onClose={onClose} modalWidth="390px">
      <Wrapper>
        <Title variant="h2">Filter by</Title>
        <InputsWrapper>
          <MultiSelectInput
            label="Object type"
            value={object3dType.state.value}
            options={object3dType.state.options}
            placeholder="Select..."
            handleOnChange={object3dType.onChange}
          />

          <MultiSelectInput
            label="Manufacturer"
            value={object3dManufacturer.state.value}
            options={object3dManufacturer.state.options}
            placeholder="Select..."
            handleOnChange={object3dManufacturer.onChange}
          />

          <MultiSelectInput
            label="Library type"
            value={object3dLibrary.state.value}
            options={object3dLibrary.state.options}
            placeholder="Select..."
            handleOnChange={object3dLibrary.onChange}
          />
        </InputsWrapper>
        <ButtonsWrapper>
          <Button onClick={handleOnResetButton} variant="text" color="blue">
            Reset
          </Button>
          <Button onClick={onClose} variant="secondary" color="blue">
            Cancel
          </Button>
          <Button onClick={handleOnApplyButton} variant="primary" color="blue">
            Apply
          </Button>
        </ButtonsWrapper>
      </Wrapper>
    </ModalWindow>
  );
};

export default Filter3dObjectsForm;

const Wrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "8px",
}));

const Title = styled(Typography)(({ theme }) => ({
  padding: "0 0 6px",
  color: theme.palette["base-label"],
}));

const InputsWrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "10px",
}));

const ButtonsWrapper = styled(Box)(() => ({
  display: "flex",
  justifyContent: "flex-end",
  gap: "8px",
  paddingTop: "16px",
}));

import { CamerasType } from "../../data/CamerasData";

export enum EntityTypeEnum {
  Camera = "camera",
  Object = "object",
  Tower = "tower",
  TowerSide = "towerSide",
  CameraZone = "Camera zone",
}

export enum ModeTypeEnum {
  standard = "standard",
  zoneView = "zoneView",
  fpvMode = "fpvMode",
}

export interface TowerDataInterface {
  id: string;
  name: string;

  EntityType: EntityTypeEnum.Tower;

  sides: SideDataInterface[];

  angle?: number;
  height?: number;
  coordinates?: { latitude: number; longitude: number };
  position?: { x: number; y: number; z: number };
  rotation?: { x: number; y: number; z: number };
  initialQuaternion?: number[];

  pathKey: string;
  assetsList: { name: string; path: string }[];
}

export interface SideDataInterface {
  index: string;
  entityName: string;
  EntityType: EntityTypeEnum.TowerSide;
  angleInRad: number;
}

export interface ObjectDataInterface {
  id: string;
  name: string;

  EntityType: EntityTypeEnum.Object;

  angle?: number;
  coordinates?: { latitude: number; longitude: number };
  position?: { x: number; y: number; z: number };
  rotation?: { x: number; y: number; z: number };
  initialQuaternion?: number[];

  modelAssets: ModelAssetsInterface;
}

export interface CameraDataInterface {
  id: string;
  name: string;
  towerId: string;
  sideIndex: string;

  EntityType: EntityTypeEnum.Camera;
  cameraType: CamerasType;

  fov: number;
  height: number;
  relativeHorizontalAngle: number;
  horizontalAngle: number;
  verticalAngle: number;
  zoom: number;

  modelAssets: ModelAssetsInterface;
}

export interface CameraHelpersInterface extends CameraDataInterface {
  isVisibleFieldOfView: boolean;
}

export interface ZoneDataInterface {
  id: string;
  name: string;
  cameraId: string;
  towerId: string;

  viewType: string;

  isSaved: boolean;

  pan: number;
  tilt: number;
  relativePan: number;
}

export interface ModelAssetsInterface {
  assetId: string;
  model3d: Model3dDataInterface;
  textures?: ModelTextureDataInterface[];
}

export interface Model3dDataInterface {
  path: string;
}

export interface ModelTextureDataInterface {
  path: string;
  mapName: string;
}

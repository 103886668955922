import { FC, Fragment, SyntheticEvent, useMemo } from "react";
import { FieldErrors } from "react-hook-form";

import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

import { useDialog } from "../../../../../../common/components/dialog/useDialog";
import withCustomPaper from "../../../../../../common/components/select/CustomPaperActionButton";
import { AutocompleteOptionType } from "../../../../../../common/models/autocomplete";
import { useGetPaginatedPlateCategories } from "../../hooks/useGetPaginatedPlateCategories";
import { IPlateNumberForm } from "../../variables/plateNumberData";
import CreateCategoryDialog from "./CreateCategoryDialog";

interface IPlatesCategoryDropdownProps {
  selectedValue: AutocompleteOptionType;
  setSelectedValue: (value: AutocompleteOptionType) => void;
  errors: FieldErrors<IPlateNumberForm>;
}
const PlatesCategoryDropdown: FC<IPlatesCategoryDropdownProps> = ({
  selectedValue,
  setSelectedValue,
  errors,
}) => {
  const { categories, loading } = useGetPaginatedPlateCategories();

  const {
    open: openCreateCategoryDialog,
    handleDialogOpen,
    handleDialogClose,
  } = useDialog();

  const handleOnChange = async (
    _event: SyntheticEvent<Element, Event>,
    optionValue: AutocompleteOptionType
  ): Promise<void> => {
    setSelectedValue(optionValue);
  };

  const options = useMemo<
    AutocompleteOptionType[]
  >((): AutocompleteOptionType[] => {
    const filteredCategories = categories?.items?.filter(category => {
      if (category?.categoryName === "UNKNOWN") return false;

      return true;
    });

    const categoriesOptions =
      filteredCategories?.map((category): AutocompleteOptionType => {
        if (
          selectedValue?.value &&
          !selectedValue?.title &&
          selectedValue?.value === category?.id
        ) {
          selectedValue.title = category.categoryName;
        }

        return {
          title: category?.categoryName ?? "",
          value: category?.id ?? "",
        };
      }) ?? [];

    return categoriesOptions;
  }, [categories]);

  const customPaper = withCustomPaper({
    text: "New category",
    onClick: handleDialogOpen,
  });

  return (
    <>
      <Autocomplete
        size="medium"
        fullWidth
        loading={loading}
        options={options}
        getOptionLabel={(option: AutocompleteOptionType): string =>
          option?.title ?? ""
        }
        isOptionEqualToValue={(option, optionValue): boolean =>
          option?.value === "" ? true : option?.value === optionValue?.value
        }
        value={selectedValue}
        onChange={handleOnChange}
        popupIcon={<ExpandMoreOutlinedIcon />}
        PaperComponent={customPaper}
        renderOption={(props, option): JSX.Element => {
          return (
            <Fragment key={option?.value}>
              {option?.value && (
                <Box {...props} component="li">
                  {option?.title}
                </Box>
              )}
            </Fragment>
          );
        }}
        ListboxProps={{
          style: {
            maxHeight: "362px", // or 363px
          },
        }}
        renderInput={(params): JSX.Element => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <TextField
              error={!!errors.category}
              sx={{ height: "60px" }}
              helperText={errors?.category?.message ?? ""}
              {...params}
              variant="outlined"
              label="Select category"
            />
          </Box>
        )}
      />

      <CreateCategoryDialog
        isOpened={openCreateCategoryDialog}
        closeDialog={handleDialogClose}
      />
    </>
  );
};

export default PlatesCategoryDropdown;

import { Typography } from "@mui/material";

type KeyFrameMessageProps = {
  message: string;
};

const KeyFrameMessage: React.FC<KeyFrameMessageProps> = ({
  message,
}): JSX.Element => {
  return (
    <Typography
      component="div"
      variant="body2Bold"
      textAlign="center"
      color="otherTextTertiary.main"
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textWrap: "wrap",
      }}
    >
      {message}
    </Typography>
  );
};

export default KeyFrameMessage;

import { ReactNode } from "react";

import Paper from "@mui/material/Paper";
import { SxProps, Theme } from "@mui/material/styles";

const SimplePaperWrapper = ({
  children,
  sx,
}: {
  children: ReactNode;
  sx?: SxProps<Theme>;
}): JSX.Element => {
  return (
    <Paper
      elevation={0}
      sx={{
        border: theme => `1px solid ${theme.palette.otherOutlineBorder.main}`,
        borderRadius: "12px",
        ...sx,
      }}
    >
      {children}
    </Paper>
  );
};

export default SimplePaperWrapper;

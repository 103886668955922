import NoMediaIcon from "../../../../common/components/icons/NoMediaIcon";
import MediaErrorBoxStyled from "./layout/MediaErrorBoxStyled";
import MediaErrorSubTitleStyled from "./layout/MediaErrorSubTitleStyled";
import MediaErrorTitleStyled from "./layout/MediaErrorTitleStyled";

const NoMedia = () => {
  return (
    <MediaErrorBoxStyled>
      <NoMediaIcon />
      <MediaErrorTitleStyled>No media is found</MediaErrorTitleStyled>
      <MediaErrorSubTitleStyled>
        The media in this URL is missing or corrupted
      </MediaErrorSubTitleStyled>
    </MediaErrorBoxStyled>
  );
};

export default NoMedia;

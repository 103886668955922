import uuid from "react-uuid";
import { Box, Typography, TypographyProps, styled } from "@mui/material";

import { HintInterface } from "./components/InputHint";
import InputContainer from "./components/InputContainer";

interface ReadOnlyFieldPropsInterface {
  label?: string;
  labelFontVariant?: TypographyProps["variant"];
  value?: string;
  type?: string;
  hint?: HintInterface;
  endAdornment?: JSX.Element;
  startAdornment?: JSX.Element;
  viewType?: "row" | "column";
  labelWidth?: string;
  inputWidth?: string;
}

const ReadOnlyField = ({
  label,
  labelFontVariant,
  value,
  hint,
  endAdornment,
  startAdornment,
  viewType = "column",
  labelWidth = "100%",
  inputWidth = "100%",
}: ReadOnlyFieldPropsInterface): JSX.Element => {
  const id = label || uuid();

  return (
    <InputContainer
      id={id}
      label={label}
      labelFontVariant={labelFontVariant}
      hint={hint}
      viewType={viewType}
      labelWidth={labelWidth}
      inputWidth={inputWidth}
    >
      <Wrapper>
        {startAdornment && startAdornment}
        <InputValue variant="body12Regular" id={`${label}-input`}>
          {value}
        </InputValue>
        {endAdornment && endAdornment}
      </Wrapper>
    </InputContainer>
  );
};

export default ReadOnlyField;

const Wrapper = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  width: "100%",
  padding: "6px",
  borderRadius: "4px",
  gap: "4px",
}));

const InputValue = styled(Typography)(({ theme }) => ({
  color: theme.palette["base-label"],
}));

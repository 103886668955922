import { useContext, useMemo } from "react";
import { Button } from "@mui/material";

import { FilterIcon } from "../../../assets/icons/svgAssets";

import { ProjectContext } from "../../../context";
import { SearchFilterGroupObjectsContext } from "../../../../pages/project-editor/context";
import { ModeTypeEnum } from "../../../context/ProjectContext/ProjectEntitesTypes";

interface Filter3dObjectsButtonInterface {
  onClick: () => void;
}

const Filter3dObjectsButton = ({
  onClick,
}: Filter3dObjectsButtonInterface): JSX.Element => {
  const { queries } = useContext(SearchFilterGroupObjectsContext);
  const { sceneStarted, isLoading, mode } = useContext(ProjectContext);

  const amountOfAppliedFilters = useMemo(
    () =>
      Object.values(queries.filterQuery).reduce(
        (sum, item) => sum + item.length,
        0
      ),
    [queries.filterQuery]
  );

  return (
    <Button
      variant="secondary"
      color="blue"
      onClick={onClick}
      disabled={!sceneStarted || isLoading || mode !== ModeTypeEnum.standard}
      startIcon={<FilterIcon />}
    >
      Filter{amountOfAppliedFilters > 0 ? ` (${amountOfAppliedFilters})` : ""}
    </Button>
  );
};

export default Filter3dObjectsButton;

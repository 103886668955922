import { useState, useEffect } from "react";

import { useSubscription } from "@apollo/client";

import { LISTEN_ADD_ZONE } from "../../../../common/operations/subscriptions";
import { ListenToAddZoneSubscription } from "../../../../API";
import { successNotification } from "../../../../common/variables/notification";
import { useCustomerIdGuard } from "../../../../common/hooks/useCustomerIdGuard";

export const useListenToAddZone = () => {
  const [isSubscribed, setIsSubscribed] = useState(false);

  const customerId = useCustomerIdGuard();

  useEffect(() => {
    if (customerId) {
      setIsSubscribed(true);
    }
  }, [customerId]);

  const { data, loading, error } = useSubscription<ListenToAddZoneSubscription>(
    LISTEN_ADD_ZONE,
    {
      fetchPolicy: "no-cache",
      variables: {
        customerId,
      },
      shouldResubscribe: true,
      skip: !isSubscribed,
      onSubscriptionData: ({ client, subscriptionData }) => {
        if (subscriptionData?.data?.listenToAddZone) {
          const newZone = subscriptionData.data.listenToAddZone;

          try {
            const cacheData = client.cache.extract();

            const existingZones = Object.values(cacheData).filter(
              entry => entry.__typename === "Zone"
            );

            const updatedZones = [...existingZones, newZone];

            const updatedData = {
              listenToAddZone: updatedZones,
            };

            client.cache.writeQuery({
              query: LISTEN_ADD_ZONE,
              data: updatedData,
              variables: { id: customerId },
            });

            successNotification(`Zone ${newZone.name} is added.`);
          } catch (error) {
            console.error("Error updating cache:", error);
          }
        }
      },
    }
  );

  if (error) {
    console.error("Subscription error:", error);
  }

  return { data, loading, error } as const;
};

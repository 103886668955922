import { Button, Paper, Stack, Typography } from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { useNavigate } from "react-router-dom";

const NoClientsAdded = (): JSX.Element => {
  const navigate = useNavigate();

  return (
    <Paper
      variant="outlined"
      square={false}
      elevation={0}
      sx={{
        width: "100%",
        height: "100%",
        padding: "1.5em",
      }}
    >
      <Stack
        spacing={2}
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ height: "100%", textAlign: "center" }}
      >
        <Typography
          variant="body1Bold"
          sx={{ fontSize: "1.1rem", "& a": { color: "primary.dark" } }}
        >
          There is no clients added
        </Typography>
        <Typography
          variant="caption"
          sx={{ fontSize: "1rem", "& a": { color: "primary.dark" } }}
        >
          Add client to upload videos to them
        </Typography>

        <Button
          variant="contained"
          size="small"
          startIcon={<AddOutlinedIcon />}
          onClick={(): void => navigate("/leak-finder/client-uploads/create")}
        >
          New client
        </Button>
      </Stack>
    </Paper>
  );
};

export default NoClientsAdded;

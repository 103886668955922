import JSZip from "jszip";

export const readFileAsDataURL = (
  file: File
): Promise<ArrayBuffer | string | null> => {
  return new Promise((resolve, rejects) => {
    const reader = new FileReader();

    reader.onloadend = () => resolve(reader.result);

    reader.onerror = erorr => rejects(erorr);

    reader.readAsDataURL(file);
  });
};

export const readFileAsArrayBuffer = (
  file: File,
  withSlice = true,
  start = 0,
  end = 2
): Promise<ArrayBuffer | string | null> => {
  return new Promise((resolve, rejects) => {
    const reader = new FileReader();

    reader.onloadend = () => resolve(reader.result);

    reader.onerror = erorr => rejects(erorr);

    if (withSlice) {
      reader.readAsArrayBuffer(file.slice(start, end));
    } else {
      reader.readAsArrayBuffer(file);
    }
  });
};

export const readFileFromPath = async (
  filePath: string
): Promise<ArrayBuffer> => {
  const response = await fetch(filePath);
  const arrayBuffer = await response.arrayBuffer();

  return arrayBuffer;
};

export const readZipFile = async (file: File): Promise<JSZip> => {
  const zip = new JSZip();

  return await zip.loadAsync(file);
};

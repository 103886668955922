import React, { forwardRef } from "react";
import uuid from "react-uuid";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { FormHelperText, useTheme } from "@mui/material";

import { VideoMethod } from "../../API";

type RadioButtonsGroupProps = {
  value?: string;
  radioGroupValues: VideoMethod[];
  radioGroupLabels: string[];
  onSetHandler: (choice: VideoMethod) => void;
  error?: boolean;
  helperText?: string;
};

const RadioButtonsGroup = forwardRef<HTMLDivElement, RadioButtonsGroupProps>(
  (
    {
      value,
      onSetHandler,
      radioGroupValues,
      radioGroupLabels,
      error,
      helperText,
    },
    ref
  ) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      event.preventDefault();

      onSetHandler(event.target.value as VideoMethod);
    };

    const theme = useTheme();

    return (
      <FormControl ref={ref}>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={value}
          onChange={handleChange}
        >
          {radioGroupLabels.map((radioLabel, index) => (
            <FormControlLabel
              key={uuid()}
              value={radioGroupValues[index]}
              control={
                <Radio
                  sx={{
                    "& .MuiSvgIcon-root": {
                      color: error ? theme.palette.error.main : "",
                    },
                  }}
                />
              }
              label={radioLabel}
            />
          ))}
        </RadioGroup>

        {helperText && (
          <FormHelperText sx={{ color: theme.palette.error.main }}>
            {helperText}
          </FormHelperText>
        )}
      </FormControl>
    );
  }
);

export default RadioButtonsGroup;

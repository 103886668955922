import { useContext, useEffect, useState } from "react";
import { Box, Button, styled } from "@mui/material";

import FieldOfZonesViewControlPanel from "./FieldOfZonesViewControlPanel";

import { CamerasType } from "../../../data/CamerasData";
import {
  CameraDataInterface,
  ZoneDataInterface,
} from "../../../context/ProjectContext/ProjectEntitesTypes";

import { ChevronsUpDown } from "../../../assets/icons/svgAssets";

import { ProjectContext } from "../../../context";

interface zoneListItemDataInterface {
  name: string;
  id: string;
  isVisible: boolean;
}

interface zoneGroupDataInterface {
  name: string;
  id: string;
  zones: zoneListItemDataInterface[];
}

const FieldOfZonesViewButton = (): JSX.Element => {
  const { threeScene, sceneStarted, isLoading, cameras, zoneList } =
    useContext(ProjectContext);

  const [isShowPanel, setIsShowPanel] = useState<boolean>(false);
  const [zoneHelpersList, setZoneHelpersList] = useState<
    zoneGroupDataInterface[] | []
  >([]);

  const handleOnFieldOfCamerasViewClick = () => {
    setIsShowPanel(!isShowPanel);
  };

  const handleOnShowHideAllClick = (isVisible: boolean) => {
    setZoneHelpersList(prev => {
      return prev.map(zoneGroup => {
        const newZones = zoneGroup.zones.map(zone => {
          return {
            ...zone,
            isVisible: isVisible,
          };
        });

        return {
          ...zoneGroup,
          zones: newZones,
        };
      });
    });
  };

  const handleOnItemClick = (idList: string[]) => {
    setZoneHelpersList(prev =>
      prev.map(data => {
        const zones = data.zones.map(zone => {
          if (idList.includes(zone.id)) {
            return {
              ...zone,
              isVisible: !zone.isVisible,
            };
          } else {
            return zone;
          }
        });

        return {
          ...data,
          zones: zones,
        };
      })
    );
  };

  useEffect(() => {
    const list = cameras.map((camera: CameraDataInterface) => {
      const zones: zoneListItemDataInterface[] = [];
      const zone360 =
        camera.cameraType.includes(CamerasType.MinervaCamera) ||
        camera.cameraType.includes(CamerasType.MinervaGasCamera);

      if (zone360) {
        zones.push({
          name: "Minerva 360",
          id: camera.id,
          isVisible: true,
        });
      }

      const singleZoneList = zoneList.filter(
        (el: ZoneDataInterface) => el.cameraId === camera.id
      );

      singleZoneList.forEach((zone: ZoneDataInterface) => {
        zones.push({
          name: zone.name,
          id: zone.id,
          isVisible: true,
        });
      });

      return {
        cameraName: camera.name,
        id: camera.id,
        zones: zones,
      };
    });

    setZoneHelpersList(list);
  }, []);

  useEffect(() => {
    const visibleZoneIdsList = zoneHelpersList
      .map(zoneGroup => {
        return zoneGroup.zones
          .filter(zone => zone.isVisible)
          .map(zone => `zone-${zone.id}`);
      })
      .flat();

    threeScene.sceneFacility3dObjects.camerasZones.switchZoneVisibility(
      visibleZoneIdsList
    );
  }, [zoneHelpersList]);

  return (
    <Wrapper>
      <Button
        variant={isShowPanel ? "primary" : "outlined"}
        color="blue"
        disabled={!zoneHelpersList.length || !sceneStarted || isLoading}
        onClick={handleOnFieldOfCamerasViewClick}
        startIcon={<ChevronsUpDown size={16} />}
      >
        Zones view
      </Button>
      {isShowPanel && zoneHelpersList.length && (
        <FieldOfZonesViewControlPanel
          zoneList={zoneHelpersList}
          onButtonToggleClick={handleOnShowHideAllClick}
          onItemclick={handleOnItemClick}
        />
      )}
    </Wrapper>
  );
};

export default FieldOfZonesViewButton;

const Wrapper = styled(Box)(() => ({
  position: "relative",
}));

import { useTheme } from "@mui/material/styles";

const ResetDrawPolygonIcon = (): JSX.Element => {
  const theme = useTheme();

  return (
    <svg
      width="14"
      height="11"
      viewBox="0 0 14 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.66699 3.16675H9.00033C10.8413 3.16675 12.3337 4.65913 12.3337 6.50008C12.3337 8.34103 10.8413 9.83341 9.00033 9.83341H4.33366M1.66699 3.16675L3.66699 1.16675M1.66699 3.16675L3.66699 5.16675"
        stroke={theme.palette.text.primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ResetDrawPolygonIcon;

import { memo, useEffect, useState } from "react";

import Box from "@mui/material/Box";
import CardMedia from "@mui/material/CardMedia";
import { SxProps, Theme } from "@mui/material/styles";

import { S3Helper } from "../../utils/s3helper";
import CircularLoading from "../progress/CircularLoading";
import { IS3MediaFile } from "./IS3MediaFileProps";
import KeyFrameMessage from "./NoKeyFrameMessage";

interface S3ImageProps extends IS3MediaFile {
  isKeyframeLoaded?: boolean;
  setKeyframeLoaded?: (value: boolean) => void;
  width?: number;
  deviceStatus?: boolean;
  height?: number;
  sx?: SxProps<Theme>;
  onClick?: () => void;
}

const S3ImageComponent = ({
  s3Key,
  width,
  height,
  sx = [],
  isKeyframeLoaded,
  deviceStatus,
  setKeyframeLoaded,
  onClick,
}: S3ImageProps): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState("");

  const defaultSx = {
    objectFit: "unset",
    aspectRatio: `${width && height ? `auto ${width}/${height}` : "auto"}`,
    width: width ? width + "px" : "fit-content",
    height: height ? height + "px" : "100%",
  };

  useEffect((): void => {
    getImage();
  }, [s3Key, S3Helper.initialized]);

  const getImage = async (): Promise<void> => {
    if (!s3Key) {
      setImageUrl("");

      setLoading(false);

      if (setKeyframeLoaded) {
        setKeyframeLoaded(false);
      }

      return;
    }

    setLoading(true);

    let mediaUrl = s3Key;

    try {
      mediaUrl = JSON.parse(mediaUrl);
    } catch (error) {
      console.log(`s3Key is already parsed`);
    }

    if (!S3Helper.initialized) {
      await S3Helper.setAWSConfig();
    }

    const response = await S3Helper.getObject(mediaUrl);

    setImageUrl(response);

    setLoading(false);

    if (setKeyframeLoaded && response !== "") {
      setKeyframeLoaded(true);
    }
  };

  return (
    <Box
      sx={{
        width: width ?? "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "8px",
        flexDirection: "column",
        gap: "1em",
      }}
    >
      {loading && !isKeyframeLoaded && <CircularLoading />}

      {!isKeyframeLoaded && !loading && (
        <>
          {!imageUrl && deviceStatus === undefined && (
            <KeyFrameMessage message="Please wait for the keyframe to retrieve" />
          )}
          {deviceStatus === false && !imageUrl && (
            <KeyFrameMessage message="The device is offline. It can take some time" />
          )}
        </>
      )}

      {!loading && imageUrl && (
        <CardMedia
          onClick={onClick}
          component="img"
          src={imageUrl}
          height={height}
          sx={[defaultSx, ...(Array.isArray(sx) ? sx : [sx])]}
        />
      )}
    </Box>
  );
};

const S3Image = memo(
  S3ImageComponent,
  (prevProps, nextProps): boolean => prevProps.s3Key === nextProps.s3Key
);

export default S3Image;

import { useContext, useEffect } from "react";
import { Button } from "@mui/material";

import { FPVModeIcon } from "../../assets/icons/svgAssets";

import { ProjectContext } from "../../context";

import { ModeTypeEnum } from "../../context/ProjectContext/ProjectEntitesTypes";

const FPVmodeButton = () => {
  const {
    threeScene,
    sceneStarted,
    isLoading,
    handleSetActiveObjectId,
    secondaryCameraId,
    mode,
    setMode,
  } = useContext(ProjectContext);

  const disabled = !!secondaryCameraId || !sceneStarted || isLoading;

  const handleOnClick = () => {
    handleSetActiveObjectId(null);

    setMode(
      mode === ModeTypeEnum.fpvMode
        ? ModeTypeEnum.standard
        : ModeTypeEnum.fpvMode
    );
  };

  const startFPVMode = () => {
    if (threeScene && threeScene.sceneControls.orbitControl) {
      threeScene.sceneControls.orbitControl.setCameraPosition(5, 45, 75, false);

      threeScene.cameraHelpers.toggleEnableState([]);
    }
  };

  const stopFPVMode = () => {
    if (threeScene) {
      threeScene.sceneControls.orbitControl.setCameraPosition(300, 25, 75);
    }
  };

  useEffect(() => {
    mode === ModeTypeEnum.fpvMode ? startFPVMode() : stopFPVMode();
  }, [mode]);

  return (
    <Button
      variant={mode === ModeTypeEnum.fpvMode ? "primary" : "outlined"}
      color="blue"
      disabled={disabled}
      onClick={handleOnClick}
      startIcon={<FPVModeIcon />}
    >
      FPV mode
    </Button>
  );
};

export default FPVmodeButton;

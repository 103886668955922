import { memo, useContext, useMemo } from "react";
import { Box, styled } from "@mui/material";
import { SwiperSlide } from "swiper/react";

import ObjectCard from "./ObjectCard";
import SwiperSlider from "../../SwiperSlider";

import { ProjectObjects3dContext } from "../../../context";
import { SearchFilterGroupObjectsContext } from "../../../../pages/project-editor/context";

import { DTObject } from "../../../../../API";

const ObjectsPanel = (): JSX.Element => {
  const { queries, applyAllQueries } = useContext(
    SearchFilterGroupObjectsContext
  );

  const { object3dData, loading: object3dLoading } = useContext(
    ProjectObjects3dContext
  );

  const data = useMemo(() => {
    if (object3dData?.length) {
      return applyAllQueries(object3dData);
    }

    return [];
  }, [object3dData, queries]);

  return (
    <Wrapper>
      {!object3dLoading && !!data?.length && (
        <SwiperSlider
          initialSlide={0}
          prevButtonPosition={4}
          nextButtonPosition={4}
          direction="horizontal"
          spaceBetween={8}
          slidesPerView={"auto"}
        >
          {data.map((obj: DTObject) => (
            <SwiperSlide key={obj.objectId}>
              <ObjectCard objectData={obj} />
            </SwiperSlide>
          ))}
        </SwiperSlider>
      )}
    </Wrapper>
  );
};

export default memo(ObjectsPanel);

const Wrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",

  width: "100%",
  height: "125px",

  "& .swiper-slide": {
    boxSizing: "border-box",
    width: "100px",
  },

  "& .swiper-slider": {
    boxSizing: "border-box",
    width: "100%",
    height: "115px",
    padding: "0 2px 10px 0",
  },

  "& .swiper-scrollbar": {
    left: "0",
    bottom: "0",
    background: "transparent",
    width: "100%",
    height: "7px",
  },

  "& .swiper-scrollbar-drag": {
    background: theme.palette["blue-scrollbar"],
    borderRadius: "30px",
    cursor: "pointer",
  },
}));

import { ApolloError, useMutation } from "@apollo/client";

import {
  DeleteClientInput,
  DeleteClientMutation,
  DeleteClientMutationVariables,
} from "../../API";
import { DELETE_CLIENT } from "../../common/operations/mutations";
import {
  errorNotification,
  successNotification,
} from "../../common/variables/notification";

export const useDeleteClient = () => {
  const [removeClient, { data, loading }] = useMutation<
    DeleteClientMutation,
    DeleteClientMutationVariables
  >(DELETE_CLIENT);

  const deleteClient = async (input: DeleteClientInput) => {
    await removeClient({
      variables: {
        input,
      },
      onCompleted: response => {
        const { deleteClient } = response;

        if (deleteClient) {
          successNotification(`${deleteClient.clientName} is deleted`);
        }
      },
      onError: (error: ApolloError): void => {
        errorNotification(error.message);

        console.error(error.message);
      },
      update(cache) {
        const normalizedId = cache.identify({
          id: input.clientId,
          __typename: "Client",
        });

        cache.evict({ id: normalizedId });

        cache.gc();
      },
    });
  };

  return { deleteClient, data, loading };
};

import { FC, ReactNode } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";

type WarningBoxProps = {
  children: ReactNode;
};

const WarningBox: FC<WarningBoxProps> = ({ children }): JSX.Element => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        border: "1px solid",
        borderColor: theme.palette.warning.shades?.["12p"],
        backgroundColor: theme.palette.warning.shades?.["4p"],
        lineHight: "1.5em",
        padding: "8px 12px",
        borderRadius: "10px",
        width: "fit-content",
      }}
    >
      <Typography
        variant="body2Regular"
        sx={{
          color: theme.palette.warning.main,
        }}
      >
        {children}
      </Typography>
    </Box>
  );
};

export default WarningBox;

import {
  PlaneGeometry,
  BufferGeometry,
  LineSegments,
  BufferAttribute,
  Float32BufferAttribute,
  Mesh,
  Group,
} from "three";
import Delaunator from "delaunator";

export const createLineSegments = (points, material) => {
  const geometry = new BufferGeometry();

  geometry.setFromPoints(points);

  geometry.computeVertexNormals();

  return new LineSegments(geometry, material);
};

export const createMeshByPoints = (points, material) => {
  const geometry = new BufferGeometry().setFromPoints(points);

  const indices = Delaunator.from(points.map(v => [v.x, v.z])).triangles.map(
    triangle => triangle
  );
  const uvs = new Float32Array([0, 0, 1, 0, 1, 1, 0, 1]);

  geometry.setIndex(new BufferAttribute(indices, 1));

  geometry.setAttribute("uv", new Float32BufferAttribute(uvs, 2));

  geometry.computeVertexNormals();

  return new Mesh(geometry, material);
};

export const createPlaneFromFlatArray = (
  points,
  numRows,
  numCols,
  material
) => {
  const geometry = new PlaneGeometry(
    numCols,
    numRows,
    numCols - 1,
    numRows - 1
  );

  const vertices = geometry.attributes.position.array;

  for (let i = 0; i < numRows; i++) {
    for (let j = 0; j < numCols; j++) {
      const index = (i * numCols + j) * 3;
      const point = points[i * numCols + j];

      vertices[index] = point.x;

      vertices[index + 1] = point.y;

      vertices[index + 2] = point.z;
    }
  }

  const plane = new Mesh(geometry, material);

  return plane;
};

export const createGroup = (name, parent) => {
  const group = new Group();

  group.name = name;

  parent && parent.add(group);

  return group;
};

import { useState } from "react";

import { ConfirmationModalContext } from "./ConfirmationModalContext";

import ConfirmationModal, {
  ConfirmationModalInterface,
} from "../../components/ConfirmationModal";

export const ConfirmationModalContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const [confirmationModalData, setConfirmationModalData] =
    useState<ConfirmationModalInterface | null>(null);

  return (
    <ConfirmationModalContext.Provider
      value={{
        confirmationModalData,
        setConfirmationModalData,
      }}
    >
      {children}

      {confirmationModalData && (
        <ConfirmationModal
          icon={confirmationModalData.icon}
          title={confirmationModalData.title}
          message={confirmationModalData.message}
          actionOnClose={confirmationModalData.actionOnClose}
          actionButton={{
            color: confirmationModalData.actionButton.color,
            title: confirmationModalData.actionButton.title,
            action: confirmationModalData.actionButton.action,
          }}
        />
      )}
    </ConfirmationModalContext.Provider>
  );
};

import TextInput from "../../../../common/components/input/TextInput";
import { TextStateInterface } from "../../../../common/hooks/useTextInputState";

interface ProjectNameInputProps {
  projectNameState: TextStateInterface;
  onProjectNameInputChange: (value: string) => void;
}

const DTProjectNameInput = ({
  projectNameState,
  onProjectNameInputChange,
}: ProjectNameInputProps): JSX.Element => {
  const invalid = projectNameState.trackError && projectNameState.error;

  return (
    <TextInput
      label="Project name"
      value={projectNameState.value}
      required={true}
      isError={invalid}
      hint={{
        type: !invalid ? "default" : "error",
        text: !invalid
          ? "From 3 to 30 characters"
          : projectNameState.errorMessage,
      }}
      handleOnChange={onProjectNameInputChange}
    />
  );
};

export default DTProjectNameInput;

import Decimal from "decimal.js";

interface ValidationResponseInterface {
  error: boolean;
  errorMessage: string;
}

const checkIfNumberIsNotOutOfRange = (
  value: number,
  min: number,
  max: number
) => {
  return min <= value && value <= max;
};

const checkIfValueMultipleOfStep = (value: number, step: number) => {
  return new Decimal(value).dividedBy(step).isInt();
};

const numberValidation = (
  value: string,
  min: number,
  max: number,
  step?: number
): ValidationResponseInterface => {
  const valueAsNumber = parseFloat(value);

  if (isNaN(valueAsNumber)) {
    return { error: true, errorMessage: "Value must be a number" };
  }

  if (!checkIfNumberIsNotOutOfRange(valueAsNumber, min, max)) {
    return {
      error: true,
      errorMessage: `Value is out of the range ${min} - ${max}`,
    };
  }

  if (step !== undefined && !checkIfValueMultipleOfStep(valueAsNumber, step)) {
    return {
      error: true,
      errorMessage: `Value is not multiple of ${step}`,
    };
  }

  return { error: false, errorMessage: "" };
};

export const numberValidationOnlyRangeCheck = (
  value: string,
  min: number,
  max: number,
  _step: number
) => {
  return numberValidation(value, min, max);
};

export const numberValidationRangeAndMultipleCheck = (
  value: string,
  min: number,
  max: number,
  step: number
) => {
  return numberValidation(value, min, max, step);
};

import { useCallback, useContext } from "react";
import { Button } from "@mui/material";

import { ProjectContext } from "../../../context";

import { CamerasType } from "../../../data/CamerasData";
import { SecondaryViewTypes } from "../../../data/SecondaryViewData";

import { CameraDataInterface } from "../../../context/ProjectContext/ProjectEntitesTypes";

const AddZoneButton = (): JSX.Element => {
  const { addZone, activeObjectsListId, activeCameraId, cameras, isLoading } =
    useContext(ProjectContext);

  const handleOnClick = useCallback(() => {
    const activeCameraData = cameras.find(
      (camera: CameraDataInterface) => camera.id === activeCameraId
    );

    const viewType =
      activeCameraData.cameraType === CamerasType.MinervaGasCamera
        ? SecondaryViewTypes.viewGas
        : SecondaryViewTypes.viewRGB;

    const data = {
      towerId: activeObjectsListId[0],
      cameraId: activeCameraId,
      tilt: activeCameraData?.verticalAngle,
      pan: activeCameraData?.horizontalAngle,
      relativePan: activeCameraData?.relativeHorizontalAngle,
      viewType: viewType,
    };

    addZone(data);
  }, [activeObjectsListId, activeCameraId, cameras]);

  const disabled = isLoading || activeObjectsListId.length !== 1;

  return (
    <Button
      variant="primary"
      color="blue"
      disabled={disabled}
      onClick={handleOnClick}
    >
      + Add Zone
    </Button>
  );
};

export default AddZoneButton;

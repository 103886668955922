import type { ComponentsOverrides, Theme } from "@mui/material";

export const MuiInputLabelLightStyleOverrides: ComponentsOverrides<Theme>["MuiInputLabel"] =
  {
    root: ({ theme }) => {
      return {
        color: theme.palette.otherTextTertiary.main,
        "&.Mui-focused": {
          color: theme.palette.primary.main,
        },
      };
    },
  };

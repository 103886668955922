import { useContext, useState } from "react";
import { Button } from "@mui/material";

import { ProjectContext } from "../../../context";

import AddTowerModal from "./AddTowerModal";
import { ModeTypeEnum } from "../../../context/ProjectContext/ProjectEntitesTypes";

const AddTowerButton = (): JSX.Element => {
  const { sceneStarted, isLoading, mode } = useContext(ProjectContext);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleOnClick = (): void => {
    setIsModalOpen(!isModalOpen);
  };

  const handlOnCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Button
        variant="outlined"
        color="blue"
        onClick={handleOnClick}
        disabled={!sceneStarted || isLoading || mode !== ModeTypeEnum.standard}
      >
        + Add tower
      </Button>
      {isModalOpen && <AddTowerModal onClose={handlOnCloseModal} />}
    </>
  );
};

export default AddTowerButton;

import { ChangeEvent, useEffect, useState } from "react";
import {
  Box,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  styled,
} from "@mui/material";

import { MeasurementSystemMode } from "../../../../common/enums";

interface MeasurementSelectorProps {
  defaultValue: MeasurementSystemMode;
  onProjectMeasurementSystemChange: (system: MeasurementSystemMode) => void;
}

const DTProjectMeasurementSelector = ({
  defaultValue,
  onProjectMeasurementSystemChange,
}: MeasurementSelectorProps): JSX.Element => {
  const [selectedSystem, selectSystem] =
    useState<MeasurementSystemMode>(defaultValue);

  const onMeasurementChange = (_: ChangeEvent, value: string): void => {
    if (
      value === MeasurementSystemMode.Metric ||
      value === MeasurementSystemMode.Imperial
    ) {
      selectSystem(value);
    }
  };

  useEffect(() => {
    onProjectMeasurementSystemChange(selectedSystem);
  }, [selectedSystem]);

  return (
    <Wrapper>
      <Typography variant="body14Regular">Measurement system</Typography>
      <RadioGroup onChange={onMeasurementChange} value={selectedSystem}>
        <RadioButton>
          <Radio color="primary" value={MeasurementSystemMode.Imperial} />
          <LabelGroup>
            <Typography component={"p"} variant="body12Regular">
              Imperial
            </Typography>
            <Typography component={"span"} variant="body12Regular">
              (Feet/Inches)
            </Typography>
          </LabelGroup>
        </RadioButton>
        <RadioButton>
          <Radio value={MeasurementSystemMode.Metric} />
          <LabelGroup>
            <Typography component={"p"} variant="body12Regular">
              Metric
            </Typography>
            <Typography component={"span"} variant="body12Regular">
              (Meters/Centimeters)
            </Typography>
          </LabelGroup>
        </RadioButton>
      </RadioGroup>
    </Wrapper>
  );
};

export default DTProjectMeasurementSelector;

const Wrapper = styled(Stack)(({ theme }) => ({
  width: "100%",
  display: "flex",
  gap: "10px",
  "& .MuiFormGroup-root": {
    gap: "10px",
  },
  "& *": {
    color: theme.palette["base-label"],
  },
}));

const LabelGroup = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "5px",
  "& span": {
    color: theme.palette["base-hint"],
  },
}));

const RadioButton = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  gap: "8px",

  "& .MuiRadio-root": {
    padding: "0px ",
  },
}));

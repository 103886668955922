import { MathUtils } from "three";

import { EntityTypeEnum } from "../context/ProjectContext/ProjectEntitesTypes";

const TowerSideData = [
  {
    index: "1",
    entityName: "Side-1",
    EntityType: EntityTypeEnum.TowerSide,
    angleInRad: MathUtils.degToRad(90),
  },
  {
    index: "2",
    entityName: "Side-2",
    EntityType: EntityTypeEnum.TowerSide,
    angleInRad: MathUtils.degToRad(-180),
  },
  {
    index: "3",
    entityName: "Side-3",
    EntityType: EntityTypeEnum.TowerSide,
    angleInRad: MathUtils.degToRad(-90),
  },
  {
    index: "4",
    entityName: "Side-4",
    EntityType: EntityTypeEnum.TowerSide,
    angleInRad: MathUtils.degToRad(0),
  },
];

export default TowerSideData;

import { Box, Theme, useTheme } from "@mui/material";
import { Autocomplete, GoogleMap, MarkerF } from "@react-google-maps/api";
import styled from "@emotion/styled";

import TextInput from "./input/TextInput";

import { UseLocationMapInterface } from "../../pages/dashboard/hooks/useMapLocation";

type Props = {
  onUserInteract?: () => void;
  mapLocation: UseLocationMapInterface;
};

function MapLocationInput({ mapLocation, onUserInteract = () => {} }: Props) {
  const theme = useTheme<Theme>();

  const {
    isGoogleMapLibLoaded,
    mapCenterCoordinates,
    onMapLoad,
    onMapUnmount,
    onMapCenterChanged,
    onAutocompleteLoad,
    onAutocompletePlaceChanged,
    locationInputRef,
  } = mapLocation;

  return (
    <Wrapper onMouseDown={onUserInteract}>
      {isGoogleMapLibLoaded && (
        <Autocomplete
          className="enter-location-autocomplete"
          onPlaceChanged={onAutocompletePlaceChanged}
          onLoad={onAutocompleteLoad}
        >
          <TextInput
            inputRef={locationInputRef}
            label="Location"
            placeholder="Type a place name"
          />
        </Autocomplete>
      )}
      <MapWrapper>
        {isGoogleMapLibLoaded && (
          <GoogleMap
            mapContainerClassName="map-container"
            mapContainerStyle={{
              width: "100%",
              height: "100%",
            }}
            zoom={12}
            onLoad={onMapLoad}
            onCenterChanged={onMapCenterChanged}
            onUnmount={onMapUnmount}
          >
            {mapCenterCoordinates && (
              <MarkerF
                draggable={false}
                clickable={false}
                position={mapCenterCoordinates}
                // eslint-disable-next-line new-cap
                icon={MapMarkerSVGIcon(theme)}
              />
            )}
          </GoogleMap>
        )}
      </MapWrapper>
    </Wrapper>
  );
}

export default MapLocationInput;

const Wrapper = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: "8px",
}));

const MapWrapper = styled(Box)(() => ({
  width: "100%",
  height: "160px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "4px",
  overflow: "hidden",
  marginTop: "8px",
}));

const MapMarkerSVGIcon = (theme: Theme) => {
  return {
    path: "M6.875 0C5.05227 0.00206776 3.30479 0.72706 2.01592 2.01592C0.72706 3.30479 0.00206776 5.05227 0 6.875C0 12.7578 6.25 17.2008 6.51641 17.3867C6.62149 17.4603 6.74669 17.4998 6.875 17.4998C7.00331 17.4998 7.12851 17.4603 7.23359 17.3867C7.5 17.2008 13.75 12.7578 13.75 6.875C13.7479 5.05227 13.0229 3.30479 11.7341 2.01592C10.4452 0.72706 8.69773 0.00206776 6.875 0ZM6.875 4.375C7.36945 4.375 7.8528 4.52162 8.26393 4.79633C8.67505 5.07103 8.99548 5.46148 9.1847 5.91829C9.37392 6.37511 9.42343 6.87777 9.32696 7.36273C9.2305 7.84768 8.9924 8.29314 8.64277 8.64277C8.29314 8.9924 7.84768 9.2305 7.36273 9.32696C6.87777 9.42343 6.37511 9.37392 5.91829 9.1847C5.46148 8.99548 5.07103 8.67505 4.79633 8.26393C4.52162 7.8528 4.375 7.36945 4.375 6.875C4.375 6.21196 4.63839 5.57607 5.10723 5.10723C5.57607 4.63839 6.21196 4.375 6.875 4.375Z",
    fillColor: theme.palette.blue.main,
    fillOpacity: 1,
    strokeWeight: 0,
    rotation: 0,
    scale: 1,
  };
};

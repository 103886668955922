import { FC, ReactNode } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

interface ItemActionsProps {
  name: string;
  children?: ReactNode;
}

const ItemDetailHeader: FC<ItemActionsProps> = ({
  name,
  children,
}): JSX.Element => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: " space-between",
        margin: "1em 0",
      }}
    >
      <Box>
        <Typography variant="h3">{name}</Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default ItemDetailHeader;

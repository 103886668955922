import { SvgIcon, SvgIconProps } from "@mui/material";
import { ReactElement } from "react";

export const CopyIcon = (props: SvgIconProps): ReactElement => {
  return (
    <SvgIcon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_3951_19537)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.16667 8.33366C8.70643 8.33366 8.33334 8.70675 8.33334 9.16699V16.667C8.33334 17.1272 8.70643 17.5003 9.16667 17.5003H16.6667C17.1269 17.5003 17.5 17.1272 17.5 16.667V9.16699C17.5 8.70675 17.1269 8.33366 16.6667 8.33366H9.16667ZM6.66667 9.16699C6.66667 7.78628 7.78596 6.66699 9.16667 6.66699H16.6667C18.0474 6.66699 19.1667 7.78628 19.1667 9.16699V16.667C19.1667 18.0477 18.0474 19.167 16.6667 19.167H9.16667C7.78596 19.167 6.66667 18.0477 6.66667 16.667V9.16699Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.33334 2.49967C3.11233 2.49967 2.90037 2.58747 2.74409 2.74375C2.58781 2.90003 2.50001 3.11199 2.50001 3.33301V10.833C2.50001 11.054 2.58781 11.266 2.74409 11.4223C2.90037 11.5785 3.11233 11.6663 3.33334 11.6663H4.16668C4.62691 11.6663 5.00001 12.0394 5.00001 12.4997C5.00001 12.9599 4.62691 13.333 4.16668 13.333H3.33334C2.6703 13.333 2.03442 13.0696 1.56558 12.6008C1.09674 12.1319 0.833344 11.496 0.833344 10.833V3.33301C0.833344 2.66997 1.09674 2.03408 1.56558 1.56524C2.03442 1.0964 2.6703 0.833008 3.33334 0.833008H10.8333C11.4964 0.833008 12.1323 1.0964 12.6011 1.56524C13.07 2.03408 13.3333 2.66997 13.3333 3.33301V4.16634C13.3333 4.62658 12.9602 4.99967 12.5 4.99967C12.0398 4.99967 11.6667 4.62658 11.6667 4.16634V3.33301C11.6667 3.11199 11.5789 2.90003 11.4226 2.74375C11.2663 2.58747 11.0544 2.49967 10.8333 2.49967H3.33334Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_3951_19537">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

import React from "react";
import Button, { ButtonProps } from "@mui/material/Button";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import CircularProgress from "@mui/material/CircularProgress";

interface ExportDataButtonProps extends ButtonProps {
  loading?: boolean;
}

const ExportDataButton: React.FC<ExportDataButtonProps> = ({
  loading = false,
  disableRipple = true,
  variant = "text",
  children,
  ...props
}) => {
  return (
    <Button
      sx={props.sx}
      variant={variant}
      startIcon={
        loading ? (
          <CircularProgress size={20} color="inherit" />
        ) : (
          <FileUploadOutlinedIcon />
        )
      }
      disabled={loading}
      {...props}
    >
      {loading ? "Fetching..." : children}
    </Button>
  );
};

export default ExportDataButton;

import { CSSInterpolation, ComponentsOverrides, Theme } from "@mui/material";

import { convertToPaletteColor } from "./Theme";

export const ThemeMuiChip: ComponentsOverrides<Theme>["MuiChip"] = {
  root: (): CSSInterpolation => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "4px",
    gap: "4px",
    minWidth: "64px",
    padding: "5px",

    "& .MuiChip-label": {
      padding: "0px",
    },

    "& .MuiChip-deleteIcon": {
      margin: 0,
      fontSize: "14px",
    },
  }),
  filled: ({ theme, ownerState }): CSSInterpolation => {
    const paletteColor = convertToPaletteColor(ownerState.color as string);
    const color = theme.palette[paletteColor];

    return {
      backgroundColor: color.lighter,
      color: color.main,

      "svg path": {
        fill: color.main,
      },

      "&:hover": {
        backgroundColor: color.lighter,
        color: color.light,

        "svg path": {
          fill: color.light,
        },
      },

      "&:active": {
        backgroundColor: color.lighter,
        color: color.main,

        "svg path": {
          fill: color.main,
        },
      },

      "&.Mui-disabled": {
        backgroundColor: theme.palette["grey-light"],
      },
    };
  },
  outlined: ({ theme, ownerState }): CSSInterpolation => {
    const paletteColor = convertToPaletteColor(ownerState.color as string);
    const color = theme.palette[paletteColor];

    return {
      backgroundColor: color.lighter,
      borderColor: color.main,
      color: color.main,

      "svg path": {
        fill: color.main,
      },

      "&:hover": {
        backgroundColor: color.lighter,
        borderColor: color.light,
        color: color.light,

        "& svg path": {
          fill: color.light,
        },
      },

      "&:active": {
        backgroundColor: color.lighter,
        borderColor: color.dark,
        color: color.dark,

        "& svg path": {
          fill: color.dark,
        },
      },

      "&.Mui-disabled": {
        backgroundColor: theme.palette["grey-light"],
        borderColor: "transparent",
      },
    };
  },
};

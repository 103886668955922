import { useGetDTFiles } from "./useGetDTFiles";
import {
  getModel3dFileData,
  getObject3dAssetsKeys,
  getTexturesFileData,
} from "../utils/dtProjectUtils/dtProjectUtils";

import { DTFile, DTObject } from "../../../API";
import {
  Model3dDataInterface,
  ModelAssetsInterface,
  ModelTextureDataInterface,
  ObjectDataInterface,
} from "../context/ProjectContext/ProjectEntitesTypes";

export interface UseSetObject3dModelAssetsInterface {
  getObject3dWithUpdatedModelAssets: (
    projectObjectList: ObjectDataInterface[],
    allDTObjects: DTObject[]
  ) => Promise<ObjectDataInterface[]>;
  loading: boolean;
}

export const useSetObject3dModelAssets =
  (): UseSetObject3dModelAssetsInterface => {
    const { fetchDTFiles, loading } = useGetDTFiles();

    const getUsedObjects = (
      projectObjectList: ObjectDataInterface[],
      allDTObjects: DTObject[]
    ): DTObject[] => {
      return allDTObjects.filter(dtObject =>
        projectObjectList.some(
          obj => obj.modelAssets.assetId === dtObject.objectId
        )
      );
    };

    const getModelAssetsList = async (
      usedDTObjects: DTObject[]
    ): Promise<ModelAssetsInterface[]> => {
      const assets3dFilesKeys = usedDTObjects
        .map(objectData => getObject3dAssetsKeys(objectData.filesInfo))
        .flat();

      const assets3dFiles = await fetchDTFiles(assets3dFilesKeys);

      return usedDTObjects.map(objectData => {
        const objectAssetsFiles = assets3dFiles.filter(({ key }: DTFile) =>
          key?.includes(objectData.objectId)
        );

        const textures = getTexturesFileData(
          objectAssetsFiles
        ) as ModelTextureDataInterface[];

        const model3d = getModel3dFileData(
          objectAssetsFiles
        ) as Model3dDataInterface;

        return {
          assetId: objectData.objectId,
          model3d,
          textures,
        };
      });
    };

    const updateObject3dModelAssets = (
      projectObjectList: ObjectDataInterface[],
      modelAssetsList: ModelAssetsInterface[]
    ): ObjectDataInterface[] => {
      return projectObjectList.map(data => {
        const modelAssets = modelAssetsList.find(
          item => item.assetId === data.modelAssets.assetId
        ) as ModelAssetsInterface;

        return {
          ...data,
          modelAssets,
        };
      });
    };

    const getObject3dWithUpdatedModelAssets = async (
      projectObjectList: ObjectDataInterface[],
      allDTObjects: DTObject[]
    ): Promise<ObjectDataInterface[]> => {
      if (!projectObjectList.length) {
        return projectObjectList;
      }

      const usedDTObjects = getUsedObjects(projectObjectList, allDTObjects);
      const modelAssetsList = await getModelAssetsList(usedDTObjects);

      const data = updateObject3dModelAssets(
        projectObjectList,
        modelAssetsList
      );

      return data;
    };

    return { getObject3dWithUpdatedModelAssets, loading };
  };

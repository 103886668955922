import { createContext } from "react";

import { UseUploadFileStateInterface } from "../../../../common/hooks/useUploadFileState";
import { TextStateInterface } from "../../../../common/hooks/useTextInputState";
import { MapCenterCoordinateInterface } from "../../hooks/useMapLocation";
import { MeasurementSystemMode } from "../../../../common/enums";

export enum ProjectCreatingType {
  Location = 1,
  GeojsonLayer = 2,
  KMZLayer = 3,
}

export enum ProjectFormViewType {
  ViaMap = 1,
  ViaFile = 2,
}

export interface CreateDTProjectContextInterface {
  isOpenModal: boolean;
  openModal: () => void;
  closeModal: () => void;

  formViewType: ProjectFormViewType;
  handleSetFormViewType: (type: ProjectFormViewType) => void;

  creatingType: ProjectCreatingType;
  handleSetCreatingType: (type: ProjectCreatingType) => void;

  projectNameState: TextStateInterface;
  onProjectNameInputChange: (value: string) => void;

  geojsonLayerFileState: UseUploadFileStateInterface;
  onProjectGeojsonLayerFileInputChange: (file: File) => void;

  kmzLayerFileState: UseUploadFileStateInterface;
  onProjectKMZLayerFileInputChange: (file: File) => void;

  onProjectLocationCoordinatesChange: (
    coordinates: MapCenterCoordinateInterface
  ) => void;

  measurementSystem: MeasurementSystemMode;
  onProjectMeasurementSystemChange: (system: MeasurementSystemMode) => void;

  onCreateProjectButton: () => Promise<void>;

  isCreatingDisabled: boolean;
  isCreatingLoading: boolean;
}

const CreateDTProjectContext =
  createContext<CreateDTProjectContextInterface | null>(null);

export default CreateDTProjectContext;

import { valid, isMultiLineString } from "geojson-validation";

import { loadGeoJson } from "../threeWebGl/SceneUtils/AssetLoaders";
import { getMeshMultilinestring } from "../threeWebGl/SceneUtils/MultiLineStringUtils";
import { readFileAsDataURL } from "../utils";

interface ValidationResponseInterface {
  error: boolean;
  errorMessage: string;
}

interface MultilinestringMeshInterface {
  type: string;
  coordinates: number[][][];
}

const checkIsGeojsonDataValid = (
  multilinestringMeshArray: MultilinestringMeshInterface[]
): boolean => {
  if (multilinestringMeshArray.length <= 0) {
    return false;
  }

  const isGeojsonDataValid = multilinestringMeshArray.every(mulSrting => {
    const isValid = valid(mulSrting, true);
    const isValidMultiLineString = isMultiLineString(mulSrting, true);

    return [...isValid, ...isValidMultiLineString].length === 0;
  });

  return isGeojsonDataValid;
};

const chackIsGeojsonCoordinatesValid = (
  multilinestringMeshArray: MultilinestringMeshInterface[]
): boolean => {
  const isValidLatitude = (latitude: number): boolean =>
    typeof latitude === "number" && latitude >= -90 && latitude <= 90;

  const isValidLongitude = (longitude: number): boolean =>
    typeof longitude === "number" && longitude >= -180 && longitude <= 180;

  const isCoordinatesValid = multilinestringMeshArray.every(
    ({ coordinates }) => {
      return coordinates.every(line =>
        line.every(point => {
          const [longitude, latitude] = point;

          return isValidLatitude(latitude) && isValidLongitude(longitude);
        })
      );
    }
  );

  return isCoordinatesValid;
};

export const geojsonValidation = async (
  file: File
): Promise<ValidationResponseInterface> => {
  const fileData = await readFileAsDataURL(file);
  const topojsonData = await loadGeoJson(fileData);
  const multilinestringMeshArray = getMeshMultilinestring(topojsonData);

  if (!checkIsGeojsonDataValid(multilinestringMeshArray)) {
    return {
      error: true,
      errorMessage: "Invalid geo data",
    };
  }

  if (!chackIsGeojsonCoordinatesValid(multilinestringMeshArray)) {
    return {
      error: true,
      errorMessage:
        "Invalid coordinates. Latitude must be between -90, 90 degrees and Longitude -180, 180 degrees.",
    };
  }

  return { error: false, errorMessage: "" };
};

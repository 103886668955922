import { useState } from "react";

import {
  DTObjectsFilterQueryInterface,
  DTObjectsGroupQueryInterface,
  DTObjectsLibraryGroupTypeEnum,
  DTObjectsLibraryType,
  DTObjectsQueriesInterface,
  DTObjectsSearchQueryInterface,
  SearchFilterGroupObjectsContext,
} from "./SearchFilterGroupObjectsContext";

import { DTObject } from "../../../../../API";

const DEFAULT_QUERIES = {
  filterQuery: {
    type: [],
    manufacturer: [],
    library: [],
  },
  searchQuery: {
    value: "",
  },
  groupQuery: {
    value: DTObjectsLibraryGroupTypeEnum.Name,
  },
};

export const SearchFilterGroupObjectsContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const [queries, setQueries] =
    useState<DTObjectsQueriesInterface>(DEFAULT_QUERIES);

  const sortDataByName = (data: DTObject[]) =>
    data.sort((a, b) => a.objectName.localeCompare(b.objectName));

  const sortDataByLibrary = (data: DTObject[]) =>
    data.sort((a, b) => +b.isCustomerObject - +a.isCustomerObject);

  const sortDataByType = (data: DTObject[]) =>
    data.sort((a, b) => a.objectType.localeCompare(b.objectType));

  const sortDataByManufacturers = (data: DTObject[]) =>
    data.sort((a, b) =>
      a.objectManufacturerName.localeCompare(b.objectManufacturerName)
    );

  const filterDataByType = (data: DTObject[]): DTObject[] =>
    data.filter(item => queries.filterQuery.type.includes(item.objectType));

  const filterDataByManufacturer = (data: DTObject[]): DTObject[] =>
    data.filter(item =>
      queries.filterQuery.manufacturer.includes(item.objectManufacturerName)
    );

  const filterDataByLibrary = (data: DTObject[]): DTObject[] =>
    data.filter(item => {
      return (
        (queries.filterQuery.library[0] === DTObjectsLibraryType.Customer &&
          item.isCustomerObject) ||
        (queries.filterQuery.library[0] === DTObjectsLibraryType.General &&
          !item.isCustomerObject)
      );
    });

  const filterDataBySearchQuery = (data: DTObject[]): DTObject[] =>
    data.filter(item =>
      item.objectName
        .toLocaleLowerCase()
        .includes(queries.searchQuery.value.toLocaleLowerCase())
    );

  const applyFilterQuery = (data: DTObject[]): DTObject[] => {
    const {
      filterQuery: { type, manufacturer, library },
    } = queries;

    const filteredByType = type.length > 0 ? filterDataByType(data) : data;

    const filteredByManufacturer =
      manufacturer.length > 0
        ? filterDataByManufacturer(filteredByType)
        : filteredByType;

    const filteredByLibrary =
      library.length <= 0 ||
      (library.includes(DTObjectsLibraryType.General) &&
        library.includes(DTObjectsLibraryType.Customer))
        ? filteredByManufacturer
        : filterDataByLibrary(filteredByManufacturer);

    return filteredByLibrary;
  };

  const applyGroupQuery = (data: DTObject[]): DTObject[] => {
    const {
      groupQuery: { value },
    } = queries;

    const sortedDataByName = sortDataByName(data);

    if (value === DTObjectsLibraryGroupTypeEnum.Library) {
      return sortDataByLibrary(data);
    } else if (value === DTObjectsLibraryGroupTypeEnum.Type) {
      return sortDataByType(data);
    } else if (value === DTObjectsLibraryGroupTypeEnum.Manufacturers) {
      return sortDataByManufacturers(data);
    }

    return sortedDataByName;
  };

  const applySearchQuery = (data: DTObject[]): DTObject[] => {
    const {
      searchQuery: { value },
    } = queries;

    return !value ? data : filterDataBySearchQuery(data);
  };

  const applyAllQueries = (data: DTObject[]): DTObject[] => {
    const groupedData = applyGroupQuery(data);
    const filteredBySearchData = applySearchQuery(groupedData);
    const filteredData = applyFilterQuery(filteredBySearchData);

    return filteredData;
  };

  const handleSetFilterQuery = (filterQuery: DTObjectsFilterQueryInterface) => {
    setQueries(prev => ({ ...prev, filterQuery }));
  };

  const handleSetGroupQuery = (groupQuery: DTObjectsGroupQueryInterface) => {
    setQueries(prev => ({ ...prev, groupQuery }));
  };

  const handleSetSearchQuery = (searchQuery: DTObjectsSearchQueryInterface) => {
    setQueries(prev => ({ ...prev, searchQuery }));
  };

  return (
    <SearchFilterGroupObjectsContext.Provider
      value={{
        queries,
        handleSetFilterQuery,
        handleSetGroupQuery,
        handleSetSearchQuery,
        applyAllQueries,
      }}
    >
      {children}
    </SearchFilterGroupObjectsContext.Provider>
  );
};
